import axios from 'axios';
import { BASE_URL_V2 } from '../configurations/url.config';

export const getReports = async (
  token: string,
  communityId: string,
  planId: string,
  startDate: string,
  endDate: string,
  // page: number, 
  // limit: number
) => {
  try {
    const response = await axios.post(
      `${BASE_URL_V2}/subscriptions/${communityId}`,
      {
        startDate: startDate,
        endDate: endDate,
        planId: planId,
        // page: page, 
        // limit: limit, 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
