import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, } from 'react-router-dom';
import { IColumn } from '../../models/table.model';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { SearchIcon1 } from '../../assets/icons';
import { ContentWrapper } from '../Layout/components/ContentWrapper';
import Loader from '../Loader/Loader';
import { NoSubscribers } from '../AlternatePages/NoSubscribers';
import { BulkInviteTable } from '../tables/BulkInviteTable';
import { InvitationsBulk } from '../../models/Invitation.model';

const columns: IColumn[] = [
  { title: 'Name', dataKey: 'firstName' },
  { title: 'Email ID', dataKey: 'emailId' },
  { title: 'Mobile No', dataKey: 'phoneNumber' },
  { title: 'Role', dataKey: 'role' },
  { title: 'Message', dataKey: 'message' },
];

export const BulkImportInvitationList = () => {
  const location = useLocation();
  const users = location.state?.users || [];

  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  let community_id = singleCommunity?._id;

  const [userInviteList, setUserInviteList] =
    useState<InvitationsBulk[]>(users);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const InvitationList = async () => {
    try {
      setIsLoading(true);
      const users = location.state?.users || [];
      if (users) {
        setUserInviteList(users);
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (community_id) {
      InvitationList();
    }
  }, [community_id]);

  const filteredUsers = userInviteList?.filter((user: InvitationsBulk) => {
    const searchString = searchQuery.toLowerCase();
    return user?.firstName.toLowerCase().includes(searchString);
  });

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.subscriberList}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack direction={'column'} sx={{ height: '100%', p: 0 }}>
        {/* filters for large screens */}
        <Stack
          direction="row"
          display={{ xs: 'none', md: 'flex' }}
          alignItems={'center'}
        >
          <Stack direction={'row'} flexGrow={1} spacing={1}>
            <Box component="form" noValidate autoComplete="off">
              <Typography
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: 600,
                  color: '#000000',
                  fontFamily: 'Montserrat',
                }}
              >
                Imported Members
              </Typography>
            </Box>
          </Stack>
          <Stack direction={'row-reverse'} flexGrow={1} spacing={2}>
            <TextField
              placeholder="Search by user"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon1 sx={{ mt: 1 }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#f9f9f9',
                  height: '37px',
                  width: { xs: '150px', md: '200px' },
                  color: '#000000',
                  fontFamily: 'Montserrat',
                  '& input::placeholder': {
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    color: '#9e9e9e',
                    opacity: 1,
                  },
                },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          display={{ xs: 'flex', md: 'none', xl: 'none', lg: 'none' }}
          sx={{ mb: '5px' }}
        >
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={6}>
              <Box component="form" noValidate autoComplete="off">
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#000000',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Imported Members
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <TextField
                placeholder="Search by user"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon1 sx={{ mt: 1 }} />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9',
                    height: '37px',
                    color: '#000000',
                    fontSize: { xs: '12px', md: '15px' },
                    fontFamily: 'Montserrat',
                  },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack sx={{ pt: '5px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <ContentWrapper>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {filteredUsers?.length === 0 ? (
                      <NoSubscribers />
                    ) : (
                      <BulkInviteTable
                        data={filteredUsers || []}
                        columns={columns}
                      />
                    )}
                  </>
                )}
              </ContentWrapper>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};
