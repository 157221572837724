import { useContext } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  createEvents,
  fetchEventById,
  fetchEventRequest,
  fetchPastEvents,
  fetchUpComingEvents,
  requestAcceptOrReject,
  updateEvent,
} from '../services/events.service';
import { IAddEventDetails } from '../models/events.models';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export const useEvents = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const getUpComingEvents = async (id: string) => {
    try {
      const response = await fetchUpComingEvents(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {}
  };


  const getPastEvents = async (id: string) => {
    try {
      const response = await fetchPastEvents(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {}
  };


  const getEventRequests = async (id: string) => {
    try {
      const response = await fetchEventRequest(getAccessToken(), id);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (error) {}
  };

  const createEvent = async (details: IAddEventDetails) => {
    try {
      const response = await createEvents(getAccessToken(), details);
      if (response?.status === 201) {
        enqueueSnackbar('Event create successfully.', { variant: 'success' });
        navigate('/events');
      } else {
        enqueueSnackbar("Couldn't create event", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't create event", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const updateEventById = async (
    details: IAddEventDetails,
    eventId: string
  ) => {
    try {
      const response = await updateEvent(getAccessToken(), details, eventId);
      if (response?.status === 200) {
        enqueueSnackbar('Event updated successfully.', {
          variant: 'success',
        });
        navigate('/events');
      } else {
        enqueueSnackbar("Couldn't updated appointment", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return { class: response };
    } catch (error) {
      enqueueSnackbar("Couldn't updated appointment", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };


   const requestAcceptAndReject = async (eventId:string, userId:string, status:string, ) => {
          try {
            const response = await requestAcceptOrReject(getAccessToken(),eventId, userId, status, );
            if(response?.status===200){
              enqueueSnackbar(`Request updated successfully.`, { variant: 'success' });
            }else {
              enqueueSnackbar("Request couldn't updated", {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
            return { class: response };
          } catch (error) {
            enqueueSnackbar("Couldn't updated appointment", {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        };


  const getEventById = async (eventId: string) => {
          try {
            const response = await fetchEventById(eventId);
            if (response.status === 200) {
              return response;
            }
            return response;
          } catch (error) {
            console.log(error, "error")
          }
        };

  return {
    getUpComingEvents,
    createEvent,
    updateEventById,
    getEventById,
    getPastEvents,
    getEventRequests,
    requestAcceptAndReject
  };
};
