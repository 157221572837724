import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid, Stack, Box, Menu, MenuItem, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from '../../Loader/Loader';
import { AlternativePage } from '../../AlternatePages/AlternativePage';
import NoClass from '../../../assets/icons/svg-icons/noPlans.png';
import { Link, useNavigate } from 'react-router-dom';
import { useAppointments } from '../../../hooks/useAppointments';
import { useSnackbar } from 'notistack';
import { ContentCopyOutlined } from '@mui/icons-material';
import { EditIcon, Share1Icon } from '../../../assets/icons';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { USER_APP_BASE_URL } from '../../../configurations/url.config';
import { useEvents } from '../../../hooks/useEvents';
import { IEvents } from '../../../models/events.models';
import dayjs from 'dayjs';
import { CalendarDays, Clock3, IndianRupee, MapPin, Users } from 'lucide-react';




const UpComingEvents = ({ searchQuery }: { searchQuery: string }) => {
  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  let community_id = singleCommunity?._id;
  const { getUpComingEvents } = useEvents();

  const [events, setEvents] = React.useState<IEvents[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedItemId, setSelectedItemId] = React.useState<string | null>(
    null
  );
  // const [status, setStatus] = React.useState('PAUSED')

  const [copied, setCopied] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const response = await getUpComingEvents(community_id);
      if (Array.isArray(response)) {
        setEvents(response);
      } else {
        setEvents([]);
      }
    } catch (err) {
      console.error('Error fetching live classes:', err);
      setEvents([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (community_id) {
      fetchEvents();
    }
  }, []);

  const navigate = useNavigate();

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    itemId: string
  ) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setSelectedItemId(itemId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const { updateAppointmentStatusById } = useAppointments();

  const handlePause = async (
    selectedItemId: string,
    communityId: string,
    status: string
  ) => {
    try {
      const response = await updateAppointmentStatusById(
        status,
        selectedItemId,
        communityId
      );
      fetchEvents();
      return response;
    } catch (error) {
      console.error('Error updating appointment status:', error);
    }
  };

  const filteredEvents = events.filter((event) =>
    event.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // console.log('filteredEvents11', filteredEvents);

  const handleCopyLink = (labelContent: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(labelContent)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
          enqueueSnackbar('Link copied successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
          enqueueSnackbar('Failed to copy link', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    } else {
      console.error('Clipboard API not supported');
      enqueueSnackbar('Clipboard API not supported', { variant: 'error' });
    }
  };

  const defaultImages =
    'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png';




  return (
    <Box
      sx={{
        position: 'relative',
        height: { xs: '100vh', md: '73vh' },
        overflow: 'auto',
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Loader />
        </Box>
      ) : events.length === 0 ? (
        <AlternativePage title="No UpComing Events" image={NoClass} />
      ) : (
        <Grid container gap={1.5} py={1}>
          {filteredEvents?.map((eventData, index) => (
            <Grid item xs={12} md={5.9} key={index}>
              <Card
                elevation={0}
                sx={{
                  borderLeft: '6px solid #D4DDED',
                  borderRight: '1px solid #D4DDED',
                  borderTop: '1px solid #D4DDED',
                  borderBottom: '1px solid #D4DDED',
                  borderRadius: '10px',
                  '&:hover': {
                    borderLeft: '6px solid #3C5AA0',
                    borderRight: '1px solid #3C5AA0',
                    borderTop: '1px solid #3C5AA0',
                    borderBottom: '1px solid #3C5AA0',
                  },
                }}
              >
                <CardContent
                  sx={{
                    p: 0,
                    '&:last-child': {
                      pb: '1px',
                    },
                  }}
                >
                  <Grid container spacing={2} sx={{ p: 1 }} alignItems="center">
                    {/* Date Section */}
                    <Grid item xs={3} textAlign="center">
                      <Typography
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          color: '#3760A9',
                        }}
                      >
                        {dayjs(eventData?.availability?.[0]?.day)
                          .format('MMM')
                          .toUpperCase()}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '25px',
                          fontWeight: '600',
                          color: '#3760A9',
                        }}
                      >
                        {dayjs(eventData?.availability?.[0]?.day).format('D')}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          color: '#3760A9',
                        }}
                      >
                        {dayjs(eventData?.availability?.[0]?.day).format(
                          'dddd'
                        )}
                      </Typography>
                    </Grid>

                    {/* Event Details */}
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: { xs: '13px', md: '14px' },
                          fontWeight: '600',
                          color: '#000000',
                        }}
                      >
                        {eventData?.title?.length > 20
                          ? eventData?.title?.slice(0, 20) + '...'
                          : eventData?.title}
                      </Typography>

                      {/* Date */}
                      <Stack direction="row" alignItems="center" gap={1}>
                        {/* <CalendarMonthOutlinedIcon sx={{ fontSize: { xs: '15px', md: '18px' }, color: '#565656' }} /> */}
                        <CalendarDays size={17} strokeWidth={1.5} color='#565656' />
                        <Typography key={index} sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656' }}>
                          {dayjs(eventData?.availability[0]?.day).format('MMM D, YYYY')}
                          {" "}   to {" "}
                          {dayjs(eventData?.availability?.[eventData?.availability?.length - 1]?.day).format('MMM D, YYYY')}
                        </Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" gap={1}>
                        {/* <AccessAlarmOutlinedIcon sx={{ fontSize: { xs: '15px', md: '18px' }, color: '#565656' }} /> */}
                        <Clock3 size={17} strokeWidth={1.5} color='#565656' />
                        <Typography sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656', textTransform: 'uppercase' }}>
                          {eventData?.availability?.[0]?.availableTimes
                            ?.map((time) => {
                              const startTime = new Date(`1970-01-01T${time.startTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                              const endTime = new Date(`1970-01-01T${time.endTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                              return `${startTime} - ${endTime}`;
                            })
                            .join(' to ')}
                        </Typography>
                      </Stack>

                      {/* Pricing */}
                      <Stack direction="row" alignItems="center" gap={1}>
                        {/* <CurrencyRupeeOutlined sx={{ fontSize: { xs: '15px', md: '18px' }, color: '#565656' }} /> */}
                        <IndianRupee size={17} strokeWidth={1.5} color='#565656' />
                        <Typography sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656' }}>
                          {/* {eventData?.pricing } */}
                          {eventData?.pricing ? `${eventData.pricing}` : "Free"}
                        </Typography>
                      </Stack>

                      {/* Location */}
                      <Stack direction="row" alignItems="center" gap={1}>
                        {/* <PinDropOutlinedIcon sx={{ fontSize: { xs: '15px', md: '18px' }, color: '#565656' }} /> */}
                        <MapPin size={17} strokeWidth={1.5} color='#565656' />
                        <Typography sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656' }}>
                          {eventData?.location?.length > 22 ? eventData?.location?.slice(0, 22) + "..." : eventData?.location}
                        </Typography>
                      </Stack>
                    </Grid>

                    {/* Event Image */}
                    <Grid item xs={3}>
                      <Link to={`/events/${eventData?._id}`}>
                        <Box sx={{ borderRadius: '10px', p: 0.5 }}>
                          <Box
                            component="img"
                            src={eventData?.coverImage?.value}
                            width="100%"
                            sx={{
                              borderRadius: '10px',
                              objectFit: 'cover',
                              height: { xs: '50px', md: '80px' },
                            }}
                          />
                        </Box>
                      </Link>
                    </Grid>
                  </Grid>

                  <Stack sx={{ backgroundColor: '#F2F2F2', p: 0.5 }}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Stack direction="row" spacing={2}>
                        {/* <Stack direction="row" alignItems="center" gap={0} >
                                                    <Person2OutlinedIcon
                                                        sx={{ fontSize: '15px', color: '#565656' }}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            fontSize: { xs: '10px', md: '12px' },
                                                            fontWeight: '400',
                                                            fontFamily: 'Montserrat',
                                                            color: '#565656'
                                                        }}
                                                    >
                                                        By {eventData?.hostedBy}
                                                    </Typography>
                                                </Stack> */}
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          {/* <SupervisorAccountOutlinedIcon
                                                        sx={{ fontSize: '15px', color: '#565656' }}
                                                    /> */}
                          <Users size={15} strokeWidth={1.5} color='#565656' />
                          <Typography
                            sx={{
                              fontSize: { xs: '10px', md: '12px' },
                              fontWeight: '400',
                              fontFamily: 'Montserrat',
                              color: '#565656'
                            }}
                          >
                            {eventData?.attendees?.length} / {eventData?.limitCapacity === 0 ? "Unlimited" : eventData?.limitCapacity}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          {/* <SupervisorAccountOutlinedIcon
                                                        sx={{ fontSize: '15px', color: '#565656' }}
                                                    /> */}
                          <Users size={15} strokeWidth={1.5} color='#565656' />
                          <Typography
                            sx={{
                              fontSize: { xs: '10px', md: '12px' },
                              fontWeight: '400',
                              fontFamily: 'Montserrat',
                              color: '#565656'
                            }}
                          >
                            {eventData?.guestApproval === true ? "Guest Approval" : 'No Guest Approval'}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          <IconButton sx={{}}
                            onClick={(event) => handleClick(event, eventData?._id!)}>
                            <MoreVertOutlinedIcon sx={{ fontSize: '20px', color: '#969696' }} />
                          </IconButton>
                          <Menu
                            id={`menu-${index}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl) && selectedItemId === eventData?._id}
                            onClose={handleClose}
                            elevation={1}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            sx={{
                              '& .MuiPaper-root': {
                                minWidth: '125px',
                                borderRadius: '10px',
                                border: '1px solid #D4DDED',

                              },
                            }}
                          >
                            <MenuItem
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                              onClick={() => {
                                navigate(`/edit-event/${eventData?._id}`)
                              }}
                              disabled={isLoading}
                            >
                              <EditIcon sx={{ fontSize: '15px', marginRight: 1 }} /> Edit
                            </MenuItem>
                            <MenuItem
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                              onClick={() => {
                                navigate(`/events/attendees/${eventData?._id}`)
                              }}
                              disabled={isLoading}
                            >
                              <PeopleOutlinedIcon sx={{ fontSize: '15px', marginRight: 1 }} /> Event Attendees
                            </MenuItem>
                            <MenuItem
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                              onClick={() => {
                                navigate(`/events/request/${eventData?._id}`)
                              }}
                              disabled={isLoading}
                            >
                              <PeopleOutlinedIcon sx={{ fontSize: '15px', marginRight: 1 }} /> Event Requests
                            </MenuItem>

                            {/* {eventData?.status === "ACTIVE" ? (
                                                            <>
                                                                <MenuItem
                                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                                                    onClick={() => handlePause(eventData?._id, community_id, "INACTIVE")}
                                                                    disabled={isLoading}
                                                                >
                                                                    <PlayArrowOutlined sx={{ fontSize: '15px', marginRight: 1 }} />  Pause
                                                                </MenuItem>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <MenuItem
                                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                                                    onClick={() => handlePause(eventData?._id, community_id, "ACTIVE")}
                                                                    disabled={isLoading}
                                                                >
                                                                    <PlayArrowOutlined sx={{ fontSize: '15px', marginRight: 1 }} />  Active
                                                                </MenuItem>
                                                            </>
                                                        )} */}


                            <MenuItem
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: '30px' } }}
                              component="a"
                              target="_blank"
                              onClick={() => handleCopyLink(`${USER_APP_BASE_URL}/event/${eventData?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${eventData?._id}`)}
                              disabled={isLoading}
                            >
                              <ContentCopyOutlined sx={{ fontSize: '13px', marginRight: 1 }} /> Copy URL
                            </MenuItem>



                            <MenuItem
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                              component="a"
                              target='_blank'
                              href={`https://wa.me/?text=${encodeURIComponent(
                                `Book your event here: ${`${USER_APP_BASE_URL}/event/${eventData?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${eventData?._id}`}`
                              )}`}
                              disabled={isLoading}
                            >
                              <Share1Icon sx={{ fontSize: '13px', marginRight: 1 }} />  Share
                            </MenuItem>

                          </Menu>

                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default UpComingEvents;
