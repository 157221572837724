import { useContext, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { Invitations } from '../models/Invitation.model';
import {
  addInvitations,
  createInvitationByCommunityId,
  deleteInvitationById,
  deleteSelectedInvitation,
  getAllCommunityInvitations,
  updateInvitationById,
  updateSelectedInvitation,
  getCommunityInvitationsForUser,
  joinToInvitedCommunity,
} from '../services/Invitations.service';
export const useInvitations = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invitations, setInvitations] = useState<Invitations[]>([]);

  const addInvites = async (usersToAdd: any, communityId: string) => {
    try {
      const response = await addInvitations(
        getAccessToken(),
        usersToAdd,
        communityId
      );

      if (response.status === 201) {
        setInvitations((prevInvitations) => [
          ...prevInvitations,
          response?.data,
        ]);
        enqueueSnackbar('New user successfully invited!', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Failed to invite new user', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }

      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't add user", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const getInviteList = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await getAllCommunityInvitations(getAccessToken(), id);
      if (response.status === 200) {
        setInvitations(response.data.data);
        return response.data;
      } else {
        enqueueSnackbar('Fetching invite list failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch {
      enqueueSnackbar('Fetching invite list failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteInviteById = async (_id: string) => {
    try {
      const response = await deleteInvitationById(getAccessToken(), _id);
      if (response.status === 200) {
        setInvitations(response.data.data);
        enqueueSnackbar('Invite deleted', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Couldn’t delete invite', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't delete invite", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const deleteSelectedInvitations = async (
    communityId: string,
    formData: FormData
  ) => {
    try {
      const response = await deleteSelectedInvitation(
        getAccessToken(),
        communityId,
        formData
      );
      if (response.status === 200) {
        setInvitations(response.data.data);
        enqueueSnackbar('Invite deleted', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Couldn’t delete invite', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't delete invite", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  const createNewInvitation = async (Id: string, formData: FormData) => {
    try {
      setIsLoading(true);
      const response = await createInvitationByCommunityId(
        getAccessToken(),
        Id,
        formData
      );

      if (response.status === 200) {
        setInvitations((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar('Invite sent', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Invite failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar('Invitation failed to send', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  const joinInvitation = async (formData: FormData) => {
    try {
      setIsLoading(true);
      const response = await joinToInvitedCommunity(
        getAccessToken(),

        formData
      );

      if (response.status === 200) {
        setInvitations((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar('Joined successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Couldn't join community", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't join community", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const updateSingleInvitations = async (Id: string, formData: FormData) => {
    try {
      const response = await updateInvitationById(
        getAccessToken(),
        Id,
        formData
      );
      if (response.status === 200) {
        // Find and update the post in the posts state
        setInvitations((prevInvitation) =>
          prevInvitation.map((Invitation) =>
            Invitation._id === Id ? response.data.data : Id
          )
        );
        enqueueSnackbar('Invite updated', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Couldn't update invitation", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar(' Invitation update failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const updateSelectedInvitations = async (
    communityId: string,
    formData: FormData
  ) => {
    try {
      const response = await updateSelectedInvitation(
        getAccessToken(),
        communityId,
        formData
      );
      if (response.status === 200) {
        setInvitations((prevInvitation) =>
          prevInvitation.map((Invitation) =>
            Invitation._id === communityId ? response.data.data : communityId
          )
        );
        enqueueSnackbar('Invitation updated!', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Couldn't update invitation", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't update invitation", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  const getInvitesForUser = async () => {
    setIsLoading(true);
    const response = await getCommunityInvitationsForUser(getAccessToken());
    // console.log(response)
    if (response) {
      setIsLoading(false);

      return response.data;
    } else {
      enqueueSnackbar('Fetching invite list failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return [];
    }
  };
  return {
    isLoading,
    invitations,
    getInviteList,
    deleteInviteById,
    deleteSelectedInvitations,
    updateSingleInvitations,
    updateSelectedInvitations,
    createNewInvitation,
    addInvites,
    getInvitesForUser,
    joinInvitation,
  };
};
