import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { IAttendees } from "../../../models/appointments.models";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../../utils/constants";
import { Box, Card, CardContent, Grid, Link, Stack, Typography } from "@mui/material";
import { ContentWrapper } from "../../Layout/components/ContentWrapper";
import Loader from "../../Loader/Loader";
import { IColumn } from "../../../models/table.model";
import { Person2Outlined, PinDropOutlined, SupervisedUserCircleOutlined } from "@mui/icons-material";
import { EventAttendeesTable } from "../../tables/EventsAttendessTable";
import { useEvents } from "../../../hooks/useEvents";
import { IEvents } from "../../../models/events.models";
import dayjs from "dayjs";
import { NoEventAttendees } from "../../AlternatePages/NoEventAttendees";
import BreadcrumbDouble from "../../AlternatePages/BreadCumsDouble";
import { CalendarDays, Clock, Clock3, IndianRupee, MapPin, User2 } from "lucide-react";


const columns: IColumn[] = [
    { title: 'Name', dataKey: 'name' },
    { title: 'Email', dataKey: 'email' },
    { title: 'Phone', dataKey: 'phone' },
    { title: 'Price', dataKey: 'price' },
    { title: 'Status', dataKey: 'status' },
];



const EventAttendees = () => {


    const { id } = useParams();

    // const [appointments, setAppointments] = React.useState<IAttendees>();

    const [isLoading, setIsLoading] = React.useState(false);

    const { getEventById } = useEvents();

    const [eventDetails, setEventDetails] = React.useState<IEvents>();
    const [attendees, setAttendees] = React.useState<IAttendees[]>([]);

    const eventAttendeesList = async () => {
        setIsLoading(true);
        try {
            const response = await getEventById(id || '');
            console.log(response, "response");
            setEventDetails(response);
            setAttendees(response?.attendees)
        } catch (err) {
            console.error('Error fetching live classes:', err);

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        eventAttendeesList();
    }, []);

    const defaultImages = 'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png'



    return (
        <>
            <Helmet>
                <title>{metaConfig.title.appointments}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>

            <BreadcrumbDouble
                items={[
                    { label: 'Events', path: '/events' },
                    { label: 'Event Attendees' }
                ]}
                actionButton={{ label: 'Events', path: '/events' }}
            />

            <Grid container gap={1.5} py={0.5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <Grid item xs={12} md={12}>
                    <Card elevation={0}
                        sx={{
                            border: '1px solid #D4DDED',
                            borderRadius: '8px'
                        }}
                    >
                        <CardContent
                            sx={{
                                p: 0,
                                "&:last-child": {
                                    pb: '1px',
                                },
                            }}>

                            <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1} sx={{ p: 1 }}>
                                <Stack flexDirection={'row'} gap={{ xs: 2, md: 8 }} alignItems={'center'} p={1}>
                                    <Stack spacing={0.5} textAlign={'center'}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: '#3760A9',
                                            }}
                                        >
                                            {dayjs(eventDetails?.availability?.[0]?.day).format('MMM').toUpperCase()}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '25px',
                                                fontWeight: '600',
                                                color: '#3760A9',
                                            }}
                                        >
                                            {dayjs(eventDetails?.availability?.[0]?.day).format('DD').toUpperCase()}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: '#3760A9',
                                            }}
                                        >
                                            {dayjs(eventDetails?.availability?.[0]?.day).format('dddd').toUpperCase()}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={0.3}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: { xs: '13px', md: '14px' },
                                                fontWeight: '600',
                                                color: '#000000',
                                            }}
                                        >
                                            {eventDetails?.title}
                                        </Typography>

                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <CalendarDays size={17} strokeWidth={1.5} color='#565656' />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '9px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                {dayjs(eventDetails?.availability[0]?.day).format('MMM D, YYYY')}
                                                {" "}   to {" "}
                                                {dayjs(eventDetails?.availability?.[eventDetails?.availability?.length - 1]?.day).format('MMM D, YYYY')}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <Clock3 size={17} strokeWidth={1.5} color='#565656' />
                                            <Typography sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656', textTransform: 'uppercase' }}>

                                                {
                                                    eventDetails?.availability?.[0]?.availableTimes
                                                        ?.map((time) => {
                                                            const startTime = new Date(`1970-01-01T${time.startTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                            const endTime = new Date(`1970-01-01T${time.endTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                            return `${startTime} - ${endTime}`;
                                                        })
                                                        .join(' to ')
                                                }
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <IndianRupee size={17} strokeWidth={1.5} color='#565656' />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '9px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                {eventDetails?.pricing ? `${eventDetails.pricing}` : "Free"}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <PinDropOutlined
                                                sx={{ fontSize: '18px', color: '#565656' }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '9px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >

                                                {eventDetails?.location && eventDetails?.location?.length > 20
                                                    ? `${eventDetails?.location.slice(0, 17)}...`
                                                    : eventDetails?.location || ""}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Stack sx={{ backgroundColor: '#F0F9FF', borderRadius: '10px', padding: { xs: 0.5, md: 1 } }}>
                                    <Box
                                        component={'img'}
                                        src={eventDetails?.coverImage?.value
                                            ? `${eventDetails.coverImage.value}`
                                            : `${defaultImages}`}
                                        width="100%"
                                        sx={{ borderRadius: '10px', objectFit: 'cover', height: { xs: '50px', md: '100px' } }} />

                                </Stack>
                            </Stack>

                            <Stack sx={{ backgroundColor: '#F2F2F2', p: 2 }}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Stack direction="row" spacing={2}>
                                        <Stack direction="row" alignItems="center" gap={0} >
                                            <Person2Outlined
                                                sx={{ fontSize: '15px', color: '#565656' }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '10px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                By {eventDetails?.hostedBy}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={0.5}>
                                            <SupervisedUserCircleOutlined
                                                sx={{ fontSize: '15px', color: '#565656' }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '10px', md: '12px' },
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    color: '#565656'
                                                }}
                                            >
                                                {eventDetails?.attendees?.length} / {eventDetails?.limitCapacity || 0}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Stack sx={{ display: { xs: 'block', md: 'none' } }}>
                <Card
                    elevation={0}
                    sx={{
                        borderRadius: 3,
                        maxWidth: 400,
                        width: '100%',
                        m: 'auto',
                        overflow: 'hidden',
                        background: '#fff',
                        mt: 1
                    }}
                >
                    <Box
                        component="img"
                        src={eventDetails?.coverImage?.value
                            ? `${eventDetails.coverImage.value}`
                            : `${defaultImages}`}
                        alt="Event"
                        sx={{
                            width: '100%',
                            height: 160,
                            p: 1.5,
                            objectFit: 'cover',
                            borderRadius: '20px'
                        }}
                    />

                    <CardContent sx={{}}>
                        <Typography
                            sx={{
                                fontFamily: 'Montserrat',
                                fontSize: { xs: '13px', md: '14px' },
                                fontWeight: '600',
                                color: '#000000',
                                mb: 1,
                            }}
                        >
                            {eventDetails?.title && eventDetails.title.length > 20
                                ? `${eventDetails.title.slice(0, 17)}...`
                                : eventDetails?.title || ""}
                        </Typography>

                        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                            <CalendarDays size={20} color="#969696" strokeWidth={1.5} />
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '12px' },
                                    fontWeight: '400',
                                    fontFamily: 'Montserrat',
                                    color: '#969696'
                                }}
                            >
                                {dayjs(eventDetails?.availability[0]?.day).format('MMM D, YYYY')}
                                {" "}   to {" "}
                                {dayjs(eventDetails?.availability?.[eventDetails?.availability?.length - 1]?.day).format('MMM D, YYYY')}
                            </Typography>
                        </Stack>

                        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                            <Clock size={20} color="#969696" strokeWidth={1.5} />
                            <Typography sx={{ fontSize: { xs: '12px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#969696', textTransform: 'uppercase' }}>

                                {
                                    eventDetails?.availability?.[0]?.availableTimes
                                        ?.map((time) => {
                                            const startTime = new Date(`1970-01-01T${time.startTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                            const endTime = new Date(`1970-01-01T${time.endTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                            return `${startTime} - ${endTime}`;
                                        })
                                        .join(' to ')
                                }
                            </Typography>
                        </Stack>

                        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                            <IndianRupee size={20} color="#969696" strokeWidth={1.5} />
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '12px' },
                                    fontWeight: '400',
                                    fontFamily: 'Montserrat',
                                    color: '#969696'
                                }}
                            >
                                {eventDetails?.pricing ? `${eventDetails.pricing}` : "Free"}
                            </Typography>
                        </Stack>

                        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                            <MapPin size={20} color="#969696" strokeWidth={1.5} />
                            <Link
                                href="https://maps.app.goo.gl/EPtg18"
                                target="_blank"
                                rel="noopener noreferrer"

                                color="text.secondary"
                                underline="hover"
                                sx={{
                                    fontSize: { xs: '12px', md: '12px' },
                                    fontWeight: '400',
                                    fontFamily: 'Montserrat',
                                    color: '#969696',
                                    wordBreak: 'break-word'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: { xs: '12px', md: '12px' },
                                        fontWeight: '400',
                                        fontFamily: 'Montserrat',
                                        color: '#969696'
                                    }}
                                >

                                    {eventDetails?.location && eventDetails?.location?.length > 20
                                        ? `${eventDetails?.location.slice(0, 17)}...`
                                        : eventDetails?.location || ""}
                                </Typography>
                            </Link>
                        </Stack>

                        <Stack direction="row" spacing={1} alignItems="center" mt={2}>
                            <User2 size={20} color="#969696" strokeWidth={1.5} />
                            <Typography
                                sx={{
                                    fontSize: { xs: '12px', md: '12px' },
                                    fontWeight: '400',
                                    fontFamily: 'Montserrat',
                                    color: '#969696'
                                }}
                            >
                                By {eventDetails?.hostedBy}
                            </Typography>
                        </Stack>
                    </CardContent>

                </Card>
            </Stack>


            <Stack sx={{ pt: '5px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <ContentWrapper>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    {(!attendees || attendees.length === 0) ? (
                                        <NoEventAttendees />
                                    ) : (
                                        <EventAttendeesTable
                                            eventDetails={eventDetails || undefined}
                                            data={attendees || []}
                                            columns={columns}
                                            eventAttendeesList={eventAttendeesList}
                                        />
                                    )}
                                </>
                            )}
                        </ContentWrapper>
                    </Grid>
                </Grid>
            </Stack>

        </>
    )
}
export default EventAttendees;