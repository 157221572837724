import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Card,
  CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
} from '@mui/material';
import CustomImageUploader from '../ImageUploader/CustomImageUploader';
import { ImageData } from '../ImageUploader/CustomImageUploader';
import { useCommunity } from '../../hooks/useCommunity';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IService,
  ITeam,
  IUpdateCommunity,
  SocialLink,
} from './CreateCommunityPage2';
import store from '../../store';
import { DeleteIcon } from '../../assets/icons';
import { useSnackbar } from 'notistack';
import { Carousel } from 'react-responsive-carousel';
import { Close } from '@mui/icons-material';
import { loadSelectedCommunity } from '../../store/slices/CommunitySlice';
import { useDispatch } from 'react-redux';
import { useFileHandler } from '../../hooks/useFileHandler';

export const BuildCommunity = () => {
  const navigate = useNavigate();
  const { communityId } = useParams();
  const communityState = store.getState();
  const currentCommunity = communityState?.selectedCommunity?.selectedCommunity;

  // console.log(currentCommunity, "currentCommunity");

  const { buildCommunityDetails, deleteGalleryInBuild, getCommunityById } =
    useCommunity();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<any>();
  const { uploadfile, updateUploadfile } = useFileHandler();
  useEffect(() => {
    window.addEventListener(
      'dragover',
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      'drop',
      function (e) {
        e.preventDefault();
      },
      false
    );
  }, []);

  // UPLOAD LOGO LOGIC'S
  //const [logoImage, setLogoImage] = useState<ImageData | null>(null);
  const [logoSingleImage, setLogoSingleImage] = useState<File | null>(null);
  const [bannerSingleImage, setBannerSingleImage] = useState<File | null>(null);
  const [galleryImage, setGalleryImage] = useState<FileList | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceImage, setServiceImage] = useState('');
  const [specificTeamIndex, setspecificTeamIndex] = useState('');

  const [teamImage, setTeamImage] = useState('');
  const [specificServiceIndex, setspecificServiceIndex] = useState('');
  //social network
  const [socialNetworkData, setSocialNetworkData] = useState({
    facebook: '',
    instagram: '',
    youtube: '',
    linkedin: '',
    twitter: '',
  });

  //social network
  const [staticSocialTypes] = useState([
    {
      type: 'facebook',
      label: 'Facebook',
      value: '',
    },
    {
      type: 'twitter',
      label: 'Twitter',
      value: '',
    },
    {
      type: 'instagram',
      label: 'Instagram',
      value: '',
    },
    {
      type: 'linkedin',
      label: 'Linkedin',
      value: '',
    },
    {
      type: 'youtube',
      label: 'YouTube',
      value: '',
    },
  ]);
  const getSocialLinks = (links: any) => {
    const mergedArray = staticSocialTypes.map((obj1: SocialLink) => ({
      ...obj1,
      ...links?.find((obj2: SocialLink) => obj2.type === obj1.type),
    }));
    return mergedArray;
  };

  const [buildData, setBuildData] = useState<IUpdateCommunity>({
    zipCode: 0,
    fullAddress: '',
    description: '',
    vision: '',
    mission: '',
    adminMessage: '',
    directorMessage: '',
    phoneNumber: 0,
    email: '',
    location: '',
    mobileNumber: 0,
    logo: '',
    banner: '',
    gallery: [],
    socialLinks: getSocialLinks(currentCommunity?.socialLinks),
    services: currentCommunity?.services,
    status: currentCommunity?.status,
    community: '',
    teams: currentCommunity?.teams || [],
    faq: currentCommunity?.faq,
  });

  // useEffect(() => {
  //   if (communityId) {
  //     const selectedCommunity = getCommunityId(communityId);
  //     if (selectedCommunity) {
  //       setBuildData({
  //         zipCode: selectedCommunity?.zipCode ?? 0,
  //         fullAddress: selectedCommunity?.fullAddress ?? "",
  //         description: selectedCommunity?.description ?? "",
  //         vision: selectedCommunity?.vision ?? "",
  //         mission: selectedCommunity?.mission ?? "",
  //         adminMessage: selectedCommunity?.adminMessage ?? "",
  //         directorMessage: selectedCommunity?.directorMessage ?? "",
  //         phoneNumber: selectedCommunity?.phoneNumber ?? 0,
  //         email: selectedCommunity?.email ?? "",
  //         location: selectedCommunity?.location ?? "",
  //         mobileNumber: selectedCommunity?.mobileNumber ?? 0,
  //         logo: selectedCommunity?.logo as string,
  //         banner: selectedCommunity?.banner as string,
  //         gallery: selectedCommunity?.gallery ?? [""],
  //         socialLinks: getSocialLinks(selectedCommunity?.socialLinks),
  //         services: selectedCommunity?.services ?? [],
  //         status: selectedCommunity?.status ?? "",
  //         community: selectedCommunity?.name,
  //         team: selectedCommunity?.team ?? [],
  //       });
  //     }
  //   }
  // }, [communityId, communities]);

  useEffect(() => {
    const fetchCommunityData = async () => {
      try {
        const response = await getCommunityById(communityId ?? '');
        if (response && response.status === 200) {
          dispatch(loadSelectedCommunity(response.data));
          const selectedCommunity = response.data;
          // Update your buildData state using the fetched community data
          setBuildData({
            zipCode: selectedCommunity.zipCode || '',
            fullAddress: selectedCommunity.fullAddress || '',
            description: selectedCommunity.description || '',
            vision: selectedCommunity.vision || '',
            mission: selectedCommunity.mission || '',
            adminMessage: selectedCommunity.adminMessage || '',
            directorMessage: selectedCommunity.directorMessage || '',
            phoneNumber: selectedCommunity.phoneNumber || '',
            email: selectedCommunity.email || '',
            location: selectedCommunity.location || '',
            mobileNumber: selectedCommunity.mobileNumber || '',
            logo: selectedCommunity.logo || '',
            banner: selectedCommunity.banner || '',
            gallery: selectedCommunity.gallery || [],
            socialLinks: getSocialLinks(selectedCommunity.socialLinks),
            services: selectedCommunity.services || [],
            status: selectedCommunity.status || '',
            community: selectedCommunity.name || '',
            teams: selectedCommunity.teams || [],
            faq: selectedCommunity.faq,
          });
        } else {
          enqueueSnackbar('Fetching selected community failed', {
            variant: 'error',
          });
        }
      } catch (error) {
        console.error('API call failed:', error);
      }
    };

    if (communityId) {
      fetchCommunityData();
    }
    // eslint-disable-next-line
  }, [communityId]);

  const handleSingleImage = (
    imageFile: File | null,
    imageData: ImageData | null
  ) => {
    // setSingleImage(imageData);
    if (imageData) {
      imageData.name = imageData?.file?.name ?? '';
      //setLogoImage(imageData)
      setLogoSingleImage(imageData?.file);
    }
  };

  const handleBannerImage = (
    imageFile: File | null,
    imageData: ImageData | null
  ) => {
    if (imageData) {
      imageData.name = imageData?.file?.name ?? '';
      //setBannerImage(imageData);
      setBannerSingleImage(imageData?.file);
    }
  };
  const handleMultipleImages = (files: FileList | null) => {
    setGalleryImage(files);
  };
  const dispatch = useDispatch();

  const handlePublish = async () => {
    const {
      services: existingServices,
      teams: existingTeams,
      faq: existingFaqs,
      socialLinks,
      ...myCommunityObject
    } = buildData;

    myCommunityObject.status = 'PUBLISHED';

    // SERVICES

    const allServices = Array.isArray(existingServices)
      ? [...existingServices]
      : [];

    // Check if newService fields are non-empty
    if (newService.title && newService.description) {
      allServices?.push(newService);
    }

    const formattedServices = allServices?.map((service: any, i) => ({
      _id: service._id,
      title: service.title,
      description: service.description,
      status: service?.status,
      images: service.images || [],
    }));

    // TEAMS

    const allTeams = Array.isArray(existingTeams) ? [...existingTeams] : [];

    // Check if newService fields are non-empty
    if (
      newTeam.name &&
      newTeam.designation &&
      newTeam.experience &&
      newTeam.description
    ) {
      allTeams?.push(newTeam);
    }

    const formattedTeams = allTeams?.map((team: any, i) => ({
      _id: team._id,
      name: team.name,
      designation: team.designation,
      experience: team.experience,
      description: team.description,
      ...(team.avatar && { avatar: team.avatar }),
      //  avatar: team.images || [],
    }));

    // FAQ's

    const allFaqs = Array.isArray(existingFaqs) ? [...existingFaqs] : [];

    // Check if newFAQ fields are non-empty
    if (newFaq.question && newFaq.answer) {
      allFaqs?.push(newFaq);
    }

    const formattedFaqs = allFaqs?.map((faq: any, i) => ({
      _id: faq._id,
      question: faq.question,
      answer: faq.answer,
    }));

    const formData = {
      community: myCommunityObject,
      services: formattedServices,
      teams: formattedTeams,
      faq: formattedFaqs,
      socialLinks: socialLinks || [],
      // gallery:galleryImage
    };

    try {
      setIsLoading(true);
      const responseData = await buildCommunityDetails(
        communityId as string,
        formData,
        logoSingleImage,
        bannerSingleImage,
        galleryImage
      );
      // console.log(responseData, "responseData");
      if (responseData && responseData.status === 200) {
        const response = await getCommunityById(communityId ?? '');
        dispatch(loadSelectedCommunity(response?.data));
        navigate(`/get-started/${communityId}`);
        enqueueSnackbar('Community published successfully', {
          variant: 'success',
        });
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    const {
      services: existingServices,
      teams: existingTeams,
      faq: existingFaqs,
      socialLinks,
      ...myCommunityObject
    } = buildData;
    myCommunityObject.status = 'CREATED';

    // SERVICES
    const allServices = Array.isArray(existingServices)
      ? [...existingServices]
      : [];
    if (newService.title && newService.description) {
      allServices?.push(newService);
    }
    const formattedServices = allServices?.map((service: any, i) => ({
      _id: service._id,
      title: service.title,
      description: service.description,
      status: service?.status,
      images: service.images || [],
    }));

    // TEAMS
    const allTeams = Array.isArray(existingTeams) ? [...existingTeams] : [];
    if (
      newTeam.name &&
      newTeam.designation &&
      newTeam.experience &&
      newTeam.description
    ) {
      allTeams?.push(newTeam);
    }

    const formattedTeams = allTeams?.map((team: any, i) => ({
      _id: team._id,
      name: team.name,
      designation: team.designation,
      experience: team.experience,
      description: team.description,
      ...(team.avatar && { avatar: team?.avatar }),
      //  avatar: team.images || [],
    }));

    // FAQ's
    const allFaqs = Array.isArray(existingFaqs) ? [...existingFaqs] : [];
    if (newFaq.question && newFaq.answer) {
      allFaqs?.push(newFaq);
    }
    const formattedFaqs = allFaqs?.map((faq: any, i) => ({
      _id: faq._id,
      question: faq.question,
      answer: faq.answer,
    }));

    const formData = {
      community: myCommunityObject,
      services: formattedServices,
      teams: formattedTeams,
      faq: formattedFaqs,
      socialLinks: socialLinks || [],
      // gallery:galleryImage
    };

    try {
      setLoading(true);
      const responseData = await buildCommunityDetails(
        communityId as string,
        formData,
        logoSingleImage,
        bannerSingleImage,
        galleryImage
      );

      if (responseData && responseData.status === 200) {
        const response = await getCommunityById(communityId ?? '');
        dispatch(loadSelectedCommunity(response?.data));
        navigate(`/get-started/${communityId}`);
        enqueueSnackbar('Community saved successfully', {
          variant: 'success',
        });
      }
    } catch (error) {
      console.error('API call failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageDeleted = () => {
    //setSingleImage(null);
    //setMultipleImages([]);
  };

  const handleSocialNetworkChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    _id: string
  ) => {
    event.preventDefault();
    const updatedData = {
      ...socialNetworkData,
      [fieldName]: event.target.value,
    };
    setSocialNetworkData(updatedData);

    buildData.socialLinks?.map((e) => {
      if (e.type === fieldName) {
        e.value = event.target.value;
      }
      return e;
    });
  };
  const [fileId, setFileId] = useState('');

  //SERVICE OFFERING LOGIC
  const [newService, setNewService] = useState<IService>({
    title: '',
    description: '',
    status: 'ACTIVE',
    images: [],
  });
  const [hoveredServiceIndex, setHoveredServiceIndex] = useState<number | null>(
    null
  );

  const handleInputChange = (field: string, value: string) => {
    setNewService((prevService) => ({ ...prevService, [field]: value }));
  };

  const handleServiceChange = (index: number, field: string, value: string) => {
    const updatedServices = buildData.services
      ? buildData.services.map((service, i) =>
        i === index ? { ...service, [field]: value } : service
      )
      : [];
    setBuildData((prevData) => ({ ...prevData, services: updatedServices }));
  };

  const addNewService = () => {
    if (newService.title && newService.description) {
      const updatedServices = buildData.services
        ? [...buildData.services, newService]
        : [newService];
      setBuildData((prevData) => ({ ...prevData, services: updatedServices }));
      setNewService({
        title: '',
        description: '',
        status: 'ACTIVE',
        images: [],
      });
    }
  };
  const handleServiceFormHover = (index: number | null) => {
    setHoveredServiceIndex(index);
  };
  const deleteSingleServiceForm = (index: number) => {
    const updatedServices = buildData.services ? [...buildData.services] : [];
    updatedServices.splice(index, 1);
    setBuildData((prevData) => ({ ...prevData, services: updatedServices }));
    setHoveredServiceIndex(null);
  };

  //OURTEAM OFFERING LOGIC
  const [newTeam, setNewTeam] = useState<ITeam>({
    name: '',
    designation: '',
    experience: '',
    description: '',
    avatar: '',
  });
  const [hoveredTeamIndex, setHoveredTeamIndex] = useState<number | null>(null);

  const handleInputTeamChange = (field: string, value: string) => {
    setNewTeam((prevTeam) => ({ ...prevTeam, [field]: value }));
  };
  const handleTeamChange = (index: number, field: string, value: string) => {
    const updatedTeams = buildData.teams
      ? buildData.teams.map((team, i) =>
        i === index ? { ...team, [field]: value } : team
      )
      : [];
    setBuildData((prevData) => ({ ...prevData, teams: updatedTeams }));
    // setLogoSingleImage(null);
  };

  const addNewTeam = () => {
    if (
      newTeam.name &&
      newTeam.designation &&
      newTeam.experience &&
      newTeam.description
    ) {
      const updatedTeams = buildData.teams
        ? [...buildData.teams, newTeam]
        : [newTeam];
      setBuildData((prevData) => ({ ...prevData, teams: updatedTeams }));
      setNewTeam({
        name: '',
        designation: '',
        experience: '',
        description: '',
        avatar: '',
      });
    }
  };

  const handleTeamFormHover = (index: number | null) => {
    setHoveredTeamIndex(index);
  };

  const deleteSingleTeamForm = (index: number) => {
    const updatedTeams = buildData.teams ? [...buildData.teams] : [];
    updatedTeams.splice(index, 1);
    setBuildData((prevData) => ({ ...prevData, team: updatedTeams }));
    setHoveredTeamIndex(null);
  };

  //Faq OFFERING LOGIC
  const [newFaq, setNewFaq] = useState<any>({
    question: '',
    answer: '',
  });
  const [hoveredFaqIndex, setHoveredFaqIndex] = useState<number | null>(null);

  const handleInputFaqChange = (field: string, value: string) => {
    setNewFaq((prevService: any) => ({ ...prevService, [field]: value }));
  };
  const handleFaqChange = (index: number, field: string, value: string) => {
    const updatedFaqs = buildData.faq
      ? buildData.faq.map((faq, i) =>
        i === index ? { ...faq, [field]: value } : faq
      )
      : [];
    setBuildData((prevData) => ({ ...prevData, faq: updatedFaqs }));
  };

  const addNewFaq = () => {
    if (newFaq.question && newFaq.answer) {
      const updatedFaqs = buildData.faq ? [...buildData.faq, newFaq] : [newFaq];
      setBuildData((prevData) => ({ ...prevData, faq: updatedFaqs }));
      setNewFaq({
        question: '',
        answer: '',
      });
    }
  };
  const handleFaqFormHover = (index: number | null) => {
    setHoveredFaqIndex(index);
  };
  const deleteSingleFaqForm = (index: number) => {
    const updatedFaqs = buildData.faq ? [...buildData.faq] : [];
    updatedFaqs.splice(index, 1);
    setBuildData((prevData) => ({ ...prevData, faq: updatedFaqs }));
    setHoveredFaqIndex(null);
  };

  const [visibleImages, setVisibleImages] = useState(3);

  const handleLoadMoreClick = () => {
    setVisibleImages((prev) => prev + 3);
  };

  const totalImages = buildData.gallery.length;

  const handleDeleteGallery = (index: number) => {
    const newGallery = [...buildData.gallery];
    newGallery.splice(index, 1);
    setBuildData((prev) => ({ ...prev, gallery: newGallery }));
    deleteGalleryInBuild('');
  };

  const [showCarousel, setShowCarousel] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openCarousel = (index: number) => {
    setCurrentImageIndex(index);
    setShowCarousel(true);
  };

  const closeCarousel = () => {
    setShowCarousel(false);
  };

  // const isDataAvailable =
  //   buildData?.name
  // console.log(specificServiceIndex);

  const handleSinglefile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    _id: string,
    location: string
  ) => {
    const files = e.target.files;
    // Replace with actual token
    // console.log(fileId);
    if (files && location === 'services') {
      setServiceImage(URL?.createObjectURL(files[0]));
    }
    if (files && location === 'teams') {
      setTeamImage(URL?.createObjectURL(files[0]));
    }
    // console.log(teamImage);
    if (files && files.length) {
      setLoading(true);
      try {
        const response = await uploadfile(
          files,
          `communities/${currentCommunity?._id}/${location}`
        );
        // console.log('Upload response:', response.data); // Debug log
        if (response?.status) {
          location === 'services'
            ? setNewService((prevService) => ({
              ...prevService,
              images: [response.data._id],
            }))
            : setNewTeam((prev: any) => ({
              ...prev,
              avatar: [response.data._id],
            }));
          setFileId('');
          // setServiceImage('');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        // Set loading back to false regardless of success or failure
        setLoading(false);
        setFileId('');
      }
    } else {
      if (files && files.length) {
        setspecificServiceIndex(_id);
        setspecificTeamIndex(_id);
        try {
          const response = await updateUploadfile(
            files,
            fileId,
            `communities/${currentCommunity?._id}/${location}`
          );
          // console.log('Upload response:', response.data); // Debug log
          if (response?.status) {
            location === 'services'
              ? setNewService((prevService) => ({
                ...prevService,
                images: [response.data._id],
              }))
              : setNewTeam((prev: any) => ({
                ...prev,
                avatar: [response.data._id],
              }));
            setFileId('');
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        } finally {
          // Set loading back to false regardless of success or failure
          setLoading(false);
          setFileId('');
        }
      }
    }
  };

  return (
    <Stack
      spacing={1}
      sx={{
        '& .accordion-title': {
          fontSize: '24px',
          fontWeight: 500,
        },
      }}
    >
      <Typography
        sx={{ color: '#3C5AA0', marginLeft: '10px', fontSize: '15px', fontFamily: 'Montserrat', }}
      >
        You are updating{' '}
        <span style={{ fontWeight: 600 }}> {buildData?.community}</span>
      </Typography>
      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '8px', mt: '0px' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Upload Logo
            </Typography>
          </AccordionSummary>
          <CustomImageUploader
            initialImage={buildData.logo || ''}
            onImageDeleted={handleImageDeleted}
            onSingleImageSelected={handleSingleImage}
            onMultipleImagesSelected={handleMultipleImages}
            imageWidth={90}
            imageHeight={90}
            imageName="logo"
            multiple={false}
            uploadLabelHeight={30}
            uploadLabelWidth={30}
          />
          {/* </Stack> */}
        </Accordion>
      </Grid>
      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0px' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Upload Main Banner
            </Typography>
          </AccordionSummary>
          <CustomImageUploader
            initialImage={buildData.banner || ''}
            onImageDeleted={handleImageDeleted}
            onSingleImageSelected={handleBannerImage}
            onMultipleImagesSelected={handleMultipleImages}
            imageWidth={100}
            imageHeight={100}
            imageName="banner"
            multiple={false}
            uploadLabelHeight={30}
            uploadLabelWidth={30}
          />
        </Accordion>
      </Grid>
      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
                borderRadius: '8px',

              }}
            >
              Describe about your community
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: '5px' }}>
            <Grid item xs={12} md={12}>
              <Stack sx={{}}>
                <TextField
                  placeholder="Describe about your community"
                  multiline
                  rows={5}
                  maxRows={10}
                  label="Enter Description"
                  variant="outlined"
                  value={buildData?.description}
                  onChange={(e) =>
                    setBuildData({ ...buildData, description: e.target.value })
                  }
                  InputLabelProps={{ style: { fontSize: '13px' } }}
                  inputProps={{ style: { fontSize: '13px' } }}
                  //({ ...buildData, description: e.target.value })}
                  sx={{
                    m: 1, width: 'auto',
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                    '& textarea': {
                      fontFamily: 'Montserrat',
                    },
                  }}
                />
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Services Offering
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: '15px' }}>
            <Stack spacing={2} key="services">
              {buildData.services?.map((service: any, _id: any) => (
                <Stack
                  key={_id}
                  sx={{
                    border: '1px solid #E0E0E0',
                    padding: '30px',
                    margin: '15px 0px',
                    position: 'relative',
                  }}
                  onMouseEnter={() => handleServiceFormHover(_id)}
                  onMouseLeave={() => handleServiceFormHover(null)}
                >
                  {hoveredServiceIndex === _id && (
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '1px',
                        right: '1px',
                        backgroundColor: 'white',
                      }}
                      onClick={() => deleteSingleServiceForm(_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <Grid container>
                    <Grid item xs={12} md={9}>
                      <TextField
                        id={`service-title-${_id}`}
                        label="Service Title"
                        variant="outlined"
                        fullWidth
                        value={service.title}
                        onChange={(e) =>
                          handleServiceChange(_id, 'title', e.target.value)
                        }
                        sx={{
                          marginBottom: '15px',
                          '& .MuiInputLabel-root': {
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                          '& input': {
                            fontFamily: 'Montserrat',
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: '13px' } }}
                        inputProps={{ style: { fontSize: '13px' } }}
                      />
                      <TextField
                        id={`service-description-${_id}`}
                        label="Service Description"
                        variant="outlined"
                        fullWidth
                        value={service.description}
                        onChange={(e) =>
                          handleServiceChange(
                            _id,
                            'description',
                            e.target.value
                          )
                        }
                        sx={{
                          '& .MuiInputLabel-root': {
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                          '& input': {
                            fontFamily: 'Montserrat',
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: '13px' } }}
                        inputProps={{ style: { fontSize: '13px' } }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={1}
                      xl={1}
                      sx={{
                        display: 'felx',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <input
                        type="file"
                        name="uploadfile"
                        id={`img-${_id}`}
                        hidden
                        accept="image/jpeg, image/png, image/gif"
                        style={{ cursor: "pointer" }}
                        onChange={(e) =>
                          handleSinglefile(
                            e,
                            _id,
                            "services"
                          )
                        }
                      /> */}
                      {/* <label
                        htmlFor={`img-${_id}`}
                        onClick={() => {
                          if (service.images[0]?._id) {
                            setFileId(service.images[0]?._id)
                            setspecificServiceIndex(service._id);
                          } else {
                            setFileId('')
                          }
                        }}
                      >
                        {" "}
                        <FileUploadOutlinedIcon
                          sx={{
                            height: "5vh",
                            width: "5vh",
                            cursor: "pointer",
                            color: "gray",
                          }}
                        />
                      </label>
                      <img
                        alt=""
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "300px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        src={((_id === specificServiceIndex)) ? serviceImage : service?.images?.[0]?.value || ''}
                      ></img> */}
                    </Grid>
                    <Grid item xl={2} md={2}></Grid>
                  </Grid>
                </Stack>
              ))}

              <Stack
                sx={{
                  border: '1px solid #E0E0E0',
                  padding: '24px',
                  margin: '15px 0px',
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={9}>
                    <TextField
                      id="new-service-title"
                      label="Service Title"
                      variant="outlined"
                      fullWidth
                      value={newService.title}
                      onChange={(e) =>
                        handleInputChange('title', e.target.value)
                      }
                      sx={{
                        marginBottom: '15px',
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                        '& input': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      InputLabelProps={{ style: { fontSize: '13px' } }}
                      inputProps={{ style: { fontSize: '13px' } }}
                    />
                    <TextField
                      id="new-service-description"
                      label="Service Description"
                      variant="outlined"
                      fullWidth
                      value={newService.description}
                      onChange={(e) =>
                        handleInputChange('description', e.target.value)
                      }
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                        '& input': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      InputLabelProps={{ style: { fontSize: '13px' } }}
                      inputProps={{ style: { fontSize: '13px' } }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    xl={1}
                    sx={{
                      display: 'felx',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {/* {newService.description && newService.title && (
                      <>
                        <input
                          type="file"
                          name="uploadfile"
                          id="img1"
                          hidden
                          accept="image/jpeg, image/png, image/gif"
                          style={{ cursor: "pointer" }}
                          onChange={(e) => handleSinglefile(e, '', "services")}
                        /> */}
                    {/* <label htmlFor="img1" onClick={() => {

                        }}>
                          <FileUploadOutlinedIcon
                            sx={{
                              height: "5vh",
                              width: "5vh",
                              cursor: "pointer",
                              color: "gray",
                            }}
                          />
                        </label> */}
                    {/* </>
                    )} */}
                  </Grid>
                  <Grid item xl={2} md={2}>
                    {/* <img
                      alt=""
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "300px",
                        objectFit: "cover",

                        cursor: "pointer",
                      }}
                      src={specificServiceIndex !== '' ? '' : serviceImage}
                    ></img> */}
                  </Grid>
                </Grid>
              </Stack>

              <Button
                variant="outlined"
                sx={{
                  textTransform: 'uppercase',
                  width: 'fit-content',
                  fontSize: '13px',
                  backgroundColor: '#2952A2',
                  fontFamily: 'Montserrat',
                  color: '#ffffff',
                  border: '1px solid #2952A2',
                  ':hover': {
                    border: '1px solid #2952A2',
                    color: '#2952A2',
                  },
                }}
                onClick={addNewService}
              >
                Add New Service
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Gallery
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Stack sx={{ marginTop: '10px' }}>
              <CustomImageUploader
                initialImage={''}
                onImageDeleted={handleImageDeleted}
                onSingleImageSelected={handleSingleImage}
                onMultipleImagesSelected={handleMultipleImages}
                imageWidth={100}
                imageHeight={100}
                imageName="Gallery"
                multiple={true}
                uploadLabelHeight={30}
                uploadLabelWidth={30}
              />
            </Stack>

            <>
              <Box>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    paddingLeft: '20px',
                    maxHeight: 220,
                    overflowY: 'auto',
                    ml: 0,
                    mt: 0.5,
                  }}
                >
                  {Array.isArray(buildData.gallery) &&
                    buildData.gallery
                      .slice(0, visibleImages)
                      .map((imageUrl, index) => (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          sx={{ position: 'relative' }}
                        >
                          <Card
                            style={{
                              width: '100%',
                              height: '100%',
                              maxHeight: 200,
                            }}
                          >
                            <CardMedia
                              component="img"
                              alt={`Image ${index + 1}`}
                              image={imageUrl}
                              style={{
                                height: '100%',
                                objectFit: 'cover',
                                width: '100%',
                              }}
                              onClick={() => openCarousel(index)}
                            />
                            <IconButton
                              size="small"
                              sx={{
                                position: 'absolute',
                                top: 10,
                                right: 0,
                                backgroundColor: '#3C5AA0',
                                borderRadius: '50%',
                                padding: '4px',
                                margin: '4px',
                                '&:hover': {
                                  color: 'red',
                                },
                              }}
                              onClick={() => handleDeleteGallery(index)}
                            >
                              <Close
                                sx={{
                                  color: '#fff',
                                  fontSize: '15px',
                                  '&:hover': { color: 'red' },
                                }}
                              />
                            </IconButton>
                          </Card>
                          {index === visibleImages - 1 &&
                            visibleImages < totalImages && (
                              <Button
                                variant="contained"
                                sx={{
                                  position: 'absolute',
                                  bottom: 0,
                                  right: 0,
                                  background:
                                    ' -webkit-linear-gradient(180deg, #3C5AA0 0%, #50A1CA 100%);',
                                  backgroundColor: '#50A1CA',
                                  fontFamily: 'Montserrat',
                                }}
                                onClick={handleLoadMoreClick}
                              >
                                More Images
                              </Button>
                            )}
                        </Grid>
                      ))}
                </Grid>
              </Box>

              <Dialog open={showCarousel} onClose={closeCarousel} maxWidth="sm">
                <Carousel
                  selectedItem={currentImageIndex}
                  showThumbs={false}
                  infiniteLoop={false}
                  showStatus={false}
                  showArrows={true}
                  onChange={(index) => setCurrentImageIndex(index)}
                >
                  {buildData.gallery.map((image, index) => (
                    <div key={index} style={{ height: '100%' }}>
                      <img
                        src={image}
                        alt=""
                        style={{
                          height: '450px', // Set a fixed height for all images
                          width: '100%', // Set a fixed width for all images
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={closeCarousel}
                  aria-label="close"
                  sx={{
                    position: 'absolute',
                    right: 35,
                    top: 6,
                    backgroundColor: '#3C5AA0',
                    color: '#fff',
                    fontSize: '16px',
                    padding: 0.5,
                    '&:hover': {
                      color: 'red',
                    },
                  }}
                >
                  <Close sx={{ fontSize: '15px' }} />
                </IconButton>
              </Dialog>
            </>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Mission & Vision
            </Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ p: '15px' }}>
            <Stack>
              <Stack>
                <Stack spacing={1.5}>
                  <TextField
                    id="outlined-basic"
                    label="Enter Mission"
                    variant="outlined"
                    placeholder="Type Here"
                    multiline
                    rows={3}
                    value={buildData?.mission}
                    onChange={(e) =>
                      setBuildData({ ...buildData, mission: e.target.value })
                    }
                    sx={{
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& textarea': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: '13px' } }}
                    inputProps={{ style: { fontSize: '13px' } }}
                  ></TextField>
                  <TextField
                    id="outlined-basic"
                    label="Enter Vision"
                    variant="outlined"
                    placeholder="Type Here"
                    multiline
                    rows={3}
                    value={buildData?.vision}
                    onChange={(e) =>
                      setBuildData({ ...buildData, vision: e.target.value })
                    }
                    sx={{
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& textarea': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: '13px' } }}
                    inputProps={{ style: { fontSize: '13px' } }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{
              '.css-o4b71y-MuiAccordionSummary-content': {
                marginTop: '10px',
                marginBottom: '10px',
                // other styles for the specified class
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Our Team
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: '15px' }}>
            <Stack spacing={2} key="team">
              {buildData.teams?.map((team: any, _id: any) => (
                <Stack
                  key={_id}
                  sx={{
                    border: '1px solid #E0E0E0',
                    padding: '30px',
                    margin: '15px 0px',
                    position: 'relative',
                  }}
                  onMouseEnter={() => handleTeamFormHover(_id)}
                  onMouseLeave={() => handleTeamFormHover(null)}
                >
                  {hoveredTeamIndex === _id && (
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '1px',
                        right: '1px',
                        backgroundColor: 'white',
                      }}
                      onClick={() => deleteSingleTeamForm(_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <Grid container>
                    <Grid item xs={12} md={9}>
                      <TextField
                        id={`team-name-${_id}`}
                        label="Full name"
                        variant="outlined"
                        fullWidth
                        value={team.name}
                        onChange={(e) =>
                          handleTeamChange(_id, 'name', e.target.value)
                        }
                        sx={{
                          marginBottom: '15px',
                          '& .MuiInputLabel-root': {
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                          '& input': {
                            fontFamily: 'Montserrat',
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: '13px' } }}
                        inputProps={{ style: { fontSize: '13px' } }}
                      />
                      <TextField
                        id={`team-designation-${_id}`}
                        label="Designation"
                        variant="outlined"
                        fullWidth
                        value={team.designation}
                        onChange={(e) =>
                          handleTeamChange(_id, 'designation', e.target.value)
                        }
                        sx={{
                          marginBottom: '15px',
                          '& .MuiInputLabel-root': {
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                          '& input': {
                            fontFamily: 'Montserrat',
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: '13px' } }}
                        inputProps={{ style: { fontSize: '13px' } }}
                      />
                      <TextField
                        id={`team-experience-${_id}`}
                        label="Year of experience "
                        variant="outlined"
                        fullWidth
                        value={team?.experience}
                        onChange={(e) =>
                          handleTeamChange(_id, 'experience', e.target.value)
                        }
                        sx={{
                          marginBottom: '15px',
                          '& .MuiInputLabel-root': {
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                          '& input': {
                            fontFamily: 'Montserrat',
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: '13px' } }}
                        inputProps={{ style: { fontSize: '13px' } }}
                      />
                      <TextField
                        id={`team-description-${_id}`}
                        label="Description"
                        variant="outlined"
                        fullWidth
                        name="description"
                        value={team?.description}
                        onChange={(e) =>
                          handleTeamChange(_id, 'description', e.target.value)
                        }
                        sx={{
                          '& .MuiInputLabel-root': {
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                          '& input': {
                            fontFamily: 'Montserrat',
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: '13px' } }}
                        inputProps={{ style: { fontSize: '13px' } }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={1}
                      xl={1}
                      sx={{
                        display: 'felx',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <input
                        type="file"
                        name="uploadfil"
                        id={`im-${_id}`}
                        hidden
                        accept="image/jpeg, image/png, image/gif"
                        style={{ cursor: "pointer" }}
                        onChange={(e) =>
                          handleSinglefile(
                            e,
                            '',
                            "teams"
                          )
                        }
                      />
                      <label
                        htmlFor={`im-${_id}`}
                        onClick={() => {
                          if (team?.avatar?._id) {
                            setFileId(team?.avatar?._id)
                            console.log(team._id);
                            setspecificTeamIndex(team?._id);
                          } else {
                            setFileId('')
                          }
                        }}
                      >
                        {" "}
                        <FileUploadOutlinedIcon
                          sx={{
                            height: "5vh",
                            width: "5vh",
                            cursor: "pointer",
                            color: "gray",
                          }}
                        />
                      </label> */}
                    </Grid>
                    <Grid item xl={2} md={2}>
                      {/* <img
                        alt=""
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "300px",
                          objectFit: "cover",

                          cursor: "pointer",
                        }}
                        src={((_id === specificTeamIndex)) ? teamImage : team?.avatar?.value || ''}
                      ></img> */}
                    </Grid>
                  </Grid>
                </Stack>
              ))}
              <Stack
                sx={{
                  border: '1px solid #E0E0E0',
                  padding: '24px',
                  margin: '15px 0px',
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={9}>
                    <TextField
                      id="new-team-name"
                      label="Full name"
                      variant="outlined"
                      fullWidth
                      value={newTeam.name}
                      onChange={(e) =>
                        handleInputTeamChange('name', e.target.value)
                      }
                      sx={{
                        marginBottom: '15px',
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                        '& input': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      InputLabelProps={{ style: { fontSize: '13px' } }}
                      inputProps={{ style: { fontSize: '13px' } }}
                    />
                    <TextField
                      id="new-team-designation"
                      label="Designation"
                      variant="outlined"
                      fullWidth
                      value={newTeam.designation}
                      onChange={(e) =>
                        handleInputTeamChange('designation', e.target.value)
                      }
                      sx={{
                        marginBottom: '15px',
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                        '& input': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      InputLabelProps={{ style: { fontSize: '13px' } }}
                      inputProps={{ style: { fontSize: '13px' } }}
                    />
                    <TextField
                      id="new-team-experience"
                      label="Year of experience"
                      variant="outlined"
                      fullWidth
                      value={newTeam.experience}
                      onChange={(e) =>
                        handleInputTeamChange('experience', e.target.value)
                      }
                      sx={{
                        marginBottom: '15px',
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                        '& input': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      InputLabelProps={{ style: { fontSize: '13px' } }}
                      inputProps={{ style: { fontSize: '13px' } }}
                    />
                    <TextField
                      id="new-team-description"
                      label="Description"
                      variant="outlined"
                      fullWidth
                      name="description"
                      value={newTeam.description}
                      onChange={(e) =>
                        handleInputTeamChange('description', e.target.value)
                      }
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                        '& input': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      InputLabelProps={{ style: { fontSize: '13px' } }}
                      inputProps={{ style: { fontSize: '13px' } }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    xl={1}
                    sx={{ pt: { xs: 2, md: 0 } }}
                  >
                    {/* <CustomImageUploader
                      initialImage={""}
                      onImageDeleted={handleImageDeleted}
                      onSingleImageSelected={handleSingleImage}
                      onMultipleImagesSelected={handleMultipleImages}
                      imageWidth={70}
                      imageHeight={70}
                      imageName="logo"
                      multiple={false}
                      uploadLabelHeight={30}
                      uploadLabelWidth={40}
                    />
                     */}

                    {/* <input
                      type="file"
                      name="upload"
                      id="img1"
                      hidden
                      accept="image/jpeg, image/png, image/gif"
                      style={{ cursor: "pointer" }}
                      onChange={(e) => handleSinglefile(e, '', "teams")}
                    />
                    {newTeam.name && newTeam.description && newTeam.designation && newTeam.experience && (
                      <label htmlFor="img1">
                        <FileUploadOutlinedIcon
                          sx={{ height: "5vh", width: "5vh", color: "gray" }}
                        />
                      </label>)} */}
                  </Grid>
                  <Grid item xl={2} md={2}>
                    {/* <img
                      alt=""
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "300px",
                        objectFit: "cover",

                        cursor: "pointer",
                      }}
                      src={specificTeamIndex !== '' ? '' : teamImage}
                    ></img> */}
                  </Grid>
                </Grid>
              </Stack>
              {/* {newTeam?.avatar && ( */}
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'uppercase',
                  width: 'fit-content',
                  fontSize: '13px',
                  backgroundColor: '#2952A2',
                  color: '#ffffff',
                  border: '1px solid #2952A2',
                  fontFamily: 'Montserrat',
                  ':hover': {
                    border: '1px solid #2952A2',
                    color: '#2952A2',
                  },
                }}
                onClick={addNewTeam}
              >
                Add New Member
              </Button>
              {/* )} */}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* <Accordion
        sx={{ border: "1px solid #E0E0E0", borderRadius: "5px", mt: "0" }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ marginRight: "15px", marginLeft: "15px" }} />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            sx={{

              fontSize: "13px",
              color: "#515151",
              fontWeight: 500,
            }}
          >
            Community Admins
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: '5px' }}>
          <Grid item xs={12} md={12}>
            <Stack >
              <TextField
                placeholder="Type here"
                multiline
                rows={5}
                maxRows={10}
                label="Enter"
                variant="outlined"
                value={buildData?.adminMessage}
                onChange={(e) =>
                  setBuildData({ ...buildData, adminMessage: e.target.value })
                }
                sx={{ m: 1, width: "auto" }}
                InputLabelProps={{ style: { fontSize: "13px" } }}
                inputProps={{ style: { fontSize: "13px" } }}
              />
            </Stack>
          </Grid>
        </AccordionDetails>
      </Accordion> */}
      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Social Network
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: '15px' }}>
            <Stack>
              <Grid container spacing={1.5}>
                {buildData?.socialLinks?.map(
                  ({ type, label, value, _id }: any, i: number) => (
                    <>
                      <Grid item xs={12} md={12}>
                        <TextField
                          id={label}
                          label={label}
                          variant="outlined"
                          fullWidth
                          key={i}
                          value={value || ''}
                          name={type}
                          onChange={(e) =>
                            handleSocialNetworkChange(e, type, _id)
                          }
                          sx={{
                            '& .MuiInputLabel-root': {
                              fontFamily: 'Montserrat',
                            },
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '8px',
                            },
                            '& input': {
                              fontFamily: 'Montserrat',
                            },
                          }}
                          InputLabelProps={{ style: { fontSize: '13px' } }}
                          inputProps={{ style: { fontSize: '13px' } }}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Directors Message
            </Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ p: '5px' }}>
            <Grid item xs={12} md={12}>
              <Stack>
                <TextField
                  placeholder="Type here"
                  multiline
                  rows={5}
                  maxRows={10}
                  label="Enter your message"
                  variant="outlined"
                  value={buildData?.directorMessage}
                  onChange={(e) =>
                    setBuildData({
                      ...buildData,
                      directorMessage: e.target.value,
                    })
                  }
                  sx={{
                    m: 1, width: 'auto',
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                    '& input': {
                      fontFamily: 'Montserrat',
                    },
                  }}
                  InputLabelProps={{ style: { fontSize: '13px' } }}
                  inputProps={{ style: { fontSize: '13px' } }}
                />
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid>
        <Accordion
          sx={{ border: '1px solid #E0E0E0', borderRadius: '5px', mt: '0' }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{
              '.css-o4b71y-MuiAccordionSummary-content': {
                marginTop: '10px',
                marginBottom: '10px',
                // other styles for the specified class
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              FAQ
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: '15px' }}>
            <Stack spacing={2} key="faq">
              {buildData.faq?.map((faq, _id) => (
                <Stack
                  key={_id}
                  sx={{
                    border: '1px solid #E0E0E0',
                    padding: '30px',
                    margin: '15px 0px',
                    position: 'relative',
                  }}
                  onMouseEnter={() => handleFaqFormHover(_id)}
                  onMouseLeave={() => handleFaqFormHover(null)}
                >
                  {hoveredFaqIndex === _id && (
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '1px',
                        right: '1px',
                        backgroundColor: 'white',
                      }}
                      onClick={() => deleteSingleFaqForm(_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id={`question-${_id}`}
                        label="Question"
                        variant="outlined"
                        fullWidth
                        value={faq.question}
                        onChange={(e) =>
                          handleFaqChange(_id, 'question', e.target.value)
                        }
                        sx={{
                          marginBottom: '15px',
                          '& .MuiInputLabel-root': {
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                          '& input': {
                            fontFamily: 'Montserrat',
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: '13px' } }}
                        inputProps={{ style: { fontSize: '13px' } }}
                      />
                      <TextField
                        id={`answer-${_id}`}
                        label="Answer"
                        variant="outlined"
                        fullWidth
                        value={faq.answer}
                        onChange={(e) =>
                          handleFaqChange(_id, 'answer', e.target.value)
                        }
                        sx={{
                          marginBottom: '15px',
                          '& .MuiInputLabel-root': {
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                          '& input': {
                            fontFamily: 'Montserrat',
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: '13px' } }}
                        inputProps={{ style: { fontSize: '13px' } }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              ))}
              <Stack
                sx={{
                  border: '1px solid #E0E0E0',
                  padding: '24px',
                  margin: '15px 0px',
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="new-faq-question"
                      label="Question"
                      variant="outlined"
                      fullWidth
                      value={newFaq.question}
                      onChange={(e) =>
                        handleInputFaqChange('question', e.target.value)
                      }
                      sx={{
                        marginBottom: '15px',
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                        '& input': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      InputLabelProps={{ style: { fontSize: '13px' } }}
                      inputProps={{ style: { fontSize: '13px' } }}
                    />
                    <TextField
                      id="new-faq-answer"
                      label="Answer"
                      variant="outlined"
                      fullWidth
                      value={newFaq.answer}
                      onChange={(e) =>
                        handleInputFaqChange('answer', e.target.value)
                      }

                      sx={{
                        marginBottom: '15px',
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                        '& input': {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      InputLabelProps={{ style: { fontSize: '13px' } }}
                      inputProps={{ style: { fontSize: '13px' } }}
                    />
                  </Grid>
                </Grid>
              </Stack>

              <Button
                variant="outlined"
                sx={{
                  textTransform: 'uppercase',
                  width: 'fit-content',
                  fontSize: '13px',
                  backgroundColor: '#2952A2',
                  color: '#ffffff',
                  fontFamily: 'Montserrat',
                  border: '1px solid #2952A2',
                  ':hover': {
                    border: '1px solid #2952A2',
                    color: '#2952A2',
                  },
                }}
                onClick={addNewFaq}
              >
                Add New Faq
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid>

        <Accordion sx={{ border: '0.5px solid #d2d2d2', boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ marginRight: '15px', marginLeft: '15px' }}
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#515151',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              Communications Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: '15px' }}>
            <Stack>
              <Grid container spacing={1.5}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    value={buildData?.email}
                    onChange={(e) =>
                      setBuildData({ ...buildData, email: e.target.value })
                    }
                    sx={{
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& input': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: '13px' } }}
                    inputProps={{ style: { fontSize: '13px' } }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    required
                    value={buildData?.phoneNumber}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^\d{0,10}$/.test(input)) {
                        setBuildData({
                          ...buildData,
                          phoneNumber: +e.target.value,
                        });
                      }
                    }}
                    sx={{
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& input': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: '13px' } }}
                    inputProps={{ style: { fontSize: '13px' } }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile Number"
                    variant="outlined"
                    fullWidth
                    value={buildData?.mobileNumber}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^\d{0,10}$/.test(input)) {
                        setBuildData({
                          ...buildData,
                          mobileNumber: +e.target.value,
                        });
                      }
                    }}
                    sx={{
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& input': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: '13px' } }}
                    inputProps={{ style: { fontSize: '13px' } }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Address Line"
                    variant="outlined"
                    fullWidth
                    value={buildData?.fullAddress}
                    onChange={(e) =>
                      setBuildData({
                        ...buildData,
                        fullAddress: e.target.value,
                      })
                    }
                    sx={{
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& input': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: '13px' } }}
                    inputProps={{ style: { fontSize: '13px' } }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Pin/Zip Code"
                    variant="outlined"
                    fullWidth
                    value={buildData?.zipCode}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^\d{0,6}$/.test(input)) {
                        setBuildData({
                          ...buildData,
                          zipCode: +e.target.value,
                        });
                      }
                    }}
                    sx={{
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& input': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: '13px' } }}
                    inputProps={{ style: { fontSize: '13px' } }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Google Map URL"
                    variant="outlined"
                    fullWidth
                    value={buildData?.location}
                    onChange={(e) =>
                      setBuildData({ ...buildData, location: e.target.value })
                    }
                    sx={{
                      '& .MuiInputLabel-root': {
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& input': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: '13px' } }}
                    inputProps={{ style: { fontSize: '13px' } }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Stack direction={'row'} spacing={2} sx={{ mt: 2, pt: 1, mb: 2, pb: 1 }}>
        <Button
          variant="contained"
          size="large"
          sx={{
            textTransform: 'capitalize',
            fontFamily: 'Montserrat',
            borderRadius: '8px',
            boxShadow: 'none',
            mt: 2,
            mb: 2,
            backgroundColor: ' #3C5AA0',
            '&:hover': {
              backgroundColor: '#3C5AA0', // Background color on hover
              cursor: 'pointer',
              border: 'none',
              boxShadow: 'none',
            },
          }}
          onClick={handlePublish}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Publish Now'
          )}
        </Button>
        {currentCommunity?.status === 'CREATED' && (
          <Button
            variant="outlined"
            size="large"
            sx={{
              textTransform: 'capitalize',
              // fontWeight:600,
              fontFamily: 'Montserrat',
              borderRadius: '8px',
              boxShadow: 'none',
              color: '#3C5AA0',
              border: '1px solid #3C5AA0',

              '&:hover': {
                cursor: 'pointer',
                border: '1px solid #3C5AA0',
                boxShadow: 'none',
              },
            }}
            onClick={handleSave}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Save Now'
            )}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
