import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Event from '../../assets/images/Event Accepted.svg';
import Appointments from '../../assets/images/Tear-Off Calendar.svg';
import Subscriptions from '../../assets/images/Renew.svg';
import Courses from '../../assets/images/Knowledge Sharing.svg';
import Meetings from '../../assets/images/Video Conference.svg';
import Products from '../../assets/images/Package.svg';
import Members from '../../assets/images/file.svg';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useCommunity } from '../../hooks/useCommunity';
import { ICommunity } from '../../models/communities.modal';
import store from '../../store';

interface Feature {
  id: string;
  title: string;
  icon: string;
  enabled: boolean;
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #E7EBF1',
  boxShadow: 'none',
  transition: 'all 0.3s ease',
  borderRadius: '8px',
}));

export const Settings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { getCommunitiesList, updateCommunityDetails } = useCommunity();

  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const selectedCommunityData = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );


  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const communitySelected = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  const [selectedCommunity, setSelectedCommunity] = useState(
    communitySelected.name || ''
  );

  const [features, setFeatures] = useState<Feature[]>([
    {
      id: 'subscription',
      title: 'Subscription',
      icon: Subscriptions,
      enabled: true,
    },
    { id: 'event', title: 'Event', icon: Event, enabled: true },
    {
      id: 'appointment',
      title: 'Appointment',
      icon: Appointments,
      enabled: true,
    },
    { id: 'course', title: 'Course', icon: Courses, enabled: true },
    { id: 'meeting', title: 'Meeting', icon: Meetings, enabled: true },
    { id: 'product', title: 'Product', icon: Products, enabled: true },
    { id: 'member', title: 'Members', icon: Meetings, enabled: true },
  ]);

  useEffect(() => {
    const getList = async () => {
      const communitiesDataList = await getCommunitiesList();
      setCommunities(communitiesDataList);
    };
    getList();

    // Initialize features based on selectedCommunityData.enabledService
    if (selectedCommunityData?.enabledService) {
      setFeatures((prevFeatures) =>
        prevFeatures.map((feature) => ({
          ...feature,
          enabled: selectedCommunityData.enabledService[feature.id] || false,
        }))
      );
    }
  }, [selectedCommunityData]);

  const communityTitles = communities
    ? communities.map((community) => community.title)
    : [];

  const handleToggle = async (id: string) => {
    const updatedFeatures = features.map((feature) =>
      feature.id === id ? { ...feature, enabled: !feature.enabled } : feature
    );

    const enabledService = updatedFeatures.reduce(
      (acc, feature) => {
        acc[feature.id] = feature.enabled;
        return acc;
      },
      {} as Record<string, boolean>
    );

    // console.log('selectedData:', selectedCommunityData._id);

    // console.log('Updated features:', enabledService);

    if (selectedCommunityData?._id) {
      const response = await updateCommunityDetails(selectedCommunityData._id, {
        enabledService,
      });
    } else {
      console.error('Community ID is missing');
    }
    setFeatures(updatedFeatures);
    enqueueSnackbar('Updated successfully', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  const handleEnableAll = () => {
    const updatedFeatures = features.map((feature) => ({
      ...feature,
      enabled: true,
    }));

    setFeatures(updatedFeatures);

    if (selectedCommunityData?._id) {
      const enabledService = updatedFeatures.reduce(
        (acc, feature) => {
          acc[feature.id] = feature.enabled;
          return acc;
        },
        {} as Record<string, boolean>
      );
      updateCommunityDetails(selectedCommunityData._id, { enabledService });
    }

    enqueueSnackbar('All settings enabled successfully', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  const handleDisableAll = () => {
    const updatedFeatures = features.map((feature) => ({
      ...feature,
      enabled: false,
    }));

    setFeatures(updatedFeatures);

    if (selectedCommunityData?._id) {
      const enabledService = updatedFeatures.reduce(
        (acc, feature) => {
          acc[feature.id] = feature.enabled;
          return acc;
        },
        {} as Record<string, boolean>
      );
      updateCommunityDetails(selectedCommunityData._id, { enabledService });
    }

    enqueueSnackbar('All settings disabled successfully', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  return (
    <>
      <Helmet>
        <title>Settings - Onecommunn - Build & Manage your community</title>
      </Helmet>
      <Stack
        flexGrow={1}
        sx={{ pb: 1 }}
        flexDirection={'row'}
        gap={1}
        display={'flex'}
        justifyItems={'center'}
        alignItems={'center'}
      >
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '16px' },
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Montserrat',
          }}
        >
          Settings
        </Typography>
      </Stack>
      <Stack>
        <Paper
          elevation={0}
          sx={{
            padding: { xs: '15px', md: '30px' },
            height: { xs: '85vhs', md: '85vhl' },
            border: '1px solid #E7EBF1',
            borderRadius: '8px',
          }}
        >
          <Stack spacing={2}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              Community Feature Management
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              Enable or disable features across your platform
            </Typography>
          </Stack>
          {loggedInUserData?.user?.role === `${'SUPERADMIN'}` && (
            <Paper
              elevation={0}
              sx={{
                border: '1px solid #E7EBF1',
                borderRadius: '8px',
                p: 3,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: '600',
                  mb: 2,
                }}
              >
                Select Community
              </Typography>
              <Autocomplete
                options={communityTitles}
                value={selectedCommunity}
                disabled
                componentsProps={{
                  paper: {
                    sx: {
                      padding: '0px',
                      fontFamily: 'Montserrat',
                    },
                  },
                }}
                size="small"
                sx={{ backgroundColor: '#F9F9F9' }}
                ListboxProps={{
                  sx: { fontSize: '13px', fontFamily: 'Montserrat' },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Community"
                    size="small"
                    sx={{
                      padding: '0px',
                      fontFamily: 'Montserrat',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        fontFamily: 'Montserrat',
                        fontSize: '13px',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: '13px',
                        fontFamily: 'Montserrat',
                      },
                    }}
                    value={selectedCommunity}
                  />
                )}
              />
            </Paper>
          )}

          <Paper
            elevation={0}
            sx={{
              border: '1px solid #E7EBF1',
              borderRadius: '8px',
              p: { xs: 2, md: 3 },
              mt: 2,
            }}
          >
            <Box sx={{ pb: 2, margin: '0 auto' }}>
              <Box sx={{ mb: 0, display: 'flex', gap: 2, justifyContent: { xs: 'center', md: 'flex-start' } }}>
                <Button
                  variant="outlined"
                  onClick={handleEnableAll}
                  sx={{
                    borderRadius: 1,
                    border: '1px solid #E7EBF1',
                    boxShadow: 'none',
                    backgroundColor: '#f9f9f9',
                    color: '#646464',
                    fontFamily: 'Montserrat',
                    textTransform: 'capitalize',
                  }}
                >
                  Enable All
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleDisableAll}
                  sx={{
                    borderRadius: 1,
                    border: '1px solid #E7EBF1',
                    boxShadow: 'none',
                    backgroundColor: '#f9f9f9',
                    color: '#646464',
                    fontFamily: 'Montserrat',
                    textTransform: 'capitalize',
                  }}
                >
                  Disable All
                </Button>
              </Box>
            </Box>
            <Grid container spacing={2}>
              {features.map((feature) => (
                <Grid item xs={6} sm={6} md={3} key={feature.id}>
                  <StyledCard>
                    <CardContent
                      sx={{
                        width: '100%',
                        display: '',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '&:last-child': {
                          paddingBottom: '12px',
                          paddingTop: '12px',
                        },
                      }}
                    >

                      <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Box
                          component="img"
                          src={feature.icon as string}
                          alt="Feature Icon"
                        />
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          {feature.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: '500',
                          }}
                          color={feature.enabled ? '#05A231' : '#EB3223'}
                        >
                          {feature.enabled ? 'Enabled' : 'Disabled'}
                        </Typography>
                        <Switch
                          checked={feature.enabled}
                          onChange={() => handleToggle(feature.id)}
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: '#3C5AA0',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                            {
                              backgroundColor: '#3C5AA0',
                            },
                          }}
                        />
                      </Stack>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Paper>
      </Stack>
    </>
  );
};
