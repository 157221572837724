import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { SubNavbar } from '../Layout/components/SubNavbar';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { usePayment } from '../../hooks/usePayment';

import {
  TotalRevenueIcon,
  SettledBalanceIcon,
  UnSettledBalanceIcon,
  SearchIcon1,
} from '../../assets/icons';
import { IPaymentList } from '../../models/payment.model';
import { useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import { usePlans } from '../../hooks/usePlans';
import { Reports } from '../tables/ReportsTable';
// import { DateRangePicker } from "react-date-range";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";
// import { enUS } from 'date-fns/locale';
import { useReports } from '../../hooks/useReports';
import { NoReports } from '../AlternatePages/NoReports';
import { IReports } from '../../models/reports.model';
import { ChevronDown, Info, ListFilter } from 'lucide-react';








export const PaymentReports = () => {
  // const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-07"));
  const [selectedPlans, setSelectedPlans] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [paymentsList, setPaymentList] = useState<IPaymentList[]>([]);
  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');


  const [searchQuery, setSearchQuery] = useState<string>("");

  // console.log(paymentsList, "paymentsList");
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );

  const selectedCommunityId = selectedCommunity?.selectedCommunity?._id;
  const { getCommunityPaymentsById, isLoading } = usePayment();
  const { getCommunityReports } = useReports();

  const [loading, setLoading] = useState(true);

  const [reports, setReports] = useState<IReports[]>([]);



  // console.log(reports, "reports");

  const planId = '';


  const reportsList = async () => {
    setLoading(true);
    try {
      const response = await getCommunityReports(
        selectedCommunityId,
        planId,
        startDate,
        endDate
      );
      if (response) {
        setReports(response);
      }
    } catch (err) {
      console.log(err, 'Error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reportsList();
  }, []);

  const { getPlansList } = usePlans();

  useEffect(() => {
    const fetchData = async () => {
      const communityPayments =
        await getCommunityPaymentsById(selectedCommunityId);
      if (communityPayments) setPaymentList(communityPayments);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);

  const loadPlans = async () => {
    try {
      if (selectedCommunityId) {
        const plans_data = await getPlansList(selectedCommunityId);
        if (plans_data) {
          setPlans(plans_data);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
    }
  };
  useEffect(() => {
    loadPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);
  // console.log(plans)
  const handleAutocompleteChange = (_: any, newValue: any[]) => {
    setSelectedPlans(newValue);

    // Update paymentTypeFilter to include the names of the selected plans
    const updatedFilter = newValue.map((option) => option.name || option.title);
    setPaymentTypeFilter(updatedFilter);
  };

  // eslint-disable-next-line
  const [checkboxes, setCheckboxes] = useState<{
    [key: string]: boolean;
  }>({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
  });

  // eslint-disable-next-line
  const [markedAsFilter, setMarkedAsFilter] = useState<string>('');



  // Default value when neither checkbox is checked
  // eslint-disable-next-line
  const [categoryFilter, setCategoryFilter] = useState('');
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<string[]>([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState<string[]>([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");

  //Filter Starts

  const handlePaymentStatusFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    // console.log("Checkbox value:", value); // Debugging: Check the value attribute of the checkbox
    // console.log("Checkbox checked:", checked);
    if (checked) {
      setPaymentStatusFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setPaymentStatusFilter((prevFilters) =>
        prevFilters.filter((filter) => filter !== value)
      );
    }
    // console.log("Status Filters:", paymentStatusFilter);
  };

  let totalCashAmount = 0;
  let totalOnlineAmount = 0;

  // console.log(totalCashAmount, "totalCashAmount")

  const filteredReportData = reports?.filter((item: IReports) => {
    const paymentTypeFilterArray = paymentTypeFilter || [];
    const paymentStatusFilterArray = paymentStatusFilter || [];
    const PaymentPlanMatch =
      paymentTypeFilterArray.length === 0 ||
      paymentTypeFilterArray.includes(item?.plan?.name);
    const PaymentStatusMatch =
      paymentStatusFilterArray.length === 0 ||
      paymentStatusFilterArray.includes(item?.paymentMode);
    const amount = Number(item?.plan?.pricing) || 0;
    if (item?.paymentMode === 'CASH') {
      totalCashAmount += amount;
      // console.log(item?.paymentMode)
    } else if (item?.paymentMode === 'ONLINE') {
      totalOnlineAmount += amount;
    }
    return PaymentPlanMatch && PaymentStatusMatch;
  });

  // console.log("Total Cash Collect Amount:", totalCashAmount);
  // console.log("Total Online Collect Amount:", totalOnlineAmount);
  // console.log(filteredReportData, "filteredReported")

  const totalFinalAmount = filteredReportData?.reduce((acc, item) => {
    const finalAmount = parseFloat(item?.finalAmount || '0');
    return acc + finalAmount;
  }, 0);

  const receivedAmount = filteredReportData?.reduce((acc, item) => {
    const finalAmount = parseFloat(item?.receivedAmount || '0');
    return acc + finalAmount;
  }, 0);

  const notReceivedAmount = filteredReportData?.reduce((acc, item) => {
    const finalAmount = parseFloat(item?.notReceivedAmount || '0');
    return acc + finalAmount;
  }, 0);

  // console.log(notReceivedAmount, "notReceivedAmount");

  const handleClearButtonClick = () => {
    setMarkedAsFilter('');
    setCategoryFilter('');
    setPaymentTypeFilter([]);
    setPaymentStatusFilter([]);
    setCheckboxes({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
      checkbox8: false,
    });
  };

  const [open, setOpen] = useState(false);
  const [openxs, setOpenxs] = useState(false);

  const handleDoneButtonClick = () => {
    setOpen(false);
  };

  const totalrevenue = `Total amount of money`;

  const settledamount = `Total amount received.`;

  const unsettledamount = `Total amount pending/not yet received`;


  const filteredUsers = filteredReportData?.filter((subscriber: IReports) => {
    const searchString = searchQuery?.toLowerCase();

    const subscriberDate = new Date(subscriber?.firstPaidDate);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const isWithinDateRange =
      (!start || subscriberDate >= start) &&
      (!end || subscriberDate <= end);

    return (
      subscriber?.user?.firstName?.toLowerCase().includes(searchString) &&
      isWithinDateRange
    );
  });




  return (
    <>
      <Stack direction={'column'} spacing={1} sx={{ height: '100%' }}>
        {/* for large screen  */}
        <Stack
          direction="row"
          display={{
            xs: 'none',
            md: 'flex',
            lg: 'flex',
            xl: 'flex',
            sm: 'flex',
          }}
        >
          <Stack direction={'row'} flexGrow={1} spacing={1} alignItems={'center'}>
            <Grid item xs={12} sm={6} md={6} lg={8} xl={8}>
              <Typography
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: 600,
                  color: '#000000',
                  fontFamily: 'Montserrat',
                }}
              >
                Subscription Report
              </Typography>
            </Grid>
          </Stack>

          <Stack direction={'row-reverse'} flexGrow={1} spacing={2}>
            <TextField
              type="date"
              size="small"
              // label="End date"
              InputLabelProps={{ shrink: true }}
              sx={{
                backgroundColor: '#f9f9f9', borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
                '& .MuiChip-root': {
                  borderRadius: '8px',
                },

              }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />

            <TextField
              type="date"
              size="small"
              placeholder="Start date"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                placeholder: 'Start date',
              }}
              sx={{
                backgroundColor: '#f9f9f9', borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
                '& .MuiChip-root': {
                  borderRadius: '8px',
                },
              }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <Box component="form" noValidate autoComplete="off">
              <FormControl size="small">
                <Select
                  MenuProps={{
                    PaperProps: {
                      sx: {},
                    },
                  }}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  sx={{
                    borderRadius: '8px',
                    backgroundColor: '#F9F9F9',
                    fontFamily: 'Montserrat',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      fontFamily: 'Montserrat'
                    },
                    '& .MuiSelect-icon': {
                      color: '#757575',
                    },
                    //  fontSize: "11px" ,font:'Roboto'
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <ListFilter size={20} strokeWidth={1.5} />
                      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}> Filters</Typography>
                    </InputAdornment>
                  }
                >
                  <Accordion disableGutters={true}>
                    <AccordionSummary
                      expandIcon={<ChevronDown size={20} strokeWidth={1.5} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat', }}>
                        Payment Mode
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: '0px' }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat',
                            },
                          }}
                          control={
                            <Checkbox
                              defaultChecked
                              checked={paymentStatusFilter?.includes('CASH')}
                              onChange={handlePaymentStatusFilterChange}
                              value="CASH"
                              size="small"
                            />
                          }
                          label="Cash"
                        />
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat',
                            },
                          }}
                          control={
                            <Checkbox
                              defaultChecked
                              checked={paymentStatusFilter?.includes('ONLINE')}
                              onChange={handlePaymentStatusFilterChange}
                              value="ONLINE"
                              size="small"
                            />
                          }
                          label="Online"
                        />
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                              fontFamily: 'Montserrat',
                            },
                          }}
                          control={
                            <Checkbox
                              defaultChecked
                              checked={paymentStatusFilter?.includes(
                                'EXPIRED'
                              )}
                              onChange={handlePaymentStatusFilterChange}
                              value="EXPIRED"
                              size="small"
                            />
                          }
                          label="Expired"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  {open && (
                    <Stack direction={'row-reverse'}>
                      <Button
                        onClick={handleClearButtonClick}
                        variant="outlined"
                        size="small"
                        sx={{
                          m: 1,
                          color: '#3C5AA0',
                          textTransform: 'capitalize',
                          fontFamily: 'Montserrat',
                          border: '1px solid #3C5AA0'
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleDoneButtonClick}
                        size="small"
                        sx={{
                          m: 1,
                          backgroundColor: '#2952A2',
                          textTransform: 'capitalize',
                          fontFamily: 'Montserrat',
                          boxShadow: 'none'
                        }}
                      >
                        Done
                      </Button>
                    </Stack>
                  )}
                </Select>
              </FormControl>
            </Box>

            <Autocomplete
              sx={{
                width: '16vw',
                borderRadius: '8px',
                backgroundColor: '#F9F9F9',
                fontFamily: 'Montserrat',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  fontFamily: 'Montserrat'
                },
                '& .MuiChip-root': {
                  borderRadius: '30px',
                },
              }}
              size="small"
              limitTags={1}
              ListboxProps={{
                sx: { fontSize: '13px', font: 'Roboto', borderRadius: '30px' },
              }}
              id="community-autocomplete"
              options={plans ?? []}
              getOptionLabel={(option) => `${option?.title || option?.name}`}
              onChange={(e, newValue) => {

                setPaymentTypeFilter(newValue?.name || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Type and search Plan"
                  sx={{
                    width: '100%', borderRadius: '30px', fontFamily: 'Montserrat',
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                    },
                    '& input::placeholder': {
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      color: '#9e9e9e',
                      opacity: 1,
                    },

                  }}
                />
              )}
            />
            {/* <TextField
              placeholder="Search by user"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon1 sx={{ mt: 1 }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '30px',
                  backgroundColor: 'white',
                  height: '37px',
                  width: { xs: '150px', md: '200px' },
                  color: '#000000',
                },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            /> */}
            <Box>

            </Box>
          </Stack>
        </Stack>

        {/* for small screen */}
        <Stack
          direction="row"
          display={{
            xs: 'flex',
            md: 'none',
            lg: 'none',
            xl: 'none',
            sm: 'none',
          }}
        >
          <Stack direction={'row'} flexGrow={1}>
            <Grid
              container
              spacing={1}
              alignItems={'center'}
              justifyContent={'end'}
            >
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: { xs: '14px' },
                    fontWeight: 600,
                    color: '#000000',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Subscription Report
                </Typography>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'end'}>
                <FormControl size="small" sx={{ justifyContent: 'end' }}>
                  <Select
                    open={openxs}
                    onOpen={() => setOpenxs(true)}
                    onClose={() => setOpenxs(false)}
                    sx={{
                      backgroundColor: '#f9f9f9',
                      borderRadius: '8px',
                      '& .MuiSelect-icon': {
                        color: '#757575',
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <ListFilter strokeWidth={1.5} size={20} />
                      </InputAdornment>
                    }
                  >
                    <Accordion disableGutters={true}>
                      <AccordionSummary
                        expandIcon={<ChevronDown strokeWidth={1.5} size={20} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}>
                          Payment Mode
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: '0px' }}>
                        <Stack>
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                defaultChecked
                                checked={paymentStatusFilter?.includes('CASH')}
                                onChange={handlePaymentStatusFilterChange}
                                value="CASH"
                                size="small"
                              />
                            }
                            label="Cash"
                          />
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                defaultChecked
                                checked={paymentStatusFilter?.includes(
                                  'ONLINE'
                                )}
                                onChange={handlePaymentStatusFilterChange}
                                value="ONLINE"
                                size="small"
                              />
                            }
                            label="Online"
                          />
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Montserrat'
                              },
                            }}
                            control={
                              <Checkbox
                                defaultChecked
                                checked={paymentStatusFilter?.includes(
                                  'EXPIRED'
                                )}
                                onChange={handlePaymentStatusFilterChange}
                                value="EXPIRED"
                                size="small"
                              />
                            }
                            label="Expired"
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    {openxs && (
                      <Stack
                        direction={'row-reverse'}
                        justifyContent={'space-evenly'}
                      >
                        <Button
                          onClick={handleClearButtonClick}
                          variant="outlined"
                          size="small"
                          sx={{
                            m: 1,
                            color: '#3C5AA0',
                            textTransform: 'capitalize',
                            fontFamily: 'Montserrat',
                            border: '1px solid #355AA0'
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleDoneButtonClick}
                          size="small"
                          sx={{
                            m: 1,
                            backgroundColor: '#2952A2',
                            textTransform: 'capitalize',
                            fontFamily: 'Montserrat',
                            boxShadow: 'none',
                          }}
                        >
                          Done
                        </Button>
                      </Stack>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  placeholder="Search by user"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon1 sx={{ mt: 1 }} />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: '8px',
                      backgroundColor: '#f9f9f9',
                      height: '37px',
                      color: '#000000',
                      fontSize: '12px',
                      fontFamily: 'Montserrat'
                    },
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  sx={{
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',

                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                    '& .MuiChip-root': {
                      borderRadius: '8px',
                    },
                  }}
                  size="small"
                  limitTags={1}
                  ListboxProps={{
                    sx: { fontSize: '12px', font: 'Roboto', borderRadius: '30px' },
                  }}
                  id="community-autocomplete"
                  options={plans ?? []}
                  getOptionLabel={(option) => `${option?.title || option?.name}`}
                  onChange={(e, newValue) => {
                    setPaymentTypeFilter(newValue?.name || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose by plan"
                      // placeholder="Type and search Plan"
                      sx={{
                        width: '100%', borderRadius: '30px', fontFamily: 'Montserrat',
                        height: '37px',
                        '& .MuiInputLabel-root': {
                          fontFamily: 'Montserrat',
                        },
                      }}

                      InputLabelProps={{
                        sx: {
                          fontSize: { xs: '12px', md: '15px' },
                          mt: 0.3

                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
      {/* Reports Boxes */}

      <Stack>
        <Grid container spacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 3 }}>
          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  'linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)',
                p: { xs: 0, md: 0.5 },
                borderRadius: '10px',
                paddingBottom: '10px',
                height: { xs: '9vh', md: '19vh' },
                boxShadow: 'none'
              }}
            >
              <CardContent sx={{
                display: 'block', p: { xs: 1, md: 2 },
                "&:last-child": {
                  paddingBottom: "8px",
                },
              }}>
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TotalRevenueIcon
                    sx={{
                      color: '#ffffff',
                      fontSize: { xs: '20px', md: '30px' },
                    }}
                  />

                  <Stack sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Tooltip
                      title={totalrevenue}
                      placement="left-start"
                      sx={{ backgroundColor: 'red' }}
                      slots={{
                        transition: Fade,
                      }}
                      arrow
                      slotProps={{
                        transition: { timeout: 600 },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: "Montserrat",
                            textAlign: 'center'
                          },
                        },
                      }}
                    >
                      <Info size={20} strokeWidth={1.5} color='#ffffff' style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </Stack>
                  <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Tooltip
                      title={totalrevenue}
                      placement="left-start"
                      sx={{ backgroundColor: 'red' }}
                      slots={{
                        transition: Fade,
                      }}
                      arrow
                      slotProps={{
                        transition: { timeout: 600 },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: "Montserrat",
                            textAlign: 'center'
                          },
                        },
                      }}
                    >
                      <Info size={30} strokeWidth={1.5} color='#ffffff' style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </Stack>
                </Stack>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: '8px', md: '15px' },
                    color: '#ffffff',
                    pt: 1,
                    fontFamily: 'Montserrat'
                  }}
                >
                  Total Payment
                </Typography>
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: '8px', md: '20px' },
                      fontWeight: '500',
                      color: '#ffffff',
                      fontFamily: 'Montserrat'
                    }}
                  >
                    ₹{totalFinalAmount}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  'linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)',
                p: { xs: 0, md: 1 },
                borderRadius: '10px',
                height: { xs: '9vh', md: '19vh' },
                boxShadow: 'none'
              }}
            >
              <CardContent sx={{
                display: 'block', p: { xs: 1, md: 2 },
                "&:last-child": {
                  paddingBottom: "8px",
                },
              }}>
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <SettledBalanceIcon
                    sx={{
                      color: '#ffffff',
                      fontSize: { xs: '20px', md: '30px' },
                    }}
                  />


                  <Stack sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Tooltip
                      title={settledamount}
                      placement="left-start"
                      sx={{ backgroundColor: 'red' }}
                      slots={{
                        transition: Fade,
                      }}
                      arrow
                      slotProps={{
                        transition: { timeout: 600 },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: "Montserrat",
                            textAlign: 'center'
                          },
                        },
                      }}
                    >
                      <Info size={20} strokeWidth={1.5} color='#ffffff' style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </Stack>
                  <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Tooltip
                      title={settledamount}
                      placement="left-start"
                      sx={{ backgroundColor: 'red' }}
                      slots={{
                        transition: Fade,
                      }}
                      arrow
                      slotProps={{
                        transition: { timeout: 600 },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: "Montserrat",
                            textAlign: 'center'
                          },
                        },
                      }}
                    >
                      <Info size={30} strokeWidth={1.5} color='#ffffff' style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </Stack>
                </Stack>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: '8px', md: '15px' },
                    color: '#ffffff',
                    pt: 1,
                    fontFamily: 'Montserrat'
                  }}
                >
                  Paid
                </Typography>
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: '8px', md: '20px' },
                      fontWeight: '500',
                      color: '#ffffff',
                      fontFamily: 'Montserrat'
                    }}
                  >
                    ₹{receivedAmount}
                  </Typography>
                  <Stack display={'flex'} flexDirection={'column'}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: '6px', md: '13px' },
                        fontWeight: '500',
                        color: '#ffffff',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      Cash : {'  '}
                      {'  '} ₹ {totalCashAmount}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: '6px', md: '13px' },
                        fontWeight: '500',
                        color: '#ffffff',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      Online : ₹ {totalOnlineAmount}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  'linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)',
                p: { xs: 0, md: 1 },
                borderRadius: '10px',
                height: { xs: '9vh', md: '19vh' },
                boxShadow: 'none'
              }}
            >
              <CardContent sx={{
                display: 'block', p: { xs: 1, md: 2 },
                "&:last-child": {
                  paddingBottom: "8px",
                },
              }}>
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <UnSettledBalanceIcon
                    sx={{
                      color: '#ffffff',
                      fontSize: { xs: '20px', md: '30px', fontFamily: 'Montserrat' },
                    }}
                  />
                  <Stack sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Tooltip
                      title={unsettledamount}
                      placement="left-start"
                      sx={{ backgroundColor: 'red' }}
                      slots={{
                        transition: Fade,
                      }}
                      arrow
                      slotProps={{
                        transition: { timeout: 600 },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: "Montserrat",
                            textAlign: 'center'
                          },
                        },
                      }}
                    >
                      <Info size={20} strokeWidth={1.5} color='#ffffff' style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </Stack>
                  <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Tooltip
                      title={unsettledamount}
                      placement="left-start"
                      sx={{ backgroundColor: 'red' }}
                      slots={{
                        transition: Fade,
                      }}
                      arrow
                      slotProps={{
                        transition: { timeout: 600 },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: "Montserrat",
                            textAlign: 'center'
                          },
                        },
                      }}
                    >
                      <Info size={30} strokeWidth={1.5} color='#ffffff' style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </Stack>
                </Stack>

                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: '8px', md: '15px' },
                    color: '#ffffff',
                    pt: 1,
                    fontFamily: 'Montserrat'
                  }}
                >
                  Unpaid
                </Typography>
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: '8px', md: '20px' },
                      fontWeight: '500',
                      color: '#ffffff',
                      fontFamily: 'Montserrat'
                    }}
                  >
                    ₹{notReceivedAmount}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>

      <Stack direction={'column'} spacing={1}>
        <SubNavbar>
          <Stack direction={'row'} spacing={5}></Stack>
        </SubNavbar>
        <Paper elevation={0} sx={{ borderRadius: '12px' }}>
          <Stack>
            {loading ? (
              <Loader />
            ) : (
              <>
                {filteredUsers?.length === 0 ? (
                  <Reports alternatePage={<NoReports />} data={filteredUsers || []} />
                ) : (
                  <>
                    <Reports alternatePage={''} data={filteredUsers || []} />
                  </>
                )}
              </>
            )}
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};
// function setPaymetCycleFilter(arg0: string) {
//   throw new Error("Function not implemented.");
// }
