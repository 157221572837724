import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import Avatar from '@mui/material/Avatar';
import deepOrange from '@mui/material/colors/deepOrange';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Fade,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
  // Tooltip,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { getStaticValue } from '../../utils/StaticValues';
import React from 'react';
import { IColumn } from '../../models/table.model';
import { CustomTableHeader } from './components/CustomTableHeader';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IReports } from '../../models/reports.model';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { BellRing, ChevronLeft, ChevronRight } from 'lucide-react';
import { IPaymentNotify } from '../../models/subscription.model';
import { sendPaymentNotify } from '../../services/subscription.service';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { useSnackbar } from 'notistack';
// import { GetColor } from './GetColor';

interface ITableProps {
  data: any;
  alternatePage?: React.ReactNode;
}
const columns: IColumn[] = [
  { title: 'Member ', dataKey: 'Payeedetails' },
  { title: 'Plan Name', dataKey: 'planname' },
  { title: 'Cycle', dataKey: 'paymentcycle' },
  { title: 'Amount(₹)', dataKey: 'amount' },
  { title: 'Mode', dataKey: 'mode' },
  { title: 'Date Paid', dataKey: 'date' },
  { title: 'Due on', dataKey: 'comments' },
  { title: 'Status', dataKey: 'status' },
];
export const Reports = ({ data, alternatePage }: ITableProps) => {
  const [page, setPage] = useState(10);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [notify, setNotify] = useState<boolean>(false);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  useEffect(() => {
    handleChangePage(null, 0);
  }, [data]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;

  const [sortDirections, setSortDirections] = useState<{
    [key: string]: 'asc' | 'desc' | undefined;
  }>({});
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
      let newSortedColumns: string[] = [];

      if (prevSortDirections[columnTitle] === 'asc') {
        newSortDirections[columnTitle] = 'desc';
      } else {
        newSortDirections[columnTitle] = 'asc';
      }

      newSortedColumns.push(columnTitle);
      setSortedColumns(newSortedColumns);
      return newSortDirections;
    });
  };

  const sortedColumnTitles = sortedColumns.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );
  if (sortedColumnTitles.length > 0) {
    data?.sort((a: any, b: any) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns.find((col) => col.title === columnTitle);
        if (column) {
          if (column.dataKey === 'Payeedetails') {
            const aValue = a?.user?.firstName;
            const bValue = b?.user?.firstName;
            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === 'community') {
            const aValue = a?.udf3?.name;
            const bValue = b?.udf3?.name;
            if (sortDirection === 'asc') {
              return aValue < bValue;
            } else {
              return bValue > aValue;
            }
          } else if (column?.dataKey === 'planname') {
            const aValue = a?.plan?.name;
            const bValue = b?.plan?.name;
            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === 'paymentcycle') {
            const aValue = a?.udf2?.offerEndsDuration;
            const bValue = b?.udf2?.offerEndsDuration;
            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column.dataKey === 'mode') {
            const aValue = a?.paymentMode;
            const bValue = b?.paymentMode;
            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column.dataKey === 'amount') {
            const aValue = a[column?.dataKey];
            const bValue = b[column?.dataKey];

            if (sortDirection === 'asc') {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column.dataKey === 'date') {
            const aValueDate = new Date(a?.createdAt);
            const bValueDate = new Date(b?.createdAt);
            if (aValueDate.getTime() !== bValueDate.getTime()) {
              if (sortDirection === 'asc') {
                return aValueDate?.getTime() - bValueDate?.getTime();
              } else {
                return bValueDate?.getTime() - aValueDate?.getTime();
              }
            } else {
              const aValueTime = new Date(`${a?.createdAt}T${a?.createdAt}`);
              const bValueTime = new Date(
                `${b?.createdAt?.date}T${b?.createdAt?.time}`
              );
              if (sortDirection === 'asc') {
                return aValueTime?.getTime() - bValueTime?.getTime();
              } else {
                return bValueTime?.getTime() - aValueTime?.getTime();
              }
            }
          } else {
            if (sortDirection === 'asc') {
            } else {
            }
          }
        }
      }
      return 0;
    });
  }

  const paginatedData = data?.slice(startIndex, endIndex);

  // console.log(paginatedData, "paginatedData")

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);

  const { enqueueSnackbar } = useSnackbar();


  const NotifyPayment = async (subscriptionId: string) => {
    try {
      setNotify(true);
      const subscriptionNotify: IPaymentNotify = {
        notify: true,
      };
      const response = await sendPaymentNotify(getAccessToken(), subscriptionId || "", subscriptionNotify);
      enqueueSnackbar("Notification sent", { variant: "success" });
      return response;
    } catch (err) {
      console.log(err, "err");
    }
  };

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'block' }, borderRadius: '12px !important', }}>
        <SimpleBar style={{ height: '63vh', overflowY: 'auto' }}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, }}>
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{
                height: '55vh',
                borderRadius: '12px !important',
                border: '1px solid #E7EBF1',
              }}
            >
              <Table
                sx={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  '@media (max-width: 1500px)': {
                    width: '100%',
                  },
                  '& th': {
                    fontSize: '12px',
                    fontWeight: 700,
                    color: '#565656',
                    fontFamily: 'Montserrat',
                    p: 1.5,
                  },
                  '& td': {
                    fontSize: '15px',
                    fontWeight: 500,
                    fontFamily: 'Montserrat',
                    color: '#1A1A1A',
                    p: '5px 10px',
                    '& .MuiTypography-root': {
                      m: 0,
                      fontSize: '11px',
                      width: '100px',
                    },
                  },
                }}
              >
                <TableHead
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: '#f9f9f9',
                  }}
                >
                  <TableRow sx={{ ml: 0 }}>
                    {columns.map((column: IColumn, i: number) => (
                      <TableCell
                        key={i}
                        onClick={() => handleSort(column?.title)}
                        sx={{}}
                      >
                        <CustomTableHeader
                          title={column?.title}
                          sortDirection={sortDirections[column?.title]}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ ml: 1 }}>
                  {paginatedData?.map((order: IReports, i: number) => (
                    <TableRow hover key={i}>
                      <TableCell sx={{ width: '250px' }}>
                        {columns[0].dataKey === 'Payeedetails' && (
                          <>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              sx={{}}
                            >
                              <Link to={`/subscribers/${order?.subscriptionId}`}>
                                <Avatar
                                  sx={{
                                    bgcolor: deepOrange[500],
                                    height: '25px',
                                    width: '25px',
                                    fontSize: '11px',
                                  }}
                                  alt="profile"
                                  src={order?.user?.avatar}
                                />
                              </Link>

                              <Box>
                                <Tooltip
                                  title={order?.user?.firstName}
                                  arrow
                                  sx={{ backgroundColor: 'red' }}
                                  slots={{
                                    transition: Fade,
                                  }}

                                  slotProps={{
                                    transition: { timeout: 600 },
                                  }}
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        fontFamily: "Montserrat",
                                        textAlign: 'center'
                                      },
                                    },
                                  }}
                                >
                                  <Typography sx={{ fontSize: '11px', fontFamily: 'Montserrat', }}>
                                    {order?.user?.firstName
                                      ? order?.user?.firstName?.length > 14
                                        ? `${order?.user?.firstName?.substring(0, 14)}...`
                                        : order?.user?.firstName
                                      : "Nil"}
                                  </Typography>
                                </Tooltip>
                                {/* <Typography sx={{ fontFamily: 'Montserrat', }}>
                                  {order?.user?.phoneNumber}
                                </Typography> */}

                              </Box>
                            </Stack>
                          </>
                        )}
                      </TableCell>

                      <TableCell sx={{ width: '250px' }}>
                        {columns[1].dataKey === "planname" && (
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#1A1A1A",
                              fontFamily: "Montserrat",
                            }}
                          >
                            <Tooltip
                              title={order?.plan?.name || "N/A"}
                              placement="bottom"
                              slots={{ transition: Fade }}
                              arrow
                              slotProps={{ transition: { timeout: 600 } }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontFamily: "Montserrat",
                                    textAlign: "center",
                                  },
                                },
                              }}
                            >
                              <Stack
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color: "#1A1A1A",

                                  fontFamily: "Montserrat",
                                }}
                              >
                                {order?.plan?.name
                                  ? order.plan.name.length > 10
                                    ? `${order.plan.name.substring(0, 10)}...`
                                    : order.plan.name
                                  : "N/A"}
                              </Stack>
                            </Tooltip>
                          </Typography>
                        )}



                      </TableCell>
                      <TableCell sx={{ width: '150px' }}>
                        {columns[2].dataKey === 'paymentcycle' && (
                          <Typography
                            variant="caption"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              textTransform: 'capitalize',
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#1A1A1A',
                              textAlign: 'center',
                              fontFamily: 'Montserrat'
                            }}
                          >
                            {order?.plan?.interval}{' '}
                            {getStaticValue(order?.plan?.duration)}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '250px' }}>
                        {columns[3].dataKey === 'amount' && (
                          <>
                            <Typography
                              variant="caption"
                              sx={{
                                display: 'flex',
                                textTransform: 'capitalize',
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#1A1A1A',
                                fontFamily: 'Montserrat'
                              }}
                            >
                              &#8377;
                              {order?.plan?.pricing}

                            </Typography>
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '250px' }}>
                        {columns[4].dataKey === 'mode' && (
                          <Typography sx={{ fontFamily: 'Montserrat' }}>
                            {getStaticValue(order?.paymentMode)}
                          </Typography>
                        )}
                      </TableCell>
                      {/* <TableCell sx={{ width: "250px" }}>
                      {columns[6].dataKey === "mode" && (
                        <>
                          <Typography>{order?.mode}</Typography>
                        </>
                      )}
                    </TableCell> */}

                      <TableCell sx={{ width: '150px' }}>
                        {columns[5].dataKey === 'date' && (
                          <Typography
                            variant="caption"
                            sx={{
                              display: 'flex',
                              textTransform: 'capitalize',
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#1A1A1A',
                              fontFamily: 'Montserrat'
                            }}
                          >
                            {new Date(order?.lastPaidDate).toLocaleString(
                              'en-GB',
                              {
                                timeZone: 'Asia/Kolkata',
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                                // hour: '2-digit',
                                // minute: '2-digit',
                                // second: '2-digit',
                                // hour12: false,
                              }
                            )}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell sx={{ width: '250px' }}>
                        <Stack
                          direction={'row'}
                          spacing={2}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Grid
                            container
                            direction={'row'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Grid item xs={12} md={6} lg={6} xl={4}>
                              <Typography
                                variant="caption"
                                sx={{
                                  display: 'flex',
                                  textTransform: 'capitalize',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  color: '#1A1A1A',
                                  fontFamily: 'Montserrat'
                                }}
                              >
                                {order?.dueDate === "forever"
                                  ? "Forever"
                                  : `${new Date(order?.dueDate).toLocaleDateString('en-GB', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit',
                                  })}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} xl={6}></Grid>
                          </Grid>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack
                          display={'flex'}
                          direction={'row'}
                          justifyContent={'space-between'}
                          // alignItems={'flex-start'}
                          sx={{ boxShadow: 'none' }}>
                          <Typography
                            sx={{
                              display: 'flex',
                              textTransform: 'capitalize',
                              fontSize: '14px',
                              fontWeight: 400,
                              color: '#1A1A1A',
                              // textAlign: 'left',
                              fontFamily: 'Montserrat',

                            }}
                          >
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#1A1A1A',
                                fontFamily: 'Montserrat'
                              }}
                            >
                              {new Date(order?.dueDate) < new Date() ||
                                order?.paymentMode === 'NOT_STARTED' ? (
                                <>
                                  <FiberManualRecordIcon
                                    sx={{ color: 'red', fontSize: '11px' }}
                                  />
                                  Not Paid
                                </>
                              ) : (
                                <>
                                  <FiberManualRecordIcon
                                    sx={{ color: '#4caf50', fontSize: '11px' }}
                                  />
                                  Paid
                                </>
                              )}
                            </Typography>
                            <Tooltip
                              title={`Send the payment link for ${order?.plan?.name} subscription to ${order?.user?.firstName} via WhatsApp and email for payment completion.`}
                              slots={{
                                transition: Fade,
                              }}
                              arrow
                              slotProps={{
                                transition: { timeout: 600 },
                              }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontFamily: "Montserrat",
                                    textAlign: 'center'
                                  },
                                },
                              }}
                            >
                              <IconButton
                                onClick={async () => {
                                  await NotifyPayment(order?.subscriptionId);
                                }}
                              >
                                <BellRing
                                  style={{ color: '#646464' }}
                                  size={20} strokeWidth={1.5}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableBody
                  sx={{
                    '& td': {
                      borderColor: '#E0E0E0',
                      fontSize: '13px',
                      fontWeight: 400,
                      color: '#565656',
                      fontFamily: 'Montserrat',

                      '& .MuiTypography-root': { fontSize: '15px', fontFamily: 'Montserrat' },
                    },
                  }}
                >
                  <TableRow>
                    <TableCell
                      colSpan={columns?.length + 1}
                      style={{ textAlign: 'center', border: 'none' }}
                    >
                      {alternatePage}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Stack
              direction={'row-reverse'}
              alignItems={'center'}
              sx={{
                color: '#A5A5A5',
                fontSize: { xs: '10px', md: '1.5vh' },
                pr: { xs: '0px', md: '25px' },
              }}
            >
              <Typography sx={{ color: '#A5A5A5', fontSize: '13px', fontFamily: 'Montserrat' }}>
                {data?.length > 0
                  ? `Showing ${Math.min(
                    rowsPerPage,
                    data.length - page * rowsPerPage
                  )} of ${data.length} entries`
                  : 'Showing 0 of 0 entries'}
              </Typography>
              <TablePagination
                rowsPerPageOptions={[8, 10, 15, 20]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={
                  <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
                }
                labelDisplayedRows={() => null}
                SelectProps={{
                  SelectDisplayProps: {
                    style: { border: '1px solid #A5A5A5', borderRadius: '5px', fontFamily: 'Montserrat', fontSize: '14px' },
                  },

                  MenuProps: {
                    PaperProps: { style: { border: '1px solid #A5A5A5', fontFamily: 'Montserrat', fontSize: '14px' } },
                  },
                }}
                ActionsComponent={({ onPageChange, page }) => {
                  const totalPages = Math.ceil(data?.length / rowsPerPage);
                  return (
                    <Stack
                      direction={'row'}
                      display="flex"
                      alignItems="center"
                      justifyContent={'space-between'}
                    >
                      {/* <IconButton
                        onClick={(event) => onPageChange(event, 0)} 
                        disabled={page === 0}
                        aria-label="go to first page"
                      >
                        <KeyboardDoubleArrowLeftIcon />
                      </IconButton> */}

                      <IconButton
                        onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                        disabled={page === 0}
                        aria-label="go to previous page"
                      >
                        <ChevronLeft size={20} strokeWidth={1.5} />
                      </IconButton>

                      {[...Array(3)].map((_, index) => {
                        const pageNumber = page + index;
                        if (pageNumber < totalPages) {
                          return (
                            <IconButton
                              key={pageNumber}
                              onClick={(event) =>
                                onPageChange(event, pageNumber)
                              }
                              style={{
                                fontSize: '1.9vh',
                                border:
                                  page === pageNumber
                                    ? '1px solid #3C5AA0'
                                    : 'none', // Highlight the active page with a border
                                color:
                                  page === pageNumber ? '#3C5AA0' : '#000000', // Highlight the active page with a color
                                width: page === pageNumber ? '30px' : '30px',
                                height: page === pageNumber ? '30px' : '30px',
                              }}
                            >
                              {pageNumber + 1}
                            </IconButton>
                          );
                        } else {
                          return null; // Don't render the button if it's out of bounds
                        }
                      })}

                      <IconButton
                        onClick={(event) => onPageChange(event, page + 1)}
                        disabled={page >= totalPages - 1}
                        aria-label="next page"
                      >
                        <ChevronRight size={20} strokeWidth={1.5} />
                      </IconButton>

                      {/* <IconButton
                        onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                        aria-label="go to last page"
                      >
                        <KeyboardDoubleArrowRightIcon />
                      </IconButton> */}
                    </Stack>
                  );
                }}
              />
            </Stack>
          </Box>
        </SimpleBar>
      </Box>
      <Stack
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: { xs: 'block', md: 'none' },
          mb: 5,
        }}
      >
        {paginatedData?.map((order: IReports, i: number) => (
          <Accordion sx={{ boxShadow: 'none' }} key={i}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                    }}
                  >
                    {order?.user?.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      textTransform: 'capitalize',
                      justifyContent: 'end',
                      fontFamily: 'Montserrat'
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'Montserrat',
                        color: '#1A1A1A',
                      }}
                    >
                      {new Date(order?.dueDate) < new Date() ||
                        order?.paymentMode === 'NOT_STARTED' ? (
                        <>
                          <FiberManualRecordIcon
                            sx={{ color: 'red', fontSize: '11px' }}
                          />
                          Not Paid
                        </>
                      ) : (
                        <>
                          <FiberManualRecordIcon
                            sx={{ color: '#4caf50', fontSize: '11px' }}
                          />
                          Paid
                        </>
                      )}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <Stack display={'flex'} flexDirection={'column'}>
                    <Typography sx={{ color: '#565656', fontSize: '10px', fontFamily: 'Montserrat' }}>
                      {order?.plan?.name}
                    </Typography>
                    <Typography sx={{ color: '#565656', fontSize: '10px', fontFamily: 'Montserrat' }}>
                      {order?.plan?.interval}{' '}
                      {getStaticValue(order?.plan?.duration)}
                    </Typography>
                    <Typography sx={{ color: '#565656', fontSize: '10px', fontFamily: 'Montserrat' }}>
                      Paid on :{' '}
                      {/* {(() => {
                        const originalDate = new Date(order?.lastPaidDate);
                        const istDate = new Date(
                          originalDate.getTime() + 5.5 * 60 * 60 * 1000
                        );
                        return istDate.toLocaleString('en-GB', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          // hour: '2-digit',
                          // minute: '2-digit',
                          // second: '2-digit',
                          // hour12: false,
                        });
                      })()} */}
                      {new Date(order?.lastPaidDate).toLocaleString(
                        'en-GB',
                        {
                          timeZone: 'Asia/Kolkata',
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',

                        }
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack display={'flex'} flexDirection={'column'}>
                    <Typography
                      sx={{
                        color: '#565656',
                        fontSize: '14px',
                        textAlign: 'end',
                        fontWeight: 500,
                        fontFamily: 'Montserrat'
                      }}
                    >
                      &#8377;
                      {order?.plan?.pricing}
                    </Typography>

                    <Typography
                      sx={{
                        color: '#565656',
                        fontSize: '10px',
                        textAlign: 'end',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      {/* {order?.mode} */}
                      Due on :{' '}
                      {/* {(() => {
                        const originalDate = new Date(order?.dueDate);
                        const istDate = new Date(
                          originalDate.getTime() + 5.5 * 60 * 60 * 1000
                        );
                        return istDate.toLocaleString('en-GB', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        });
                      })()} */}
                      {order?.dueDate === "forever"
                        ? "Forever"
                        : `${new Date(order?.dueDate).toLocaleDateString('en-GB', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })}`}
                    </Typography>
                    {/* {order?.addedon ? (
                      <Typography
                        sx={{
                          color: "#565656",
                          fontSize: "10px",
                          textAlign: "end",
                        }}
                      >
                        {(() => {
                          const originalDate = new Date(order?.addedon);
                          const istDate = new Date(
                            originalDate.getTime() + 5.5 * 60 * 60 * 1000
                          );
                          return istDate.toLocaleString("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                          });
                        })()}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            color: "#565656",
                            fontSize: "10px",
                            textAlign: "end",
                          }}
                        >
                          {(() => {
                            const originalDate = new Date(order?.createdAt);
                            const istDate = new Date(
                              originalDate.getTime() + 5.5 * 60 * 60 * 1000
                            );
                            return istDate.toLocaleString("en-GB", {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false,
                            });
                          })()}
                        </Typography>
                      </>
                    )} */}
                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Divider></Divider>
        <TablePagination
          rowsPerPageOptions={[8, 10, 15, 20]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
          }
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: {
                border: '1px solid #A5A5A5',
                borderRadius: '5px',
                backgroundColor: 'white',
                fontFamily: 'Montserrat', fontSize: '14px'
              },
            },

            MenuProps: {
              PaperProps: { style: { border: '1px solid #A5A5A5', fontFamily: 'Montserrat', fontSize: '14px' } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(data?.length / rowsPerPage);
            return (
              <Stack
                direction={'row'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
                sx={{ p: 0, backgroundColor: 'white' }}
              >
                {/* <IconButton
                  onClick={(event) => onPageChange(event, 0)}
                  disabled={page === 0}
                  aria-label="go to first page"
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton> */}

                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)}
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <ChevronLeft size={20} strokeWidth={1.5} />
                </IconButton>
                {[...Array(2)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: '14px',
                          border:
                            page === pageNumber ? '1px solid #3C5AA0' : 'none',
                          color: page === pageNumber ? '#3C5AA0' : '#000000',
                          width: page === pageNumber ? '30px' : '30px',
                          height: page === pageNumber ? '30px' : '30px',
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  } else {
                    return null;
                  }
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)}
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <ChevronRight size={20} strokeWidth={1.5} />
                </IconButton>

                {/* <IconButton
                  onClick={(event) => onPageChange(event, totalPages - 1)}
                  aria-label="go to last page"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton> */}
              </Stack>
            );
          }}
        />
      </Stack>
    </>
  );
};
