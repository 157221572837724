import React, { useContext, useEffect } from 'react';
import {
    // Autocomplete,
    Box,
    Button,
    // Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    CircularProgress,
    FormGroup,
    FormControlLabel,
    Checkbox,
    AccordionDetails,
    Accordion,
    AccordionSummary,
    RadioGroup,
    Radio,
} from '@mui/material';
import { useState } from 'react';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createPlan, getPlanById, updatePlanById } from '../../services/plans.service';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { ICommunity } from '../../models/communities.modal';
import store from '../../store';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Loader from '../Loader/Loader';
import { IMultiMedia } from '../../models/multi-media.model';
import Close from '@mui/icons-material/Close';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin
import 'dayjs/locale/en'; // Import the English locale
import { ExpandMore } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import BreadcrumbDouble from '../AlternatePages/BreadCumsDouble';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { IndianRupee } from 'lucide-react';

dayjs.extend(utc);

interface Community {
    name: string;
    _id: string;
}

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}
//
export interface IAddPlan {
    name: string;
    duration: string;
    interval: number;
    startDate: string;
    endDate: boolean;
    status: string;
    billingFrequency: string[];
    totalPlanValue: number;
    offerValue: number;
    promoCode: string;
    description: string;
    summary: string;
    image: IMultiMedia | '';
    document: string;
    community: string;
    images: IMultiMedia | '';
    isOfferEnds: boolean;
    offerEndsAfter: number;
    offerEndsDuration: string;
    offerEndDate: string;
    totalCount: string;
}


export interface ICreatePlan {
    name: string;
    description: string;
    duration: string;
    interval: string;
    status: string;
    pricing: number;
    community: string;
    initialPayment: number;
    image: string | null;
    document: string | null;
    totalSequences: number;
    endDate: string | null;
    isSequenceAvailable: boolean;
    endDateDurationCount: string;
    endDateDuration: string;

}


interface ISelectedCommunity {
    selectedCommunity: ICommunity[];
    closeDialog: () => void;
    loadPlans: () => void;
}

// duration dropdown values
const durationDropdownOptions: { key: string; value: string; label: string }[] =
    [
        { key: 'YEAR', value: 'Year', label: 'Year' },
        { key: 'MONTH', value: 'Month', label: 'Month' },
        { key: 'WEEK', value: 'Week', label: 'Week' },
        { key: 'DAY', value: 'Day', label: 'Day' },
        { key: 'ONE_TIME', value: 'One Time', label: 'One Time' },

    ];



const expireDropdownOptions: { key: string; value: string; label: string }[] =
    [
        { key: 'YEAR', value: 'Year', label: 'Year' },
        { key: 'MONTH', value: 'Month', label: 'Month' },
        { key: 'WEEK', value: 'Week', label: 'Week' },
        { key: 'DAY', value: 'Day', label: 'Day' },
    ];


export const CreatePlan: React.FC<ISelectedCommunity> = ({
    closeDialog,
    loadPlans,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = store.getState();
    const communitiesFromStore = useSelector(() => {
        return state?.communities;
    });
    const { communities } = communitiesFromStore;
    const communityState = store.getState();
    const currentCommunity = communityState.selectedCommunity.selectedCommunity;

    //   console.log(currentCommunity?.name);



    const { getAccessToken } = useContext<IAuthContext>(AuthContext);
    const [community, setCommunity] = React.useState(false);
    const [options, setOptions] = React.useState<Community[]>([]);
    const loading = community && options.length === 0;
    const [loadingButton, setLoadingButton] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    //label change logic start
    const [durationFieldValue, setDurationFieldValue] = useState<string>('');
    const [endDateDuration, setEndDateDuration] = useState<string>('');
    const [labelText, setLabelText] = useState('Year');
    const [open, setOpen] = React.useState(false);
    const [opensPublish, setOpensPublish] = React.useState(false);
    const [planDocuments, setPlanDocuments] = useState<FileList | null>(null);
    const [subscribersCount, setSubscribersCount] = useState<any>();
    const [selectedOption, setSelectedOption] = useState("sequence");
    const [oneTimeExpiry, setOneTimeExpiry] = useState("forever");
    const [error, setError] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(null);
    const [loader, setLoader] = useState(false);
    const [plans, setPlans] = useState<IAddPlan[]>([]);



    const { id } = useParams();

    const fetchPlan = async () => {
        const { data } = await getPlanById(id || "", getAccessToken());
        setSubscribersCount(data);
        const labelValue: any = durationDropdownOptions.find((e) => e.key === data.duration);
        const labelEndDateValue: any = expireDropdownOptions.find((e) => e.key === data.endDateDuration);
        setEndDateDuration(labelEndDateValue?.value)

        setOneTimeExpiry(data.endDateDuration === "" && data?.endDateDurationCount === "" ? "forever" : "expiry");
        setSelectedOption(
            data?.isSequenceAvailable || data?.totalSequences > 0
                ? "sequence"
                : "non-sequence"
        );
        setDurationFieldValue(labelValue?.value);
        setIsChecked(!!data.initialPayment);
        setFormValues({
            ...formValues,
            name: data.name,
            duration: data.duration,
            interval: data.interval,
            status: data.status,
            description: data.description,
            community: data.community,
            image: data?.image,
            document: data?.document,
            endDate: data?.endDate,
            pricing: data?.pricing,
            initialPayment: data?.initialPayment || "",
            totalSequences: data?.totalSequences,
            endDateDurationCount: data?.endDateDurationCount,
            endDateDuration: data?.endDateDuration
        });
        if (data.endDate) {
            setSelectedStartDate(data.endDate);
        }
    };




    useEffect(() => {
        if (!id) return;
        fetchPlan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    React.useEffect(() => {
        if (!loading) {
            return undefined;
        }
        let active = true;
        (async () => {
            let tempCommunity = communities.map((community) => {
                return {
                    _id: community._id,
                    name: community.name,
                };
            });
            await sleep(1e3); // For demo purposes.
            if (active) {
                setOptions([...tempCommunity]);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (durationFieldValue) {
            const labelValue: any = durationDropdownOptions.find(
                (e) => e.value === durationFieldValue
            );
            setLabelText(`Set ${labelValue?.value}s`);
        }
    }, [durationFieldValue]);



    const [planValues, setPlanValues] = useState<IAddPlan>({
        name: '',
        duration: '',
        interval: 0,
        startDate: '',
        endDate: false,
        billingFrequency: [],
        status: '',
        totalPlanValue: 0,
        offerValue: 0,
        promoCode: '',
        description: '',
        summary: '',
        image: '',
        images: '',
        document: '',
        community: currentCommunity?._id ?? '',
        isOfferEnds: true,
        offerEndsAfter: 0,
        offerEndsDuration: 'Year',
        offerEndDate: '',
        totalCount: '',
    });



    const { uploadImages } = useWebBuilders();


    const [formValues, setFormValues] = useState<ICreatePlan>({
        name: '',
        description: '',
        duration: '',
        interval: '',
        endDate: '',
        pricing: 0,
        initialPayment: 0,
        totalSequences: 0,
        image: '',
        document: '',
        community: currentCommunity?._id ?? '',
        status: '',
        isSequenceAvailable: false,
        endDateDurationCount: '',
        endDateDuration: '',


    });


    // useEffect(() => {
    //     setFormValues((prevValues) => ({
    //         ...prevValues,
    //         totalSequences: prevValues.duration === 'ONE_TIME' ? 0 : 15,
    //     }));
    // }, [formValues?.duration]);

    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    };

    // const handleMyDateChange = (date: Dayjs | null) => {
    //     if (!date) return;
    //     const isoDateString = date.toISOString();
    //     setFormValues({ ...formValues, endDate: isoDateString });
    // };

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setError(value === '');
    };


    const handleImageChange = async (files: FileList | null) => {
        if (!files?.length) return;
        try {
            const file = files[0];
            const [response] = await uploadImages([file]);
            const uploadedImageUrl = response?._id;
            if (!uploadedImageUrl) {
                throw new Error('Image upload failed: No response value');
            }
            setFormValues((prev) => ({
                ...prev,
                image: uploadedImageUrl,
            }));
            enqueueSnackbar('Plan Image uploaded successfully', { variant: 'success' });
        } catch (error) {
            console.error('Error uploading image:', error);
            enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
        } finally {
        }
    };


    const handleSubmit = async (e: any, status: string) => {
        e.preventDefault();
        setLoadingButton(true);
        setLoader(true);
        formValues.community = currentCommunity?._id ?? '';
        formValues.status = status;
        const updatedFormValues = {
            ...formValues,
            image: formValues.image ? formValues.image : null,
            document: formValues.document ? formValues?.document : null,
            isSequenceAvailable: formValues.isSequenceAvailable ?? false,

        };
        try {
            if (id) {
                const updateResponse = await updatePlanById(id, getAccessToken(), formValues,);
                if (updateResponse && updateResponse.status === 200) {
                    enqueueSnackbar('Plan updated successfully', { variant: 'success' });
                    navigate('/plans');
                    closeDialog();
                } else {
                    enqueueSnackbar('Failed to update plan', { variant: 'error' });
                }
            } else {
                const createResponse = await createPlan(getAccessToken(), updatedFormValues);
                setLoader(true);
                if (createResponse && createResponse?.status === 201) {
                    setPlans((prevPlans) => [...prevPlans, createResponse.data]);
                    enqueueSnackbar('Plan created successfully', { variant: 'success' });
                    navigate('/plans');
                    if (location.pathname === '/plans' || location.pathname === '/create-plan') {
                        navigate('/plans');
                    } else if (location.pathname === '/communities/create-community') {
                        navigate('/communities/create-community');
                    } else if (location.pathname === '/get-started') {
                        navigate('/get-started');
                    }
                    closeDialog();
                    loadPlans();
                } else {
                    enqueueSnackbar('Failed to create plan', { variant: 'error' });
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoader(false);
            setLoadingButton(false);
        }
    };


    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');


    useEffect(() => {
        setFormValues((prevValues) => ({
            ...prevValues,
            isSequenceAvailable: selectedOption === "sequence",
        }));
    }, [selectedOption]);


    const handleDurationChange = (duration: string) => {
        setFormValues((prevValues) => {
            let updatedValues = { ...prevValues, duration };
            if (duration === "ONE_TIME") {
                updatedValues.isSequenceAvailable = false;
            } else if (selectedOption === "sequence" && (updatedValues.totalSequences ?? 0) === 0) {
                updatedValues.isSequenceAvailable = true;
            } else if (selectedOption === "non-sequence") {
                updatedValues.isSequenceAvailable = false;
            }

            // console.log("Updated Values:", updatedValues);

            return updatedValues;
        });
    };


    const handleEndDurationChange = (endDateDuration: string) => {
        setFormValues((prevValues) => {
            let updatedValues = { ...prevValues, endDateDuration };
            return updatedValues;
        });
    };








    // console.log(selectedOption, "selectedOption")


    const handlePlanDocuments = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setPlanDocuments(e.target.files);
        }
    };

    const handleClickOpenImg = () => {
        setOpen(true);
    };

    const handleClosesImg = () => {
        setOpen(false);
    };

    const handleClickOpenPublish = () => {
        setOpensPublish(true);
    };

    const handleClickClosesPublish = () => {
        setOpensPublish(false);
    };

    const locations = useLocation();
    const IsTitleDisplay = locations.pathname.includes('/create-plan') && id;

    const isDataAvailable =
        formValues.name &&
        formValues.duration &&
        formValues.pricing



    return (
        <>
            <Helmet>
                <title>{metaConfig.title.createPlan}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>
            <div>
                <BreadcrumbDouble
                    items={[
                        { label: 'Plans', path: '/plans' },
                        { label: `${id ? "Edit Plan" : "Create Plan"}` }
                    ]}
                />
                {loading ? (
                    <Loader />
                ) : (
                    <Stack direction={'column'} spacing={1}>
                        <Stack sx={{ display: IsTitleDisplay ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    color: '#3760A9',
                                    fontFamily: 'Montserrat',
                                }}
                            >
                                {id ? 'Publish' : 'Create'} a Plan
                            </Typography>
                        </Stack>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                pl: 1,
                                pr: 1,
                                pb: 2,
                                borderRadius: '12px !important', border: '1px solid #E7EBF1',
                            }}
                        >
                            <>
                                <Box
                                    sx={{
                                        // backgroundColor: '#E0E0E04D',
                                        p: { xs: 1, md: 2 },
                                        borderRadius: '10px',
                                        mt: 1,
                                    }}
                                >
                                    <Grid container sx={{ borderRadius: '10px' }}>
                                        <Grid item xs={12} md={12}>
                                            <Stack spacing={1} sx={{ m: 1 }}>
                                                <Stack>
                                                    <Stack>
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: '14px',
                                                                color: '#000000',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Plan Name
                                                        </Typography>
                                                        <TextField
                                                            required
                                                            name="name"
                                                            label="Name"
                                                            size="small"
                                                            onChange={handleChanges}
                                                            value={formValues?.name}

                                                            InputLabelProps={{
                                                                style: { fontSize: '13px', color: '#565656' },
                                                            }}
                                                            sx={{
                                                                mt: 1,
                                                                mr: 1,
                                                                fontSize: '13px',
                                                                backgroundColor: '#F9F9F9',
                                                                borderRadius: '8px',
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Montserrat',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius: '8px',
                                                                },
                                                                '& input': {
                                                                    fontFamily: 'Montserrat',

                                                                },
                                                            }}
                                                        ></TextField>
                                                        <Typography
                                                            sx={{
                                                                fontSize: { xs: '10px', md: '11px' },
                                                                color: '#5E6C84',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Enter a unique name for your plan
                                                        </Typography>
                                                    </Stack>

                                                </Stack>

                                                <Stack>
                                                    <Stack>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                color: '#000000',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Plan Description
                                                        </Typography>
                                                        <TextField
                                                            name="description"
                                                            id="description"
                                                            //placeholder="Describe your plan in short words..."
                                                            label="Description"
                                                            size="medium"
                                                            value={formValues?.description}
                                                            onChange={handleChanges}
                                                            required
                                                            multiline
                                                            rows={2}
                                                            InputLabelProps={{
                                                                style: { fontSize: 13, color: '#565656' },
                                                            }}
                                                            sx={{
                                                                mt: 1, mr: 1, backgroundColor: '#F9F9F9',
                                                                borderRadius: '8px',
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Montserrat',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius: '8px',
                                                                },
                                                                '& textarea': {
                                                                    fontFamily: 'Montserrat',

                                                                },
                                                            }}
                                                        ></TextField>
                                                    </Stack>
                                                    <Typography
                                                        sx={{

                                                            fontSize: { xs: '10px', md: '11px' },
                                                            color: '#5E6C84',
                                                            fontWeight: '500',
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        Provide a detailed description of what this plan includes
                                                    </Typography>
                                                </Stack>
                                                {/* <Divider></Divider> */}
                                            </Stack>
                                            <Stack sx={{ m: 1 }}>
                                                <Grid container spacing={{ xs: 1, md: 5 }}>
                                                    <Grid item xs={12} md={6}>
                                                        <Stack sx={{}}>
                                                            <Typography
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 1,
                                                                    mb: 1,
                                                                    fontSize: '14px',
                                                                    color: '#000000',
                                                                    fontWeight: '500',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                Billing Frequency
                                                            </Typography>
                                                        </Stack>{' '}

                                                        <FormControl fullWidth size="small">
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="duration"
                                                                label="Plan Duration"
                                                                name="duration"

                                                                disabled={subscribersCount?.subscribers?.length > 1}
                                                                sx={{
                                                                    backgroundColor: '#F9F9F9',
                                                                    borderRadius: '8px',
                                                                    '& .MuiOutlinedInput-root': {
                                                                        borderRadius: '8px',
                                                                    },
                                                                    '& input': {
                                                                        fontFamily: 'Montserrat',
                                                                    },
                                                                }}
                                                                onChange={
                                                                    (e) => {
                                                                        setDurationFieldValue(e.target.value);
                                                                    }
                                                                }
                                                                value={durationFieldValue}
                                                            >
                                                                {durationDropdownOptions.map((option) => (
                                                                    <MenuItem
                                                                        disabled={subscribersCount?.subscribers?.length > 1}
                                                                        key={option.key}
                                                                        value={option.value}
                                                                        onClick={(e) =>
                                                                            handleDurationChange(option.key)
                                                                        }
                                                                        sx={{ fontFamily: 'Montserrat' }}
                                                                    >
                                                                        {option.value}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <Typography
                                                            sx={{
                                                                fontSize: { xs: '10px', md: '11px' },
                                                                color: '#5E6C84',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Choose how often your plan will be billed
                                                        </Typography>

                                                    </Grid>
                                                    {durationFieldValue !== "One Time" && (
                                                        <Grid item xs={12} md={6} >
                                                            <Stack >
                                                                <Stack sx={{}}>
                                                                    <Typography
                                                                        sx={{
                                                                            ml: 1,
                                                                            mt: 1,
                                                                            mb: 1,
                                                                            fontSize: '14px',
                                                                            color: '#000000',
                                                                            fontWeight: '500',
                                                                            fontFamily: 'Montserrat',
                                                                        }}
                                                                    >
                                                                        Interval
                                                                    </Typography>
                                                                </Stack>

                                                                <TextField
                                                                    sx={{
                                                                        fontSize: '13px',
                                                                        borderRadius: '8px',
                                                                        backgroundColor: '#F9F9F9',
                                                                        '& .MuiOutlinedInput-root': {
                                                                            borderRadius: '8px',

                                                                        },
                                                                        '& .MuiInputLabel-root': {
                                                                            fontFamily: 'Montserrat',
                                                                            fontSize: '13px',
                                                                        },
                                                                        '& input': {
                                                                            fontFamily: 'Montserrat',

                                                                        },
                                                                    }}
                                                                    name="interval"
                                                                    id="interval"
                                                                    label={labelText}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    // disabled={checked}
                                                                    disabled={subscribersCount?.subscribers?.length > 1}
                                                                    fullWidth
                                                                    onChange={handleChanges}
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: { min: 1 },
                                                                    }}
                                                                    value={formValues.interval}
                                                                />
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: { xs: '10px', md: '11px' },
                                                                        color: '#5E6C84',
                                                                        fontWeight: '500',
                                                                        fontFamily: 'Montserrat',
                                                                    }}
                                                                >
                                                                    Enter the {durationFieldValue} for this plan
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                {durationFieldValue === "One Time" && (
                                                    <Stack sx={{ m: 1 }}>
                                                        <Grid container spacing={{ xs: 1, md: 5 }}>
                                                            <Grid item xs={12} md={12}>
                                                                <Stack>
                                                                    <Stack sx={{}}>
                                                                        <Typography
                                                                            sx={{
                                                                                ml: 1,
                                                                                mt: 1,
                                                                                fontSize: '14px',
                                                                                color: '#000000',
                                                                                fontWeight: '500',
                                                                                fontFamily: 'Montserrat',
                                                                            }}
                                                                        >
                                                                            One Time Price
                                                                        </Typography>
                                                                    </Stack>
                                                                    <TextField
                                                                        sx={{
                                                                            mt: 1,
                                                                            backgroundColor: '#F9F9F9',
                                                                            borderRadius: "8px",
                                                                            "& .MuiInputLabel-root": {
                                                                                fontFamily: "Montserrat",
                                                                            },
                                                                            "& .MuiOutlinedInput-root": {
                                                                                borderRadius: "8px",
                                                                            },
                                                                            "& input": {
                                                                                fontFamily: "Montserrat",

                                                                            },
                                                                        }}
                                                                        required
                                                                        fullWidth
                                                                        onChange={handleChanges}
                                                                        name="pricing"
                                                                        id="pricing"
                                                                        value={formValues?.pricing}
                                                                        label="Enter one time payment amount"
                                                                        size="small"
                                                                        type="number"
                                                                        inputProps={{
                                                                            inputMode: 'numeric',
                                                                            pattern: '[0-9]*',
                                                                            min: 1,
                                                                            onInput: (e: any) => {
                                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{
                                                                            style: { fontSize: 13, color: "#565656" },
                                                                        }}
                                                                        InputProps={{

                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <CurrencyRupeeIcon
                                                                                        sx={{
                                                                                            color: 'black',
                                                                                            fontSize: '16px',
                                                                                        }}
                                                                                    />
                                                                                </InputAdornment>
                                                                            ),

                                                                        }}
                                                                    />
                                                                    <FormControl component="fieldset">
                                                                        <RadioGroup
                                                                            value={oneTimeExpiry}
                                                                            onChange={(e) => setOneTimeExpiry(e.target.value)}
                                                                        >
                                                                            <FormControlLabel
                                                                                value="forever"
                                                                                control={<Radio sx={{ color: "#2653A3" }} />}
                                                                                label={
                                                                                    <Box>
                                                                                        <Typography sx={{ fontWeight: "500", color: "#000000", fontSize: '13px', fontFamily: 'Montserrat' }}>
                                                                                            Forever
                                                                                        </Typography>
                                                                                        <Typography sx={{ color: "#5E6C84", fontSize: '12px', fontFamily: 'Montserrat' }}>
                                                                                            Users will have unlimited access to this plan without any expiry date
                                                                                        </Typography>
                                                                                    </Box>
                                                                                }
                                                                            />

                                                                            <FormControlLabel
                                                                                value="expiry"
                                                                                control={<Radio sx={{ color: "#2653A3" }} />}
                                                                                label={
                                                                                    <Box>
                                                                                        <Typography sx={{ fontWeight: "500", color: "#000000", fontSize: '13px', fontFamily: 'Montserrat' }}>
                                                                                            Add  Expiry
                                                                                        </Typography>
                                                                                        <Typography sx={{ color: "#5E6C84", fontSize: '12px', fontFamily: 'Montserrat' }}>
                                                                                            Set a specific date when the plan access  will expire for users
                                                                                        </Typography>
                                                                                    </Box>
                                                                                }
                                                                            />
                                                                            {oneTimeExpiry !== "forever" && (
                                                                                // <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                //     <DemoContainer
                                                                                //         components={['DesktopDatePicker']}
                                                                                //         sx={{
                                                                                //             overflow: 'hidden',
                                                                                //             backgroundColor: 'white',
                                                                                //             mt: 2,
                                                                                //             p: 0,
                                                                                //             borderRadius: '8px'
                                                                                //         }}
                                                                                //     >
                                                                                //         <DatePicker
                                                                                //             disablePast
                                                                                //             format="DD/MM/YYYY"
                                                                                //             value={formValues.endDate ? dayjs(formValues.endDate) : null}
                                                                                //             onChange={handleMyDateChange}
                                                                                //             slots={{
                                                                                //                 openPickerIcon: () => <CalendarDays size={20} strokeWidth={1.5} />,
                                                                                //             }}
                                                                                //             sx={{

                                                                                //                 fontSize: '12px',
                                                                                //                 backgroundColor: 'white',

                                                                                //                 '& .css-q9e13y-MuiStack-root>.MuiTextField-root':
                                                                                //                 {
                                                                                //                     backgroundColor: 'white',
                                                                                //                 },
                                                                                //                 '& .MuiInputLabel-root': {
                                                                                //                     fontSize: '13px',
                                                                                //                     fontFamily: 'Montserrat',
                                                                                //                 },
                                                                                //                 '& .MuiInputLabel-root.Mui-focused': {
                                                                                //                     fontSize: '13px',
                                                                                //                 },

                                                                                //             }}
                                                                                //             slotProps={{

                                                                                //                 textField: {
                                                                                //                     size: 'small',
                                                                                //                     fullWidth: true,

                                                                                //                     sx: {
                                                                                //                         '& .MuiOutlinedInput-root': {
                                                                                //                             borderRadius: '8px', // Ensuring radius on TextField
                                                                                //                         },
                                                                                //                         '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                                                                                //                         {
                                                                                //                             color: '#00000061',
                                                                                //                         },
                                                                                //                         '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                                                                //                         {
                                                                                //                             borderColor: 'green',
                                                                                //                             border: '1px solid',
                                                                                //                         },
                                                                                //                         '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                                                                                //                         {
                                                                                //                             color: '#92929D',
                                                                                //                             fontSize: '13px',
                                                                                //                         },
                                                                                //                         '& .MuiInputLabel-root': {
                                                                                //                             fontSize: '13px',
                                                                                //                         },
                                                                                //                         '& .MuiInputLabel-root.Mui-focused': {
                                                                                //                             fontSize: '13px',
                                                                                //                         },

                                                                                //                     },
                                                                                //                 },
                                                                                //                 layout: {
                                                                                //                     sx: {
                                                                                //                         color: '#2653A3',
                                                                                //                         borderRadius: '20px',
                                                                                //                         borderWidth: '0px',
                                                                                //                         borderColor: '#2653A3',
                                                                                //                         border: '0px solid',
                                                                                //                         backgroundColor: 'white',
                                                                                //                         fontFamily: 'Montserrat',
                                                                                //                         boxShadow: 'none'
                                                                                //                         ,
                                                                                //                         '& .MuiInputLabel-root': {
                                                                                //                             fontFamily: 'Montserrat',
                                                                                //                         },
                                                                                //                     }
                                                                                //                 }
                                                                                //             }}
                                                                                //         />
                                                                                //     </DemoContainer>
                                                                                // </LocalizationProvider>
                                                                                <Grid container gap={2}>
                                                                                    <Grid xs={12} md={5.8}>
                                                                                        <TextField
                                                                                            sx={{
                                                                                                mt: 1,
                                                                                                backgroundColor: '#F9F9F9',
                                                                                                borderRadius: "8px",
                                                                                                "& .MuiInputLabel-root": {
                                                                                                    fontFamily: "Montserrat",
                                                                                                },
                                                                                                "& .MuiOutlinedInput-root": {
                                                                                                    borderRadius: "8px",
                                                                                                },
                                                                                                "& input": {
                                                                                                    fontFamily: "Montserrat",

                                                                                                },
                                                                                            }}
                                                                                            required
                                                                                            fullWidth
                                                                                            onChange={handleChanges}
                                                                                            name="endDateDurationCount"
                                                                                            id="endDateDurationCount"
                                                                                            value={formValues?.endDateDurationCount}
                                                                                            label="Enter Day, Month, Year"
                                                                                            size="small"
                                                                                            type="number"
                                                                                            inputProps={{
                                                                                                inputMode: 'numeric',
                                                                                                pattern: '[0-9]*',
                                                                                                min: 1,
                                                                                                onInput: (e: any) => {
                                                                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                                                },
                                                                                            }}
                                                                                            InputLabelProps={{
                                                                                                style: { fontSize: 13, color: "#565656" },
                                                                                            }}
                                                                                            InputProps={{

                                                                                            }}
                                                                                        />

                                                                                    </Grid>
                                                                                    <Grid xs={12} md={5.8}>
                                                                                        <FormControl fullWidth size="small" >
                                                                                            <Select
                                                                                                labelId="demo-simple-select-label"
                                                                                                id="duration"
                                                                                                label="Plan Duration"
                                                                                                name="duration"

                                                                                                disabled={subscribersCount?.subscribers?.length > 1}
                                                                                                sx={{
                                                                                                    mt: 1,
                                                                                                    backgroundColor: '#F9F9F9',
                                                                                                    borderRadius: '8px',
                                                                                                    '& .MuiOutlinedInput-root': {
                                                                                                        borderRadius: '8px',
                                                                                                    },
                                                                                                    '& input': {
                                                                                                        fontFamily: 'Montserrat',
                                                                                                    },
                                                                                                }}
                                                                                                onChange={
                                                                                                    (e) => {
                                                                                                        setEndDateDuration(e.target.value);
                                                                                                    }
                                                                                                }
                                                                                                value={endDateDuration}
                                                                                            >
                                                                                                {expireDropdownOptions.map((option) => (
                                                                                                    <MenuItem
                                                                                                        disabled={subscribersCount?.subscribers?.length > 1}
                                                                                                        key={option.key}
                                                                                                        value={option.value}
                                                                                                        onClick={(e) =>
                                                                                                            handleEndDurationChange(option.key)
                                                                                                        }
                                                                                                        sx={{ fontFamily: 'Montserrat' }}
                                                                                                    >
                                                                                                        {option.value}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Stack>
                                                            </Grid>

                                                        </Grid>
                                                    </Stack>
                                                )}



                                                {durationFieldValue !== "One Time" && (
                                                    <Grid container spacing={{ xs: 1, md: 5 }}>
                                                        <Grid item xs={12} md={12} >
                                                            <Stack sx={{}}>
                                                                <Typography
                                                                    sx={{
                                                                        ml: 1,
                                                                        mt: 1,
                                                                        fontSize: '14px',
                                                                        color: '#000000',
                                                                        fontWeight: '500',
                                                                        fontFamily: 'Montserrat',
                                                                    }}
                                                                >
                                                                    Subscription Price
                                                                </Typography>
                                                            </Stack>
                                                            <TextField
                                                                sx={{
                                                                    mt: 2, backgroundColor: '#F9F9F9',
                                                                    borderRadius: '8px',
                                                                    '& .MuiInputLabel-root': {
                                                                        fontFamily: 'Montserrat',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        borderRadius: '8px',
                                                                    },
                                                                    '& input': {
                                                                        fontFamily: 'Montserrat',
                                                                    },
                                                                }}

                                                                disabled={subscribersCount?.subscribers?.length > 1}
                                                                required
                                                                fullWidth
                                                                onChange={handleChanges}
                                                                name="pricing"
                                                                id="pricing"
                                                                value={formValues?.pricing}
                                                                label="Enter price"
                                                                size="small"
                                                                type="number"
                                                                inputProps={{
                                                                    inputMode: 'numeric',
                                                                    pattern: '[0-9]*',
                                                                    min: 1,
                                                                    onInput: (e: any) => {
                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    style: { fontSize: 17, color: '#565656' },
                                                                }}
                                                                InputProps={{

                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <IndianRupee size={20} strokeWidth={1.5} />
                                                                        </InputAdornment>
                                                                    ),

                                                                }}

                                                            ></TextField>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: { xs: '10px', md: '11px' },
                                                                    color: '#5E6C84',
                                                                    fontWeight: '500',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                Regular {durationFieldValue}  payment amount
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}



                                                <Grid container spacing={{ xs: 1, md: 5 }}>
                                                    <Grid item xs={12} md={12} >
                                                        <Stack sx={{}}>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            sx={{
                                                                                transform: 'scale(1.1)',
                                                                                color: '#92929D',
                                                                                '&.Mui-checked': {
                                                                                    color: '#2653A3',
                                                                                },
                                                                                '& .MuiSvgIcon-root': {
                                                                                    borderRadius: '4px',
                                                                                },
                                                                            }}
                                                                        />
                                                                    }
                                                                    checked={isChecked} // Ensure controlled state
                                                                    onChange={handleCheckboxChange}
                                                                    label="Add One - time Initial Fee"
                                                                    sx={{
                                                                        fontSize: '10px',
                                                                        color: '#515151',
                                                                        fontWeight: '500',
                                                                        fontFamily: 'Montserrat',
                                                                        '& .MuiFormControlLabel-label': {
                                                                            fontSize: '14px',
                                                                            color: '#000000',
                                                                            fontWeight: '500',
                                                                            fontFamily: 'Montserrat',
                                                                        },
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Stack>
                                                        {isChecked && (
                                                            <>
                                                                <TextField
                                                                    sx={{
                                                                        mt: 1,
                                                                        backgroundColor: '#F9F9F9',
                                                                        borderRadius: "8px",
                                                                        "& .MuiInputLabel-root": {
                                                                            fontFamily: "Montserrat",
                                                                        },
                                                                        "& .MuiOutlinedInput-root": {
                                                                            borderRadius: "8px",
                                                                        },
                                                                        "& input": {
                                                                            fontFamily: "Montserrat",
                                                                        },
                                                                    }}
                                                                    required
                                                                    fullWidth
                                                                    onChange={handleChanges}
                                                                    name="initialPayment"
                                                                    id="initialPayment"
                                                                    value={formValues?.initialPayment}
                                                                    label="Enter price"
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{
                                                                        inputMode: 'numeric',
                                                                        pattern: '[0-9]*',
                                                                        min: 1,
                                                                        onInput: (e: any) => {
                                                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                        },
                                                                    }}
                                                                    InputLabelProps={{
                                                                        style: { fontSize: 17, color: "#565656" },
                                                                    }}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <IndianRupee size={20} strokeWidth={1.5} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: { xs: "10px", md: "11px" },
                                                                        color: "#5E6C84",
                                                                        fontWeight: "500",
                                                                        fontFamily: "Montserrat",
                                                                    }}
                                                                >
                                                                    One-time fee charged with first subscription payment
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Stack>


                                            <Stack
                                                sx={{ p: 0.5, borderRadius: '10px', backgroundColor: '#ffffff' }}
                                            >
                                                <Accordion

                                                    sx={{ borderRadius: '10px', boxShadow: 'none' }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMore sx={{ color: '#2653A3' }} />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                        sx={{
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                            borderRadius: '10px',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: { xs: '14px', md: '14px' },
                                                                fontWeight: 600,
                                                                textDecoration: 'none',
                                                                color: '#2653A3',
                                                                fontFamily: 'Montserrat'
                                                            }}
                                                        >
                                                            Advance Options
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails
                                                        sx={{
                                                            pl: 1,
                                                            pr: 1,
                                                            backgroundColor: 'white',
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                        }}
                                                    >
                                                        <Stack>
                                                            <Stack sx={{ pt: 1 }}>
                                                                <Typography
                                                                    sx={{
                                                                        ml: 1,
                                                                        fontSize: '13px',
                                                                        color: '#2653A3',
                                                                        fontWeight: '500',
                                                                        fontFamily: 'Montserrat',
                                                                    }}
                                                                >
                                                                    Attachments
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        ml: 1,
                                                                        fontSize: { xs: '10px', md: '12px' },
                                                                        // color: "#292D32",
                                                                        fontFamily: 'Montserrat',
                                                                    }}
                                                                >
                                                                    * Upload additional documents that provide more
                                                                    information.
                                                                </Typography>
                                                            </Stack>

                                                            <Grid container spacing={{ xs: 1, md: 3 }}>
                                                                <Grid item xs={12} md={6}>
                                                                    <Stack
                                                                        sx={{
                                                                            backgroundColor: '#F9F9F9',
                                                                            p: 1.5,
                                                                            m: 1,
                                                                            borderRadius: '8px',
                                                                            border: '1px solid #E7EBF1',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '13px',
                                                                                color: '#5E6C84',
                                                                                pb: 1,
                                                                                fontFamily: 'Montserrat',
                                                                            }}
                                                                        >
                                                                            Attach Image
                                                                        </Typography>
                                                                        <input
                                                                            type="file"
                                                                            name="images"
                                                                            onChange={(e) => handleImageChange(e.target.files)}
                                                                            style={{
                                                                                border: '2px solid #5E6C84',
                                                                                borderRadius: '8px',
                                                                                padding: '8px',
                                                                                backgroundColor: '#ffffff',
                                                                                color: '#5E6C84',
                                                                                fontSize: '12px',
                                                                                fontWeight: '500',
                                                                                cursor: 'pointer',
                                                                                width: '70%',
                                                                                fontFamily: 'Montserrat',
                                                                            }}

                                                                        ></input>

                                                                        {/* {planValues?.image && (
                                                                            <Button
                                                                                variant="outlined"
                                                                                onClick={handleClickOpenImg}
                                                                                sx={{
                                                                                    textTransform: 'capitalize',
                                                                                    mt: { xs: 2, md: 1.5 },
                                                                                    fontSize: '13px',

                                                                                    width: '160px',
                                                                                    color: '#5E6C84',
                                                                                    fontFamily: 'Montserrat',
                                                                                    border: '1px solid #5E6C84',
                                                                                    ':hover': {
                                                                                        color: '#5E6C84',
                                                                                        border: '1px solid #5E6C84',
                                                                                    },
                                                                                }}
                                                                                startIcon={<InsertPhotoOutlined />}
                                                                            >
                                                                                Preview Image
                                                                            </Button>
                                                                        )} */}

                                                                        <Dialog
                                                                            fullWidth={fullWidth}
                                                                            maxWidth={maxWidth}
                                                                            open={open}
                                                                            onClose={handleClosesImg}
                                                                        >
                                                                            <DialogActions
                                                                                sx={{ backgroundColor: '#F0F9FF' }}
                                                                            >
                                                                                <Stack
                                                                                    direction={'row'}
                                                                                    justifyContent={'space-between'}
                                                                                    alignItems={'center'}
                                                                                    sx={{ backgroundColor: '#F0F9FF' }}
                                                                                >
                                                                                    <Stack></Stack>
                                                                                    <Stack></Stack>
                                                                                    <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                                                                                        <Box
                                                                                            onClick={handleClosesImg}
                                                                                            sx={{
                                                                                                backgroundColor: '#3C5AA0',
                                                                                                padding: '4px',
                                                                                                borderRadius: '50%',
                                                                                                width: '20px',
                                                                                                height: '20px',
                                                                                                display: 'flex',
                                                                                                justifyContent: 'center',
                                                                                                alignItems: 'center',
                                                                                                color: 'white',
                                                                                                fontSize: '10px',
                                                                                                fontFamily: 'Montserrat',
                                                                                                mt: '0.5px',
                                                                                                mr: '10px',
                                                                                                cursor: 'pointer',
                                                                                                '&:hover': {
                                                                                                    // backgroundColor: "#50A1CA", // Background color on hover
                                                                                                    cursor: 'pointer',
                                                                                                    border: 'none',
                                                                                                },
                                                                                            }}
                                                                                        >
                                                                                            <Close sx={{ fontSize: '13px' }} />
                                                                                        </Box>
                                                                                    </Stack>
                                                                                </Stack>
                                                                            </DialogActions>

                                                                            <DialogContent>
                                                                                <img
                                                                                    alt=""
                                                                                    // onClick={() => navigate(`/plans/${plan?._id}`)}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        height: 'auto',
                                                                                        maxHeight: '300px',
                                                                                        objectFit: 'cover',
                                                                                        borderRadius: '4px',
                                                                                        cursor: 'pointer',
                                                                                        fontFamily: 'Montserrat',
                                                                                    }}
                                                                                    src={
                                                                                        planValues?.images
                                                                                            ? planValues?.images?.value
                                                                                            : ' '
                                                                                    }
                                                                                ></img>
                                                                            </DialogContent>
                                                                        </Dialog>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Stack
                                                                        sx={{
                                                                            backgroundColor: '#F9F9F9',
                                                                            p: 1.5,
                                                                            m: 1,
                                                                            borderRadius: '8px',
                                                                            border: '1px solid #E7EBF1',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '13px',
                                                                                color: '#5E6C84',
                                                                                pb: 1,
                                                                                fontFamily: 'Montserrat',
                                                                            }}
                                                                        >
                                                                            Attach Document
                                                                        </Typography>
                                                                        <input
                                                                            type="file"
                                                                            name="documents"
                                                                            onChange={handlePlanDocuments}
                                                                            style={{
                                                                                border: '2px solid #5E6C84',
                                                                                borderRadius: '8px',
                                                                                padding: '8px',
                                                                                backgroundColor: '#ffffff',
                                                                                color: '#5E6C84',
                                                                                fontSize: '12px',
                                                                                fontFamily: 'Montserrat',
                                                                                fontWeight: '500',
                                                                                cursor: 'pointer',
                                                                                width: '70%',
                                                                            }}
                                                                        ></input>

                                                                        {planValues?.document ? (
                                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                            <a
                                                                                href={
                                                                                    typeof planValues?.document === 'object' &&
                                                                                        'value' in planValues?.document
                                                                                        ? (
                                                                                            planValues?.document as {
                                                                                                value: string;
                                                                                            }
                                                                                        ).value
                                                                                        : ''
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    onClick={handleClickOpenImg}
                                                                                    sx={{
                                                                                        textTransform: 'capitalize',
                                                                                        mt: { xs: 2, md: 1.5 },
                                                                                        fontSize: '13px',

                                                                                        width: '200px',
                                                                                        color: '#5E6C84',
                                                                                        fontFamily: 'Montserrat',
                                                                                        border: '1px solid #5E6C84',
                                                                                        ':hover': {
                                                                                            color: '#5E6C84',
                                                                                            border: '1px solid #5E6C84',
                                                                                        },
                                                                                    }}
                                                                                    startIcon={<CloudDownloadOutlinedIcon />}
                                                                                >
                                                                                    Document
                                                                                </Button>
                                                                            </a>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>

                                                        {durationFieldValue !== "One Time" && (
                                                            <Stack>
                                                                <FormControl component="fieldset">
                                                                    <RadioGroup
                                                                        value={selectedOption}
                                                                        onChange={(e) => setSelectedOption(e.target.value)}
                                                                    >
                                                                        <FormControlLabel
                                                                            value="sequence"
                                                                            control={<Radio sx={{ color: "#2653A3" }} />}
                                                                            label={
                                                                                <Box>
                                                                                    <Typography sx={{ fontWeight: "600", color: "#000000", fontSize: '16px', fontFamily: 'Montserrat' }}>
                                                                                        Sequence
                                                                                    </Typography>
                                                                                    <Typography sx={{ color: "#5E6C84", fontSize: { xs: '10px', md: '11px' }, fontFamily: 'Montserrat' }}>
                                                                                        Allow users to select and pay for multiple yearly cycles at once
                                                                                    </Typography>
                                                                                </Box>
                                                                            }
                                                                        />
                                                                        {selectedOption === "sequence" && (
                                                                            <Box sx={{ mt: 2, ml: 4 }}>
                                                                                <Typography sx={{ fontWeight: "500", color: "#000000", fontSize: { xs: '10px', md: '11px' }, fontFamily: 'Montserrat' }}>
                                                                                    Number of Sequences
                                                                                </Typography>
                                                                                <TextField
                                                                                    sx={{
                                                                                        mt: 1,
                                                                                        backgroundColor: '#F9F9F9',
                                                                                        borderRadius: "8px",
                                                                                        "& .MuiInputLabel-root": {
                                                                                            fontFamily: "Montserrat",
                                                                                        },
                                                                                        "& .MuiOutlinedInput-root": {
                                                                                            borderRadius: "8px",
                                                                                        },
                                                                                        "& input": {
                                                                                            fontFamily: "Montserrat",
                                                                                        },
                                                                                    }}
                                                                                    required
                                                                                    fullWidth
                                                                                    onChange={handleChanges}
                                                                                    name="totalSequences"
                                                                                    id="totalSequences"
                                                                                    value={formValues?.totalSequences}
                                                                                    label="Enter maximum number of sequences"
                                                                                    size="small"
                                                                                    type="number"
                                                                                    inputProps={{
                                                                                        inputMode: 'numeric',
                                                                                        pattern: '[0-9]*',
                                                                                        min: 1,
                                                                                        onInput: (e: any) => {
                                                                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                                        },
                                                                                    }}
                                                                                    InputLabelProps={{
                                                                                        style: { fontSize: 13, color: "#565656" },
                                                                                    }}
                                                                                />
                                                                                <Typography sx={{ mt: 1, fontSize: { xs: '10px', md: '11px' }, color: "#5E6C84", fontFamily: "Montserrat", }}>
                                                                                    Maximum number of yearly cycles users can select at once
                                                                                </Typography>
                                                                            </Box>
                                                                        )}
                                                                        <FormControlLabel
                                                                            value="non-sequence"
                                                                            control={<Radio sx={{ color: "#2653A3" }} />}
                                                                            label={
                                                                                <Box>
                                                                                    <Typography sx={{ fontWeight: "600", color: "#000000", fontSize: '16px', fontFamily: 'Montserrat' }}>
                                                                                        Non  Sequence
                                                                                    </Typography>
                                                                                    <Typography sx={{ color: "#5E6C84", fontSize: { xs: '10px', md: '11px' }, fontFamily: 'Montserrat' }}>
                                                                                        Users will need to pay every yearly cycle individually
                                                                                    </Typography>
                                                                                </Box>
                                                                            }
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Stack>
                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Stack>
                                            <Stack direction="row" spacing={1} mt={2}>
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: '#3C5AA0',
                                                        textTransform: 'capitalize',
                                                        border: 'none',
                                                        borderRadius: '8px',
                                                        boxShadow: 'none',
                                                        color: 'white',
                                                        fontFamily: 'Montserrat',
                                                        // display: hideCreateButton ? "none" : "block",
                                                        '&:hover': {
                                                            backgroundColor: '#3C5AA0',
                                                            cursor: 'pointer',
                                                            color: 'white',
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                    onClick={handleClickOpenPublish}
                                                    disabled={!isDataAvailable && !id}
                                                >
                                                    Publish
                                                </Button>
                                                {(location.pathname === '/plans' ||
                                                    location.pathname === '/create-plan' ||
                                                    location.pathname === '/create-plan-new' ||
                                                    location.pathname === `/plans/new/${id}`) && (
                                                        <Button
                                                            variant="outlined"
                                                            size="large"
                                                            sx={{
                                                                textTransform: 'capitalize',
                                                                // fontWeight:600,
                                                                display: planValues?.status === "ACTIVE" ? "none" : 'block',
                                                                color: '#3C5AA0',
                                                                border: '1px solid #3C5AA0',
                                                                borderRadius: '8px',
                                                                boxShadow: 'none',
                                                                fontFamily: 'Montserrat',
                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                    border: '1px solid #3C5AA0',
                                                                    boxShadow: 'none',
                                                                },
                                                            }}
                                                            onChange={(e: any) => setPlanValues(e.target.value)}
                                                            // onClick={handleSubmit}
                                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                                            onClick={(e) => handleSubmit(e, 'CREATED')}
                                                            disabled={!isDataAvailable && !id}

                                                        >
                                                            {loadingButton ? (
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{ color: 'white' }}
                                                                />
                                                            ) : id ? (
                                                                'Update'
                                                            ) : (
                                                                'Create'
                                                            )}
                                                        </Button>
                                                    )}

                                                {/* Pop Up for About us Content */}
                                                <Dialog
                                                    fullWidth={fullWidth}
                                                    maxWidth={maxWidth}
                                                    open={opensPublish}
                                                    onClose={handleClickClosesPublish}
                                                >
                                                    <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            sx={{ backgroundColor: '#F0F9FF' }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: { xs: '14px', md: '16px' },
                                                                    fontWeight: 500,
                                                                    textDecoration: 'none',
                                                                    color: '#3C5AA0',
                                                                    fontFamily: 'Montserrat',

                                                                    marginLeft: 2,
                                                                }}
                                                            >
                                                                {'Publish the Plan?'}
                                                            </Typography>
                                                            <Box
                                                                onClick={handleClickClosesPublish}
                                                                sx={{
                                                                    backgroundColor: '#3C5AA0',
                                                                    padding: '4px',
                                                                    borderRadius: '50%',
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: 'white',
                                                                    fontSize: '10px',
                                                                    mt: '0.5px',
                                                                    mr: '10px',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        border: 'none',
                                                                    },
                                                                }}
                                                            >
                                                                <Close sx={{ fontSize: '15px' }} />
                                                            </Box>
                                                        </Grid>
                                                    </DialogActions>

                                                    <DialogContent>
                                                        <DialogContentText
                                                            id="alert-dialog-description"
                                                            sx={{ fontSize: '12px', textAlign: 'center', fontFamily: 'Montserrat', }}
                                                        >
                                                            Once you publish your subscription plan, you cannot
                                                            make any more changes to it. This is to be
                                                            transparent and build trust with subscribers. Are
                                                            you ready to publish the plan?.
                                                        </DialogContentText>
                                                    </DialogContent>

                                                    <DialogActions >
                                                        <Button
                                                            onChange={(e: any) => setPlanValues(e.target.value)}
                                                            onClick={(e) => handleSubmit(e, 'ACTIVE')}
                                                            size="large"
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor:
                                                                    '#3C5AA0 ',
                                                                textTransform: 'capitalize',
                                                                border: 'none',
                                                                borderRadius: '8px',
                                                                boxShadow: 'none',
                                                                color: 'white',
                                                                fontFamily: 'Montserrat',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        '#3C5AA0',
                                                                    cursor: 'pointer',
                                                                    color: 'white',
                                                                    border: 'none',
                                                                    boxShadow: 'none',
                                                                },
                                                            }}
                                                        >
                                                            {loadingButton ? (
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{ color: 'white' }}
                                                                />
                                                            ) : (
                                                                'Publish'
                                                            )}
                                                        </Button>
                                                        <Button
                                                            size="large"
                                                            sx={{
                                                                textTransform: 'capitalize',
                                                                // fontWeight:600,
                                                                color: '#3C5AA0',
                                                                border: '1px solid #3C5AA0',
                                                                borderRadius: '8px',
                                                                boxShadow: 'none',
                                                                fontFamily: 'Montserrat',
                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                    border: '1px solid #3C5AA0',
                                                                    boxShadow: 'none',
                                                                },
                                                            }}
                                                            onClick={handleClickClosesPublish}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/* <Grid container sx={{ mt: 2, mb: { xs: 5, md: 0 } }}>
                                    <Grid item xs={12} md={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Button
                                                size="large"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#3C5AA0',
                                                    textTransform: 'capitalize',
                                                    border: 'none',
                                                    borderRadius: '8px',
                                                    boxShadow: 'none',
                                                    color: 'white',
                                                    fontFamily: 'Montserrat',
                                                    // display: hideCreateButton ? "none" : "block",
                                                    '&:hover': {
                                                        backgroundColor: '#3C5AA0',
                                                        cursor: 'pointer',
                                                        color: 'white',
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                    },
                                                }}
                                                onClick={handleClickOpenPublish}
                                            // disabled={!isDataAvailable && !id}
                                            >
                                                Publish
                                            </Button>
                                            {(location.pathname === '/plans' ||
                                                location.pathname === '/create-plan' ||
                                                location.pathname === '/create-plan-new' ||
                                                location.pathname === `/plans/new/${id}`) && (
                                                    <Button
                                                        variant="outlined"
                                                        size="large"
                                                        sx={{
                                                            textTransform: 'capitalize',
                                                            // fontWeight:600,
                                                            display: planValues?.status === "ACTIVE" ? "none" : 'block',
                                                            color: '#3C5AA0',
                                                            border: '1px solid #3C5AA0',
                                                            borderRadius: '8px',
                                                            boxShadow: 'none',
                                                            fontFamily: 'Montserrat',
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                border: '1px solid #3C5AA0',
                                                                boxShadow: 'none',
                                                            },
                                                        }}
                                                        onChange={(e: any) => setPlanValues(e.target.value)}
                                                        // onClick={handleSubmit}
                                                        // eslint-disable-next-line react/jsx-no-duplicate-props
                                                        onClick={(e) => handleSubmit(e, 'CREATED')}

                                                    >
                                                        {loadingButton ? (
                                                            <CircularProgress
                                                                size={24}
                                                                sx={{ color: 'white' }}
                                                            />
                                                        ) : id ? (
                                                            'Update'
                                                        ) : (
                                                            'Create'
                                                        )}
                                                    </Button>
                                                )}

                                        
                                            <Dialog
                                                fullWidth={fullWidth}
                                                maxWidth={maxWidth}
                                                open={opensPublish}
                                                onClose={handleClickClosesPublish}
                                            >
                                                <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        sx={{ backgroundColor: '#F0F9FF' }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: { xs: '14px', md: '16px' },
                                                                fontWeight: 500,
                                                                textDecoration: 'none',
                                                                color: '#3C5AA0',
                                                                fontFamily: 'Montserrat',

                                                                marginLeft: 2,
                                                            }}
                                                        >
                                                            {'Publish the Plan?'}
                                                        </Typography>
                                                        <Box
                                                            onClick={handleClickClosesPublish}
                                                            sx={{
                                                                backgroundColor: '#3C5AA0',
                                                                padding: '4px',
                                                                borderRadius: '50%',
                                                                width: '20px',
                                                                height: '20px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: 'white',
                                                                fontSize: '10px',
                                                                mt: '0.5px',
                                                                mr: '10px',
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                    border: 'none',
                                                                },
                                                            }}
                                                        >
                                                            <Close sx={{ fontSize: '15px' }} />
                                                        </Box>
                                                    </Grid>
                                                </DialogActions>

                                                <DialogContent>
                                                    <DialogContentText
                                                        id="alert-dialog-description"
                                                        sx={{ fontSize: '12px', textAlign: 'center', fontFamily: 'Montserrat', }}
                                                    >
                                                        Once you publish your subscription plan, you cannot
                                                        make any more changes to it. This is to be
                                                        transparent and build trust with subscribers. Are
                                                        you ready to publish the plan?.
                                                    </DialogContentText>
                                                </DialogContent>

                                                <DialogActions >
                                                    <Button
                                                        onChange={(e: any) => setPlanValues(e.target.value)}
                                                        onClick={(e) => handleSubmit(e, 'ACTIVE')}
                                                        size="large"
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor:
                                                                '#3C5AA0 ',
                                                            textTransform: 'capitalize',
                                                            border: 'none',
                                                            borderRadius: '8px',
                                                            boxShadow: 'none',
                                                            color: 'white',
                                                            fontFamily: 'Montserrat',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    '#3C5AA0',
                                                                cursor: 'pointer',
                                                                color: 'white',
                                                                border: 'none',
                                                                boxShadow: 'none',
                                                            },
                                                        }}
                                                    >
                                                        {loadingButton ? (
                                                            <CircularProgress
                                                                size={24}
                                                                sx={{ color: 'white' }}
                                                            />
                                                        ) : (
                                                            'Publish'
                                                        )}
                                                    </Button>
                                                    <Button
                                                        size="large"
                                                        sx={{
                                                            textTransform: 'capitalize',
                                                            // fontWeight:600,
                                                            color: '#3C5AA0',
                                                            border: '1px solid #3C5AA0',
                                                            borderRadius: '8px',
                                                            boxShadow: 'none',
                                                            fontFamily: 'Montserrat',
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                border: '1px solid #3C5AA0',
                                                                boxShadow: 'none',
                                                            },
                                                        }}
                                                        onClick={handleClickClosesPublish}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Stack>
                                    </Grid>
                                </Grid> */}
                            </>
                        </Box>
                    </Stack >
                )}
            </div >
        </>
    );
};
