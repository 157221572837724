import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  Grid,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IColumn } from '../../../models/table.model';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { SearchIcon1 } from '../../../assets/icons';
import { ContentWrapper } from '../../Layout/components/ContentWrapper';
import Loader from '../../Loader/Loader';
import { NoSubscribers } from '../../AlternatePages/NoSubscribers';
// import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { BulkSubscribersTable } from '../../tables/BuikSubscibersTable';
import { ISubscriberBulk } from '../../../models/subscription.model';

const columns: IColumn[] = [
  { title: 'Name', dataKey: 'user' },
  { title: 'Email ID', dataKey: 'email' },
  { title: 'Mobile No', dataKey: 'phone' },
  { title: 'Plan Id', dataKey: 'plan' },
  { title: 'Start Date', dataKey: 'startDate' },
  { title: 'Immediate', dataKey: 'select' },
];

export const BulkImportSubscriptionList = () => {
  const location = useLocation();
  const subscribers = location.state?.subscribers || [];

  // const navigate = useNavigate();
  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  let community_id = singleCommunity?._id;

  const [subscriberList, setSubscriberList] =
    useState<ISubscriberBulk[]>(subscribers);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [planOptions, setPlanOptions] = useState<string[]>([]);

  const subscriptionList = async () => {
    try {
      setIsLoading(true);
      const subscribers = location.state?.subscribers || [];
      if (subscribers) {
        setSubscriberList(subscribers);
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (community_id) {
      subscriptionList();
    }
  }, [community_id]);

  useEffect(() => {
    const uniquePlans = Array.from(
      new Set(subscriberList.map((subscriber) => subscriber?.planId))
    );
    setPlanOptions(uniquePlans);
  }, [subscriberList]);

  // const handleCreateNew = () => {
  //   navigate('/create-subscription');
  // };

  const filteredUsers = subscriberList?.filter(
    (subscriber: ISubscriberBulk) => {
      const searchString = searchQuery.toLowerCase();
      const planMatch = selectedPlan
        ? subscriber?.planId === selectedPlan
        : true;
      return (
        planMatch &&
        (subscriber?.user?.firstName.toLowerCase().includes(searchString) ||
          subscriber?.planId.toLowerCase().includes(searchString))
      );
    }
  );

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.subscriberList}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack direction={'column'} sx={{ height: '100%', p: 0 }}>
        {/* filters for large screens */}
        <Stack
          direction="row"
          display={{ xs: 'none', md: 'flex' }}
          alignItems={'center'}
        >
          <Stack direction={'row'} flexGrow={1} spacing={1}>
            <Box component="form" noValidate autoComplete="off">
              <Typography
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: 600,
                  color: '#000000',
                  fontFamily: 'Montserrat',
                }}
              >
                Imported List
              </Typography>
            </Box>
          </Stack>
          <Stack direction={'row-reverse'} flexGrow={1} spacing={2}>
            {/* <Button
              variant="contained"
              endIcon={<AddCircleOutlineOutlined />}
              onClick={handleCreateNew}
              sx={{
                textTransform: 'capitalize',
                borderRadius: '8px',
                backgroundColor: '#3C5AA0',
                fontFamily: 'Montserrat',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  cursor: 'pointer',
                  border: 'none',
                },
              }}
            >
              Add New
            </Button> */}

            {/* <TextField
              placeholder="Search by user"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon1 sx={{ mt: 1 }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#f9f9f9',
                  height: '37px',
                  width: { xs: '150px', md: '200px' },
                  color: '#000000',
                  fontFamily: 'Montserrat',
                  '& input::placeholder': {
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    color: '#9e9e9e',
                    opacity: 1,
                  },
                },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <Autocomplete
              freeSolo
              options={planOptions}
              value={selectedPlan}
              onChange={(e, newValue) => setSelectedPlan(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search by plan"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon1 sx={{ mt: 1, ml: 1 }} />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: '8px',
                      backgroundColor: '#f9f9f9',
                      height: '37px',
                      width: { xs: '150px', md: '200px' },
                      color: '#000000',
                      fontSize: '16px',
                      fontFamily: 'Montserrat',
                      '& input::placeholder': {
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        color: '#9e9e9e',
                        opacity: 1,
                      },
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  '& .MuiAutocomplete-option': {
                    fontSize: '13px',
                    padding: '10px 16px',
                    "&[aria-selected='true']": {
                      backgroundColor: '#3760A9',
                      color: '#fff',
                    },
                    '&:hover': {
                      backgroundColor: '#eceff1',
                    },
                  },
                },
              }}
            /> */}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          display={{ xs: 'flex', md: 'none', xl: 'none', lg: 'none' }}
          sx={{ mb: '5px' }}
        >
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={6}>
              <Box component="form" noValidate autoComplete="off">
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#000000',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Subscribers
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'end'}>
              {/* <Button
                variant="contained"
                onClick={handleCreateNew}
                endIcon={<AddCircleOutlineOutlined />}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  backgroundColor: '#3C5AA0',
                  fontFamily: 'Montserrat',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#3C5AA0',
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}
              >
                Add New
              </Button> */}
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                options={planOptions}
                value={selectedPlan}
                onChange={(e, newValue) => setSelectedPlan(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search by plan"
                    size="small"
                    sx={{
                      fontSize: '16px',
                    }}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        borderRadius: '8px',
                        backgroundColor: '#f9f9f9',
                        height: '37px',
                        color: '#000000',
                        fontSize: { xs: '12px', md: '15px' },
                        fontFamily: 'Montserrat',
                      },
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon1 sx={{ mt: 1, ml: 1 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    '& .MuiAutocomplete-option': {
                      fontSize: '13px',
                      padding: '10px 16px',
                      "&[aria-selected='true']": {
                        backgroundColor: '#3760A9',
                        color: '#fff',
                      },
                      '&:hover': {
                        backgroundColor: '#eceff1',
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Search by user"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon1 sx={{ mt: 1 }} />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9',
                    height: '37px',
                    color: '#000000',
                    fontSize: { xs: '12px', md: '15px' },
                    fontFamily: 'Montserrat',
                  },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack sx={{ pt: '5px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <ContentWrapper>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {filteredUsers?.length === 0 ? (
                      <NoSubscribers />
                    ) : (
                      <BulkSubscribersTable
                        data={filteredUsers || []}
                        columns={columns}
                        subscriptionList={subscriptionList}
                      />
                    )}
                  </>
                )}
              </ContentWrapper>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};
