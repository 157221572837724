import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useSubscription } from '../../hooks/useSubscription';

import { CustomTableHeader } from './components/CustomTableHeader';
import { IColumn } from '../../models/table.model';
import {
  ISubscriberBulk,
  ISubscriberBulkArray,
} from '../../models/subscription.model';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface ITableProps {
  data: ISubscriberBulk[];
  columns: IColumn[];
  subscriptionList: () => void;
}
export const BulkSubscribersTable = ({ columns, data = [] }: ITableProps) => {
  const { bulkAddSubscibers } = useSubscription();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [originalDates, setOriginalDates] = useState<{ [key: number]: string }>(
    {}
  );
  const [tableData, setTableData] = useState<ISubscriberBulk[]>([]);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: 'asc' | 'desc' | undefined;
  }>({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  useEffect(() => {
    if (Array.isArray(data)) {
      setTableData(
        data.map((item) => ({
          ...item,
          selected: false,
        }))
      );

      const dates: { [key: number]: string } = {};
      data.forEach((item, index) => {
        dates[index] = item.startDate || '';
      });
      setOriginalDates(dates);
      setPage(0);
    }
  }, [data]);

  const sortedData = Array.isArray(tableData) ? [...tableData] : [];

  const handleCheckboxChange = (index: number) => {
    setTableData((prevData) =>
      prevData.map((item, i) => {
        if (i === index) {
          if (!item.selected) {
            if (!originalDates[index]) {
              setOriginalDates((prev) => ({
                ...prev,
                [index]: item.startDate || '',
              }));
            }
            return {
              ...item,
              selected: true,
              startDate: new Date().toISOString().split('T')[0],
            };
          } else {
            return {
              ...item,
              selected: false,
              startDate: originalDates[index] || item.startDate,
            };
          }
        }
        return item;
      })
    );
  };

  const handleSort = (columnTitle: string) => {
    setSortDirections((prev) => {
      const newDirection = prev[columnTitle] === 'asc' ? 'desc' : 'asc';
      return { [columnTitle]: newDirection };
    });
    setSortedColumns([columnTitle]);
  };

  const handleSelectAll = (checked: boolean) => {
    setTableData((prevData) =>
      prevData.map((item, index) => {
        if (checked) {
          if (!item.selected) {
            if (!originalDates[index]) {
              setOriginalDates((prev) => ({
                ...prev,
                [index]: item.startDate || '',
              }));
            }
            return {
              ...item,
              selected: true,
              startDate: new Date().toISOString().split('T')[0],
            };
          }
        } else {
          return {
            ...item,
            selected: false,
            startDate: originalDates[index] || item.startDate,
          };
        }
        return item;
      })
    );
  };
  function getValueByDataKey(item: ISubscriberBulk, dataKey: string): any {
    switch (dataKey) {
      case 'user':
        return item?.user?.firstName || '';
      case 'email':
        return item?.user?.emailId || '';
      case 'phone':
        return item?.user?.phoneNumber || '';
      case 'plan':
        return item?.planId;
      case 'startDate':
        return item?.startDate || '';
      default:
        return '';
    }
  }
  const sortedColumnTitle = sortedColumns[0];
  if (sortedColumnTitle && sortDirections[sortedColumnTitle]) {
    const sortDirection = sortDirections[sortedColumnTitle];
    const column = columns.find((col) => col?.title === sortedColumnTitle);

    if (column) {
      sortedData.sort((a, b) => {
        const aValue = getValueByDataKey(a, column.dataKey);
        const bValue = getValueByDataKey(b, column.dataKey);

        if (aValue != null && bValue != null) {
          if (sortDirection === 'asc') {
            return aValue.localeCompare
              ? aValue.localeCompare(bValue)
              : aValue - bValue;
          } else {
            return bValue.localeCompare
              ? bValue.localeCompare(aValue)
              : bValue - aValue;
          }
        }
        return 0;
      });
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);

  const handleSubmit = async () => {
    const formattedData: ISubscriberBulk[] = sortedData.map((item) => ({
      user: {
        firstName: item.user?.firstName || '',
        emailId: item.user?.emailId || '',
        phoneNumber: item.user?.phoneNumber || '',
      },
      planId: item?.planId || '',
      communityId: selectedCommunity._id || '',
      startDate: item.startDate || '',
    }));

    const requestData: ISubscriberBulkArray = { subscribers: formattedData };

    const bulkAddResponse = await bulkAddSubscibers(requestData);
    if (bulkAddResponse?.status === 201) {
      enqueueSnackbar('Subscriber updated successfully', {
        variant: 'success',
      });
      navigate('/subscribers');
    }
  };

  return (
    <>
      <SimpleBar style={{ height: '70vh', overflowY: 'auto' }}>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            height: { xs: 'calc(100vh - 32vh)', md: 'calc(100vh - 30vh)' },
            borderRadius: '12px !important',
            border: '1px solid #E7EBF1',
          }}
        >
          <Table
            sx={{
              '& th': {
                fontSize: '12px',
                fontWeight: 700,
                color: '#565656',
                pr: 1,
                pl: 1,
                pt: 0,
                pb: 0
              },
              '& td': {
                fontSize: '15px',
                fontWeight: 500,
                color: '#1A1A1A',
                p: '5px 10px',
                '& .MuiTypography-root': { m: 0, fontSize: '11px' },
              },
            }}
          >
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: '#f9f9f9',
              }}
            >
              <TableRow>
                {columns?.map((column: IColumn, i: number) => (
                  <TableCell
                    key={i}
                    onClick={() => handleSort(column.title)}
                    sx={{ width: '100px' }}
                  >
                    <Stack direction="row" alignItems="center">
                      <CustomTableHeader
                        title={column.title}
                        sortDirection={sortDirections[column.title]}
                      />
                      {/* Add a checkbox next to the "Immediate" column */}
                      {column.dataKey === 'select' && (
                        <Checkbox
                          checked={paginatedData.every((item) => item.selected)}
                          indeterminate={
                            paginatedData.some((item) => item.selected) &&
                            !paginatedData.every((item) => item.selected)
                          }
                          onChange={(event) =>
                            handleSelectAll(event.target.checked)
                          }

                        />
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData?.map((item: ISubscriberBulk, index) => (
                <TableRow hover key={index}>
                  {columns.map((column: IColumn, colIndex) => (
                    <TableCell key={colIndex} sx={{ width: '220px' }}>
                      {column.dataKey === 'select' ? (
                        <Checkbox
                          checked={item.selected || false}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      ) : column.dataKey === 'user' ? (
                        <Stack flexDirection={'row'} alignItems={'center'}>
                          <Avatar
                            src={item?.user?.firstName.charAt(0)}
                            sx={{
                              height: '30px',
                              width: '30px',
                              fontSize: '11px',
                            }}
                            alt={`${item?.user?.firstName}`}
                          />
                          <Typography
                            style={{
                              marginLeft: '8px',
                              fontWeight: 600,
                              fontSize: '12px !important',
                              color: '#1A1A1A',
                              textTransform: 'capitalize',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {item?.user?.firstName}
                          </Typography>
                        </Stack>
                      ) : column.dataKey === 'email' ? (
                        <Typography
                          sx={{
                            fontSize: '12px !important',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          {item?.user?.emailId}
                        </Typography>
                      ) : column.dataKey === 'phone' ? (
                        <Typography
                          sx={{
                            fontSize: '12px !important',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          {item?.user?.phoneNumber}
                        </Typography>
                      ) : column.dataKey === 'plan' ? (
                        <Typography
                          sx={{
                            textTransform: 'Capitalize',
                            fontSize: '12px !important',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          {item?.planId}
                        </Typography>
                      ) : column.dataKey === 'startDate' ? (
                        <Typography
                          sx={{
                            fontSize: '12px !important',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          {item?.startDate}
                        </Typography>
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SimpleBar>


      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mt: 2, mb: 2, mr: 2 }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#3C5AA0',
            textTransform: 'capitalize',
            border: 'none',
            borderRadius: '8px',
            boxShadow: 'none',
            color: 'white',
            fontFamily: 'Montserrat',
            '&:hover': {
              backgroundColor: '#3C5AA0',
              cursor: 'pointer',
              color: 'white',
              border: 'none',
              boxShadow: 'none',
            },
          }}
          onClick={handleSubmit}
        >
          Add Subscribers
        </Button>
      </Stack>

      <Stack
        direction={'row-reverse'}
        alignItems={'center'}
        sx={{
          color: '#A5A5A5',
          fontSize: { xs: '10px', md: '1.5vh' },
          pr: { xs: '0px', md: '25px' },
          mb: { xs: 12, md: 0 },
        }}
      >
        <Typography
          sx={{
            color: '#A5A5A5',
            fontSize: '13px',
            display: { xs: 'none', md: 'block' },
            fontFamily: 'Montserrat',
          }}
        >
          Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{' '}
          {data.length} entries
        </Typography>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
              View
            </span>
          }
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: { border: '1px solid #A5A5A5', borderRadius: '5px' },
            },
            MenuProps: {
              PaperProps: { style: { border: '1px solid #A5A5A5' } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(data.length / rowsPerPage);
            return (
              <Stack
                direction={'row'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
              >
                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)}
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <KeyboardArrowLeft />
                </IconButton>

                {[...Array(3)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: '1.9vh',
                          border:
                            page === pageNumber ? '1px solid #3C5AA0' : 'none',
                          color: page === pageNumber ? '#3C5AA0' : '#000000',
                          width: '30px',
                          height: '30px',
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  }
                  return null;
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)}
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <KeyboardArrowRight />
                </IconButton>
              </Stack>
            );
          }}
        />
      </Stack>


    </>
  );
};
