import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from './CustomButton';
import { Close } from '@mui/icons-material';
import { useCourses } from '../../../hooks/useCourses';
import { IAddSection, interfaceCourse } from '../../../models/courses.model';




interface AddSectionPopUpProps {
  handleCloseAddSectionDialog: () => void;
  data: interfaceCourse;
  section: any;
  refetchCourseData: () => void;
}
const AddSectionPopUp: React.FC<AddSectionPopUpProps> = ({
  handleCloseAddSectionDialog,
  data,
  section,
  refetchCourseData,
}) => {
  const defaultSectionName = section ? section.name : '';
  const defaultSectionDescription = section ? section.description : '';
  const courseId = data?._id;

  const [sectionName, setSectionName] = useState(defaultSectionName);
  const [sectionDescription, setSectionDescription] = useState(defaultSectionDescription);
  const { updateSectionCourse, addSectionCourse } = useCourses();
  const [loading, setLoading] = useState(false);
  const disable = !sectionName && !sectionDescription;


  const [formValues, setFormValues] = useState<IAddSection>({
    name: '',
    description: '',
    links: [],
    documents: []
  })

  useEffect(() => {
    if (section) {
      setFormValues({
        name: section.name || '',
        description: section.description || '',
        links: section.links || [],
        documents: section.documents || []
      });
    } else {
      setFormValues({
        name: '',
        description: '',
        links: [],
        documents: []
      });
    }
  }, [section]);



  // const handleSaveSection = async () => {
  //   try {
  //     if (section) {
  //       setLoading(true);
  //       const response = await updateSectionCourse(id || "", formValues);
  //       setFormValues(response?.data)
  //       refetchCourseData();
  //     } else {
  //       setLoading(true);
  //       const response = await addSectionCourse(id || "", formValues);
  //       setFormValues(response?.data)
  //       refetchCourseData();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     handleCloseAddSectionDialog();
  //     setLoading(false);
  //   }
  // };


  const handleSaveSection = async () => {
    try {
      setLoading(true);
      if (section) {
        const response = await updateSectionCourse(courseId || "", section?._id, formValues);
        setFormValues(response?.data);
      } else {
        const response = await addSectionCourse(courseId || "", formValues);
        setFormValues(response?.data);
      }
      refetchCourseData();
    } catch (error) {
      console.log(error);
    } finally {
      handleCloseAddSectionDialog();
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  }


  return (
    <Stack>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: '#F0F9FF', height: '35px' }}
      >
        <Typography
          sx={{
            color: '#3C5AA0',
            fontSize: { xs: '14px', md: '16px' },
            fontWeight: '500',
            backgroundColor: '#F0F9FF',
            px: 2,
            fontFamily: 'Montserrat'
          }}
        >
          {section.length === 0 ? 'Add New Section' : 'Edit Section'}
        </Typography>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            backgroundColor: '#3C5AA0',
            padding: '4px',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '10px',
            mt: '0.5px',
            mr: '10px',
            cursor: 'pointer',
          }}
        >
          <IconButton onClick={handleCloseAddSectionDialog}>
            <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
          </IconButton>
        </Stack>
      </Grid>
      <Stack sx={{ p: 2 }}>
        <Stack
          sx={{
            display: 'flex',
          }}
        >
          <Stack sx={{ py: 2 }}>
            <Typography
              sx={{ fontSize: '12px', fontWeight: '500', color: '#3C5AA0', fontFamily: 'Montserrat', mb: 1 }}
            >
              {' '}
              Name
            </Typography>
            <TextField
              placeholder="Section name"
              variant="outlined"
              fullWidth
              name="name"
              size="small"
              value={formValues?.name}
              onChange={handleChange}
              sx={{
                mr: 1,
                '& input::placeholder': {
                  fontSize: '12px',
                  fontFamily: 'Montserrat'
                },
                '& .MuiInputLabel-root': {
                  fontFamily: 'Montserrat',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
                '& input': {
                  fontFamily: 'Montserrat',
                },
              }}
            />
          </Stack>
          <Stack>
            <Typography
              sx={{ fontSize: '12px', fontWeight: '500', color: '#3C5AA0', fontFamily: 'Montserrat', mb: 1 }}
            >
              Description (Optional)
            </Typography>
            <TextField
              placeholder="Section description"
              variant="outlined"
              fullWidth
              size="medium"
              name="description"
              value={formValues?.description}
              onChange={handleChange}
              sx={{
                mr: 1,
                '& input::placeholder': {
                  fontSize: '12px',
                  fontFamily: 'Montserrat'
                },
                '& .MuiInputLabel-root': {
                  fontFamily: 'Montserrat',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
                '& input': {
                  fontFamily: 'Montserrat',
                },
              }}
            />
          </Stack>
        </Stack>

        <Stack
          display={'flex'}
          // alignItems={"center"}
          justifyContent={'flex-end'}
          flexDirection={'row'}
          sx={{ pt: 1.5 }}
        >
          <Box sx={{ mr: '10px' }}>
            <CustomButton
              loading={loading}
              children={' Save '}
              background={'#3C5AA0'}
              textColor={'#FFFFFF'}
              onClick={handleSaveSection}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AddSectionPopUp;
