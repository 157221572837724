import { Box, Dialog, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AddLink, Folder } from '../../../assets/icons';
import AddLinkPopUp from './AddLinkPopUp';
import { interfaceCourse } from '../../../models/courses.model';
import { useSnackbar } from 'notistack';
import { useWebBuilders } from '../../../hooks/useWebBuilder';
import { AuthContext, IAuthContext } from '../../../contexts/Auth.context';
import { updateCourses } from '../../../services/courses.service';
import { useParams } from 'react-router-dom';
import { useCourses } from '../../../hooks/useCourses';
interface sectionProps {
  data: interfaceCourse;
  sectionPopUp: boolean;
  section: any;
  refetchCourseData: () => void;
  onFilesSelected: (files: FileList | null) => void;
  inputId: string;
}
const DragAndAddFiles: React.FC<sectionProps> = ({
  data,
  sectionPopUp,
  section,
  refetchCourseData,
  onFilesSelected,
  inputId,
}) => {
  const [openAddLinkDialog, setOpenAddLinkDialog] = useState(false);
  const [linkInputs, setLinkInputs] = useState(['']);

  // console.log(section?._id, "section")

  const handleAddLink = () => {
    setLinkInputs([...linkInputs, '']);
  };

  const handleDeleteLink = (index: any) => {
    const updatedInputs = linkInputs.filter((_, i) => i !== index);
    setLinkInputs(updatedInputs);
  };

  const handleInputChange = (value: any, index: any) => {
    const updatedInputs = [...linkInputs];
    updatedInputs[index] = value;
    setLinkInputs(updatedInputs);
  };

  const handleOpenAddLinkDialog = () => {
    setOpenAddLinkDialog(true);
  };
  const handleCloseAddLinkDialog = () => {
    setOpenAddLinkDialog(false);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };
  const handleDrop = async (event: any) => {
    event.preventDefault();
    onFilesSelected(event.dataTransfer.files);
  };

  const { enqueueSnackbar } = useSnackbar();

  const { uploadImages } = useWebBuilders();


  const { updateSectionCourse } = useCourses();

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);

  const { id } = useParams()

  const [documents, setDocuments] = useState<string[]>([]);

  useEffect(() => {
    if (data?.documents) {
      setDocuments(data.documents);
    }
  }, [data]);

  const handleUploadDocument = async (files: FileList | null, sectionId?: string) => {
    // console.log("🟢 Clicked Section ID:", sectionId);
    if (!files?.length) return;

    try {
      const uploadedUrls = await Promise.all(
        Array.from(files).map(async (file) => {
          const [response] = await uploadImages([file]);
          return response?._id;
        })
      );

      const validUrls = uploadedUrls.filter((url) => url);
      // console.log("📂 Uploaded File IDs:", validUrls);

      if (validUrls.length > 0) {
        if (sectionId) {
          // console.log("✅ Updating Correct Section:", sectionId);

          // Find the exact section
          let updatedSection = data.sections.find(section => section._id === sectionId);

          if (!updatedSection) {
            // console.error("🚨 Section not found in course data!");
            return;
          }

          // console.log("📄 Existing Documents:", updatedSection.documents);
          // console.log("📂 New Documents:", validUrls);

          // Extract and update documents
          const existingDocuments = Array.isArray(updatedSection.documents)
            ? updatedSection.documents.map((doc: string | { _id: string }) =>
              typeof doc === "string" ? doc : doc._id
            )
            : [];

          const uniqueDocuments = Array.from(new Set([...existingDocuments, ...validUrls]));

          // console.log("🔄 Final Documents After Update:", uniqueDocuments);

          // Update the section in the backend
          await updateSectionCourse(id || "", sectionId, {
            documents: uniqueDocuments,
          });

        } else {
          // console.log("🌍 Updating Course-Level Documents");

          const existingIds = (data.documents || []).map(
            (doc: string | { _id: string }) => (typeof doc === 'string' ? doc : doc._id)
          );

          const uniqueDocuments = Array.from(new Set([...existingIds, ...validUrls]));

          await updateCourses(id || "", { documents: uniqueDocuments }, getAccessToken());
        }

        await refetchCourseData();
        enqueueSnackbar('Document uploaded successfully', { variant: 'success' });
      }
    } catch (error) {
      console.error('❌ Error uploading document:', error);
      enqueueSnackbar('Failed to upload document. Please try again.', { variant: 'error' });
    }
  };


  return (
    <Stack>
      <Grid container px={2.5} sx={{ pb: 2 }}>
        <input
          id={inputId}
          type="file"
          accept=".jpg,.png,.mp3,.mp4,.mov,.docx,.pdf,.zip,.csv,.txt"
          style={{ display: 'none' }}
          onChange={(e) => handleUploadDocument(e.target.files, section._id)}
        />
        <Grid
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          item
          xs={12}
          sm={5}
          md={5.5}
          alignItems="center"
          sx={{
            p: 2,
            background: '#F9F9F9',
            border: '1px dashed #D4DDED',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <label htmlFor={inputId} style={{ cursor: 'pointer' }}>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Box mr={1}>
                <Folder />
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: '13px', fontWeight: '600', color: '#565656', fontFamily: 'Montserrat' }}
                >
                  Drag & Drop to upload files
                </Typography>
                <Typography
                  sx={{ fontSize: '11px', fontWeight: '400', color: '#565656', fontFamily: 'Montserrat' }}
                >
                  Supported files: JPG, PNG, MP3, MP4, MOV, DOCX, PDF, ZIP, CSV,
                  TXT
                </Typography>
              </Box>
            </Stack>
          </label>
        </Grid>

        <Grid
          item
          xs={12}
          sm={1}
          md={1}
          justifyContent="center"
          alignItems="center"
          sx={{ p: 0.5, display: 'flex', flexDirection: 'column' }}
        >
          <Box
            sx={{
              borderRadius: '50%',
              background: '#E6E6E6',
              width: '24px',
              height: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: '400', color: '#000000', fontFamily: 'Montserrat' }}
            >
              or
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          md={5.5}
          onClick={handleOpenAddLinkDialog}
          alignItems="center"
          sx={{
            p: 2,
            background: '#F9F9F9',
            border: '1px dashed #D4DDED',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >

          <Stack flexDirection={'row'} alignItems={'center'}>
            <Box mr={1}>
              <AddLink />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',

              }}
            >
              <Typography
                sx={{ fontSize: '13px', fontWeight: '600', color: '#565656', fontFamily: 'Montserrat' }}
              >
                Add Link
              </Typography>
              <Typography
                sx={{ fontSize: '11px', fontWeight: '400', color: '#565656', fontFamily: 'Montserrat' }}
              >
                Direct your members to YouTube, Google Drive or articles
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAddLinkDialog}
        onClose={handleCloseAddLinkDialog}
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <AddLinkPopUp
          handleCloseAddLinkDialog={handleCloseAddLinkDialog}
          linkInputs={linkInputs}
          handleInputChange={handleInputChange}
          handleDeleteLink={handleDeleteLink}
          handleAddLink={handleAddLink}
          sectionPopUp={sectionPopUp}
          data={data}
          section={section}
          refetchCourseData={refetchCourseData}
          linkData={''}
          courseLinks={''}
        />
      </Dialog>
    </Stack>
  );
};

export default DragAndAddFiles;
