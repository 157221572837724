import * as React from 'react';
// import { useHistory } from 'react-router-dom';
import { StyledLogin } from './Login.styles';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts/Auth.context';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
// import { BASE_URL } from '../../../configurations/url.config';
// import { auth } from '../../../configurations/fireBase';
// import { signInWithPopup } from '@firebase/auth';
// import axios from 'axios';
import { metaConfig } from '../../../utils/constants';
// import { getDeviceToken } from '../../../services/pushNotification/pushNotification.service';

// << ---- Class or interface definition  starts ---- >>
interface FormValues {
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  isPhoneVerified: boolean;
  userName: string;
  deviceToken: any;
}

export const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = React.useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  // console.log(location, "location")

  const [formValues, setFormValues] = useState<FormValues>({
    firstName: '',
    lastName: '',
    emailId: location?.state?.emailId ?? '',
    phoneNumber: location?.state?.phoneNumber ?? '',
    password: '',
    confirmPassword: '',
    isPhoneVerified: true,
    userName: '',
    deviceToken: '',
  });

  const [formErrors, setFormErrors] = useState<FormValues>({
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    isPhoneVerified: true,
    userName: '',
    deviceToken: '',
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'phoneNumber' && location?.state?.phoneNumber) {
      return;
    }
    if (name === 'emailId' && location?.state?.emailId) {
      return;
    }
    setFormValues({ ...formValues, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleCloseSnackbar = () => {
    setErrorMessage(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: FormValues = {
      firstName: '',
      lastName: '',
      emailId: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      isPhoneVerified: true,
      userName: '',
      deviceToken: '',
    };
    if (!formValues.firstName) {
      errors.firstName = 'Please Enter your First Name';
    } else if (!/^[A-Za-z ,.'-]+$/.test(formValues.firstName)) {
      errors.firstName = 'Special characters not allowed';
    } else if (
      formValues.firstName.length < 4 ||
      formValues.firstName.length > 50
    ) {
      errors.firstName = 'Please Enter min 3 or max 50 characters';
    }
    if (!formValues.emailId) {
      errors.emailId = 'Please Enter your Email ID';
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailId)) {
      errors.emailId = 'Please Enter a valid Email ID';
    }
    if (!formValues.phoneNumber) {
      errors.phoneNumber = 'Please enter Mobile Number';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = 'Please enter valid Mobile Number';
    }

    setFormErrors(errors);

    if (formValues.password !== formValues.confirmPassword) {
      enqueueSnackbar('Passwords do not match', { variant: 'error' });
      return;
    }

    const deviceToken = localStorage.getItem('deviceToken') || '';
    const updatedFormValues = { ...formValues, deviceToken };

    try {
      setIsLoading(true);
      const response: any = await authContext.autoCreate(
        JSON.stringify(updatedFormValues)
      );

      if (response.status === 200) {
        const { community, ...userData } = response.data;
        navigate('/create-community-new', {
          state: { id: 2, data: community ? response.data : userData },
        });

        enqueueSnackbar('Account Created successfully', {
          variant: 'success',
        });
      } else if (
        response?.response?.data &&
        Array.isArray(response.response.data)
      ) {
        const errorMessages = response.response.data.map(
          (error: { msg: string }) => error.msg
        );
        setErrorMessage(errorMessages.join(', '));
      } else {
        setErrorMessage(response.response.data.message);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);

      setFormValues({
        ...formValues,
        firstName: userInfo.given_name || '',
        lastName: userInfo.family_name || '',
        emailId: userInfo.email || '',
        phoneNumber: userInfo.phoneNumber || '',
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.loginPage}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <StyledLogin>
        <Grid container>
          <Grid
            item
            md={7}
            sx={{
              background: 'linear-gradient(to right bottom, #50a1ca,#469EA7)',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ height: '100%', width: '100%' }}
            >
              <Box sx={{ cursor: 'pointer', px: 2 }}>
                <Box
                  component={'img'}
                  src={require('./../../../assets/images/registerbckg.png')}
                  alt=""
                  maxWidth={'100%'}
                />
              </Box>
              <Stack alignItems={'center'} sx={{ mt: 1 }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: '28px',
                    fontWeight: '700',
                    color: '#ffffff',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Unlock Community Access
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#ffffff',
                    fontFamily: 'Montserrat',
                    pt: 1,
                  }}
                >
                  Take the first step to embrace the limitless possibilities
                  that await you here.
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              sx={{
                height: '100vh',
                overflow: 'auto',
                alignItems: 'center',
                justifyContent: { xs: 'flex-start', md: 'center' },
              }}
              className="login-form-container"
              alignItems={'center'}
            >
              <Box>
                <Stack
                  direction={'row'}
                  sx={{
                    pr: 10,
                    pl: 2,
                    pt: 0.5,
                    display: { xs: 'none', md: 'block' },
                  }}
                >
                  <ArrowBackIcon
                    sx={{ color: '#1C1B1F', cursor: 'pointer' }}
                    onClick={() => navigate('/login')}
                  />
                </Stack>
                <Divider
                  sx={{ pt: 1, display: { xs: 'none', md: 'block' } }}
                ></Divider>

                <Box
                  component={'img'}
                  src={require('./../../../assets/images/RegisterMBg.png')}
                  sx={{
                    height: '180px',
                    width: '100%',
                    display: { xs: 'block', md: 'none' },
                  }}
                  alt=""
                ></Box>
                <Box
                  component={'form'}
                  sx={{
                    pt: { xs: 3, sm: 3, md: 3, lg: 3, xxl: 5 },
                    mt: { xs: '5vh', sm: '5vh', md: '0vh', lg: '0vh' },
                    width: { xs: 'auto', md: '328px' },
                    px: { xs: 5, md: 0 },
                  }}
                  onSubmit={handleSubmit}
                >
                  <Stack direction={'row'} justifyContent={'center'}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#424242',
                        fontFamily: 'Montserrat',
                        pb: 3,
                      }}
                    >
                      Register
                    </Typography>
                  </Stack>
                  <Stack>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          onChange={handleChange}
                          value={formValues.firstName}
                          fullWidth
                          label="Name"
                          id="outlined-size-small"
                          size="small"
                          type="text"
                          name="firstName"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor: '#E5E3EF',
                                  borderRadius: '100px',
                                }}
                              ></InputAdornment>
                            ),
                            sx: {
                              borderRadius: '30px',
                              backgroundColor: '#ffffff',
                              '& .MuiInputLabel-root': {
                                fontFamily: 'Montserrat',
                              },
                              '& input': {
                                fontFamily: 'Montserrat',
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={12}>
                        <TextField
                          aria-readonly
                          value={formValues.phoneNumber}
                          onChange={handleChange}
                          fullWidth
                          label="Mobile"
                          type="tel"
                          size="small"
                          name="phoneNumber"
                          InputProps={{
                            readOnly: !!location?.state?.phoneNumber,
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor: '#E5E3EF',
                                  borderRadius: '100px',
                                }}
                              ></InputAdornment>
                            ),
                            sx: {
                              borderRadius: '30px',
                              backgroundColor: '#ffffff',
                              '& .MuiInputLabel-root': {
                                fontFamily: 'Montserrat',
                              },
                              '& input': {
                                fontFamily: 'Montserrat',
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.emailId}
                          onChange={handleChange}
                          fullWidth
                          label="Email"
                          type="email"
                          size="small"
                          name="emailId"
                          InputProps={{
                            readOnly: !!location?.state?.emailId,
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor: '#E5E3EF',
                                  borderRadius: '100px',
                                }}
                              ></InputAdornment>
                            ),
                            sx: {
                              borderRadius: '30px',
                              fontFamily: 'Montserrat',
                              backgroundColor: '#ffffff',
                              '& .MuiInputLabel-root': {
                                fontFamily: 'Montserrat',
                              },
                              '& input': {
                                fontFamily: 'Montserrat',
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Grid container sx={{ mt: 3 }}>
                    <Grid item>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignContent={'center'}
                        justifyContent={'center'}
                      >
                        <Button
                          size="large"
                          variant="contained"
                          type="submit"
                          fullWidth
                          sx={{
                            background:
                              'linear-gradient(to right bottom, #50a1ca, #3b9b7f)',
                            textTransform: 'capitalize',
                            borderRadius: '100px',
                            fontSize: '16px',
                            width: 210,
                            boxShadow: 'none',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          {isLoading ? (
                            <CircularProgress
                              size={24}
                              sx={{ color: 'white' }}
                            />
                          ) : (
                            'Save & Continue'
                          )}
                        </Button>
                      </Stack>
                      {errorMessage && (
                        <Snackbar
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(errorMessage)}
                          autoHideDuration={6000}
                          onClose={handleCloseSnackbar}
                        >
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleCloseSnackbar}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            onClose={handleCloseSnackbar}
                          >
                            <AlertTitle>Error</AlertTitle>
                            {errorMessage}
                          </Alert>
                        </Snackbar>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </StyledLogin>
    </>
  );
};
