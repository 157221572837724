import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Drawer, DrawerHeader, styles } from './Layout.styles';
import NavigationBar from './components/NavigationBar/NavigationBar';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Stack from '@mui/material/Stack';
import { MenuIcon } from '../../assets/icons';
import MobileNav from './components/MobileNav/MobileNav';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

export default function Layout() {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };


  const selectedCommunity = useSelector((state: any) => state.selectedCommunity.selectedCommunity);

  // console.log(selectedCommunity, "community Data")

  const location = useLocation();
  const isCreateClassRoute = location.pathname === '/create-meeting' || location.pathname === "/add-appointment" || location.pathname === "/add-event";

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          display: { xs: "none", md: "block" },
          ...(isCreateClassRoute && {
            height: "100vh",
            zIndex: 1302,
            backgroundColor: 'white',
          }),

        }}
      >
        <DrawerHeader>
          <Stack alignItems={'center'} justifyContent={'space-between'}>
            {open && (
              <Box
                component={'img'}
                src={selectedCommunity?.logo}
                alt="Dashboard Logo"
                sx={{ width: '100%', objectFit: 'cover', height: '60px', p: 1, borderRadius: '15px', }}
              />
            )}
          </Stack>
          <Stack alignItems={'center'} justifyContent={'center'}>
            {open && (
              <Typography sx={{
                fontSize: { xs: '12px', md: '14px' },
                fontWeight: 'bold',
                background:
                  '-webkit-linear-gradient(90deg, #3C5AA0 0%, #50A1CA 100%);',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: 'Montserrat',
                textTransform: 'capitalize'

              }}>{selectedCommunity?.name?.length > 10 ? selectedCommunity?.name?.slice(0, 10) + "..." : selectedCommunity?.name}</Typography>
            )}
          </Stack>
          <Stack alignItems={'center'} justifyContent={'center'}>
            <IconButton disableRipple={true} onClick={handleDrawerOpen}>
              {!open
                ? ''
                : // <img
                //     src={require("../../assets/images/Communn-favicon.png")}
                //     alt="home page logo not found"
                //     style={{ width: "3.5vh", }}
                // />
                ''}

              {/* <SidebarTogglerIcon /> */}
              <MenuIcon sx={{}} />
            </IconButton>
          </Stack>
          {/* <MobileSideBar /> */}
        </DrawerHeader>
        <Sidebar open={open} />
      </Drawer>

      <Box sx={styles.main}>
        {/* Header Section */}

        <NavigationBar />

        {/* Content Section */}
        <Box sx={styles.contentSection}>
          {/* <Dashbszoard /> */}
          {/* <Box sx={{
            display: { xs: 'block', md: 'none' }, mb: 1,
            position: 'sticky',
            top: 0, // Ensures it sticks to the top
            zIndex: 1000, // Adjust z-index as needed 
          }}>
            <MobileTopNav />
          </Box> */}
          <Outlet />
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MobileNav />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
