import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogProps,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import store from '../../../store';
import { usePlans } from '../../../hooks/usePlans';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getStaticValue } from '../../../utils/StaticValues';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AuthContext, IAuthContext } from '../../../contexts/Auth.context';
import {
  createSubscription,
  updateSubscriptionById,
} from '../../../services/subscription.service';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { useSubscription } from '../../../hooks/useSubscription';
import BreadcrumbDouble from '../../AlternatePages/BreadCumsDouble';
import { ArrowDropDown } from '@mui/icons-material';
import { ISubscriberBulk } from '../../../models/subscription.model';
import jsPDF from 'jspdf';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { CalendarDays, ChevronDown, CloudDownload, CloudUpload } from 'lucide-react';

export interface IUser {
  firstName?: string;
  lastName?: string;
  emailId?: string;
  phoneNumber?: string;
}

export interface IAddSubscription {
  user?: IUser;
  plan?: string;
  startDate?: string;

}

export interface IPlanData {
  _id: string;
  name: string;
  description: string;
  endDate: string;
  pricing: string;
  status: string;
}

interface Subscriber {
  user: {
    firstName: string;
    emailId: string;
    phoneNumber: string;
  };
  plan: string;
  startDate: string;
}

const CreateSubscription = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openPlans, setOpenPlans] = useState(false);
  const [planDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [plansList, setPlansList] = useState<IPlanData[]>([]);
  const { getCommunityPlansList } = usePlans();
  const [selectedPlan, setSelectedPlan] = useState<any>(planDetail);

  const { id } = useParams();

  const [userData, setUserData] = useState<IUser>({
    firstName: '',
    phoneNumber: '',
    emailId: '',
  });
  const [errors, setErrors] = useState<IUser>({
    firstName: '',
    phoneNumber: '',
    emailId: '',
  });
  const [fullWidth] = useState(true);
  const [maxWidth] = useState<DialogProps['maxWidth']>('lg');
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    null
  );
  const [show, setShow] = useState(false);
  const [immediateStart, setImmediateStart] = useState(false);

  const handleCheckboxChange = (event: any) => {
    setImmediateStart(event.target.checked);
    if (event.target.checked) {
      setSelectedStartDate(dayjs());
    }
  };

  const handleDateChange = (newValue: any) => {
    setSelectedStartDate(newValue);
  };

  const handleClickShow = () => {
    setShow((prev) => !prev);
  };

  const navigate = useNavigate();

  const subscriptionId = id;

  const { subscriptionListBySubscriptionId } = useSubscription();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) return;

      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData: any[] = XLSX.utils.sheet_to_json(sheet);

      const normalizedData = jsonData.map((row) => {
        const normalizedRow: any = {};
        for (const key in row) {
          const normalizedKey = key.trim().toLowerCase().replace(/\s+/g, '');
          normalizedRow[normalizedKey] = row[key];
        }
        return normalizedRow;
      });

      const subscribers: ISubscriberBulk[] = normalizedData.map((row) => ({
        user: {
          firstName:
            row.username || row.name || row['username'] || row['name'] || '',
          emailId:
            row.emailid || row.email || row['emailid'] || row['email'] || '',
          phoneNumber:
            row.mobilenumber ||
            row.phone ||
            row['mobilenumber'] ||
            row['phone'] ||
            '',
        },
        planId: row.planid || row.plan || row['planid'] || row['plan'] || '',
        startDate: convertExcelDate(
          row.startdate || row.startdate || row['startdate'] || row['startdate']
        ),
      }));

      console.log('subscribers', subscribers);
      navigate('/bulk-import', { state: { subscribers } });
    };
  };

  const convertExcelDate = (serial: any | string): string => {
    if (!serial || isNaN(Number(serial))) return '';

    const excelEpoch = new Date(1900, 0, 0);
    const daysOffset = Number(serial) - 1 + 1;
    const actualDate = new Date(excelEpoch.getTime() + daysOffset * 86400000);

    return actualDate.toISOString().split('T')[0];
  };

  const handleDownloadClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadOption = (option: any) => {
    if (option === 'Template') {
      generateExcelTemplate();
    } else if (option === 'IDs and Names') {
      generatePDF();
    }
    handleDownloadClose();
  };

  const generateExcelTemplate = () => {
    const headers = [
      'SL.No',
      'userName',
      'mobileNumber',
      'emailId',
      'planId',
      'startDate',
      'planName',
    ];
    const data = [
      [
        1,
        'YourName',
        '9876543210',
        'user@gmail.com',
        '6733596b02b892b7972fa666',
        '2025-12-31',
        'Test plan',
      ],
    ];

    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Sample_Template.xlsx');
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(16);
    doc.text(`Community Name: ${selectedCommunityName}`, 10, 10);

    doc.setFontSize(12);

    const text =
      "Add the plan names and their respective IDs to the sheet for bulk upload under 'Plan Name' and 'Plan ID' for each user.";
    const wrappedText = doc.splitTextToSize(text, pageWidth - 20);

    doc.text(wrappedText, 10, 20);

    let y = 40;

    // ✅ Filter only "ACTIVE" plans before adding them to the PDF
    const activePlans = plansList.filter(plan => plan.status === "ACTIVE");

    activePlans.forEach((plan, index) => {
      doc.text(`${index + 1}. ${plan.name} - ${plan._id}`, 10, y);
      y += 10;
    });

    if (activePlans.length === 0) {
      doc.text("No active plans available.", 10, y);
    }

    doc.save('Sample_IDs_and_Names.pdf');
  };


  useEffect(() => {
    const fetchSubscriptionData = async () => {
      if (subscriptionId) {
        try {
          const response = await subscriptionListBySubscriptionId(
            selectedCommunityId,
            subscriptionId
          );
          // console.log(response, "response");
          if (response) {
            const { user, plan, startDate } = response;
            // console.log("Start Date:", startDate);
            setUserData({
              firstName: user.firstName,
              lastName: user.lastName,
              phoneNumber: user.phoneNumber,
              emailId: user.emailId,
            });
            setSelectedPlan(plan);
            setSelectedStartDate(dayjs(startDate));
          }
        } catch (error) {
          console.error('Error fetching subscription data:', error);
        }
      }
    };

    fetchSubscriptionData();
  }, [subscriptionId]);

  const subscriptionDetails: IAddSubscription = {
    user: {
      firstName: userData?.firstName,
      lastName: userData?.lastName || ' ',
      phoneNumber: userData?.phoneNumber,
      emailId: userData?.emailId,
    },
    plan: selectedPlan?._id || '',
    startDate: selectedStartDate ? selectedStartDate.format("YYYY-MM-DD") : '',
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Validation checks
    let newErrors: any = {};

    if (!userData.phoneNumber || !/^\d{10}$/.test(userData.phoneNumber)) {
      newErrors.phoneNumber = 'Enter a valid 10-digit phone number.';
    }

    if (
      !userData.emailId ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.emailId)
    ) {
      newErrors.emailId = 'Enter a valid email address.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      if (subscriptionId) {
        const updateSubscription = await updateSubscriptionById(
          getAccessToken(),
          subscriptionId,
          subscriptionDetails
        );
        if (updateSubscription.data && updateSubscription.status === 200) {
          enqueueSnackbar('Subscriber updated successfully', {
            variant: 'success',
          });
          navigate('/subscribers');
        } else {
          enqueueSnackbar('Failed to update subscriber', { variant: 'error' });
        }
      } else {
        // Creating a new subscription
        const response = await createSubscription(
          getAccessToken(),
          selectedCommunityId,
          subscriptionDetails
        );

        if (response.status === 201) {
          navigate('/subscribers');
          enqueueSnackbar('Subscriber added successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else if (response.status === 400) {
          enqueueSnackbar('User already added', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar('Subscriber not added', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  const state = store.getState();

  const selectedCommunity = useSelector(() => {
    return state?.selectedCommunity;
  });

  const communitiesSuggestion = useSelector(() => {
    return state?.communities;
  });

  let filteredSelectedCommunity: any = [];
  if (Array.isArray(communitiesSuggestion?.communities)) {
    filteredSelectedCommunity = communitiesSuggestion.communities.filter(
      (community: any) =>
        community._id === selectedCommunity.selectedCommunity?._id
    );
  }

  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );
  const selectedCommunityName = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?.name
  );


  const { getPlansList } = usePlans();

  //   Plans List
  const loadPlans = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const plans_data = await getPlansList(selectedCommunityId);
        if (plans_data) {
          setIsLoading(false);
          setPlansList(plans_data);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(plansList);
  useEffect(() => {
    loadPlans();
  }, [selectedCommunityId]);




  const disableReview =
    userData?.firstName &&
    userData?.emailId &&
    userData?.phoneNumber &&
    selectedPlan &&
    selectedStartDate;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      if (/^\d{0,10}$/.test(value)) {
        setUserData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: 'Enter a valid mobile number',
        }));
      }
    } else {
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <Helmet>
        <title>{id ? 'Update' : 'Create'} Subscription</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* Breadcrumb */}
        <BreadcrumbDouble
          items={[
            { label: 'Subscribers', path: '/subscribers' },
            { label: `${id ? 'Edit Subscriber' : 'Add Subscriber'}` },
          ]}
        />
        {/* Download Options and Bulk Add Button */}
        <Box display="flex" alignItems="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            variant="contained"
            endIcon={<CloudUpload size={20} strokeWidth={1.5} />}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '8px',
              backgroundColor: '#3C5AA0',
              fontFamily: 'Montserrat',
              boxShadow: 'none',
              marginRight: '16px',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                cursor: 'pointer',
                border: 'none',
                boxShadow: 'none',
              },
            }}
            onClick={() => fileInputRef.current?.click()}
          >
            Add Bulk
          </Button>
          {/* Download Options Button */}
          <Button
            variant="contained"
            endIcon={<CloudDownload size={20} strokeWidth={1.5} color='white' />}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '8px',
              backgroundColor: '#3C5AA0',
              fontFamily: 'Montserrat',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                cursor: 'pointer',
                border: 'none',
                boxShadow: 'none',
              },
            }}
            onClick={handleDownloadClick}
          >
            Download Samples
          </Button>

          {/* Download Options Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDownloadClose}
          >
            <MenuItem onClick={() => handleDownloadOption('Template')} sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
              Download Sample Template
            </MenuItem>
            <MenuItem onClick={() => handleDownloadOption('IDs and Names')} sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
              Download Plans IDs and Names
            </MenuItem>
          </Menu>

          {/* Bulk Add Button */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
        </Box>

        <Box display="flex" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }} gap={2}>
          <IconButton sx={{
            backgroundColor: '#3C5AA0', borderRadius: '8px',
            "&:hover": {
              backgroundColor: '#3C5AA0'
            }
          }} onClick={() => fileInputRef.current?.click()}>
            <CloudUpload size={20} strokeWidth={1.5} color='white' />
          </IconButton>
          <IconButton sx={{
            backgroundColor: '#3C5AA0', borderRadius: '8px',
            "&:hover": {
              backgroundColor: '#3C5AA0'
            }
          }} onClick={handleDownloadClick}>
            <CloudDownload size={20} strokeWidth={1.5} color='white' />
          </IconButton>
          {/* Download Options Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDownloadClose}
          >
            <MenuItem onClick={() => handleDownloadOption('Template')} sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
              Download Sample Template
            </MenuItem>
            <MenuItem onClick={() => handleDownloadOption('IDs and Names')} sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
              Download Plans IDs and Names
            </MenuItem>
          </Menu>

          {/* Bulk Add Button */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
        </Box>
      </Box>
      {/* Sections 1 */}
      <Paper
        sx={{
          p: 1,
          boxShadow: 'none',
          borderRadius: '12px !important',
          border: '1px solid #E7EBF1',
          backgroundColor: '#ffffff',
          mt: 1,
        }}
      >
        <Stack sx={{ backgroundColor: '#ffffff', p: 2, borderRadius: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: '13px',
                  color: '#2653A3',
                  fontWeight: '500',
                  fontFamily: 'Montserrat',
                  mb: 1,
                }}
              >
                Name
              </Typography>
              <TextField
                label="Name"
                name="firstName"
                variant="outlined"
                size="small"
                fullWidth
                value={userData?.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
                disabled={!!id}
                sx={{
                  fontSize: '12px',
                  backgroundColor: '#F9F9F9',
                  borderRadius: '8px',
                  // width: { xs: '85vw', md: '15vw' },
                  '& .MuiInputLabel-root': {
                    fontSize: '13px',
                    fontFamily: 'Montserrat',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    fontSize: '13px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                  '& input': {
                    fontFamily: 'Montserrat',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: '13px',
                  color: '#2653A3',
                  fontWeight: '500',
                  fontFamily: 'Montserrat',
                  mb: 1,
                }}
              >
                Mobile Number
              </Typography>
              <TextField
                name="phoneNumber"
                type="text"
                label="Mobile Number"
                variant="outlined"
                size="small"
                fullWidth
                value={userData?.phoneNumber}
                onChange={handleChange}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                disabled={!!id}
                sx={{
                  fontSize: '12px',
                  backgroundColor: '#F9F9F9',
                  borderRadius: '8px',
                  '& .MuiInputLabel-root': {
                    fontSize: '13px',
                    fontFamily: 'Montserrat',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    fontSize: '13px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                  '& input': {
                    fontFamily: 'Montserrat',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: '13px',
                  color: '#2653A3',
                  fontWeight: '500',
                  fontFamily: 'Montserrat',
                  mb: 1,
                }}
              >
                Email ID
              </Typography>
              <TextField
                type="mail"
                label="Email"
                name="emailId"
                variant="outlined"
                size="small"
                fullWidth
                value={userData?.emailId}
                onChange={handleChange}
                error={!!errors.emailId}
                helperText={errors.emailId}
                disabled={!!id}
                sx={{
                  fontSize: '12px',
                  fontFamily: 'Montserrat',
                  backgroundColor: '#F9F9F9',
                  borderRadius: '8px',
                  '& .MuiInputLabel-root': {
                    fontSize: '13px',
                    fontFamily: 'Montserrat',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    fontSize: '13px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                  '& input': {
                    fontFamily: 'Montserrat',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: '13px',
                  color: '#2653A3',
                  fontWeight: '500',
                  fontFamily: 'Montserrat',
                  mb: 1,
                }}
              >
                Select Plan
              </Typography>
              <Autocomplete
                disabled={!!id}
                ListboxProps={{
                  style: { fontSize: '13px', fontFamily: 'Montserrat' },
                }}
                options={plansList.filter((plan) => plan.status === 'ACTIVE')}
                size="small"
                disablePortal
                id="combo-box-demo"
                loading={isLoading}
                sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                getOptionLabel={(option) =>
                  `${option?.name || ''} - ₹${option?.offerValue === 0 ? option?.totalPlanValue : option?.offerValue || option?.pricing}`
                }
                noOptionsText="No Plans"
                value={selectedPlan || null}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    const selectedPlanObject = plansList.find(
                      (plan) => plan.name === newValue.name
                    );
                    setSelectedPlan(selectedPlanObject || null);
                  } else {
                    setSelectedPlan(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      fontSize: '13px',
                      backgroundColor: '#F9F9F9',
                      fontFamily: 'Montserrat',
                      borderRadius: '8px',
                      '& .MuiInputLabel-root': {
                        fontSize: '13px',
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        fontSize: '13px',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                      '& input': {
                        fontFamily: 'Montserrat',
                      },
                    }}
                    {...params}
                    label="Select Plan"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: '13px',
                  color: '#2653A3',
                  fontWeight: '500',
                  fontFamily: 'Montserrat',
                  mb: 1,
                }}
              >
                Select Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={['DesktopDatePicker']}
                  sx={{
                    overflow: 'hidden',
                    backgroundColor: '#F9F9F9',
                    borderRadius: '8px',
                    p: 0,
                  }}
                >
                  <DatePicker
                    disabled={immediateStart}
                    value={selectedStartDate}
                    onChange={handleDateChange}
                    slots={{
                      openPickerIcon: () => <CalendarDays size={20} strokeWidth={1.5} />,
                    }}
                    format="DD/MM/YYYY"
                    sx={{
                      fontSize: '12px',
                      backgroundColor: '#F9F9F9',
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        fontFamily: 'Montserrat',
                      },
                      '& .css-q9e13y-MuiStack-root>.MuiTextField-root': {
                        backgroundColor: '#F9F9F9',
                        borderRadius: '10px',
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '13px',
                        fontFamily: 'Montserrat',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        fontSize: '13px',
                        fontFamily: 'Montserrat',
                      },
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        sx: {
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            fontFamily: 'Montserrat',
                          },
                          '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                          {
                            color: '#00000061',
                            borderRadius: '10px',
                            fontFamily: 'Montserrat',
                          },
                          '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                          {
                            borderColor: 'green',
                            border: '1px solid',
                            borderRadius: '10px',
                          },
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                          {
                            color: '#92929D',
                            fontSize: '13px',
                            borderRadius: '10px',
                          },
                          '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root':
                          {
                            borderRadius: '9px',
                          },
                          '& .css-tta2c4-MuiFormControl-root-MuiTextField-root .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root':
                          {
                            borderRadius: '9px',
                          },
                          '& .MuiInputLabel-root': {
                            fontSize: '13px',
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            fontSize: '13px',
                            fontFamily: 'Montserrat',
                          },
                        },
                      },
                      layout: {
                        sx: {
                          color: '#2653A3',
                          borderRadius: '20px',
                          borderWidth: '0px',
                          borderColor: '#2653A3',
                          border: '0px solid',
                          backgroundColor: '#F9F9F9',
                          fontFamily: 'Montserrat',
                          boxShadow: 'none',
                        },
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        transform: 'scale(1.1)',
                        color: '#92929D',
                        '&.Mui-checked': {
                          color: '#5E6C84',
                        },
                        '& .MuiSvgIcon-root': {
                          borderRadius: '4px',
                        },
                      }}
                    />
                  }
                  checked={immediateStart}
                  onChange={handleCheckboxChange}
                  label="Check if you need Immediately."
                  sx={{
                    fontSize: '12px',
                    color: '#515151',
                    fontWeight: '500',
                    fontFamily: 'Montserrat',
                    '& .MuiFormControlLabel-label': {
                      fontSize: { xs: '10px', md: '12px' },
                      color: '#515151',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                    },
                  }}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Stack>
            {!id && (
              <Button
                size="large"
                variant="contained"
                sx={{
                  backgroundColor: '#3C5AA0',
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  width: { xs: '50%', md: '10%' },
                  boxShadow: 'none',
                  fontFamily: 'Montserrat',
                  '&:hover': {
                    backgroundColor: '#3C5AA0',
                    color: 'white',
                    boxShadow: 'none',
                  },
                }}
                onClick={handleClickShow}
                disabled={!disableReview}
              >
                Review
              </Button>
            )}
          </Stack>
        </Stack>
      </Paper>
      {(id || show) && (
        <Paper
          sx={{
            p: 3,
            mt: 2,
            mb: { xs: 4, md: 0 },
            boxShadow: 'none',
            borderRadius: '12px !important',
            border: '1px solid #E7EBF1',
          }}
          elevation={0}
        >
          <Grid container sx={{ display: { xs: 'block', md: 'none' } }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#3C5AA0',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                }}
              >
                Name
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#000000',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {userData?.firstName
                    ? userData?.firstName
                    : 'No User Selected'}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#3C5AA0',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                }}
              >
                Plan
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#000000',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {selectedPlan?.name ? selectedPlan?.name : 'No Plan Selected'}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#3C5AA0',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                }}
              >
                Start Date
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#000000',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {selectedStartDate
                    ? selectedStartDate.format('DD/MM/YYYY')
                    : 'No Date Selected'}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Stack
            display="flex"
            flexDirection="row"
            gap={5}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            <Stack>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#3C5AA0',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                }}
              >
                Name
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#000000',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {userData?.firstName
                    ? userData?.firstName
                    : 'No User Selected'}
                </span>
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#3C5AA0',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                }}
              >
                Plan
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#000000',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {selectedPlan?.name ? selectedPlan?.name : 'No Plan Selected'}
                </span>
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  ml: 1,
                  mt: 1,
                  fontSize: '13px',
                  color: '#3C5AA0',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  textTransform: 'capitalize',
                }}
              >
                Start Date
                <br />
                <span
                  style={{
                    fontSize: '12px',
                    color: '#000000',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {selectedStartDate
                    ? selectedStartDate.format('DD/MM/YYYY')
                    : 'No Date Selected'}
                </span>
              </Typography>
            </Stack>
          </Stack>
          <Stack display="flex" flexDirection="column" gap={2} mt={5}>
            <Stack>
              <Typography
                sx={{
                  fontSize: '13px',
                  color: '#515151',
                  fontFamily: 'Montserrat',
                }}
              >
                For every {selectedPlan?.interval}{' '}
                {selectedPlan?.interval > '1'
                  ? `${getStaticValue(selectedPlan?.duration)}s`
                  : getStaticValue(selectedPlan?.duration)}{' '}
                user is charged :
                <span
                  style={{
                    fontSize: '13px',
                    color: '#515151',
                    fontWeight: '600',
                    fontFamily: 'Montserrat',
                  }}
                >
                  ₹

                  {selectedPlan?.pricing}
                </span>
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  fontSize: '13px',
                  color: '#515151',
                  fontFamily: 'Montserrat',
                }}
              >
                Receiving Payment :
                <span
                  style={{
                    fontSize: '13px',
                    color: '#515151',
                    fontWeight: '600',
                    fontFamily: 'Montserrat',
                  }}
                >
                  ₹

                  {selectedPlan?.pricing}
                </span>
              </Typography>
            </Stack>
          </Stack>
          <Button
            size="large"
            variant="contained"
            sx={{
              backgroundColor: '#3C5AA0',
              textTransform: 'capitalize',
              borderRadius: '8px',
              boxShadow: 'none',
              fontFamily: 'Montserrat',
              width: { xs: '50%', md: '10%' },
              mt: 3,
              '&:hover': {
                backgroundColor: '#3C5AA0',
                color: 'white',
                boxShadow: 'none',
              },
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              <>{id ? 'Update' : 'Add'}</>
            )}
          </Button>
        </Paper>
      )}
    </>
  );
};
export default CreateSubscription;
