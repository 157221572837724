import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Stack,
  Dialog,
  DialogContent,
  Button,
  TablePagination,
  Checkbox,
  Popover,
  Typography,
  Divider,
  Grid,
  Box,
  Tooltip,
  Fade,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { AddUser } from '../users/AddNewUser';
import { getStaticValue } from '../../utils/StaticValues';
import { Invitations } from '../../models/Invitation.model';
import { Close, MessageOutlined } from '@mui/icons-material';
import { useInvitations } from '../../hooks/useInvitations';
import Loader from '../Loader/Loader';
import { CommunityMembers } from '../../models/community-user.model';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { GetColor } from '../tables/GetColor';
import { ChevronLeft, ChevronRight, EllipsisVertical, MessageCircleMore, Pencil, RotateCcw, Trash2, X } from 'lucide-react';
interface invitationProps {
  invitations: Invitations[];
  refetchInvitesData: () => void;
  communityMembers: CommunityMembers[];

  communityId: string;
  updateInvitationList: (newInvitation: Invitations[]) => void;
}

const InvitationList: React.FC<invitationProps> = ({
  invitations,
  refetchInvitesData,
  communityMembers,
  communityId,
  updateInvitationList,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState<Invitations | null>(null);
  const [deleteSinglePopoverAnchor, setDeleteSinglePopoverAnchor] =
    useState<null | HTMLElement>(null);
  const [deleteCheckboxPopOverAnchor, setDeleteCheckboxPopOverAnchor] =
    useState<null | HTMLElement>(null);

  const [newSelectedInvitations, setNewSelectedInvitations] = useState<
    Invitations[]
  >([]);
  const [selectInvitations, setSelectInvitations] = useState(false);
  const [selectedInvitationsIds, setSelectedInvitationsIds] = useState<
    string[]
  >([]);

  const selectAllInvitations = () => {
    setSelectInvitations(!selectInvitations);
    const newSelectedRows = selectInvitations
      ? []
      : invitations.map((row: Invitations) => row._id);
    setSelectedInvitationsIds(newSelectedRows);
  };

  const handleRowSelect = (id: string, row: Invitations) => {
    const newSelectedRows = [...selectedInvitationsIds];
    const index = newSelectedRows.indexOf(id);
    if (index === -1) {
      newSelectedRows.push(id);
    } else {
      newSelectedRows.splice(index, 1);
    }
    setSelectedInvitationsIds(newSelectedRows);
  };

  const { isLoading, deleteInviteById, deleteSelectedInvitations } =
    useInvitations();

  const openDeletePopOver = Boolean(deleteCheckboxPopOverAnchor);
  const idCheckBoxDeletePopover = openDeletePopOver
    ? 'delete-multiple-popover'
    : undefined;
  const openSingleDeletePopover = Boolean(deleteSinglePopoverAnchor);
  const idSingleDeletePopover = openSingleDeletePopover
    ? 'delete-popover'
    : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpenAddUser(true);
    setNewSelectedInvitations([selectedRow!]);
  };
  //single-delete
  const handleDeleteClick = () => {
    setDeleteSinglePopoverAnchor(anchorEl);
    handleClose();
  };

  //checkbox-delete
  const handleDeleteSelected = (e: any) => {
    if (selectedInvitationsIds.length > 0) {
      setDeleteCheckboxPopOverAnchor(e.currentTarget);
      handleClose();
    }
  };

  const handleSingleDelete = async () => {
    if (selectedRow) {
      await deleteInviteById(selectedRow._id || '');
      await refetchInvitesData();
      handleClose();
      setDeleteSinglePopoverAnchor(null);
    }
  };

  const handleCheckboxSelectDelete = async () => {
    const selectedRowsData: Invitations[] =
      invitations?.filter((inv) => selectedInvitationsIds?.includes(inv._id)) ||
      [];
    const formData = new FormData();
    formData.append('data', JSON.stringify(selectedRowsData));
    selectedRowsData.forEach((invitation, index) => {
      formData.append(`data[${index}][_id]`, invitation._id);
      formData.append(`data[${index}][firstName]`, invitation.firstName);
      formData.append(`data[${index}][lastName]`, invitation.lastName);
      formData.append(
        `data[${index}][phoneNumber]`,
        invitation.phoneNumber.toString()
      );
      formData.append(`data[${index}][emailId]`, invitation.emailId);
      formData.append(`data[${index}][community]`, invitation.community.id);
      formData.append(`data[${index}][message]`, invitation.message);
    });

    await deleteSelectedInvitations(communityId || '', formData);
    handleDeleteAllSelected();
    await setSelectedRow(null);
    refetchInvitesData();
    setDeleteCheckboxPopOverAnchor(null);
  };

  const handleCloseSingleDeletePopOver = () => {
    setDeleteSinglePopoverAnchor(null);
  };
  const handleCloseCheckboxDeletePopover = () => {
    setDeleteCheckboxPopOverAnchor(null);
  };

  // const handleResendClick = async (rows: Invitations[]) => {
  //   console.log(rows, "rowdata");
  // };

  const handleAddUserClose = () => {
    setOpenAddUser(false);
    // getInviteList(communityId ?? "");
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    // setSelectedInvitations([]);
    setSelectedRow(null);
  };

  const handleEditSelected = () => {
    const selectedInvitationData = invitations.filter((e: Invitations) =>
      selectedInvitationsIds.includes(e._id)
    );
    setNewSelectedInvitations(selectedInvitationData);
    setOpenAddUser(selectedInvitationsIds.length > 0);
  };

  const handleDeleteAllSelected = () => {
    // getInviteList(communityId || "");
    // setSelectedInvitations([]);
    setSelectedInvitationsIds([]); // IN_USE
    setSelectInvitations(false); //IN_USE
  };

  const handleResendSelected = () => {
    selectedInvitationsIds?.forEach((invitationId) => {
      const selectedRow = invitations?.find((inv) => inv?._id === invitationId);
      if (selectedRow) {
        // console.log('Resend clicked for:', selectedRow);
      }
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    //setSelectedInvitations([]);
    setSelectedRow(null);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedInvitations =
    Array.isArray(invitations) && invitations.length > 0
      ? invitations?.slice(startIndex, endIndex)
      : null;

  return (
    <Stack>
      {selectedInvitationsIds?.length > 0 && (
        <Paper
          sx={{
            paddingLeft: '16px',
            display: 'flex',
            alignItems: 'center',
            mt: 0,
          }}
          elevation={0}
        >
          <IconButton
            onClick={handleDeleteAllSelected}
            aria-label="delete-all"
            sx={{ color: '#000000', fontSize: '14px' }}
          >
            <X color='#000000' size={15} strokeWidth={1.5} />
          </IconButton>
          <span
            style={{ marginRight: '10px', color: '#000000', fontSize: '13px' }}
          >{`${selectedInvitationsIds.length} selected`}</span>
          <IconButton onClick={handleEditSelected} aria-label="edit">
            <Pencil color='#000000' size={15} strokeWidth={1.5} />
          </IconButton>
          <IconButton onClick={handleResendSelected} aria-label="resend">
            <RotateCcw strokeWidth={1.5} size={15} />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={(e) => handleDeleteSelected(e)}
          >
            <Trash2 size={15} strokeWidth={1.5} color='#000000' />
          </IconButton>
        </Paper>
      )}
      <PerfectScrollbar >
        <Box sx={{ borderRadius: '12px !important', }}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              height: 'calc(100vh - 25vh)', paddingTop: '0',
              borderRadius: ' 12px',
              border: '1px solid #E7EBF1',
            }}
          >
            <Table
              sx={{
                '& th': {
                  fontSize: '13px',
                  fontWeight: 500,
                  color: '#000000',
                  fontFamily: 'Montserrat',
                  pt: 0,
                  pl: 1,
                  pb: 0,
                },
                '& td': {
                  borderColor: '#E0E0E0',
                  fontSize: '13px',
                  fontWeight: 400,
                  color: '#565656',
                  p: '0px 10px 0px 10px',
                  borderRadius: '12px !important',

                  '& .MuiTypography-root': { fontSize: '11px' },
                },
              }}
            >
              <TableHead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  background: '#f9f9f9',
                }}
              >
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      size="small"
                      sx={{ pl: '11px' }}
                      style={{
                        transform: 'scale(1)',
                      }}
                      checked={
                        selectInvitations &&
                        selectedInvitationsIds?.length === invitations?.length
                      }
                      onChange={selectAllInvitations}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ display: "none" }}>MSG</TableCell> */}
                  <TableCell sx={{ minWidth: 80 }}>User Name</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>Mobile No</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>Email ID</TableCell>
                  <TableCell sx={{ minWidth: 100 }}>Role Type</TableCell>
                  <TableCell sx={{ minWidth: 100 }}>Status</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {!isLoading && !communityId ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Loader /> {/* Replace Loader with your loader component */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {paginatedInvitations && paginatedInvitations?.length > 0 ? (
                    paginatedInvitations?.map((row, index) => (
                      <TableRow key={row?._id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            //  checked={selectedInvitations?.includes(row?._id)}
                            //   onChange={(e) => handleCheckboxChange(row,e)}

                            size="small"
                            sx={{ pl: '11px' }}
                            style={{
                              transform: 'scale(1)',
                              paddingBottom: '14px',
                            }}
                            checked={selectedInvitationsIds.includes(row._id)}
                            onChange={() => handleRowSelect(row._id, row)}
                          />
                        </TableCell>
                        {/* <TableCell sx={{ display: "none" }}>
                      {row?.message}
                    </TableCell> */}
                        <TableCell padding="normal">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={row?.firstName?.charAt(1)}
                              sx={{ height: '25px', width: '25px', fontFamily: 'Montserrat', fontSize: '12px' }}
                              alt={`${row?.firstName} ${row.lastName}`}
                            />
                            <Tooltip
                              title={`${row?.firstName} ${row?.lastName}`}
                              placement="bottom"
                              slots={{
                                transition: Fade,
                              }}
                              arrow
                              slotProps={{
                                transition: { timeout: 600 },
                              }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontFamily: "Montserrat",
                                    textAlign: 'center'
                                  },
                                },
                              }}
                            >
                              <Stack
                                style={{
                                  marginLeft: '8px',
                                  fontWeight: 500,
                                  fontSize: '13px !important',
                                  color: '#1A1A1A',
                                  fontFamily: 'Montserrat'
                                }}
                              >
                                {row.firstName?.length + row.lastName.length > 20
                                  ? `${row.firstName.substring(0, 20)}...`
                                  : `${row.firstName} ${row?.lastName}`}
                              </Stack>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '14px', }}>
                          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', }}>
                            {row.phoneNumber}
                          </Typography>
                        </TableCell>
                        <TableCell padding="normal">
                          <Tooltip title={row.emailId}
                            slots={{
                              transition: Fade,
                            }}
                            arrow
                            slotProps={{
                              transition: { timeout: 600 },
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: "Montserrat",
                                  textAlign: 'center'
                                },
                              },
                            }}>
                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '13px', }}>
                              {row.emailId.length > 20
                                ? `${row?.emailId.substring(0, 20)}...`
                                : row?.emailId}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '13px', }}>
                            {getStaticValue(row.role)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack
                            sx={{
                              textTransform: 'capitalize',
                              fontSize: '11px',
                              color: '#000000',
                              fontWeight: '400',
                              fontFamily: 'Montserrat'
                            }}
                            direction={'row'}
                          >
                            <FiberManualRecordIcon
                              key={index}
                              sx={{
                                hieght: '10px',
                                width: '10px',
                                pb: 1,
                                color: GetColor(row.status),
                              }}
                            />
                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '13px', }}>
                              {getStaticValue(row.status)}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell>
                          <IconButton
                            aria-controls={`menu-${index}`}
                            aria-haspopup="true"
                            onClick={(event) => {
                              handleClick(event);
                              setSelectedRow(row);
                            }}
                          >

                            <EllipsisVertical strokeWidth={1.5} />
                          </IconButton>
                          <Menu
                            id={`menu-${index}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            elevation={1}
                          >
                            <MenuItem
                              onClick={() => handleEditClick()}
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                            >
                              Edit
                            </MenuItem>
                            {/* <MenuItem   sx={{fontSize:"13px"}} onClick={() => handleResendClick([row])}>
                          Resend
                        </MenuItem> */}
                            <MenuItem
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                              onClick={() => handleDeleteClick()}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                        <TableCell>
                          {row.message && row.message.length > 0 && (
                            <Tooltip title={row?.message}
                              slots={{
                                transition: Fade,
                              }}
                              arrow
                              slotProps={{
                                transition: { timeout: 600 },
                              }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontFamily: "Montserrat",
                                    textAlign: 'center'
                                  },
                                },
                              }}
                            >

                              <IconButton>
                                <MessageCircleMore strokeWidth={1.5} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7}>{/* <NoInvitations/> */}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              fontSize: { xs: '10px', md: '1.5vh' },
              pr: { xs: '0px', md: '25px' },
              backgroundColor: 'white',
              borderRadius: ' 0px 0px 12px 12px'
            }}
          >
            <Typography sx={{ color: '#A5A5A5', fontSize: '13px', fontFamily: 'Montserrat', }}>
              Showing{' '}
              {Math.min(rowsPerPage, invitations?.length - page * rowsPerPage)} of{' '}
              {invitations?.length} entries
            </Typography>

            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={invitations?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={
                <span style={{ fontFamily: 'Montserrat', fontSize: '14px', }}>View</span>
              }
              labelDisplayedRows={() => null}
              SelectProps={{
                SelectDisplayProps: {
                  style: { border: '1px solid #A5A5A5', borderRadius: '5px', fontFamily: 'Montserrat', fontSize: '14px' },
                },
                MenuProps: {
                  PaperProps: { style: { border: '1px solid #A5A5A5', fontFamily: 'Montserrat', borderRadius: '5px', fontSize: '14px' } },
                },
              }}
              ActionsComponent={({ onPageChange, page }) => {
                const totalPages = Math.ceil(invitations?.length / rowsPerPage);
                return (
                  <Stack
                    direction={'row'}
                    display="flex"
                    alignItems="center"
                    justifyContent={'space-between'}
                  >

                    <IconButton
                      onClick={(event) => onPageChange(event, page - 1)}
                      disabled={page === 0}
                      aria-label="previous page"
                    >
                      <ChevronLeft size={20} strokeWidth={1.5} />
                    </IconButton>
                    {[...Array(3)].map((_, index) => {
                      const pageNumber = page + index;
                      if (pageNumber < totalPages) {
                        return (
                          <IconButton
                            key={pageNumber}
                            onClick={(event) => onPageChange(event, pageNumber)}
                            style={{
                              fontSize: '14px',
                              border:
                                page === pageNumber ? '1px solid #3C5AA0' : 'none', // Highlight the active page with a border
                              color: page === pageNumber ? '#3C5AA0' : '#000000', // Highlight the active page with a color
                              width: page === pageNumber ? '30px' : '30px',
                              height: page === pageNumber ? '30px' : '30px',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {pageNumber + 1}
                          </IconButton>
                        );
                      } else {
                        return null; // Don't render the button if it's out of bounds
                      }
                    })}
                    <IconButton
                      onClick={(event) => onPageChange(event, page + 1)}
                      style={{ fontSize: '14px' }}
                      disabled={page >= totalPages - 1}
                      aria-label="next page"
                    >
                      <ChevronRight size={20} strokeWidth={1.5} />
                    </IconButton>

                  </Stack>
                );
              }}
            />
          </Stack>

        </Box>
      </PerfectScrollbar>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openAddUser}
        onClose={() => setOpenAddUser(false)}
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: '7px', backgroundColor: '#F0F9FF', height: '50px' }}
        >
          <Typography
            sx={{
              fontSize: { xs: '16px', md: '18px' },
              fontWeight: 500,
              textDecoration: 'none',
              color: '#3C5AA0',
              marginLeft: 3,
              fontFamily: 'Montserrat'
            }}
          >
            {selectInvitations
              ? newSelectedInvitations.length >= 1
                ? 'Edit Invites'
                : 'Add Invitations'
              : newSelectedInvitations.length >= 1
                ? 'Edit Invites'
                : 'Add Invitations'}
          </Typography>
          <Box
            onClick={() => setOpenAddUser(false)}
            sx={{
              backgroundColor: '#3C5AA0',
              padding: '4px',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '10px',
              mt: '0.5px',
              mr: '10px',
              cursor: 'pointer',
              // "&:hover": {
              //   backgroundColor: "#50A1CA", // Background color on hover
              //   cursor: "pointer",
              //   border: "none",
              // },
            }}
          >
            <Close sx={{ fontSize: '15px' }} />
          </Box>
        </Grid>
        <DialogContent sx={{ padding: '10px 20px' }}>
          <AddUser
            onClose={handleAddUserClose}
            isCheckboxSelected={selectInvitations}
            initialInviteData={invitations}
            refetchInvitesData={refetchInvitesData}
            selectedInvitations={newSelectedInvitations}
            communityMembers={communityMembers}
            selectedCommunityId={communityId}
            updateInvitationList={updateInvitationList}
          />
        </DialogContent>
      </Dialog>

      <Popover
        id={idCheckBoxDeletePopover}
        open={openDeletePopOver}
        anchorEl={deleteCheckboxPopOverAnchor}
        onClose={() => setDeleteCheckboxPopOverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '600',
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          Are you sure you want to delete selected invitations?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseCheckboxDeletePopover}
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              color: '#3C5AA0',
              '&:hover': {
                borderColor: '#3C5AA0',
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleCheckboxSelectDelete}
              sx={{
                background: 'linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  borderColor: '#3C5AA0',
                },
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <Popover
        id={idSingleDeletePopover}
        open={openSingleDeletePopover}
        anchorEl={deleteSinglePopoverAnchor}
        onClose={() => setDeleteSinglePopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '600',
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          Are you sure you want to delete the invitation for
          {selectedRow?.firstName} {selectedRow?.lastName}?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseSingleDeletePopOver}
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              color: '#3C5AA0',
              '&:hover': {
                borderColor: '#3C5AA0',
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleSingleDelete}
              sx={{
                background: 'linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  borderColor: '#3C5AA0',
                },
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default InvitationList;
