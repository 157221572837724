import { Box, Button, Card, CardContent, Grid, Stack, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import TypographyField from '../../AppComponents/Buttons/Typography';
import { ICategory } from '../../../models/category.model';
import { useCategories } from '../../../hooks/useCategories';
import { useContext, useEffect, useState } from 'react';
import { ICreateCommunity } from '../../../models/communities.modal';
import CreateCommunityNew2 from './CreateCommunityNew2';
import { useCommunity } from '../../../hooks/useCommunity';
import { useNavigate, useParams } from 'react-router-dom';
import { communityById } from '../../../services/communities.service';
import { AuthContext, IAuthContext } from '../../../contexts/Auth.context';
import Logo from '../../../assets/images/Logo.png'
import Logo2 from '../../../assets/images/Communn-favicon.png'
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import BackgroundImage from "../../../assets/images/bgcommunity.png"


type CreateCommunityProps = {
    communityData: ICreateCommunity | null;
};

const CreateCommunityNew: React.FC<CreateCommunityProps> = ({
    communityData,
}) => {
    const { id } = useParams();
    const categories = useCategories();
    const { getAccessToken } = useContext<IAuthContext>(AuthContext);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const { isCommunityExist } = useCommunity();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [isCommunityAvailable, setIsCommunityAvailable] = useState<boolean>(false);
    const [communityNameAvailability, setCommunityNameAvailability] = useState('');
    const [communityNameColor, setCommunityNameColor] = useState('');
    const [communityNameModified, setCommunityNameModified] = useState(false);
    const [communityName, setCommunityName] = useState('');
    const [initialCommunityName, setInitialCommunityName] = useState('');
    const [CommunityData, setCommunityData] = useState<ICreateCommunity>(() => {
        return (
            communityData || {
                name: '',
                category: '',
                zipCode: '',
                fullAddress: '',
                type: 'PRIVATE',
                collectSubscription: 'YES',
                city: '',
                maxMembers: 0,
                gstNumber: '',
                status: 'CREATED',
                otherCategory: '',
            }
        );
    });


    const setCurrentCommunityName = (val: string) => {
        if (val) {
            setCommunityName(val);
            setIsCommunityAvailable(false);
            if (id) {
                setCommunityNameModified(true);
            }
        } else {
            setCommunityNameModified(true);
            setIsCommunityAvailable(false);
            setCommunityNameAvailability('');
        }
    };

    useEffect(() => {
        if (!id) {
            return;
        }
        if (id && !communityData) {
            const fetchCommunity = async () => {
                try {
                    const { data } = await communityById(getAccessToken(), id);
                    setCommunityData({
                        ...CommunityData,
                        name: data.name,
                        category: data.category,
                        zipCode: data.zipCode,
                        fullAddress: data.fullAddress,
                        gstNumber: data.gstNumber,
                        type: data.type,
                        collectSubscription: data.collectSubscription,
                        maxMembers: data.maxMembers,
                        city: data.city,
                        status: data?.status,
                        otherCategory: data?.otherCategory,
                    });
                    setCurrentCommunityName(data.name);
                    setCommunityNameColor('#00d26a');
                    setCommunityNameAvailability(' ✅ ' + data?.name + ' is available');
                    setIsCommunityAvailable(true);
                    setCommunityNameModified(false);
                    setSelectedCategory(data?.category);
                    if (data?.category === 'Other') {
                        setOpen(true);
                    }
                } catch (error) {
                } finally {
                }
            };

            fetchCommunity();
        }
        setCommunityNameModified(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, communityData]);

    const checkCommunity = async () => {
        if (communityName || (communityData && !id)) {
            const data = await isCommunityExist(
                communityName || communityData?.name || ''
            );
            if (data) {
                setCommunityNameAvailability(
                    '✅ ' + (communityName || communityData?.name || '') + ' is available'
                );
                setCommunityNameColor('#00d26a');
                setIsCommunityAvailable(true);
            } else {
                setCommunityNameAvailability(
                    '🚫 ' +
                    (communityName || communityData?.name || '') +
                    ' is not available'
                );
                setCommunityNameColor('red');
            }
        }
    };

    const handleCategorySelection = (categoryId: string) => {
        if (categoryId === 'Other') {
            setOpen(true);
            setSelectedCategory(categoryId);
            const temp = {
                ...CommunityData,
                category: categoryId,
                otherCategory: CommunityData?.otherCategory,
            };
            setCommunityData(temp);
        } else {
            setOpen(false);
            setSelectedCategory(categoryId);
            const temp = {
                ...CommunityData,
                category: categoryId,
                otherCategory: '',
            };
            setCommunityData(temp);
        }
    };



    const updateCommunityData = (updatedData: Partial<ICreateCommunity>) => {
        setCommunityData((prevData) => ({
            ...prevData,
            ...updatedData,
        }));
    };


    useEffect(() => {
        if (!id) {
            if (communityData?.category === 'Other' && categories.list.length > 0) {
                setOpen(true);
            } else {
                setOpen(false);
                setSelectedCategory(communityData?.category || '');
                setInitialCommunityName(communityData?.name || '');
            }
            setCommunityNameModified(false);
            if (communityData) {
                setIsCommunityAvailable(true);
                setCommunityNameAvailability(
                    '✅' + communityData.name + ' is available'
                );
            }
        }
        setCommunityNameModified(true);

        ////when  id is there
        if (id) {
            if (communityData?.category === 'Other' && categories.list.length > 0) {
                setOpen(true);
                setSelectedCategory(communityData?.category || '');
            } else {
                setIsCommunityAvailable(true);
                setCommunityNameModified(false);
                setSelectedCategory(communityData?.category || '');
                if (communityData) {
                    setCommunityNameAvailability(
                        '✅' + communityData?.name + ' is available'
                    );
                    setCommunityNameColor('#00d26a');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityData, categories.list]);


    const isSaveButtonEnabled = () => {
        const val =
            isCommunityAvailable &&
                (selectedCategory || CommunityData.otherCategory) !== ''
                ? true
                : false;
        return val;
    };


    const steps = [
        {
            icon: <Diversity2OutlinedIcon fontSize="medium" sx={{ color: "grey.600" }} />,
            title: "Create Community",
            description: "Kickstart your community with a clear purpose and vision.",
        },
        {
            icon: <AssignmentRoundedIcon fontSize="medium" sx={{ color: "grey.600" }} />,
            title: "Add Basic Details",
            description: "Set up your community with key info and preferences.",
        },
        {
            icon: <PeopleRoundedIcon fontSize="medium" sx={{ color: "grey.600" }} />,
            title: "Add Members, Create Plans, and Host Events",
            description:
                "Engage your community by inviting members and organizing activities.",
        },
        {
            icon: <StartOutlinedIcon fontSize="medium" sx={{ color: "grey.600" }} />,
            title: "Start Monetizing",
            description:
                "Unlock earning potential with premium features and exclusive offers.",
        },
    ];


    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>{metaConfig.title.createCommunity}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>
            {page === 1 && (
                <Stack direction={'column'} spacing={1} height={'100%'}>
                    <Grid container sx={{ height: '100vh', p: 2 }} gap={2}>
                        <Grid
                            item
                            xs={12}
                            md={5.9}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                display: { xs: 'none', md: 'flex' },
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 10,
                                borderRadius: '10px',
                                backgroundImage: `url(${BackgroundImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <Stack>
                                <Stack>
                                    <Box
                                        component={'img'}
                                        src={Logo}
                                        width={220}
                                    />
                                </Stack>
                                <Stack>
                                    <Card elevation={0} sx={{ py: 2, borderRadius: 2, backgroundColor: 'transparent' }}>
                                        <CardContent sx={{ px: 0, }}>
                                            <Stack spacing={3}>
                                                {steps.map((step, index) => (
                                                    <Stack key={index} direction="row" spacing={3} alignItems="flex-start">
                                                        <Box sx={{ width: 48, height: 48, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: "8px" }}>
                                                            {step.icon}
                                                        </Box>
                                                        <Box>
                                                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '16px', }}>
                                                                {step.title}
                                                            </Typography>
                                                            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '13px', color: '#575757' }}>
                                                                {step.description}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                ))}
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={5.9}
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: 'flex-start', md: 'center' },
                                flexDirection: 'column',
                                p: { xs: 1, md: 5 },
                                pt: { xs: 5, md: 0 }
                            }}
                        >

                            <Stack sx={{ p: 2, display: { xs: 'block', md: 'none' } }}>
                                <Box
                                    component={'img'}
                                    src={Logo2}
                                    width={50}
                                />
                            </Stack>
                            <Stack display={"flex"} flexDirection={"row"} alignItems={'center'} justifyContent={'space-between'}>
                                <Stack>
                                    <TypographyField
                                        name="Create Community"
                                        fontSize="22px"
                                        fontWeight={600}
                                        textAlign="justify"
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", md: "14px", },
                                            fontFamily: "Montserrat",
                                            fontWeight: '500',
                                            textAlign: 'justify'
                                        }}>
                                        Create a vibrant space for like-minded individuals.
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        onClick={() => (
                                            navigate("/explore")
                                        )}
                                        disabled={!communityNameModified}
                                        sx={{
                                            ml: 2,
                                            borderRadius: '8px',
                                            boxShadow: 'none',
                                            color: '#2653A3',
                                            fontSize: '14px',
                                            border: '1px solid #2653A3',
                                            fontWeight: 500,
                                            fontFamily: 'Montserrat',
                                            textTransform: 'capitalize',
                                            display: {
                                                xs: 'none',
                                                sm: 'none',
                                                md: 'none',
                                                lg: 'block',
                                                xlg: 'block',
                                            },
                                            '&:hover': {
                                                backgroundColor: '#2653A3',
                                                cursor: 'pointer',
                                                border: '1px solid #2653A3',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        Explore
                                    </Button>
                                </Stack>
                            </Stack>
                            <Stack sx={{ mt: 5 }}>
                                <Typography
                                    sx={{
                                        fontSize: { xs: "11px", md: "14px", },
                                        fontFamily: "Montserrat",
                                        fontWeight: '500',
                                        // textAlign: 'justify'
                                    }}>
                                    What’s the Name of Your Community or Business?
                                </Typography>
                                <Box
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            sm: 'none',
                                            md: 'none',
                                            lg: 'block',
                                            xlg: 'block',
                                        },
                                    }}
                                >
                                    <Stack
                                        direction={'row'}
                                        alignContent={'center'}
                                        spacing={1}
                                        sx={{ mt: 1 }}
                                    >
                                        <Box
                                            sx={{
                                                padding: '7px',
                                                border: '1px solid #E7EBF1',
                                                borderColor: '#E7EBF1',
                                                // backgroundColor: '#F9F9F9',
                                                display: 'inline-block',
                                                borderRadius: '8px',
                                                width: '400px',
                                                '& > input': {
                                                    border: 'none',
                                                    background: 'transparent',
                                                    fontSize: '24px',
                                                    fontWeight: 400,
                                                },
                                                '& > input:focus-visible': {
                                                    outline: 'none !important',
                                                },
                                            }}
                                        >
                                            <input
                                                type="text"
                                                required
                                                value={CommunityData?.name}
                                                onChange={(e) => {
                                                    setCurrentCommunityName(e.target.value);
                                                    const temp = {
                                                        ...CommunityData,
                                                        ...communityData,
                                                        name: e.target.value,
                                                    };
                                                    setCommunityData(temp);
                                                }}
                                                placeholder=" onecommunn.com / Community or business name"
                                                style={{
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    width: '500px',
                                                    color: '#646464',
                                                }}
                                            />
                                        </Box>

                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={checkCommunity}
                                            disabled={!communityNameModified}
                                            sx={{
                                                ml: 2,
                                                mb: 1.6,
                                                borderRadius: '8px',
                                                boxShadow: 'none',
                                                color: 'black',
                                                fontSize: '14px',
                                                border: '1px solid #E7EBF1',
                                                fontWeight: 500,
                                                fontFamily: 'Montserrat',
                                                textTransform: 'capitalize',
                                                display: {
                                                    xs: 'none',
                                                    sm: 'none',
                                                    md: 'none',
                                                    lg: 'block',
                                                    xlg: 'block',
                                                },
                                                '&:hover': {
                                                    backgroundColor: '#2653A3',
                                                    cursor: 'pointer',
                                                    border: '1px solid #2653A3',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            Check Availability
                                        </Button>
                                    </Stack>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: `${communityNameColor}`,
                                            fontSize: '12px',
                                            fontFamily: 'Montserrat',
                                            mt: 1,

                                        }}
                                    >
                                        {`${communityNameAvailability}`}

                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: {
                                            xs: 'block',
                                            sm: 'block',
                                            md: 'none',
                                            lg: 'none',
                                            xlg: 'none',
                                        },
                                    }}
                                >
                                    <Stack
                                        direction={'column'}
                                        alignContent={'center'}
                                        spacing={1}
                                        sx={{ mt: 1 }}
                                    >
                                        <Box
                                            sx={{
                                                padding: '7px',
                                                border: '1px solid #E7EBF1',
                                                borderColor: '#E7EBF1',
                                                // backgroundColor: '#F9F9F9',
                                                display: 'inline-block',
                                                borderRadius: '8px',

                                                '& > input': {
                                                    border: 'none',
                                                    background: 'transparent',
                                                    fontSize: '24px',
                                                    fontWeight: 400,
                                                },
                                                '& > input:focus-visible': {
                                                    outline: 'none !important',
                                                },
                                            }}
                                        >
                                            <input
                                                type="text"
                                                required
                                                value={CommunityData?.name}
                                                onChange={(e) => {
                                                    setCurrentCommunityName(e.target.value);
                                                    const temp = {
                                                        ...CommunityData,
                                                        ...communityData,
                                                        name: e.target.value,
                                                    };
                                                    setCommunityData(temp);
                                                }}
                                                placeholder=" onecommunn.com / Community or business name"
                                                style={{
                                                    fontSize: '11px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Montserrat',
                                                    width: '100%',
                                                    color: '#646464',
                                                }}
                                            />
                                        </Box>

                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={checkCommunity}
                                            disabled={!communityNameModified}
                                            sx={{
                                                // ml: 2,
                                                mb: 1.6,
                                                borderRadius: '8px',
                                                boxShadow: 'none',
                                                color: 'black',
                                                fontSize: { xs: '12px', md: '14px' },
                                                border: '1px solid #E7EBF1',
                                                fontWeight: 500,
                                                fontFamily: 'Montserrat',
                                                textTransform: 'capitalize',
                                                display: {
                                                    xs: 'block',
                                                    sm: 'block',
                                                    md: 'none',
                                                    lg: 'none',
                                                    xlg: 'none',
                                                },
                                                '&:hover': {
                                                    backgroundColor: '#2653A3',
                                                    cursor: 'pointer',
                                                    border: '1px solid #2653A3',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            Check Availability
                                        </Button>
                                    </Stack>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: `${communityNameColor}`,
                                            fontSize: '12px',
                                            fontFamily: 'Montserrat',
                                            mt: 1,

                                        }}
                                    >
                                        {`${communityNameAvailability}`}

                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack sx={{ mt: 2 }}>

                                <Typography
                                    sx={{
                                        fontSize: { xs: "11px", md: "14px", },
                                        fontFamily: "Montserrat",
                                        fontWeight: '500',
                                    }}>
                                    What Type of Community or Business Are You Creating?
                                </Typography>
                                <Grid container direction="row" sx={{ width: '100%' }}>
                                    <Grid item xl={9}>
                                        {categories.list.map((categoryName: ICategory) => (
                                            <Button
                                                key={categoryName.name}
                                                sx={{
                                                    color:
                                                        selectedCategory === categoryName.name
                                                            ? '#ffffff'
                                                            : '#000000',
                                                    fontSize: {
                                                        xs: '12px',
                                                        sm: '12px',
                                                        md: '12px',
                                                        lg: '12px',
                                                        xl: '15px',
                                                    },
                                                    fontFamily: 'Montserrat',
                                                    fontWeight: 500,
                                                    p: '7px 20px',
                                                    border: '1px solid #E7EBF1',
                                                    borderRadius: '8px',
                                                    backgroundColor:
                                                        selectedCategory === categoryName.name
                                                            ? '#2653A3'
                                                            : '#ffffff',
                                                    m: 1,
                                                    ml: 0,
                                                    textTransform: 'capitalize',
                                                    '&:hover': {
                                                        backgroundColor: '#2653A3',
                                                        cursor: 'pointer',
                                                        border: '1px solid #2653A3',
                                                        color: 'white',
                                                    },

                                                }}
                                                onClick={() => {
                                                    handleCategorySelection(categoryName.name);
                                                }}
                                            >
                                                {categoryName.name}
                                            </Button>
                                        ))}
                                        {open && (
                                            <TextField
                                                fullWidth
                                                sx={{
                                                    mt: 1,
                                                    color: '#646464',
                                                    fontWeight: '500',
                                                    borderRadius: '8px',
                                                    backgroundColor: 'white',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '8px',
                                                        '& fieldset': {
                                                            borderColor: '#E7EBF1',
                                                            borderRadius: '8px',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#E7EBF1',
                                                        },
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#E7EBF1',
                                                    },
                                                }}
                                                placeholder="Community or business type"
                                                InputLabelProps={{ shrink: false }}
                                                inputProps={{
                                                    style: {
                                                        padding: 11,
                                                        fontSize: '12px',
                                                        color: '#646464',
                                                        fontWeight: 400,
                                                        fontFamily: 'Montserrat',
                                                    },
                                                }}
                                                size="small"
                                                variant="outlined"
                                                value={CommunityData.otherCategory}
                                                onChange={(e) => {
                                                    const temp = {
                                                        ...CommunityData,
                                                        otherCategory: e.target.value,
                                                    };
                                                    setCommunityData(temp);
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Stack>

                            <Stack sx={{ pt: 2 }}>
                                <Button
                                    fullWidth
                                    onClick={() => setPage(2)}
                                    variant="contained"
                                    disabled={!isSaveButtonEnabled()}
                                    sx={{
                                        boxShadow: 'none',
                                        backgroundColor: '#2653A3',
                                        textTransform: 'capitalize',
                                        borderRadius: '8px',
                                        fontFamily: 'Montserrat',
                                        border: '1px solid #2653A3',
                                        fontWeight: 500,
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            cursor: 'pointer',
                                            boxShadow: 'none',
                                            color: '#2653A3',
                                        },
                                        '&.Mui-disabled': {
                                            backgroundColor: '#2653A3',
                                            cursor: 'not-allowed',
                                            border: '1px solid #2653A3',
                                        },
                                    }}
                                >
                                    Next
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            )}
            {page === 2 && (
                <CreateCommunityNew2
                    CommunityData={CommunityData}
                    updateCommunityData={updateCommunityData}
                    setCommunityData={setCommunityData}
                />
            )}
        </>
    );
};

export default CreateCommunityNew;
