import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

export const ContentWrapper = ({ children }: any) => {
  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Paper
        elevation={0}
        sx={{
          flex: '1 1 100%',
          // padding: "5px 20px",
          minHeight: '200px',
          borderRadius: '12px'

        }}
      >
        {children}
      </Paper>
    </Stack>
  );
};
