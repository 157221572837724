import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Divider, Grid, Stack, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, } from 'react';
import Loader from '../../Loader/Loader';
import { AlternativePage } from '../../AlternatePages/AlternativePage';
import NoClass from '../../../assets/icons/svg-icons/noPlans.png';
import { Link, useNavigate } from 'react-router-dom';
import { EditIcon, Share1Icon } from '../../../assets/icons';
import { useAppointments } from '../../../hooks/useAppointments';
import { IAppointment } from '../../../models/appointments.models';
import { ContentCopyOutlined, PlayArrowOutlined } from '@mui/icons-material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { USER_APP_BASE_URL } from '../../../configurations/url.config';
import { useSnackbar } from 'notistack';





const UpComingAppointments = ({ searchQuery }: { searchQuery: string }) => {
    const singleCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    let community_id = singleCommunity?._id;
    const { getAllAppointments } = useAppointments();

    const [appointments, setAppointments] = React.useState<IAppointment[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [selectedItemId, setSelectedItemId] = React.useState<string | null>(null);
    // const [status, setStatus] = React.useState('PAUSED')

    const [copied, setCopied] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const fetchAppointments = async () => {
        setIsLoading(true);
        try {
            const response = await getAllAppointments(community_id);
            if (Array.isArray(response)) {
                setAppointments(response);
            } else {
                setAppointments([]);
            }
        } catch (err) {
            console.error('Error fetching live classes:', err);
            setAppointments([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);


    const navigate = useNavigate()


    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        itemId: string
    ) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        setSelectedItemId(itemId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedItemId(null);
    };


    const { updateAppointmentStatusById } = useAppointments();

    const handlePause = async (selectedItemId: string, communityId: string, status: string) => {
        try {
            const response = await updateAppointmentStatusById(status, selectedItemId, communityId);
            fetchAppointments();
            return response;
        } catch (error) {
            console.error("Error updating appointment status:", error);
        }
    };

    const filteredAppointments = appointments.filter((appointment) =>
        appointment.title.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const handleCopyLink = (labelContent: string) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(labelContent)
                .then(() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 3000);
                    enqueueSnackbar('Link copied successfully', {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                })
                .catch((error) => {
                    console.error('Error copying text: ', error);
                    enqueueSnackbar('Failed to copy link', {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                });
        } else {
            console.error('Clipboard API not supported');
            enqueueSnackbar('Clipboard API not supported', { variant: 'error' });
        }
    };


    return (
        <Box sx={{ position: 'relative', height: { xs: '100vh', md: '73vh' }, overflow: 'auto' }}>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Loader />
                </Box>
            ) : appointments.length === 0 ? (
                <AlternativePage title="No UpComing Appointments" image={NoClass} />
            ) : (
                <Grid container gap={1.5} py={1}>
                    {filteredAppointments?.map((classData, index) => (
                        <Grid item xs={12} md={3.9} key={index}>
                            <Card elevation={0} sx={{
                                borderLeft: '6px solid #D4DDED',
                                borderRight: '1px solid #D4DDED',
                                borderTop: '1px solid #D4DDED',
                                borderBottom: '1px solid #D4DDED',
                                borderRadius: '12px',
                                '&:hover': {
                                    borderLeft: '6px solid #3C5AA0',
                                    borderRight: '1px solid #3C5AA0',
                                    borderTop: '1px solid #3C5AA0',
                                    borderBottom: '1px solid #3C5AA0',
                                }
                            }}
                            >
                                <CardContent
                                    sx={{
                                        pt: 1,
                                        pb: 0,
                                        "&:last-child": {
                                            pb: '1px',
                                        },
                                    }}>
                                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ py: 1 }}>
                                        <Stack>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    color: '#646464',
                                                }}
                                            >

                                                {classData?.pricing ? `₹${classData.pricing}` : "Free"} - {classData?.location?.length > 30 ? classData?.location?.substring(0, 30) + "..." : classData?.location}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    color: '#000000',
                                                    cursor: 'pointer'
                                                }}

                                                component={'a'}

                                                onClick={() => {
                                                    navigate(`/edit-appointment/${classData?._id}`)
                                                }}
                                            >
                                                {classData?.title?.length > 30 ? classData?.title?.substring(0, 30) + '...' : classData?.title}
                                            </Typography>
                                        </Stack>

                                        <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} >
                                            <IconButton sx={{}}>
                                                <PeopleOutlinedIcon onClick={() => {
                                                    navigate(`/attendees/${classData?._id}`)
                                                }} sx={{ fontSize: '16px', color: '#969696' }} />
                                            </IconButton>
                                            <IconButton sx={{}}
                                                onClick={(event) => handleClick(event, classData?._id!)}>
                                                <MoreVertOutlinedIcon sx={{ fontSize: '20px', color: '#969696' }} />
                                            </IconButton>
                                            <Menu
                                                id={`menu-${index}`}
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl) && selectedItemId === classData?._id}
                                                onClose={handleClose}
                                                elevation={1}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                sx={{
                                                    '& .MuiPaper-root': {
                                                        minWidth: '125px',
                                                        borderRadius: '10px',
                                                        border: '1px solid #D4DDED',

                                                    },
                                                }}
                                            >
                                                <MenuItem
                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                                    onClick={() => {
                                                        navigate(`/edit-appointment/${classData?._id}`)
                                                    }}
                                                    disabled={isLoading}
                                                >
                                                    <EditIcon sx={{ fontSize: '15px', marginRight: 1 }} /> Edit
                                                </MenuItem>
                                                <MenuItem
                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                                    onClick={() => {
                                                        navigate(`/attendees/${classData?._id}`)
                                                    }}
                                                    disabled={isLoading}
                                                >
                                                    <PeopleOutlinedIcon sx={{ fontSize: '15px', marginRight: 1 }} />  Attendees
                                                </MenuItem>

                                                {classData?.status === "ACTIVE" ? (
                                                    <>
                                                        <MenuItem
                                                            sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                                            onClick={() => handlePause(classData?._id, community_id, "INACTIVE")}
                                                            disabled={isLoading}
                                                        >
                                                            <PlayArrowOutlined sx={{ fontSize: '15px', marginRight: 1 }} />  Pause
                                                        </MenuItem>
                                                    </>
                                                ) : (
                                                    <>
                                                        <MenuItem
                                                            sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                                            onClick={() => handlePause(classData?._id, community_id, "ACTIVE")}
                                                            disabled={isLoading}
                                                        >
                                                            <PlayArrowOutlined sx={{ fontSize: '15px', marginRight: 1 }} />  Active
                                                        </MenuItem>
                                                    </>
                                                )}


                                                <MenuItem
                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: '30px' } }}
                                                    component="a"
                                                    target="_blank"
                                                    onClick={() => handleCopyLink(`${USER_APP_BASE_URL}/book-appointment/${classData?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${classData?._id}`)}
                                                    disabled={isLoading}
                                                >
                                                    <ContentCopyOutlined sx={{ fontSize: '13px', marginRight: 1 }} /> Copy URL
                                                </MenuItem>



                                                <MenuItem
                                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                                    component="a"
                                                    target='_blank'
                                                    href={`https://wa.me/?text=${encodeURIComponent(
                                                        `Book your appointment here: ${`${USER_APP_BASE_URL}/book-appointment/${classData?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${classData?._id}`}`
                                                    )}`}
                                                    disabled={isLoading}
                                                >
                                                    <Share1Icon sx={{ fontSize: '13px', marginRight: 1 }} />  Share
                                                </MenuItem>

                                            </Menu>
                                        </Stack>
                                    </Stack>
                                    <Divider />
                                    <Stack
                                        direction="row"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ py: 1.5, }}
                                    >
                                        <Stack
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            gap={0.5}
                                        >
                                            <Link
                                                to={`/edit-appointment/${classData?._id}`}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textDecoration: 'none',
                                                    gap: '10px',
                                                }}
                                            >

                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '12px',
                                                    fontFamily: 'Montserrat',
                                                    fontWeight: '500'
                                                }}>
                                                    {classData?.description?.length > 45 ? classData?.description.substring(0, 45) + "..." : classData?.description}
                                                </Typography>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

export default UpComingAppointments;
