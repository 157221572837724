import axios from 'axios';
import { INewUser, INotes } from '../models/user.model';
import { BASE_URL, BASE_URL_V2 } from '../configurations/url.config';
import { CommunityMembers } from '../models/community-user.model';
// import { getDeviceToken } from './pushNotification/pushNotification.service';

export const getUsers = async (token: string, id: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/community/${id}/members/all`,
      {
        //  const response = await axios.get(`${BASE_URL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      if (Array.isArray(response.data)) {
        response.data = response.data.map((user: CommunityMembers) =>
          memberParse(user)
        );
      } else {
        // If not an array, just apply memberParse to the single object
        response.data = memberParse(response.data);
      }
    }

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const createUsers = async (token: string, usersToAdd: any) => {
  try {
    const response = await axios.post(`${BASE_URL}/users/create`, usersToAdd, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      response.data = addUserParse(response.data);
    }
    return response;
  } catch (err) {
    console.log('Err :================================ ', err);
    return { status: 500, data: [] };
  }
};

export const existUserCheck = async (phoneNumber: string) => {
  try {
    if (typeof phoneNumber !== 'string' || !phoneNumber.trim()) {
      throw new Error('Invalid phoneNumber: It must be a non-empty string.');
    }

    const response = await axios.post(
      `${BASE_URL_V2}/auth/send-verify-mobile`,
      { phoneNumber },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    return response;
  } catch (err: any) {
    console.error('Error in existUserCheck:', err);
    return { status: 500, data: [], message: err.message };
  }
};

export const sendEmailOtp = async (email: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL_V2}/auth/otp/send-otp-email`,
      { emailId: email },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    return response;
  } catch (err: any) {
    console.error('Error in existUserCheck:', err);
    return { status: 500, data: [], message: err.message };
  }
};

export const verifyOtpEditProfile = async (
  phoneNumber: string,
  otp: string
) => {
  console.log('verify otp');
  try {
    const formData = new FormData();
    formData.append('phoneNumber', phoneNumber);
    formData.append('otp', otp.trim());
    const response = await axios.post(
      `${BASE_URL}/auth/otp/verify-mobile-number`,
      formData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (err: any) {
    console.log('ERR:', err);
    return { status: 500, verifyResponse: err.response || undefined };
  }
};

export const verifyPhoneNumber = async (phoneNumber: string, otp: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL_V2}/auth/verify-mobile-number`,
      { phoneNumber, otp },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    return response;
  } catch (err: any) {
    console.error('Error in existUserCheck:', err);
    return { status: 500, data: [], message: err.message };
  }
};

export const getUser = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    // if (response.status === 200) {
    //   response.data = response.data;
    // }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const updateUser = async (
  token: string,
  id: string,
  data: any,
  avatar: File | null
) => {
  try {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        // Append the key and value to the FormData object
        formData.append(key, value);
      }
    }
    if (avatar) {
      formData.append('avatar', avatar);
    }
    const response = await axios.put(`${BASE_URL}/users/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: '*',
      },
    });

    // if (response.status === 200) {
    //   response.data = response.data;
    // }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const updateWhatsapp = async (
  token: string,
  userId: string,
  whatsappNumber: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/users/${userId}`,
      {
        whatsappNumber: whatsappNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          Accept: '*',
        },
      }
    );
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

//Invitations
export const addUsers = async (token: string, usersToAdd: any) => {
  try {
    const response = await axios.post(`${BASE_URL}/bulk/`, usersToAdd, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      response.data = addUserParse(response.data);
    }
    return response;
  } catch (err) {
    console.log('Err :================================ ', err);
    return { status: 500, data: [] };
  }
};

export const getInvitations = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/bulk/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    // if (response.status === 200) {
    //   response.data = response.data;
    // }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const deleteInvite = async (token: string, communityId: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/bulk/${communityId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      response.data = addUserParse(response.data);
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const leaveCommunityUser = async (token: string, id: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${id}/leaveCommunity`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      response.data = addUserParse(response.data);
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

const addUserParse = (user: INewUser) => {
  return {
    ...user,
    firstName: user.firstName ?? ' ',
    lastName: user.lastName ?? ' ',
    role: user.role ?? ' ',
    emailId: user.emailId ?? ' ',
    phoneNumber: user.phoneNumber ?? ' ',
    status: user.status ?? ' ',
    community: user.community ?? ' ',
  };
};

const memberParse = (member: CommunityMembers) => {
  const createdAtDate = new Date(member.createdAt);
  return {
    ...member,
    createdAt:
      createdAtDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }) ?? '',
    createdAtTime:
      createdAtDate.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }) ?? '',
  };
};

export const removeUserFromCommunity = async (
  token: string,
  userId: string,
  communityId: string
) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/community/${communityId}/leave/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      response.data = addUserParse(response.data);
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const removeSubscriber = async (
  token: string,
  planId: string,
  userId: string
) => {
  try {
    const response = await axios.delete(
      `${BASE_URL_V2}/subscriptions/${planId}/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      response.data = addUserParse(response.data);
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const createNotes = async (token: string, notesData: INotes) => {
  try {
    const response = await axios.post(`${BASE_URL_V2}/notes`, notesData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log(response, "response");
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const updateNotes = async (
  token: string,
  communityId: string,
  notesId: string,
  description: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/notes/${notesId}/community/${communityId}`,
      { description },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const getNotes = async (
  token: string,
  userId: string,
  communityId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/notes/community/${communityId}/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};
