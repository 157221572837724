import axios from 'axios';
import { BASE_URL } from '../configurations/url.config';
import { ICommunity, ICommunityResponse } from '../models/communities.modal';
import { IDate } from '../models/general.model';
import { formatDate } from '../utils/dateUtil';

export const getAllCommunities = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/communities/all-super-admin`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200 && response.data.length > 0) {
      response.data = response.data.map((d: ICommunityResponse): ICommunity => {
        const updatedDate = new Date(d.updatedAt);
        const updated: IDate = {
          date: `${updatedDate.getDate()} ${updatedDate.toLocaleString(
            'default',
            { month: 'short' }
          )}. ${updatedDate.getFullYear()}`,
          time: `${updatedDate.toLocaleString('default', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })}`,
        };

        return {
          ...d,
          _id: d._id ?? '',
          title: d.name,
          createdBy: d.createdBy ?? '',
          totalMembers: d.members?.length ? d.members.length : 0,
          category: d.category ?? '',
          markedAs: d?.type,
          status: d?.status,
          updated: updated,
          membership: d?.collectSubscription,
          earnings: d.earnings ?? 0,
          fullAddress: d?.fullAddress,
          createdAt: formatDate(new Date(d.createdAt)),
          updatedAt: formatDate(new Date(d.updatedAt)),
          favorite: d?.favorite ?? false,
          logo: d?.logo,
          updatedBy: d.updatedBy ?? '',
          phoneNumber: d?.phoneNumber,
          mobileNumber: d?.mobileNumber,
          images: d.images ?? [''],
          services: d?.services,
          maxMembers: d?.maxMembers ?? 0,
          banner: d?.banner,
          otherCategory: d?.otherCategory,
          teams: d?.team,
          faq: d?.faq,
          enabledService: d?.enabledService,
        };
      });
    }
    return response?.data?.data;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
