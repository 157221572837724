import {
    Avatar,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Paper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useRequests } from '../../hooks/useRequests';
import { Invitations } from '../../models/Invitation.model';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { useSelector } from 'react-redux';
import store from '../../store';
import { NoRequests2 } from '../AlternatePages/NoRequests2';



const RightBarInvites = () => {


    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const { acceptInvitation, getCommunityRequests, deleteInvitation } = useRequests();
    const [requests, setRequests] = useState<Invitations[]>([]);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);

    // console.log(requests, "requests")

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const communityRequestsData = await getCommunityRequests();
                setRequests(communityRequestsData?.data);
                setLoading(false);
                setReload(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }
        fetchData();
        return () => {
            setLoading(false);
            setReload(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    const formatDateRelative = (dateString: string): string => {
        const currentDate = new Date();
        const inputDate = new Date(dateString);
        const timeDifference = currentDate.getTime() - inputDate.getTime();
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        if (daysDifference < 1) {
            if (hoursDifference < 1) {
                if (minutesDifference < 1) {
                    return 'Just Now';
                }
                return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''
                    } ago`;
            } else if (hoursDifference < 24) {
                if (hoursDifference === 1) {
                    return '1 hour ago';
                }
                return `${hoursDifference} Hours ago`;
            } else {
                return 'Today';
            }
        } else if (daysDifference === 1) {
            return 'Yesterday';
        } else if (daysDifference === 2) {
            return '2 days ago';
        } else if (daysDifference === 3) {
            return '3 days ago';
        }
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };
        return inputDate.toLocaleDateString(undefined, options);
    };

    const handleDeleteClick = async (id: string) => {
        try {
            setReload(true)
            const response = await deleteInvitation(id);
            if (response && response.status === 200) {
            } else {
                // console.log("joined community failed");
            }
        } catch (error) {

        }
        finally {
            setReload(false)
        }
    };

    const handleAcceptClick = async (id: string, message: string) => {
        try {
            setLoader(true)
            const formData = new FormData();
            formData.append('request_id', id);
            const response = await acceptInvitation(formData);
            if (response && response.status === 200) {
                navigate('/users');
            } else {
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoader(false)
        }
    };


    return (
        <>
            <Helmet>
                <title>{metaConfig.title.requestsandInvites}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>

            <Paper elevation={0} sx={{ p: 1, borderRadius: '12px !important', }}>
                {requests && requests.length > 0 ? (
                    requests.map((item) => (
                        <Card key={item?._id} sx={{ mb: 2, borderRadius: '8px', boxShadow: '0 1px 3px rgba(0,0,0,0.12)' }}>
                            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <Avatar
                                        sx={{ width: 40, height: 40, mr: 2 }}
                                        alt={item?.createdBy?.firstName}
                                        src={item?.createdBy?.avatar}
                                    />
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: '#000000',
                                                fontFamily: 'Montserrat',
                                            }}
                                        >
                                            {item?.createdBy?.firstName}{' '}
                                            {item.createdBy?.lastName}{' '}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: '#565656',
                                                fontSize: '11px',
                                                fontWeight: '400',
                                                fontFamily: 'Montserrat',
                                            }}
                                        >
                                            {item?.createdBy?.emailId}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: '#787878',
                                                fontSize: '11px',
                                                fontFamily: 'Montserrat',
                                            }}
                                        >
                                            {formatDateRelative(item?.createdAt)}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                    <Button
                                        size='small'
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            textTransform: 'none',
                                            fontSize: '12px',
                                            backgroundColor: '#3C5AA0',
                                            color: '#ffffff',
                                            fontFamily: 'Montserrat',
                                            fontWeight: '500',
                                            alignItems: 'right',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                boxShadow: 'none',
                                                backgroundColor: '#3C5AA0',
                                                fontFamily: 'Montserrat',
                                            },
                                        }}
                                        onClick={() =>
                                            handleAcceptClick(item?._id, item?.message)
                                        }
                                    >
                                        {loader ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Accept"}
                                    </Button>
                                    <Button
                                        size='small'
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        sx={{
                                            textTransform: 'none',
                                            fontSize: '12px',
                                            color: '#3C5AA0',
                                            fontWeight: '500',
                                            border: '1px solid #3C5AA0',
                                            fontFamily: 'Montserrat',
                                            ml: 5,
                                            alignItems: 'right',
                                            py: 1,
                                            '&:hover': {
                                                boxShadow: 'none',
                                                fontFamily: 'Montserrat',
                                            },
                                        }}
                                        onClick={() => handleDeleteClick(item?._id)}
                                    >
                                        {loader ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Reject"}
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <>
                        <NoRequests2 />
                    </>
                )}
            </Paper>
        </>
    );
};
export default RightBarInvites;
