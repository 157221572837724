import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import InvitationList from './InvitationList';
import * as XLSX from 'xlsx';
import { Invitations, InvitationsBulk } from '../../models/Invitation.model';
import { useInvitations } from '../../hooks/useInvitations';
import { AddUser } from '../users/AddNewUser';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import { CommunityMembers } from '../../models/community-user.model';
import { useUsers } from '../../hooks/useUsers';
import { ArrowDropDown, Close } from '@mui/icons-material';
import { NoInvites } from '../AlternatePages/noInvitations';
import { metaConfig } from '../../utils/constants';
import BreadcrumbDouble from '../AlternatePages/BreadCumsDouble';
import { useNavigate } from 'react-router-dom';
import { CirclePlus, CloudDownload } from 'lucide-react';

interface InvitationProp {
  openMode: boolean;
}
const Invitation: React.FC<InvitationProp> = ({ openMode }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [openInvites, setOpenInvites] = React.useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isCheckboxSelected] = useState(false);
  const [selectedInvitations, setSelectedInvitations] = useState<Invitations[]>([]);
  const { isLoading, getInviteList } = useInvitations();
  const [invitations, setInvitations] = useState<Invitations[]>([]);
  const [memberList, setMemberList] = useState<CommunityMembers[]>([]);
  const { getUsersList } = useUsers();

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const selectedCommunityId = selectedCommunity?._id;
  useEffect(() => {
    const fetchInviteList = async () => {
      try {
        const response = await getInviteList(selectedCommunityId ?? '');
        setInvitations(response.data);

        const users = await getUsersList(selectedCommunityId);
        setMemberList(users || []);
      } catch (error) {
        console.error('Error fetching invite list:', error);
      }
    };
    if (selectedCommunityId) {
      fetchInviteList();
    }
    // eslint-disable-next-line
  }, [selectedCommunityId]);

  const handleClickOpenInvites = () => {
    setOpenInvites(true);
    setSelectedInvitations([]);
  };

  const generateExcelTemplate = () => {
    const headers = [
      'SL.No',
      'userName',
      'mobileNumber',
      'emailId',
      'role',
      'message',
    ];
    const data = [
      [
        1,
        'YourName',
        '9876543210',
        'user1@gmail.com',
        'User',
        'write some text',
      ],
      [
        2,
        'YourName',
        '9876543211',
        'user2@gmail.com',
        'Admin',
        'write some text',
      ],
    ];

    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Sample_Template.xlsx');
  };

  const handleDownloadClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const handleClose = async () => {
    setOpenInvites(false);
  };

  const updateInvitationList = (newInvitation: Invitations[]) => {
    setInvitations(newInvitation.concat(invitations));
  };

  const refetchInvitesData = async () => {
    const response = await getInviteList(selectedCommunityId);
    if (response) {
      setInvitations(response.data);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) return;

      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert sheet to JSON
      const jsonData: any[] = XLSX.utils.sheet_to_json(sheet);

      // Normalize column names
      const normalizedData = jsonData.map((row) => {
        const normalizedRow: any = {};
        for (const key in row) {
          const normalizedKey = key.trim().toLowerCase().replace(/\s+/g, '');
          normalizedRow[normalizedKey] = row[key];
        }
        return normalizedRow;
      });

      const users: InvitationsBulk[] = normalizedData.map((row) => ({
        role: row.role || '',
        firstName:
          row.username || row.name || row['username'] || row['name'] || '',
        lastName: '',
        phoneNumber:
          row.mobilenumber ||
          row.phone ||
          row['mobilenumber'] ||
          row['phone'] ||
          '',
        emailId:
          row.emailid || row.email || row['emailid'] || row['email'] || '',
        message: row.message || '',
        status: 'INVITED',
      }));

      console.log('users', users);
      navigate('/bulk-invite', { state: { users } });
    };
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.invitations}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        {/* Breadcrumb */}
        <BreadcrumbDouble
          items={[
            { label: 'Members', path: '/users' },
            { label: 'Invitations' },
          ]}
        />
        {/* Download Options and Bulk Add Button */}
        <Box display="flex" alignItems="center" sx={{ display: { xs: 'none', md: 'flex' } }} gap={2} mb={1}>
          <Button
            disabled={selectedCommunity.status !== 'PUBLISHED'}
            variant="contained"
            endIcon={<CirclePlus size={20} strokeWidth={1.5} />}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '8px',
              backgroundColor: '#3C5AA0',
              fontFamily: 'Montserrat',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                cursor: 'pointer',
                border: 'none',
                boxShadow: 'none',
              },
            }}
            onClick={handleClickOpenInvites}
          >
            Send Invitation
          </Button>
          {/* Download Options Button */}
          <Button
            variant="contained"
            endIcon={<ArrowDropDown />}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '8px',
              backgroundColor: '#3C5AA0',
              fontFamily: 'Montserrat',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                cursor: 'pointer',
                border: 'none',
                boxShadow: 'none',
              },
            }}
            onClick={handleDownloadClick}
          >
            Bulk Invitation
          </Button>

          {/* Download Options Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDownloadClose}
          >
            <MenuItem onClick={generateExcelTemplate} sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
              Download Sample Template
            </MenuItem>
            <MenuItem
              disabled={selectedCommunity.status !== 'PUBLISHED'}
              onClick={() => fileInputRef.current?.click()}
              sx={{ fontFamily: 'Montserrat', fontSize: '14px' }}
            >
              Add Bulk Users
            </MenuItem>
          </Menu>


          {/* Bulk Add Button */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
        </Box>

        <Box display="flex" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }} gap={2} mb={1}>
          <IconButton sx={{
            backgroundColor: '#3C5AA0', borderRadius: '8px',
            "&:hover": {
              backgroundColor: '#3C5AA0'
            }
          }} onClick={handleClickOpenInvites}>
            <CirclePlus size={20} strokeWidth={1.5} color='white' />
          </IconButton>
          <IconButton sx={{
            backgroundColor: '#3C5AA0', borderRadius: '8px',
            "&:hover": {
              backgroundColor: '#3C5AA0'
            }
          }} onClick={handleDownloadClick}>
            <CloudDownload size={20} strokeWidth={1.5} color='white' />
          </IconButton>
          {/* Download Options Menu */}


          {/* Bulk Add Button */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
        </Box>
      </Box>

      <Grid container spacing={1}>

        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : invitations.length === 0 ? (
            <Paper
              elevation={0}
              sx={{
                height: '80vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                pb: 10,
              }}
            >
              <NoInvites />
              <Button
                onClick={handleClickOpenInvites}
                sx={{
                  backgroundColor: '#3C5AA0',
                  padding: '7px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  color: '#ffffff',
                  borderRadius: '8px',
                  marginTop: '10px',
                  textTransform: 'capitalize',

                  px: 2,
                  '&:hover': {
                    backgroundColor: '#3C5AA0',
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}
                disabled={selectedCommunity.status !== 'PUBLISHED'}
              >
                <Typography
                  sx={{
                    color: '#ffffff',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  Send Invitation
                </Typography>
              </Button>
              {selectedCommunity.status !== 'PUBLISHED' && (
                <Typography
                  sx={{
                    color: 'red',
                    fontSize: '14px',
                    mt: '5px',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Publish Your Community to Send Invitation
                </Typography>
              )}
            </Paper>
          ) : (
            <InvitationList
              invitations={invitations}
              refetchInvitesData={refetchInvitesData}
              communityMembers={memberList}
              communityId={selectedCommunityId}
              updateInvitationList={updateInvitationList}
            />
          )}
        </Grid>
        <Dialog
          fullWidth
          maxWidth="md"
          open={openInvites}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '100%',
            },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: '3px', backgroundColor: '#F0F9FF', height: '50px' }}
          >
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: '500',
                textDecoration: 'none',
                color: '#3C5AA0',
                fontFamily: 'Montserrat',
                marginLeft: 3,
              }}
            >
              {isCheckboxSelected
                ? selectedInvitations.length > 0
                  ? 'Edit Invites'
                  : 'Add Invitations'
                : selectedInvitations.length === 1
                  ? 'Edit Invites'
                  : 'Add Invitations'}
            </Typography>
            <Box
              onClick={handleClose}
              sx={{
                backgroundColor: '#3C5AA0',
                padding: '4px',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '10px',
                mt: '0.5px',
                mr: '10px',
                cursor: 'pointer',
              }}
            >
              <Close sx={{ fontSize: '15px' }} />
            </Box>
          </Grid>
          <DialogContent sx={{ padding: '10px 20px' }}>
            <AddUser
              onClose={handleClose}
              isCheckboxSelected={isCheckboxSelected}
              initialInviteData={invitations}
              selectedInvitations={[]}
              communityMembers={memberList}
              selectedCommunityId={selectedCommunityId}
              refetchInvitesData={refetchInvitesData}
              updateInvitationList={updateInvitationList}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
export default Invitation;
