
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/system';
import { CustomTableHeader } from './components/CustomTableHeader';
import { IColumn } from '../../models/table.model';
import { useEffect, useState } from 'react';
import {
    Box,
    Card,
    Chip,
    IconButton,
    Paper,
    TableContainer,
    TablePagination,
    Typography,
} from '@mui/material';
import { FiberManualRecordRounded, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import SimpleBar from 'simplebar-react';
import { IAttendees } from '../../models/appointments.models';
import { IEvents } from '../../models/events.models';


interface ITableProps {
    data: IAttendees[];
    eventDetails: IEvents | undefined;
    columns?: IColumn[];
    eventAttendeesList: () => void;
}


export const EventAttendeesTable = ({
    columns,
    data = [],
    eventAttendeesList,
    eventDetails
}: ITableProps) => {
    const [sortedColumns, setSortedColumns] = useState<string[]>([]);
    const [tableData, setTableData] = useState<IAttendees[]>(data);
    const [appointment, setAppointments] = useState<IEvents | undefined>(eventDetails);

    useEffect(() => {
        if (eventDetails) {
            setAppointments(eventDetails);
        }
    }, [eventDetails]);


    // console.log(appointment, "appointment");

    // console.log(tableData, "tableData")



    useEffect(() => {
        // console.log("Incoming data:", data);
        setTableData(Array.isArray(data) ? data : []);
    }, [data]);

    // console.log(tableData, "tableData");
    const sortedData = Array.isArray(tableData) ? [...tableData] : [];

    // console.log(sortedData, "sortedData");
    const [sortDirections, setSortDirections] = useState<{
        [key: string]: 'asc' | 'desc' | undefined;
    }>({});

    const handleSort = (columnTitle: string) => {
        setSortDirections((prevSortDirections) => {
            let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
            let newSortedColumns: string[] = [];

            // Toggle the sorting direction for the clicked column
            if (prevSortDirections[columnTitle] === 'asc') {
                newSortDirections[columnTitle] = 'desc';
            } else {
                newSortDirections[columnTitle] = 'asc';
            }

            // Include the clicked column in the list of sorted columns
            newSortedColumns.push(columnTitle);

            setSortedColumns(newSortedColumns);

            return newSortDirections;
        });
    };


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        handleChangePage(null, 0);
    }, [data]);
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    const paginatedData = sortedData.slice(startIndex, endIndex);




    return (
        <>

            {/* Desktop Version */}


            <Stack sx={{ display: { xs: 'none', md: 'block' } }}>
                <SimpleBar style={{ height: '50vh', overflowY: 'auto' }}>
                    <TableContainer
                        component={Paper}
                        elevation={0}
                        sx={{
                            height: { xs: 'calc(100vh - 32vh)', md: 'calc(100vh - 50vh)' },
                            border: '1px solid #D4DDED',
                            borderRadius: '12px',
                        }}
                    >
                        <Table
                            sx={{
                                '& th': {
                                    fontSize: '1px',
                                    fontWeight: 700,
                                    color: '#000000',
                                    p: 1.5,
                                    '& .MuiTypography-root': { m: 0, fontSize: '13px', fontWeight: 500, fontFamily: 'Montserrat', color: '#646464' },
                                },
                                '& td': {
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#1A1A1A',
                                    p: '5px 10px',
                                    '& .MuiTypography-root': { m: 0, fontSize: '12px', fontWeight: 500, fontFamily: 'Montserrat' },
                                },
                            }}
                        >
                            <TableHead
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                    background: '#f9f9f9',
                                }}
                            >
                                <TableRow sx={{}}>
                                    {columns?.map((column: IColumn, i: number) => (
                                        <TableCell
                                            key={i}
                                            onClick={() => handleSort(column.title)}
                                            sx={{ width: '100px' }}
                                        >
                                            <CustomTableHeader
                                                title={column.title}
                                                sortDirection={sortDirections[column.title]}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData?.map((item: any, index) => (
                                    <TableRow hover key={index}>

                                        <TableCell sx={{ width: '100px' }}>
                                            <Typography sx={{ fontSize: '13px', color: '#000000' }}>
                                                {item?.attendeeId?.firstName}
                                            </Typography>
                                        </TableCell>

                                        <TableCell >
                                            <Typography sx={{ fontSize: '13px', color: '#1A1A1A', }}>
                                                {item?.attendeeId?.emailId}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography sx={{ fontSize: '13px', color: '#1A1A1A', }}>
                                                {item?.attendeeId?.phoneNumber}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography sx={{ fontSize: '13px', color: '#1A1A1A', }}>
                                                {eventDetails?.pricing ? `${eventDetails.pricing}` : "Free"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                <FiberManualRecordRounded
                                                    key={index}
                                                    sx={{
                                                        hieght: '10px',
                                                        width: '10px',
                                                        color: '#4caf50',
                                                    }}
                                                />
                                                Confirmed
                                            </Typography>

                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </SimpleBar>
            </Stack>




            {/* Mobile Version */}

            <Stack sx={{ display: { xs: 'block', md: 'none' }, p: 1 }}>
                {paginatedData?.map((item: any, index) => (
                    <Card
                        elevation={0}
                        sx={{
                            borderRadius: "12px",
                            p: 2,
                            maxWidth: 400,
                            backgroundColor: '#fff',
                            border: '1px solid #E7EBF1',
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#000000', fontFamily: 'Montserrat' }}>
                                {item?.attendeeId?.firstName}
                            </Typography>

                            <Chip
                                label={
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <FiberManualRecordRounded sx={{ fontSize: 10, color: '#2ecc71' }} />
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#000000', fontFamily: 'Montserrat' }}>Confirmed</Typography>
                                    </Stack>
                                }
                                sx={{
                                    backgroundColor: '#eaf6ec',
                                    borderRadius: '16px',
                                    height: '26px',
                                    px: 1.5,
                                    fontFamily: 'Montserrat',
                                }}
                            />
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" mb={1}>
                            <Box>
                                <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#969696', fontFamily: 'Montserrat' }} >
                                    Amount
                                </Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#000000', fontFamily: 'Montserrat' }}>₹{eventDetails?.pricing ? `${eventDetails.pricing}` : "Free"}</Typography>
                            </Box>

                            <Box textAlign="right">
                                <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#969696', fontFamily: 'Montserrat' }}>
                                    Contact
                                </Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#000000', fontFamily: 'Montserrat' }}>  {item?.attendeeId?.phoneNumber}</Typography>
                            </Box>
                        </Stack>

                        <Box mt={2}>
                            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#969696', fontFamily: 'Montserrat' }}>
                                Email
                            </Typography>
                            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#000000', fontFamily: 'Montserrat' }}> {item?.attendeeId?.emailId}</Typography>
                        </Box>
                    </Card>
                ))}
            </Stack>

            <Stack
                direction={'row-reverse'}
                alignItems={'center'}
                sx={{
                    color: '#A5A5A5',
                    fontSize: { xs: '10px', md: '1.5vh' },
                    pr: { xs: '0px', md: '25px' },
                    mb: { xs: 12, md: 0 },
                    // border: '1px solid #D4DDED',

                }}
            >
                <Typography
                    sx={{
                        color: '#1a1a1a',
                        fontSize: '13px',
                        display: { xs: 'none', md: 'block' },
                        fontFamily: 'Montserrat'
                    }}
                >
                    Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{' '}
                    {data.length} entries
                </Typography>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={
                        <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
                    }
                    labelDisplayedRows={() => null}
                    SelectProps={{
                        SelectDisplayProps: {
                            style: { border: '1px solid #A5A5A5', borderRadius: '5px', fontFamily: 'Montserrat' },
                        },
                        // You can also customize the style of the menu options if desired
                        MenuProps: {
                            PaperProps: { style: { border: '1px solid #A5A5A5', fontFamily: 'Montserrat' } },
                        },
                        inputProps: {
                            style: {
                                fontFamily: 'Montserrat'
                            }

                        }
                    }}
                    ActionsComponent={({ onPageChange, page }) => {
                        const totalPages = Math.ceil(data.length / rowsPerPage);

                        return (
                            <Stack
                                direction={'row'}
                                display="flex"
                                alignItems="center"
                                justifyContent={'space-between'}
                            >
                                <IconButton
                                    onClick={(event) => onPageChange(event, page - 1)}
                                    disabled={page === 0}
                                    aria-label="go to previous page"
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>

                                {[...Array(3)].map((_, index) => {
                                    const pageNumber = page + index;
                                    if (pageNumber < totalPages) {
                                        return (
                                            <IconButton
                                                key={pageNumber}
                                                onClick={(event) => onPageChange(event, pageNumber)}
                                                style={{
                                                    fontSize: '1.9vh',
                                                    border:
                                                        page === pageNumber ? '1px solid #3C5AA0' : 'none',
                                                    color: page === pageNumber ? '#3C5AA0' : '#000000',
                                                    width: page === pageNumber ? '30px' : '30px',
                                                    height: page === pageNumber ? '30px' : '30px',
                                                }}
                                            >
                                                {pageNumber + 1}
                                            </IconButton>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}

                                <IconButton
                                    onClick={(event) => onPageChange(event, page + 1)}
                                    disabled={page >= totalPages - 1}
                                    aria-label="next page"
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                            </Stack>
                        );
                    }}
                />
            </Stack>
        </>
    );
};
