
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { BorderIcon, GreenFillIcon } from '../../../assets/icons';
import { Alert, Box, Dialog, DialogContent, DialogProps, Grid, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { usePlans } from '../../../hooks/usePlans';
import { useBankAccounts } from '../../../hooks/useBankAccounts';
import { IPlan } from '../../Plans/Plans';
import { useInvitations } from '../../../hooks/useInvitations';
import { BankAccInterface } from '../../../models/BankAcc.models';
import { Invitations } from '../../../models/Invitation.model';
import { useNavigate } from 'react-router-dom';
import Close from '@mui/icons-material/Close';
import PaymentList from '../../Payments/PaymentList';
import Invitation from '../../Invitations/Invitation';
import { PlansList } from '../../Plans/NewPlansList';
import { ChevronRight } from 'lucide-react';

const CommunitySteps = () => {



    const communitySelected = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );

    const community_id = communitySelected?._id;

    const [openPlans, setOpenPlans] = React.useState(false);
    const [openAddUser, setOpenAddUser] = React.useState(false);
    const [openBankDetails, setOpenBankDetails] = React.useState(false);
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('lg');
    const { getPlansList } = usePlans();
    const { getBankDetailById } = useBankAccounts();
    const { getInviteList } = useInvitations();
    const [plans, setPlans] = React.useState<IPlan[]>([]);
    const [accountDetails, setAccountDetails] = React.useState<BankAccInterface[]>([]);
    const [invitesList, setInvitesList] = React.useState<Invitations[]>([]);
    const [progress, setProgress] = React.useState<number>(0);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    //Popup Fuctions Start//


    // console.log(plans, "plans");

    React.useEffect(() => {
        const fetchDetails = async () => {
            try {
                if (community_id) {
                    const plans_data = await getPlansList(community_id);
                    // console.log(plans_data, "plans_data")
                    if (communitySelected?.status === 'PUBLISHED') {
                        setProgress((prevProgress) => prevProgress + 25);
                    }
                    if (plans_data && plans_data?.length > 0) {
                        setPlans(plans_data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }

                    const response = await getBankDetailById(community_id);

                    // memberListRef.current = users || [];
                    if (response && response?.data?.length > 0) {
                        setAccountDetails(response.data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }
                    const invitesList = await getInviteList(community_id);
                    if (invitesList && invitesList?.data?.length > 0) {
                        setInvitesList(invitesList?.data);
                        setProgress((prevProgress) => prevProgress + 25);
                    }
                }
            } catch (error) {
                console.error('Error fetching invite list:', error);
            }
        };

        fetchDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [community_id]);

    const refetchPlanDetails = async () => {
        try {
            if (community_id) {
                const plans_data = await getPlansList(community_id);
                if (plans_data && plans_data?.length > 0) {
                    setPlans(plans_data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const refetchBankDetails = async () => {
        try {
            if (community_id) {
                const response = await getBankDetailById(community_id);
                // memberListRef.current = users || [];
                if (response && response.data?.length > 0) {
                    setAccountDetails(response.data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const refetchInviteDetails = async () => {
        try {
            if (community_id) {
                const invitesList = await getInviteList(community_id);
                if (invitesList && invitesList?.data?.length > 0) {
                    setInvitesList(invitesList?.data);
                    setProgress((prevProgress) =>
                        prevProgress < 100 ? prevProgress + 25 : 100
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching invite list:', error);
        }
    };

    const handleClickOpenPlans = () => {
        // if( communitySelected?.status === "CREATED" ){
        setOpenPlans(true);
        // }
    };

    const handleClose = () => {
        setOpenPlans(false);
        refetchPlanDetails();
    };

    const navigate = useNavigate();



    const handleClickCommunityPublish = () => {
        if (plans?.length > 0) {
            navigate(`/communities/${community_id}/build`);
        }
    };
    const handleClickCommunityPublishFree = () => {
        navigate(`/communities/${community_id}/build`);
    };


    const handleClickOpenAddUser = () => {
        if (
            communitySelected.collectSubscription === 'YES' &&
            communitySelected?.status === 'PUBLISHED' &&
            plans.length > 0
        ) {
            setOpenAddUser(true);
        } else if (
            communitySelected.collectSubscription === 'NO' &&
            communitySelected.status === 'PUBLISHED'
        ) {
            setOpenAddUser(true);
        } else {
            setError(true);
            if (
                communitySelected.collectSubscription === 'NO' &&
                communitySelected?.status !== 'PUBLISHED'
            ) {
                setErrorMessage('Please build your community to invite members');
            } else {
                setErrorMessage(
                    'Please complete the first 2 necessary steps to invite members.'
                );
            }
        }
    };

    const handleCloseAddUser = () => {
        setOpenAddUser(false);
        refetchInviteDetails();
    };

    const handleOpenBankDetails = () => {
        if (communitySelected?.status === 'PUBLISHED') {
            setOpenBankDetails(true);
        } else {
            setError(true)
            setErrorMessage('Please build your community to add bank details');
        }
    };

    const handleCloseBankDetails = () => {
        setOpenBankDetails(false);
        refetchBankDetails();
    };

    return (


        <Box sx={{
            borderRadius: '8px',
            border: '1px solid #E7EBF1',
            backgroundColor: 'white'
        }}>
            <Snackbar
                open={error}
                autoHideDuration={3000}
                onClose={() => setError(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={() => setError(false)} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    p: 3,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    borderBottom: '1px solid #E7EBF1'
                }}
            // onClick={handleClickCommunityPublish}
            >
                <GreenFillIcon />
                <Typography
                    sx={{
                        flexShrink: 0,
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: '500'
                    }}
                >
                    Create your community
                </Typography>
                <ChevronRight size={30} strokeWidth={1.5} color='#E7EBF1' style={{ marginLeft: 'auto' }} />
            </Box>


            <Box
                sx={{
                    p: 3,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    borderBottom: '1px solid #E7EBF1'
                }}
                onClick={handleClickOpenPlans}
            >
                {plans?.length > 0 ? <GreenFillIcon /> : <BorderIcon />}
                <Typography
                    sx={{
                        flexShrink: 0,
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: '500'
                    }}
                >
                    Create plan
                </Typography>
                <ChevronRight size={30} strokeWidth={1.5} color='#E7EBF1' style={{ marginLeft: 'auto' }} />
            </Box>


            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={openPlans}
                onClose={handleClose}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ backgroundColor: '#F0F9FF', height: '50px', px: 2, py: 1 }}
                >
                    <Typography
                        sx={{
                            color: '#3C5AA0',
                            fontSize: '20px',
                            fontWeight: '500',
                            backgroundColor: '#F0F9FF',
                        }}
                    >

                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#3C5AA0',
                            padding: '4px',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleClose()}
                    >
                        <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
                    </Box>
                </Grid>
                <DialogContent sx={{ pt: '10px' }}>
                    <PlansList />
                </DialogContent>
            </Dialog>

            {communitySelected?.type === 'PRIVATE' ? (
                <>
                    <Box
                        sx={{
                            p: 3,
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            borderBottom: '1px solid #E7EBF1'
                        }}
                        onClick={handleClickCommunityPublish}
                    >
                        {communitySelected?.status === 'PUBLISHED' ? <GreenFillIcon /> : <BorderIcon />}
                        <Typography
                            sx={{
                                flexShrink: 0,
                                fontFamily: 'Montserrat',
                                fontSize: { xs: '14px', md: '16px' },
                                fontWeight: '500'
                            }}
                        >
                            Build community
                        </Typography>
                        <ChevronRight size={30} strokeWidth={1.5} color='#E7EBF1' style={{ marginLeft: 'auto' }} />
                    </Box>
                </>
            ) : (
                <>
                    <Box
                        sx={{
                            p: 3,
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            borderBottom: '1px solid #E7EBF1'
                        }}
                        onClick={handleClickCommunityPublishFree}
                    >
                        {communitySelected?.status === 'PUBLISHED' ? <GreenFillIcon /> : <BorderIcon />}
                        <Typography
                            sx={{
                                flexShrink: 0,
                                fontFamily: 'Montserrat',
                                fontSize: { xs: '14px', md: '16px' },
                                fontWeight: '500'
                            }}
                        >
                            Build community
                        </Typography>
                        <ChevronRight size={30} strokeWidth={1.5} color='#E7EBF1' style={{ marginLeft: 'auto' }} />
                    </Box>
                </>
            )
            }



            <Box
                sx={{
                    p: 3,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    borderBottom: '1px solid #E7EBF1'
                }}
                onClick={handleOpenBankDetails}
            >
                {accountDetails?.length > 0 ? <GreenFillIcon /> : <BorderIcon />}
                <Typography
                    sx={{
                        flexShrink: 0,
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: '500'
                    }}
                >
                    Add bank details
                </Typography>
                <ChevronRight size={30} strokeWidth={1.5} color='#E7EBF1' style={{ marginLeft: 'auto' }} />
            </Box>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={openBankDetails}
                onClose={() => handleCloseBankDetails()}
                PaperProps={{
                    sx: {
                        width: '100%',
                    },
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ backgroundColor: '#F0F9FF', height: '50px', px: 2 }}
                >
                    <Typography
                        sx={{
                            color: '#3C5AA0',
                            fontSize: { xs: '15px', md: '20px' },
                            fontWeight: '500',
                            backgroundColor: '#F0F9FF',
                        }}
                    >
                        {/* Account Details */}
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#3C5AA0',
                            padding: '4px',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',

                        }}
                        onClick={() => handleCloseBankDetails()}
                    >
                        <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
                    </Box>
                </Grid>
                <DialogContent sx={{ pt: 0, overflowY: 'hidden' }}>
                    <PaymentList openMode={openBankDetails} />
                </DialogContent>
            </Dialog>



            <Box
                sx={{
                    p: 3,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,

                }}
                onClick={handleClickOpenAddUser}
            >
                {invitesList?.length > 0 ? <GreenFillIcon /> : <BorderIcon />}
                <Typography
                    sx={{
                        flexShrink: 0,
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: '500'
                    }}
                >
                    Invite
                </Typography>
                <ChevronRight size={30} strokeWidth={1.5} color='#E7EBF1' style={{ marginLeft: 'auto' }} />
            </Box>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={openAddUser}
                onClose={handleCloseAddUser}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ backgroundColor: '#F0F9FF', height: '50px', px: 2 }}
                >
                    <Typography
                        sx={{
                            color: '#3C5AA0',
                            fontSize: '20px',
                            fontWeight: '500',
                            backgroundColor: '#F0F9FF',
                        }}
                    >
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#3C5AA0',
                            padding: '4px',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleCloseAddUser()}
                    >
                        <Close sx={{ fontSize: '15px', color: '#FFFFFF' }} />
                    </Box>
                </Grid>
                <DialogContent
                    sx={{ pt: '10px', overflowY: 'hidden', pb: '10px' }}
                >
                    <Invitation openMode={openAddUser} />
                </DialogContent>
            </Dialog>



        </Box>
    );
}
export default CommunitySteps;