import { useEffect, useState } from "react";
import { usePlans } from "../../hooks/usePlans";
import store from "../../store";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { IPlan, Plan } from "./Plans";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { metaConfig } from "../../utils/constants";
import { SubNavbar } from "../Layout/components/SubNavbar";
import { SearchIcon1 } from "../../assets/icons";
import { AddCircleOutlineOutlined, Close } from "@mui/icons-material";
import { NewAddPlan } from "./NewAddPlan";
import Loader from "../Loader/Loader";
import { NoPlans } from "../AlternatePages/noPlans";

export const Plans = () => {
    const { getPlansList } = usePlans();
    const [openPlans, setOpenPlans] = useState(false);
    const [fullWidth] = useState(true);
    const [maxWidth] = useState<DialogProps['maxWidth']>('lg');
    const state = store.getState();
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl] = useState<HTMLButtonElement | null>(null);
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [searchQuery, setSearchQuery] = useState('');


    const selectedCommunity = useSelector(() => {
        return state?.selectedCommunity;
    });

    const communitiesSuggestion = useSelector(() => {
        return state?.communities;
    });

    let filteredSelectedCommunity: any = [];
    if (Array.isArray(communitiesSuggestion?.communities)) {
        filteredSelectedCommunity = communitiesSuggestion.communities.filter(
            (community: any) =>
                community._id === selectedCommunity.selectedCommunity?._id
        );
    }
    const selectedCommunityId = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity?._id
    );


    const loadPlans = async () => {
        try {
            setIsLoading(true);
            if (selectedCommunityId) {
                const plans_data = await getPlansList(selectedCommunityId);
                console.log(plans_data, "plans_data");
                if (plans_data) {
                    setIsLoading(false);
                    setPlans(plans_data);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadPlans();
    }, [selectedCommunityId]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickOpenPlans = () => {
        setOpenPlans(true);
    };
    const handleClose = () => {
        setOpenPlans(false);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const filteredPlans = plans.filter((plan) =>
        plan?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <Helmet>
                <title>{metaConfig.title.plans}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>

            <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, pb: 1 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>

                <Typography
                    sx={{
                        fontSize: { xs: '14px' },
                        fontWeight: 600,
                        color: '#000000',
                        fontFamily: 'Montserrat',
                    }}
                >
                    Plans
                </Typography>
            </Stack>

            <Stack direction={'column'} spacing={1}>
                <SubNavbar>
                    <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0}>
                        <Stack direction={'row'} flexGrow={1} sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Typography
                                sx={{
                                    fontSize: { xs: '14px', md: '16px' },
                                    fontWeight: 600,
                                    color: '#000000',
                                    fontFamily: 'Montserrat',
                                }}
                            >
                                Plans
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} flexGrow={1} sx={{ justifyContent: { xs: 'space-between', md: 'flex-end' } }} gap={2} >
                            <TextField
                                placeholder="Search "
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon1 sx={{ mt: 1, }} />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        borderRadius: '8px',
                                        backgroundColor: '#F9F9F9',
                                        fontSize: { xs: '12px', md: '15px' },
                                        height: '37px',
                                        width: { xs: '100%', md: '300px' },
                                        color: '#000000',
                                        fontFamily: 'Montserrat',
                                        ml: { xs: -1 },
                                        '& input::placeholder': {
                                            fontFamily: 'Montserrat',
                                            fontSize: '14px',
                                            color: '#9e9e9e',
                                            opacity: 1,
                                        },
                                    },
                                }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            {location.pathname.match('/plans') && (
                                <Button
                                    variant="contained"
                                    endIcon={<AddCircleOutlineOutlined />}
                                    sx={{
                                        textTransform: 'capitalize',
                                        borderRadius: '8px',
                                        backgroundColor: '#3C5AA0',
                                        fontFamily: 'Montserrat',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: '#3C5AA0',
                                            cursor: 'pointer',
                                            border: 'none',
                                        },
                                    }}
                                    onClick={() => {
                                        navigate('/create-plan')
                                    }}
                                >
                                    Add New
                                </Button>
                            )}
                            {(location.pathname.match(/^\/communities\/[a-zA-Z0-9]+$/) || location.pathname === '/get-started') && (
                                <Button
                                    variant="contained"
                                    onClick={handleClickOpenPlans}
                                    endIcon={<AddCircleOutlineOutlined />}
                                    sx={{
                                        textTransform: 'capitalize',
                                        borderRadius: '8px',
                                        backgroundColor: '#3C5AA0',
                                        fontFamily: 'Montserrat',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: '#3C5AA0',
                                            cursor: 'pointer',
                                            border: 'none',
                                        },
                                    }}
                                >
                                    New
                                </Button>
                            )}

                            <Dialog
                                fullWidth={fullWidth}
                                maxWidth={maxWidth}
                                open={openPlans}
                                onClose={handleClose}
                            >
                                <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ backgroundColor: '#F0F9FF' }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: { xs: '16px', md: '18px' },
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                                color: '#3C5AA0',
                                                marginLeft: 2,
                                                fontFamily: 'Montserrat'
                                            }}
                                        >
                                            {id ? 'Publish' : 'Create'} a Plan
                                        </Typography>
                                        <Box
                                            onClick={handleClose}
                                            sx={{
                                                backgroundColor: '#3C5AA0',
                                                padding: '4px',
                                                borderRadius: '50%',
                                                width: '20px',
                                                height: '20px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'white',
                                                fontSize: '10px',
                                                mt: '0.5px',
                                                mr: '10px',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    border: 'none',
                                                },
                                            }}
                                        >
                                            <Close sx={{ fontSize: '15px' }} />
                                        </Box>
                                    </Grid>
                                </DialogActions>

                                <DialogContent>
                                    <NewAddPlan
                                        selectedCommunity={filteredSelectedCommunity}
                                        closeDialog={handleClose}
                                        loadPlans={loadPlans}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Stack>
                    </Stack >
                </SubNavbar>
                {isLoading ? (
                    <Loader /> //
                ) : filteredPlans.length === 0 ? (
                    <NoPlans />
                ) : (
                    <Stack direction="column" spacing={2}>
                        {filteredPlans?.map((plan: IPlan) => (
                            <Plan plan={plan} key={plan?._id} loadPlans={loadPlans} />
                        ))}
                    </Stack>
                )}
                <Box pb={1.5}></Box>
            </Stack>
        </>
    );
};