import axios from 'axios';
import { BASE_URL, BASE_URL_V2 } from '../configurations/url.config';
import { IWebAboutUs, IWebBanners, IWebContact, IWebFaqs, IWebService, IWebTeam } from '../models/builder.model';
import { IBuildData } from '../components/Deployment/Deployment';







const addServiceParse = (service: IWebService) => {
  return {
    ...service,
    title: service?.title,
    description: service?.description,
    images: service?.images,
  };
};



const addBannerParse = (banner: IWebBanners) => {
  return {
    ...banner,
    title: banner?.heading,
    description: banner?.subheading,
    image: banner?.image,
  };
};

const addTeamParse = (team: IWebTeam) => {
  return {
    ...team,
    name: team?.name,
    description: team?.description,
    experience: team?.experience,
    designation: team?.designation,
    avatar: team?.avatar,
  };
};

const addFaqParse = (faq: IWebFaqs) => {
  return {
    ...faq,
    question: faq?.question,
    answer: faq?.answer,
  };
};

const addAboutUsParse = (aboutUs: IWebAboutUs) => {
  return {
    ...aboutUs,
    heading: aboutUs?.heading,
    about: aboutUs.about,
    vision: aboutUs?.vision,
    mission: aboutUs?.mission,
    // image: aboutUs?.image,
  };
};



const addContactParse = (contact: IWebContact) => ({
  email: contact.email,
  phone: contact.phone,
  city: contact.city,
  address: contact.address,
  pinCode: contact.pinCode,
});





export const getWebsiteData = async ( communityId: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/builders/community/${communityId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateContact = async (token: string,communityId: string, contactDetails: IWebContact
) => {
  try { 
    const body = { contactUs: contactDetails };
    const response = await axios.put(
      `${BASE_URL_V2}/builder/community/${communityId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      response.data = addContactParse(response?.data);
    }

    return response;
  } catch (err) {
    console.log("error", err);
  }
};

export const updateAbout= async (token: string,communityId: string, aboutDetails:IWebAboutUs) => {
  try {
    const body = { aboutUs: aboutDetails };
    const response = await axios.put(
      `${BASE_URL_V2}/builder/community/${communityId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      response.data = addAboutUsParse(response.data);
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateFaq = async (token: string, communityId: string, faqDetails: IWebFaqs[]) => {
  try {
    const body = {faq:faqDetails}
    const response = await axios.put(`${BASE_URL_V2}/builder/community/${communityId}`, 
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      response.data = addFaqParse(response.data);
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateService = async (token: string,communityId: string, serviceDetails: IWebService[]) => {
  try {
    const body = {services:serviceDetails}
    const response = await axios.put(`${BASE_URL_V2}/builder/community/${communityId}`, 
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      response.data = addServiceParse(response.data);
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateBanner = async (token: string,communityId: string, bannersDetails: IWebBanners[]) => {
  try {
    const body = {banner:bannersDetails}
    const response = await axios.put(`${BASE_URL_V2}/builder/community/${communityId}`, 
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      response.data = addBannerParse(response.data);
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateTeam = async (token: string, communityId: string, teamDetails:IWebTeam[]) => {
  try {
    const body = {teams:teamDetails}
    const response = await axios.put(`${BASE_URL_V2}/builder/community/${communityId}`, 
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      response.data = addTeamParse(response.data);
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};


export const deleteEntity = async (
  token: string,
  communityId: string,
  entityType: 'faq' | 'services' | 'teams',
  entityDetails: string | IWebFaqs[]
) => {
  try {
    const body = {
      [entityType]: Array.isArray(entityDetails)
        ? entityDetails
        : [{ _id: entityDetails }],
    };

    const response = await axios.delete(
      `${BASE_URL_V2}/builder/community/${communityId}`,
      {
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 && entityType === 'faq') {
      response.data = addFaqParse(response.data);
    }

    return response;
  } catch (err) {
    console.error(`Error in deleteEntity for ${entityType}:`, err);
    throw err;
  }
};


export const deployWeb = async (token:string, buildData:IBuildData) => {
  try {
      const response = await axios.post(`${BASE_URL}/utility/web-builder/deploy`,
             buildData,
             {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
      });
      console.log(response?.data);
      return response
  } catch (err) { 
    console.log(err,"error")
  }
};






