import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PropTypes from 'prop-types';
import { useCommunity } from '../../../../hooks/useCommunity';
import { ICommunity } from '../../../../models/communities.modal';
import SearchCommunityCard from './SwitchCommunityCard';
import { SearchIcon1 } from '../../../../assets/icons';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import SmallLoader from '../../../Loader/SmallLoader';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { USER_APP_BASE_URL } from '../../../../configurations/url.config';


function CustomTabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },


  },
}));

type Props = {
  getData: (data: any) => Promise<ICommunity>;
  onCommunitySelect: () => void;
};

const MAX_MODAL_HEIGHT = 410;



const SwitchCommunity = ({ getData, onCommunitySelect }: Props) => {


  const { isLoading, getCommunitiesList, memberCommunities } = useCommunity();
  const [value, setValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const [filteredCommunities] = useState<any[]>([]);
  const [myMemberCommunities, setMyMemberCommunities] = useState<ICommunity[]>([]);
  const [selectedCommunityIds] = useState<string[]>([]);
  const [sortedFilter, setSortedFilter] = useState<ICommunity[]>([]);
  const [sortedMemberCommunities, setSortedMemberCommunities] = useState<ICommunity[]>([]);
  // eslint-disable-next-line
  const [selectSearch, setSelectSearch] = useState<null | string>('Search Community...');
  // eslint-disable-next-line
  const [communityDataSearch, setCommunityDataSearch] = useState<ICommunity | null>(null);

  const getAdminCommunitiesList = async () => {
    let communitiesDataList = await getCommunitiesList();
    setCommunities(communitiesDataList);
  };

  const getMemberCommunitiesList = async () => {
    const responseData = await memberCommunities();
    let memberCommunitiesList = responseData?.data;
    setMyMemberCommunities(memberCommunitiesList);
  };
  // console.log(sortedMemberCommunities);

  const handleFavUpdate = async () => {
    if (value === 0) {
      await getAdminCommunitiesList();
    }
    if (value === 1) {
      await getMemberCommunitiesList();
    }
  };

  useEffect(() => {
    getAdminCommunitiesList();
    getMemberCommunitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (
    _event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setSearchQuery('');
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 0) {
      let filtered = [];
      if (searchQuery && searchQuery.length) {
        filtered = communities.filter((community) =>
          community.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        );
      } else {
        filtered = communities;
      }
      if (filtered.length) {
        const sortedCommunities = [...filtered].sort((a, b) => {
          const aFavorite = a?.favorite ?? false;
          const bFavorite = b?.favorite ?? false;
          if (aFavorite && !bFavorite) {
            return -1;
          } else if (bFavorite && !aFavorite) {
            return 1;
          } else {
            return 0;
          }
        });
        setSortedFilter(sortedCommunities);
      } else {
        setSortedFilter([]);
      }
    }

    if (value === 1) {
      let filtered = [];
      if (searchQuery && searchQuery.length) {
        filtered = myMemberCommunities.filter((community) => {
          return community.community.name
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase());
        });
      } else {
        filtered = myMemberCommunities;
      }

      if (filtered.length) {
        const sortedCommunities = [...filtered].sort((a, b) => {
          const aFavorite = a?.favorite ?? false;
          const bFavorite = b?.favorite ?? false;
          if (aFavorite && !bFavorite) {
            return -1;
          } else if (bFavorite && !aFavorite) {
            return 1;
          } else {
            return 0;
          }
        });
        setSortedMemberCommunities(sortedCommunities);
      } else {
        setSortedMemberCommunities([]);
      }
    }
  }, [
    value,
    communities,
    myMemberCommunities,
    searchQuery,
    selectedCommunityIds,
  ]);

  const selectedComunity = async (elm: any) => {
    setCommunityDataSearch(elm);
    setSelectSearch(elm.title);
    onCommunitySelect();
    await getData(elm);
  };



  const maxHeight = Math.min(MAX_MODAL_HEIGHT, filteredCommunities.length * 100);

  const navigateToUserFlow = (id: string) => {
    const token = localStorage.getItem('access-token');
    // const redirectPath = encodeURIComponent('/user/home');
    const url = `${USER_APP_BASE_URL}/login?communityId=${id}&token=${token}`;
    console.log(url, "url");
    window.open(url, '_blank');
  };

  const navigateToUserSubscription = (id: string) => {
    const token = localStorage.getItem('access-token');
    const redirectPath = encodeURIComponent('/user/subscriptions');
    const url = `${USER_APP_BASE_URL}/login?communityId=${id}&token=${token}&redirect=${redirectPath}`;
    window.open(url, '_blank');
  };


  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const mobileViewHeight = 370;
  const desktopViewHeight = 380;

  const height = isMobileView ? mobileViewHeight : desktopViewHeight;

  return (
    <>
      <Box sx={{ width: '100%', height: { xs: '65vh', md: '70vh' } }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none',
                borderRadius: '100px',
                fontFamily: 'Montserrat',
              },
              '& .MuiTab-root': {
                width: '50%',
                textTransform: 'capitalize',
                fontSize: { xs: '15px', md: '14px', fontFamily: 'Inter' },
                color: '#000000',
                border: 'none',
                fontWeight: 500,
                '&.Mui-selected': {
                  backgroundColor: '#3C5AA0',
                  borderRadius: '100px',
                  color: '#ffffff',
                  fontFamily: 'Montserrat',
                  m: 1,
                  minHeight: { xs: '30px', md: '35px' },
                  maxHeight: { xs: '30px', md: '35px' },
                },
                '&:not(.Mui-selected)': {
                  backgroundColor: 'white',
                  fontWeight: 500,
                  color: '#000000',
                  fontFamily: 'Montserrat',
                },
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Admin" {...a11yProps(0)} />
            <Tab label="Member" {...a11yProps(1)} />
            <Tab label="Super Admin" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <Box
          sx={{
            border: '0.1px solid gray',
            marginBottom: { xs: '1vw', md: '1vw' },
            borderRadius: '2vw',
            mt: 2,
          }}
        >
          <Search>
            <SearchIconWrapper
              sx={{ ml: { xs: '0px', md: '-30px' }, mt: '4px' }}
            >
              <SearchIcon1 />
            </SearchIconWrapper>
            <StyledInputBase
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ fontSize: { xs: '3vw', md: '1.2vw', xl: '1vw' } }}
              inputProps={{ 'aria-label': 'search' }}
              placeholder="Search community"

            />
          </Search>
        </Box>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <CustomTabPanel value={value} index={0}>
            <Box sx={{ maxHeight: maxHeight }}>
              <SimpleBar style={{ height: `${height}px` }}>
                {sortedFilter?.map((elm: any, i: number) => (
                  <div
                    key={i}
                    onClick={() => {
                      selectedComunity(elm);
                    }}
                  >
                    <SearchCommunityCard
                      data={elm}
                      value={value}
                      handleFavUpdate={handleFavUpdate}
                      refresh={false}
                    // onFavoriteIconClick={handleFavoriteIconClick}
                    />
                  </div>
                ))}
              </SimpleBar>
            </Box>
          </CustomTabPanel>
        )}

        {!isLoading && value === 1 && (
          <CustomTabPanel value={value} index={1}>
            <Box sx={{}}>
              <SimpleBar style={{ height: `${height}px` }}>
                {sortedMemberCommunities?.map((elm: any, i: number) => (
                  <div
                    key={i}
                    onClick={() => {
                      if (elm?.status !== 'BLOCK') {
                        if (
                          elm?.community?.type === 'PRIVATE' &&
                          elm?.community?.collectSubscription === 'YES' &&
                          (elm?.subscriptionStatus === 'CREATED' || elm?.subscriptionStatus === 'EXPIRED')
                        ) {
                          navigateToUserSubscription(elm?.community._id);
                          return;
                        }
                        navigateToUserFlow(elm?.community._id);
                      }
                    }}
                  >
                    <SearchCommunityCard
                      data={elm}
                      value={value}
                      handleFavUpdate={handleFavUpdate}
                      refresh={true}
                    />
                  </div>
                ))}
              </SimpleBar>
            </Box>
          </CustomTabPanel>
        )}

        {/* {!isLoading && value === 1 && (
          <CustomTabPanel value={value} index={1}>
            <Box sx={{ maxHeight: maxHeight }}>
              <SimpleBar style={{ height: `${height}px` }}>
                {sortedMemberCommunities.length > 0 ? (
                  sortedMemberCommunities.map((elm, i) => (
                    <div key={i} onClick={() => navigateToAdminFlow(elm?._id)}>
                      <SearchCommunityCard
                        data={elm}
                      onFavoriteIconClick={handleFavoriteIconClick}
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    <NoCommunities />
                  </div>
                )}
              </SimpleBar>
            </Box>
          </CustomTabPanel>
        )} */}

      </Box>
      <Box sx={{ width: { xs: '65vw', md: '41vw' } }}>
        {/* You can add additional content if needed */}
      </Box>
    </>
  );
};

export default SwitchCommunity;
