import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Box, Stack } from '@mui/system';
// import JoinedCommunities from './SliderCommunities/JoinedCommunities';
import InvitesCommunities from './SliderCommunities/InvitesCommunities';
import TrendingCommunities from './SliderCommunities/TrendingCommunities';
import AdministeredCommunities from './SliderCommunities/AdministeredCommunities';
import { useEffect, useState } from 'react';
import { ICommunity } from '../../models/communities.modal';
import { useCommunity } from '../../hooks/useCommunity';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet';
// import Loader from "../Loader/Loader";
import SmallLoader from '../Loader/SmallLoader';
import { metaConfig } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../../hooks/useUsers';
import { useSnackbar } from 'notistack';
import store from '../../store';
import WhatsApp from '@mui/icons-material/WhatsApp';
import { Close } from '@mui/icons-material';
// import AdminCommunitiesGird from "./GirdViewCommunities/AdminCommunitiesGird";
// import SearchHeader from "./Layout/SearchHearder";
// import { getDeviceToken } from '../../services/pushNotification/pushNotification.service';

const ExploreCommunities = () => {


  const { getCommunitiesList } = useCommunity();
  const [communities, setCommunities] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState<ICommunity[]>([]);
  const [searchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [whatsappNumber, setWhatsappNumber] = useState<string>('')
  const [userDetails, setUserDetails] = useState<any>();
  const navigate = useNavigate();
  const { editWhatsappNumber, loadUser } = useUsers();
  const { enqueueSnackbar } = useSnackbar()
  const usersData = store.getState();
  const userId = usersData?.loggedInUser?.user?.id || ''


  const fetchCommunitiesList = async () => {
    try {
      setIsLoading(true);
      if (!communities.length) {
        const communitiesData = await getCommunitiesList();
        setCommunities(communitiesData);
      }
      const filteredUser = communities?.filter((community: ICommunity) =>
        `${community.title} ${community.fullAddress}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filteredUser);
    } catch (e) {
      console.log('error occurred....', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCommunitiesList();
  }, [communities?.length]);


  const UserData = async () => {
    try {
      const data = await loadUser(userId);
      setUserDetails(data);

      if (!data?.whatsappNumber) {
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Error loading user data:", error);
    }
  };


  useEffect(() => {
    UserData()
  }, [])




  const handleUpdateWhatsapp = async () => {
    if (!whatsappNumber || whatsappNumber.length < 10) {
      enqueueSnackbar("Please enter a valid WhatsApp number.", { variant: "error" });
      return;
    }
    try {
      const response = await editWhatsappNumber(userId, whatsappNumber);
      if (response?.status === 200) {
        enqueueSnackbar("Whatsapp number updated", { variant: "success", autoHideDuration: 3000 });
        navigate('/explore-communities')
        setShowPopup(false);
      }
    } catch (error) {
      console.error("Error updating WhatsApp number:", error);
      enqueueSnackbar("Something went wrong. Please try again.", { variant: "error" });
    }
  };

  const handleClose = () => {
    setShowPopup(false)
  }


  return (
    <>
      <Helmet>
        <title>{metaConfig.title.exploreCommunities}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <Box>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Stack>
              <InvitesCommunities />
            </Stack>

            {/* <Stack>
              {isLoading ? (
                <SmallLoader />
              ) : (
                <AdministeredCommunities data={filteredUsers} />
              )}
            </Stack>
            <Stack>
              <JoinedCommunities />
            </Stack> */}

            <Stack
              sx={{ p: 0.5, borderRadius: '10px', backgroundColor: '#ffffff' }}
            >
              <Accordion
                defaultExpanded
                sx={{ borderRadius: '10px', boxShadow: 'none' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    border: 'none',
                    boxShadow: 'none',
                    borderRadius: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', md: '18px' },
                      fontWeight: 700,
                      textDecoration: 'none',
                      color: 'black',
                      // marginLeft: 10,
                    }}
                  >
                    My Communities
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    pl: 1,
                    pr: 1,
                    backgroundColor: '#f4f4f4',
                    border: 'none',
                    boxShadow: 'none',
                  }}
                >
                  <Stack>
                    {isLoading ? (
                      <SmallLoader />
                    ) : (
                      <AdministeredCommunities data={filteredUsers} />
                    )}
                  </Stack>
                  {/* <Stack>
                    {isLoading ? <SmallLoader /> : <JoinedCommunities />}
                  </Stack> */}
                </AccordionDetails>
              </Accordion>
            </Stack>
            <Stack sx={{ pb: { xs: 2, md: 1 } }}>
              <TrendingCommunities />
            </Stack>
          </Grid>
        </Grid>
        {!userDetails?.whatsappNumber && (
          <>
            <Dialog open={showPopup}
              fullWidth
              BackdropProps={{
                sx: {
                  backdropFilter: 'blur(4px)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
              }}>

              <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ backgroundColor: '#F0F9FF' }}
                >
                  <Stack></Stack>
                  <Stack></Stack>
                  <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                    <Box
                      onClick={handleClose}
                      sx={{
                        backgroundColor: '#50A1CA',
                        padding: '4px',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '10px',
                        mt: '0.5px',
                        mr: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          // backgroundColor: "#50A1CA", // Background color on hover
                          cursor: 'pointer',
                          border: 'none',
                        },
                      }}
                    >
                      <Close sx={{ fontSize: '15px' }} />
                    </Box>
                  </Stack>
                </Stack>
              </DialogActions>

              <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h6" sx={{ display: "flex", alignItems: "center", gap: 1, fontFamily: 'Montserrat', fontWeight: 600, fontSize: { xs: '14px', md: '16px' } }}>
                  <WhatsApp color="success" /> Enable WhatsApp Notifications
                </Typography>
              </DialogTitle>

              <DialogContent>
                <Typography variant="body2" sx={{ color: "gray", mb: 2, fontFamily: 'Montserrat', fontSize: { xs: '12px', md: '14px' } }}>
                  We'll use this number to send you important updates via WhatsApp. You can modify the
                  number if you use a different one for WhatsApp.
                </Typography>

                <Typography variant="subtitle2" sx={{ fontFamily: 'Montserrat', fontSize: { xs: '14px', md: '16px' } }}>WhatsApp Number</Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={(whatsappNumber ?? usersData?.loggedInUser?.user?.whatsappNumber ?? userDetails?.phoneNumber) || ""}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    minLength: 10,
                    maxLength: 10,
                    onInput: (e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    },
                  }}
                  onChange={(e) => setWhatsappNumber((e.target.value))}
                  sx={{
                    mt: 1,
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Montserrat',
                      fontSize: '15px'
                    },

                  }}
                />

                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 3,
                    borderRadius: "30px",
                    textTransform: "none",
                    fontFamily: 'Montserrat',
                    boxShadow: 'none',
                    background: "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                    "&:hover": {
                      background: "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                      boxShadow: 'none',
                    },
                  }}
                  onClick={handleUpdateWhatsapp}
                >
                  Continue
                </Button>
              </DialogContent>
            </Dialog>
          </>

        )}
      </Box>
    </>
  );
};
export default ExploreCommunities;
