import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MenuIcon } from '../../../../assets/icons';
import { Button, Divider, Stack, Collapse } from '@mui/material';
import { useSelector } from 'react-redux';

import { BookOpenText, CalendarDays, CalendarSync, Cast, ChartNoAxesCombined, ChevronDown, ChevronUp, Component, Globe, House, Info, NotebookText, ReceiptIndianRupee, TicketPercent, UserPlus, Users, Wallet, X } from 'lucide-react';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function MobileSideBar() {
  const [state, setState] = useState({ left: false });
  const [openPlans, setOpenPlans] = useState(false);
  const [openSubscribers, setOpenSubscribers] = useState(false);
  const [openMeetings, setOpenMeetings] = useState(false);
  const [openAppointments, setOpenAppointments] = useState(false);
  const [openEvents, setOpenEvents] = useState(false);
  // const [openCourses, setOpenCourses] = useState(false);
  // const [openCommunities, setOpenCommunities] = useState(false);

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  const community_id = selectedCommunity?._id;

  const link = `/community/${community_id}/about`;

  const icons = [
    <House strokeWidth={1.5} size={20} />,
    <NotebookText size={20} strokeWidth={1.5} />,
    <Users size={20} strokeWidth={1.5} />,
    <Wallet size={20} strokeWidth={1.5} />,
    <ChartNoAxesCombined size={20} strokeWidth={1.5} />,
    <ReceiptIndianRupee size={20} strokeWidth={1.5} />,
    <CalendarSync size={20} strokeWidth={1.5} />,
    <Cast size={20} strokeWidth={1.5} />,
    <CalendarDays size={20} strokeWidth={1.5} />,
    <TicketPercent size={20} strokeWidth={1.5} />,
    <BookOpenText size={20} strokeWidth={1.5} />,
    <Component size={20} strokeWidth={1.5} />,
    <Info size={20} strokeWidth={1.5} />,
    <UserPlus size={20} strokeWidth={1.5} />,




    // <InfoRoundedIcon />,
    // <PersonAddRoundedIcon />,
  ];

  const allMenuItems = [
    { text: 'Home', link: '/home' },
    { text: 'Manage Posts', link: '/posts' },
    { text: 'Manage Members', link: '/users' },
    { text: 'Manage Payments', link: '/payments' },
    { text: 'Manage Reports', link: '/subscription-reports' },
    { text: 'Manage Plans', link: '/plans' },
    { text: 'Manage Subscribers', link: '/subscribers' },
    { text: 'Manage Meetings', link: '/meetings' },
    { text: 'Manage Appointments', link: '/appointments' },
    { text: 'Manage Events', link: '/events' },
    { text: 'Manage Course', link: '/courses' },
    { text: 'Communities', link: '/communities' },
    { text: 'About Community', link: link },
    { text: 'Invite Members', link: '/invitations' },
  ];

  const subPlansMenu = [
    { text: 'Add Plan', link: '/create-plan' },
    { text: 'Plans', link: '/plans' },
  ];
  const subSubscribersMenu = [
    { text: 'Add Subscriber', link: '/create-subscription' },
    { text: ' Subscribers', link: '/subscribers' },
  ];

  const subMeetingsMenu = [
    { text: 'Add Meeting', link: '/create-meeting' },
    { text: 'Meetings', link: '/meetings' },
  ];

  const subAppointmentsMenu = [
    { text: 'Add Appointment', link: '/add-appointment' },
    { text: 'Appointments', link: '/appointments' },
  ];

  const subEventsMenu = [
    { text: 'Add Event', link: '/add-event' },
    { text: 'Events', link: '/events' },
  ];


  // const subCoursesMenu = [
  //   { text: 'Create Course', link: '/courses/create' },
  //   { text: 'Edit Course', link: '/courses/edit' },
  //   { text: 'View Courses', link: '/courses/view' },
  // ];

  // const subCommunitiesMenu = [
  //   { text: 'Create Community', link: '/communities/create' },
  //   { text: 'Edit Community', link: '/communities/edit' },
  //   { text: 'View Communities', link: '/communities/view' },
  // ];


  // Combine menu items and icons into a single array
  const combinedItems = allMenuItems.map((item, index) => ({
    ...item,
    icon: icons[index],
  }));

  // Filter out the "Manage Plans" item if collectSubscription is "NO"
  const filteredItems =
    selectedCommunity?.collectSubscription === 'NO'
      ? combinedItems.filter((item) => item.text !== 'Manage Plans')
      : combinedItems;

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const location = useLocation();

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300,
        p: 2,
      }}
      role="presentation"
    >
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ alignItems: 'center' }}
      >
        <Link
          to="/home"
          style={{ textDecoration: 'none' }}
          onClick={toggleDrawer(anchor, false)}
        >
          <Box
            component={'img'}
            src={selectedCommunity?.logo}
            alt="Dashboard Logo"
            sx={{ width: '100%', objectFit: 'contain', height: '75px', borderRadius: '8px' }}
          />
        </Link>
        <IconButton
          onClick={toggleDrawer(anchor, false)}
          sx={{ border: '1px solid #515151 ' }}
        >
          <X size={20} strokeWidth={1.5} />

        </IconButton>
      </Stack>
      <List>
        {filteredItems.map((item) =>
          item.text === 'Manage Plans' ? (
            <React.Fragment key={item.text}>
              <ListItem disablePadding sx={{}}>
                <ListItemButton onClick={() => setOpenPlans(!openPlans)}>
                  <ListItemIcon
                    sx={{
                      minWidth: '35px',
                      color:
                        location.pathname === item.link
                          ? '#3C5AA0'
                          : '#515151',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '12px',
                        color:
                          location.pathname === item.link
                            ? '#3C5AA0'
                            : '#515151',
                        fontFamily: 'Montserrat',
                      },
                    }}
                  />
                  {openPlans ? <ChevronUp size={20} strokeWidth={1.5} /> : <ChevronDown size={20} strokeWidth={1.5} />}
                </ListItemButton>
              </ListItem>
              <Collapse in={openPlans} timeout="auto" unmountOnExit >
                <List component="div" disablePadding>
                  {subPlansMenu.map((subItem) => (
                    <ListItem key={subItem.text} disablePadding sx={{ fontFamily: 'Montserrat !important', }}>
                      <ListItemButton
                        component={Link}
                        to={subItem.link}
                        sx={{ pl: 4 }}
                        onClick={toggleDrawer(anchor, false)}
                      >
                        <ListItemText
                          primary={subItem.text}
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: '12px',
                              color:
                                location.pathname === subItem.link
                                  ? '#3C5AA0'
                                  : '#777777',
                              fontFamily: 'Montserrat !important',

                            },

                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <Divider />
            </React.Fragment>
          ) : item.text === 'Manage Subscribers' ? (
            <React.Fragment key={item.text}>
              <ListItem disablePadding sx={{}}>
                <ListItemButton onClick={() => setOpenSubscribers(!openSubscribers)}>
                  <ListItemIcon
                    sx={{
                      minWidth: '35px',
                      color:
                        location.pathname === item.link
                          ? '#3C5AA0'
                          : '#515151',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '12px',
                        color:
                          location.pathname === item.link
                            ? '#3C5AA0'
                            : '#515151',
                        fontFamily: 'Montserrat',
                      },
                    }}
                  />
                  {openSubscribers ? <ChevronUp size={20} strokeWidth={1.5} /> : <ChevronDown size={20} strokeWidth={1.5} />}
                </ListItemButton>
              </ListItem>
              <Collapse in={openSubscribers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ fontFamily: 'Montserrat !important', }}>
                  {subSubscribersMenu.map((subItem) => (
                    <ListItem key={subItem.text} disablePadding>
                      <ListItemButton
                        component={Link}
                        to={subItem.link}
                        sx={{ pl: 4 }}
                        onClick={toggleDrawer(anchor, false)}
                      >
                        <ListItemText
                          primary={subItem.text}
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: '12px',
                              color:
                                location.pathname === subItem.link
                                  ? '#3C5AA0'
                                  : '#777777',
                              fontFamily: 'Montserrat',
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <Divider />
            </React.Fragment>
          ) :
            item.text === 'Manage Meetings' ? (
              <React.Fragment key={item.text}>
                <ListItem disablePadding sx={{}}>
                  <ListItemButton onClick={() => setOpenMeetings(!openMeetings)}>
                    <ListItemIcon
                      sx={{
                        minWidth: '35px',
                        color:
                          location.pathname === item.link
                            ? '#3C5AA0'
                            : '#515151',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: '12px',
                          color:
                            location.pathname === item.link
                              ? '#3C5AA0'
                              : '#515151',
                          fontFamily: 'Montserrat',
                        },
                      }}
                    />
                    {openMeetings ? <ChevronUp size={20} strokeWidth={1.5} /> : <ChevronDown size={20} strokeWidth={1.5} />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={openMeetings} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {subMeetingsMenu.map((subItem) => (
                      <ListItem key={subItem.text} disablePadding>
                        <ListItemButton
                          component={Link}
                          to={subItem.link}
                          sx={{ pl: 4 }}
                          onClick={toggleDrawer(anchor, false)}
                        >
                          <ListItemText
                            primary={subItem.text}
                            sx={{
                              '& .MuiTypography-root': {
                                fontSize: '12px',
                                color:
                                  location.pathname === subItem.link
                                    ? '#3C5AA0'
                                    : '#777777',
                                fontFamily: 'Montserrat',
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
                <Divider />
              </React.Fragment>
            ) :
              item.text === 'Manage Appointments' ? (
                <React.Fragment key={item.text}>
                  <ListItem disablePadding sx={{}}>
                    <ListItemButton onClick={() => setOpenAppointments(!openAppointments)}>
                      <ListItemIcon
                        sx={{
                          minWidth: '35px',
                          color:
                            location.pathname === item.link
                              ? '#3C5AA0'
                              : '#515151',
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{
                          '& .MuiTypography-root': {
                            fontSize: '12px',
                            color:
                              location.pathname === item.link
                                ? '#3C5AA0'
                                : '#515151',
                            fontFamily: 'Montserrat',
                          },
                        }}
                      />
                      {openAppointments ? <ChevronUp size={20} strokeWidth={1.5} /> : <ChevronDown size={20} strokeWidth={1.5} />}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={openAppointments} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {subAppointmentsMenu.map((subItem) => (
                        <ListItem key={subItem.text} disablePadding>
                          <ListItemButton
                            component={Link}
                            to={subItem.link}
                            sx={{ pl: 4 }}
                            onClick={toggleDrawer(anchor, false)}
                          >
                            <ListItemText
                              primary={subItem.text}
                              sx={{
                                '& .MuiTypography-root': {
                                  fontSize: '12px',
                                  color:
                                    location.pathname === subItem.link
                                      ? '#3C5AA0'
                                      : '#777777',
                                  fontFamily: 'Montserrat',
                                },
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                  <Divider />
                </React.Fragment>
              ) :
                item.text === 'Manage Subscribers' ? (
                  <React.Fragment key={item.text}>
                    <ListItem disablePadding sx={{}}>
                      <ListItemButton onClick={() => setOpenSubscribers(!openSubscribers)}>
                        <ListItemIcon
                          sx={{
                            minWidth: '35px',
                            color:
                              location.pathname === item.link
                                ? '#3C5AA0'
                                : '#515151',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.text}
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: '12px',
                              color:
                                location.pathname === item.link
                                  ? '#3C5AA0'
                                  : '#515151',
                              fontFamily: 'Montserrat',
                            },
                          }}
                        />
                        {openSubscribers ? <ChevronUp size={20} strokeWidth={1.5} /> : <ChevronDown size={20} strokeWidth={1.5} />}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={openSubscribers} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {subSubscribersMenu.map((subItem) => (
                          <ListItem key={subItem.text} disablePadding>
                            <ListItemButton
                              component={Link}
                              to={subItem.link}
                              sx={{ pl: 4 }}
                              onClick={toggleDrawer(anchor, false)}
                            >
                              <ListItemText
                                primary={subItem.text}
                                sx={{
                                  '& .MuiTypography-root': {
                                    fontSize: '12px',
                                    color:
                                      location.pathname === subItem.link
                                        ? '#3C5AA0'
                                        : '#515151',
                                    fontFamily: 'Montserrat',
                                  },
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                    <Divider />
                  </React.Fragment>
                ) : item.text === 'Manage Events' ? (
                  <React.Fragment key={item.text}>
                    <ListItem disablePadding sx={{}}>
                      <ListItemButton onClick={() => setOpenEvents(!openEvents)}>
                        <ListItemIcon
                          sx={{
                            minWidth: '35px',
                            color:
                              location.pathname === item.link
                                ? '#3C5AA0'
                                : '#515151',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.text}
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: '12px',
                              color:
                                location.pathname === item.link
                                  ? '#3C5AA0'
                                  : '#515151',
                              fontFamily: 'Montserrat',
                            },
                          }}
                        />
                        {openEvents ? <ChevronUp size={20} strokeWidth={1.5} /> : <ChevronDown size={20} strokeWidth={1.5} />}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={openEvents} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {subEventsMenu.map((subItem) => (
                          <ListItem key={subItem.text} disablePadding>
                            <ListItemButton
                              component={Link}
                              to={subItem.link}
                              sx={{ pl: 4 }}
                              onClick={toggleDrawer(anchor, false)}
                            >
                              <ListItemText
                                primary={subItem.text}
                                sx={{
                                  '& .MuiTypography-root': {
                                    fontSize: '12px',
                                    color:
                                      location.pathname === subItem.link
                                        ? '#3C5AA0'
                                        : '#777777',
                                    fontFamily: 'Montserrat',
                                  },
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                    <Divider />
                  </React.Fragment>
                ) :
                  (
                    <React.Fragment key={item.text}>
                      <ListItem disablePadding sx={{}}>
                        <ListItemButton
                          component={Link}
                          to={item.link}
                          onClick={toggleDrawer(anchor, false)}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: '35px',
                              color:
                                location.pathname === item.link
                                  ? '#3C5AA0'
                                  : '#515151',
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            sx={{
                              '& .MuiTypography-root': {
                                fontSize: '12px',
                                color:
                                  location.pathname === item.link
                                    ? '#3C5AA0'
                                    : '#777777',
                                fontFamily: 'Montserrat',
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  )
        )}
      </List>
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ mt: 10 }}
        alignItems={'center'}
      >
        <Link to="/explore-communities" style={{ textDecoration: 'none' }}>
          <Button
            variant="outlined"
            sx={{
              color: '#50A1CA',
              border: '1px solid #50A1CA',
              borderRadius: '30px',
              fontSize: '14px',
              textTransform: 'capitalize ',
              fontWeight: '400',
              fontFamily: 'Montserrat'
            }}
            startIcon={
              <Globe size={20} strokeWidth={1.5} />
            }
          >
            Explore
          </Button>
        </Link>
      </Stack>
    </Box>
  );

  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} sx={{ p: 0 }}>
            <MenuIcon sx={{ color: '#92929D', p: 0 }} />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
