import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Button, Dialog, DialogActions, DialogProps, DialogTitle, Divider, Stack } from '@mui/material';
import { EditIcon } from '../../../assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import store from '../../../store';
import { useSelector } from 'react-redux';
import { logoutService } from '../../../services/logOut.service';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Close } from '@mui/icons-material';




const NewHeader = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [opens, setOpens] = React.useState(false);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const navigate = useNavigate();
    const location = useLocation();


    const state = store.getState();
    const loggedInUserData = useSelector(() => {
        return state?.loggedInUser;
    });

    // console.log(loggedInUserData, "loggedInUserData")

    // const cachedProgressData = useSelector(
    //   (state: any) => state.selectedCommunity.selectedCommunityProgress
    // );

    const usersData = store.getState();
    const [userId] = React.useState(usersData.loggedInUser.user?.id);


    const handleLogout = async () => {
        const success = await logoutService();
        if (success) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            window.location.reload();
        } else {
            console.error('Logout failed, unable to navigate to login.');
        }
    };

    const handleClickOpens = () => {
        setOpens(true);
    };

    const handleCloses = () => {
        setOpens(false);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none', p: 1 }}>
            <Container maxWidth="xl" >
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Box
                        ml={{ xs: 0, md: 0 }}
                        mt={1}
                        component={'img'}
                        src={require('../../../assets/images/Logo.png')}
                        alt=""
                        sx={{ width: '193px', }}
                    />
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={loggedInUserData?.user?.firstName} src={loggedInUserData?.user?.avatar} />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={loggedInUserData?.user?.firstName} src={loggedInUserData?.user?.avatar} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem
                                onClick={() => navigate(`/profile-edit/${userId}`)}
                                sx={{
                                    minHeight: '0px',
                                    p: 1,
                                    mx: 2,
                                    my: 0.5,
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: location.pathname === `/profile-edit/${userId}` ? '#EAF2FF' : 'transparent',
                                }}
                            >
                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ color: '#575757', fontWeight: '400' }}
                                // onClick={handleProfileClick}
                                >
                                    <EditIcon
                                        sx={{
                                            fontSize: '19px',
                                            fontFamily: 'Montserrat',
                                            color:
                                                location.pathname === `/profile/${userId}`
                                                    ? '#3C5AA0'
                                                    : '#575757',
                                        }}
                                    >
                                        {' '}
                                    </EditIcon>
                                    <Typography
                                        sx={{
                                            fontSize: '13px',
                                            fontFamily: 'Montserrat',
                                            color:
                                                location.pathname === `/profile-edit/${userId}`
                                                    ? '#3C5AA0'
                                                    : '#575757',
                                        }}
                                    >
                                        Edit Profile
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem
                                onClick={handleClickOpens}
                                sx={{
                                    minHeight: '0px',
                                    p: 1,
                                    mx: 2,
                                    my: 0.5,
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',

                                }}
                            >
                                <Stack
                                    direction="row"
                                    gap={1}
                                    alignItems="center"
                                    sx={{ color: '#575757', fontWeight: '400' }}
                                >
                                    <ExitToAppIcon sx={{ fontSize: '19px', color: '#575757', }} />
                                    <Typography
                                        sx={{ fontSize: '13px', color: '#575757', fontFamily: 'Montserrat' }}
                                    >
                                        Logout
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        </Menu>
                        <Dialog maxWidth={maxWidth} open={opens} onClose={handleCloses}>
                            <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                                <Stack
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    sx={{ backgroundColor: '#F0F9FF' }}
                                >
                                    <Stack></Stack>
                                    <Stack></Stack>
                                    <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                                        <Box
                                            onClick={handleCloses}
                                            sx={{
                                                backgroundColor: '#50A1CA',
                                                padding: '4px',
                                                borderRadius: '50%',
                                                width: '20px',
                                                height: '20px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'white',
                                                fontSize: '10px',
                                                mt: '0.5px',
                                                mr: '10px',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    // backgroundColor: "#50A1CA", // Background color on hover
                                                    cursor: 'pointer',
                                                    border: 'none',
                                                },
                                            }}
                                        >
                                            <Close sx={{ fontSize: '15px' }} />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </DialogActions>
                            <DialogTitle>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        fontFamily: 'Montserrat',
                                        // color: "red",
                                    }}
                                >
                                    Are you sure you want to logout?
                                </Typography>
                            </DialogTitle>
                            <Divider />

                            <Stack
                                direction="row"
                                sx={{ alignItems: 'center', justifyContent: 'center' }}
                            >
                                <DialogActions>
                                    <Button
                                        size="small"
                                        onClick={handleLogout}
                                        sx={{
                                            cursor: 'pointer',
                                            pr: 1,
                                            fontFamily: 'Montserrat',
                                            textTransform: 'capitalize',
                                            color: 'White',
                                            boxShadow: 'none',
                                            backgroundColor: ' #3C5AA0',
                                            '&:hover': {
                                                backgroundColor: ' #3C5AA0',
                                                boxShadow: 'none',
                                            }
                                        }}
                                    >
                                        Yes
                                    </Button>
                                    {/* <Divider orientation="vertical" style={{ marginLeft: 25 }} /> */}
                                </DialogActions>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        cursor: 'pointer',
                                        textTransform: 'capitalize',
                                        color: '#3C5AA0',
                                        fontFamily: 'Montserrat',
                                        border: '1px solid #3C5AA0',
                                    }}
                                    onClick={handleCloses}
                                >
                                    No
                                </Button>
                            </Stack>
                        </Dialog>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

    );
}
export default NewHeader;