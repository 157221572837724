import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { usePlans } from '../../../hooks/usePlans';
import { useBankAccounts } from '../../../hooks/useBankAccounts';
import { useInvitations } from '../../../hooks/useInvitations';
import { metaConfig } from '../../../utils/constants';
import LinkCards from './LinkCards';
import CommunitySteps from './CommunitySteps';
import Confetti from 'react-confetti';
import store from '../../../store';
import { useEffect } from 'react';


const GetStarted = () => {


    const communitySelected = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );

    // console.log(communitySelected, "communitySelected")

    const community_id = communitySelected?._id;

    const { getPlansList } = usePlans();
    const { getBankDetailById } = useBankAccounts();
    const { getInviteList } = useInvitations();
    const [progress, setProgress] = React.useState<number>(0);


    // console.log(progress, "progress")

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                if (community_id) {
                    const plans_data = await getPlansList(community_id);
                    if (communitySelected?.status === 'CREATED') {
                        setProgress((prevProgress) => prevProgress + 10);
                    }
                    if (communitySelected?.status === 'PUBLISHED') {
                        setProgress((prevProgress) => prevProgress + 40);
                    }
                    if (plans_data && plans_data?.length > 0) {
                        setProgress((prevProgress) => prevProgress + 30);
                    }
                    const response = await getBankDetailById(community_id);
                    if (response && response.data?.length > 0) {
                        setProgress((prevProgress) => prevProgress + 25);
                    }
                    const invitesList = await getInviteList(community_id);
                    if (invitesList && invitesList.data?.length > 0) {

                        setProgress((prevProgress) => prevProgress + 5);
                    }
                }
            } catch (error) {
                console.error('Error fetching invite list:', error);
            }
        };

        fetchDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const usersData = store.getState();


    return (
        <>

            <Helmet>
                <title>{metaConfig.title.createCommunity}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>
            <Confetti numberOfPieces={200} recycle={false} initialVelocityY={5} />
            <Box sx={{ px: { xs: 0, md: 0 }, py: 2 }}>
                <Stack>
                    <Stack sx={{ backgroundColor: 'white', p: 2, borderRadius: '12px', border: '1px solid #E7EBF1' }}>
                        <Stack direction={'row'} spacing={3} alignContent={'center'} alignItems={'center'}>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress
                                    variant="determinate"
                                    value={progress}
                                    size={70}
                                    color="success"
                                />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            fontFamily: 'Montserrat'
                                        }}
                                    >{`${progress}%`}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        color: '#000000',
                                        fontWeight: '600',
                                        fontSize: {
                                            xs: '15px',
                                            sm: '15px',
                                            md: '18px',
                                            lg: '18px',
                                        },
                                    }}
                                >
                                    👋🏻 Hey {usersData?.loggedInUser?.user?.firstName}, Complete your company profile
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: 1,
                                        color: '#646464',
                                        fontSize: '14px',
                                        fontFamily: 'Montserrat',
                                        display: {
                                            xs: 'none',
                                            sm: 'none',
                                            md: 'none',
                                            lg: 'block',
                                        },
                                    }}
                                >
                                    Complete your profile to unlock the full potential of your community experience.
                                </Typography>
                            </Box>
                        </Stack>
                    </Stack>

                    <Stack sx={{ backgroundColor: 'white', borderRadius: '6px', mt: 1 }}>
                        <CommunitySteps />
                    </Stack>

                    <Stack
                        sx={{
                            backgroundColor: 'white',
                            mt: 1,
                            overflow: 'auto',
                            borderRadius: '12px',
                            border: '1px solid #E7EBF1'
                        }}
                    >
                        <Stack
                            alignContent={'center'}
                            justifyContent={'center'}
                            sx={{ p: 2 }}
                        >
                            <LinkCards />
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </>
    );
};
export default GetStarted;
