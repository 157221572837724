import React, { useContext, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Avatar, Box, Divider, Tooltip } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { AuthContext, IAuthContext } from '../../../../contexts/Auth.context';
import {
  favCommunity,
  favJoinedCommunity,
} from '../../../../services/communities.service';
import { useSnackbar } from 'notistack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSelector } from 'react-redux';
import BlockIcon from '@mui/icons-material/Block';
interface ICardProps {
  data: any;
  value: number;
  handleFavUpdate: () => void;
  refresh: boolean;
}

const SearchCommunityCard = (props: ICardProps) => {
  const { data, value } = props;
  const loggedInUserData = useSelector((state: any) => state?.loggedInUser);
  // console.log(data, "data")
  const [animationStyles, setAnimationStyles] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  // const { toggleCommunityFavorite } = useCommunity();
  // const isFavourited =
  //   value === 0 ? data?.favorite : data?.community?.favorite ;
  const [favHeartTouch, setFavHeartTouch] = useState(
    data?.favorite || data?.community?.favorite
  );

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);

  const cardLogo = value === 0 ? `${data?.logo}` : `${data?.community?.logo}`;

  // const handleHeartClick = async (e: any) => {
  //   e.stopPropagation();
  //   try {
  //     const idValue = value === 0 ? data?._id : data?.community?._id;
  //     const response = await favCommunity(
  //       getAccessToken(),
  //       idValue,
  //       !favHeartTouch
  //     );
  //     if (response.status === 200) {
  //       // Update the favorite status in the state
  //       setFavHeartTouch(!favHeartTouch);
  //       setAnimationStyles({
  //         transform: "scale(1.5)",
  //         opacity: 0.8,
  //       });

  //       const message = favHeartTouch
  //         ? "Unmarked community as favorite"
  //         : "Marked community as favorite";
  //       enqueueSnackbar(message, { variant: "success" });
  //       setTimeout(() => {
  //         setAnimationStyles({});
  //       }, 1000);
  //       // refresh the communities cache
  //       handleFavUpdate();
  //     } else {
  //       console.error("Failed to update favorite status");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while updating favorite status", error);
  //   }
  // };
  const handleHeartClick = async (e: any) => {
    e.stopPropagation();
    try {
      // Check if the logged-in user ID matches the created by ID
      if (loggedInUserData?.user?.id === data?.createdBy?._id) {
        // Call the API for the favorite community
        const response = await favCommunity(
          getAccessToken(),
          data._id,
          !favHeartTouch
        );
        // console.log(response);
        if (response.status === 200) {
          // Update the favorite status in the state
          setFavHeartTouch(!favHeartTouch);

          setAnimationStyles({
            transform: 'scale(1.5)',
            opacity: 0.8,
          });
          const message = favHeartTouch
            ? 'Unmarked community as favorite'
            : 'Marked community as favorite';
          enqueueSnackbar(message, { variant: 'success' });
          setTimeout(() => {
            setAnimationStyles({});
          }, 1000);
          // handleFavUpdate()
        } else {
          console.error('Failed to update favorite status for the community');
        }
      } else {
        // Call the API for the joined community
        const response = await favJoinedCommunity(
          getAccessToken(),
          data?.community?._id,
          !favHeartTouch
        );
        // console.log(response);
        if (response.status === 200) {
          // Update the favorite status in the state
          setFavHeartTouch(!favHeartTouch);
          setAnimationStyles({
            transform: 'scale(1.5)',
            opacity: 0.8,
          });
          const message = favHeartTouch
            ? 'Unmarked community as favorite'
            : 'Marked community as favorite';
          enqueueSnackbar(message, { variant: 'success' });
          setTimeout(() => {
            setAnimationStyles({});
          }, 1000);
          // handleFavUpdate();
        } else {
          console.error(
            'Failed to update favorite status for the joined community'
          );
        }
      }
    } catch (error) {
      console.error('An error occurred while updating favorite status', error);
    }
  };

  // const handleClickData = (data: any) => {
  //   // console.log("datas", data?.community);
  // };

  // const isPublished = data?.status === "PUBLISHED" || data?.community?.status ==="PUBLISHED";

  // console.log(data)

  const isPublished =
    data?.status === 'PUBLISHED' || data?.community?.status === 'PUBLISHED';
  const communityPublished =
    data?.community?.status === 'PUBLISHED' ||
    data?.community?.status === 'CREATED';

  const selectedCommunityName =
    data?.community?.name?.length > 30 || data?.title?.length > 30
      ? (data?.community?.name?.slice(0, 30) || data?.title?.slice(0, 30)) +
      '...'
      : data?.community?.name || data?.title;

  const selectedCommunityNameMobile =
    data?.community?.name?.length > 10 || data?.title?.length > 10
      ? (data?.community?.name?.slice(0, 10) || data?.title?.slice(0, 10)) +
      '...'
      : data?.community?.name || data?.title;

  return (
    <Box>
      {/* {(data.status === "PUBLISHED" || data?.status === "ACTIVE") && ( // Check for status and isActive */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            pointerEvents: data?.status === 'BLOCK' ? 'none' : 'auto',
            opacity: data?.status === 'BLOCK' ? 0.5 : 1,
          }}
        // onClick={() => handleClickData(data)}
        >
          <Avatar
            sx={{
              minWidth: { xs: '5vh', md: '6vh' },
              minHeight: { xs: '5vh', md: '6vh' },
              cursor: 'pointer',
              borderRadius: '5px',
              border: '1px solid #E0E0E0',
              padding: '2px',
            }}
            src={cardLogo}
          />
          <CardContent sx={{ marginLeft: '1vw', width: '100%' }}>
            <Typography
              sx={{
                display: { xs: 'none', md: 'block' },
                fontSize: { xs: '12px', md: '13px' },
                fontWeight: '500',
                cursor: 'pointer',
                color: '#3C5AA0',
                fontFamily: 'Montserrat',
              }}
            >
              {selectedCommunityName}
              <FiberManualRecordIcon
                sx={{
                  color: communityPublished
                    ? '#ffffff'
                    : isPublished
                      ? '#27ae1b'
                      : '#ff4f00',
                  fontSize: '13px',
                  paddingTop: '5px',
                }}
              />
              {/* {isPublished ? (
                <FiberManualRecordIcon sx={{ color: "#27ae1b", fontSize:'13px', paddingTop:'5px'}} />
              ) : (
                <FiberManualRecordIcon sx={{ color: "#ff4f00",fontSize:'13px',paddingTop:'5px' }} />
              )} */}
            </Typography>

            <Typography
              sx={{
                display: { xs: 'block', md: 'none' },
                fontSize: { xs: '12px', md: '13px' },
                fontWeight: '500',
                cursor: 'pointer',
                color: '#3C5AA0',
                fontFamily: 'Montserrat',
              }}
            >
              {selectedCommunityNameMobile}
              <FiberManualRecordIcon
                sx={{
                  color: communityPublished
                    ? '#ffffff'
                    : isPublished
                      ? '#27ae1b'
                      : '#ff4f00',
                  fontSize: '13px',
                  paddingTop: '5px',
                }}
              />
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '10px', md: '10px' },
                cursor: 'pointer',
                color: '#1A1A1A',
                fontFamily: 'Montserrat',
              }}
            >
              {data.city || data?.community?.city}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '10px', md: '10px' },
                color: '#777777',
                fontFamily: 'Montserrat',
              }}
            >
              {data?.totalMembers || data?.community?.members?.length} Members
            </Typography>
            {((data?.subscriptionStatus === "CREATED" || data?.subscriptionStatus === "EXPIRED") && data?.community?.collectSubscription === "YES") ? (
              <Typography
                sx={{ fontSize: { xs: "10px", md: "10px" }, color: "#777777", fontFamily: 'Montserrat', }}
              >
                Subscribe to unlock community access
              </Typography>
            ) : (
              <>
              </>
            )
            }
          </CardContent>
        </Box>
        <Box>
          {data?.status === 'BLOCK' ? (
            <Tooltip
              title="You are blocked for this community"
              placement="left"
            >
              <BlockIcon
                color="disabled"
                sx={{
                  marginRight: { xs: '0vw', md: '1.5vw' },
                  cursor: 'not-allowed',
                }}
              />
            </Tooltip>
          ) : (
            <IconButton
              style={animationStyles}
              onClick={handleHeartClick}
              sx={{
                '&:hover': {
                  backgroundColor: '#ffffff',
                  cursor: 'pointer',
                },
              }}
            >
              {favHeartTouch ? (
                <FavoriteIcon
                  sx={{
                    color: 'red',
                    fontSize: { xs: '20px', md: '30px' },
                    marginRight: { xs: '0vw', md: '1vw' },
                  }}
                />
              ) : (
                <FavoriteBorderIcon
                  sx={{
                    color: '#D9D9D9',
                    fontSize: { xs: '20px', md: '30px' },
                    marginRight: { xs: '0vw', md: '1vw' },
                  }}
                />
              )}
            </IconButton>
          )}
        </Box>
      </Box>
      {/* )}
      {(data.status === "PUBLISHED" || data?.status === "ACTIVE") && ( */}
      <Divider sx={{ mt: -1 }} />
      {/* )} */}
    </Box>
  );
};

export default SearchCommunityCard;
