import { Helmet } from "react-helmet";
import { metaConfig } from "../../utils/constants";
import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, FormControl, Grid, InputAdornment, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePlans } from "../../hooks/usePlans";
import GooglesIcon from "../../assets/icons/svg-icons/googlemeet.svg"
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { useAppointments } from "../../hooks/useAppointments";
import stars from '../../assets/lotties/stars.json';
import Lottie from "react-lottie";
import { useNavigate, useParams } from "react-router-dom";
import { IPlan } from "../Plans/Plans";
import { CurrencyRupeeOutlined } from "@mui/icons-material";
import store from "../../store";
import { loadSelectedCommunity } from "../../store/slices/CommunitySlice";
import { useLiveClasses } from "../../hooks/useLiveClassess";
import { ICommunity } from "../../models/communities.modal";
import BreadcrumbDouble from "../AlternatePages/BreadCumsDouble";
import { IndianRupee, MapPin } from "lucide-react";




export interface IAvailableTime {
  startTime: string;
  endTime: string;
}

export interface IAvailability {
  day: string;
  availableTimes: IAvailableTime[];
}


// interface IAvailabilityPayload {
//     StartDate: string;
//     startWeek: string;
//     startTime: string;
//     endTime: string;
//     duration: string;
//     month: string;
// }

// interface IAvailabilityResponse {
//     message: string;
//     availability: {
//         day: string;
//         availableTimes: { startTime: string; endTime: string }[];
//     }[];
// }

interface Form {
  name: string;
  email: string;
  questions: string[];
}

export interface IAddAppointment {
  title: string;
  duration: string;
  pricing: string | number | null;
  description: string;
  community: string;
  plan: string | null;
  location: string;
  availability: IAvailability[];
  appointmentType: string;
  // form: Form;

}

export interface IAppointment {
  _id: string;
  name: string;
  description: string;
  instructorName: string;
  startDate: string;
  startTime: string;
  status: string;
  community: string;
  plan: string;
  coverImage: {
    _id: string;
    label: string;
    type: string;
    value: string;
  } | null;
  coverNote: {
    _id: string;
    label: string;
    type: string;
    value: string;
  } | null;

}

const AddAppointments = () => {

  const instantRef = useRef(null);
  const schedulingRef = useRef(null);
  const availabilityRef = useRef(null);
  const calenderRef = useRef(null);
  const bookingRef = useRef(null);
  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const { id } = useParams();

  const navigate = useNavigate()

  const { getCommunityPlansList } = usePlans();
  const [plansList, setPlansList] = useState<IAppointment[]>([]);
  const [planDetail] = useState<any>();
  const [selectedPlans, setSelectedPlans] = useState<any>(planDetail);
  const [durationFieldValue, setDurationFieldValue] = useState<string>('');
  // const [beforeTime, setBeforeTime] = useState<string>('10');
  // const [afterTime, setAfterTime] = useState<string>('10');
  // const [maxTime, setMaxTime] = useState<string>('10');
  // const [minTime, setMinTime] = useState<string>('10');
  const [locationType, setLocationType] = useState<'ONLINE' | 'offline'>('offline');
  const [priceType, setPriceType] = useState<'paid' | 0>('paid');
  const [selectedSection, setSelectedSection] = useState<string | null>("instant");
  const [selectedWeek, setSelectedWeek] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [openConnect, setOpenConnect] = useState(true)


  const scrollToSection = (ref: React.RefObject<HTMLDivElement>, section: string) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setSelectedSection(section);
    }
  };


  const { createAppointment, createAvailability, getAppointmentById, updateAppointmentById } = useAppointments();

  const [formValues, setFormValues] = useState<IAddAppointment>({
    title: '',
    community: selectedCommunityId,
    duration: '',
    location: '',
    plan: selectedPlans?._id,
    pricing: '',
    description: '',
    availability: [],
    appointmentType: '',
  });


  // console.log(formValues?.appointmentType, "formvalues")



  const handleDurationChange = (e: any) => {
    setFormValues({ ...formValues, duration: e });
  };

  const handleLocationTypeChange = (type: 'ONLINE' | 'offline') => {
    setLocationType(type);
    setFormValues((prev) => ({
      ...prev,
      location: type === 'ONLINE' ? 'ONLINE' : '',
    }));
  };


  useEffect(() => {
    if (locationType === 'ONLINE') {
      setFormValues((prevValues) => ({
        ...prevValues,
        location: 'ONLINE',
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        location: '',
      }));
    }
  }, [locationType]);

  const handlePriceTypeChange = (type: 'paid' | 0) => {
    setPriceType(type);
    setFormValues((prev) => ({
      ...prev,
      pricing: type === 0 ? 0 : null,
    }));
  };

  const durationDropdownOptions: { key: string; value: string; label: string }[] = [
    { key: '15 Minutes', value: '15', label: '15 Minutes' },
    { key: '30 Minutes', value: '30', label: '30 Minutes' },
    { key: '45 Minutes', value: '45', label: '45 Minutes' },
    { key: '1 Hour', value: '60', label: '1 Hour' },
    { key: '1 Hour 15 Minutes', value: '75', label: '1 Hour 15 Minutes' },
    { key: '1 Hour 30 Minutes', value: '90', label: '1 Hour 30 Minutes' },
    { key: '1 Hour 45 Minutes', value: '105', label: '1 Hour 45 Minutes' },
    { key: '2 Hour ', value: '120', label: '2 Hour' },
  ];

  const beforeTimeDropdown: { key: string; value: string; label: string }[] = [
    { key: '10 Minutes', value: '10', label: '10 Minutes' },
    { key: '15 Minutes', value: '15', label: '15 Minutes' },
    { key: '20 Minutes', value: '20', label: '20 Minutes' },
    { key: '25 Minutes ', value: '25', label: '25 Minutes' },
    { key: '30 Minutes ', value: '30', label: '30 Minutes' },
  ];

  const afterTimeDropdown: { key: string; value: string; label: string }[] = [
    { key: '10 Minutes', value: '10', label: '10 Minutes' },
    { key: '15 Minutes', value: '15', label: '15 Minutes' },
    { key: '20 Minutes', value: '20', label: '20 Minutes' },
    { key: '25 Minutes ', value: '25', label: '25 Minutes' },
    { key: '30 Minutes ', value: '30', label: '30 Minutes' },
  ];

  const maxTimeDropdown: { key: string; value: string; label: string }[] = [
    { key: '10 Minutes', value: '10', label: '10 Minutes' },
    { key: '15 Minutes', value: '15', label: '15 Minutes' },
    { key: '20 Minutes', value: '20', label: '20 Minutes' },
    { key: '25 Minutes ', value: '25', label: '25 Minutes' },
    { key: '30 Minutes ', value: '30', label: '30 Minutes' },
  ];

  const minTimeDropdown: { key: string; value: string; label: string }[] = [
    { key: '10 Minutes', value: '10', label: '10 Minutes' },
    { key: '15 Minutes', value: '15', label: '15 Minutes' },
    { key: '20 Minutes', value: '20', label: '20 Minutes' },
    { key: '25 Minutes ', value: '25', label: '25 Minutes' },
    { key: '30 Minutes ', value: '30', label: '30 Minutes' },
  ];


  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };



  useEffect(() => {
    if (!id) return;
    const loadAppointment = async () => {
      const data = await getAppointmentById(id || '');
      // console.log(data, 'data');
      const responseData = data[0];
      // console.log(responseData, "responseData")
      if (responseData) {
        // console.log(responseData?.appointmentType, "responseData")
        const isOnline = responseData.location === 'ONLINE';
        const isPrice = responseData.pricing === 0
        setFormValues((prev: any) => ({
          ...prev,
          title: responseData.title || '',
          description: responseData.description || '',
          pricing: isPrice ? 0 : responseData.pricing || 0,
          location: isOnline ? 'ONLINE' : responseData.location || '',
          duration: responseData?.duration || '',
          community: selectedCommunityId || '',
          plan: responseData?.plan || [],
          appointmentType: responseData?.appointmentType || '',
          availability: responseData?.availability || []
        }));
        setSelectedPlans(responseData?.plan);
        setDurationFieldValue(responseData?.duration);
        setLocationType(isOnline ? 'ONLINE' : 'offline');
        setPriceType(isPrice ? 0 : 'paid');
      }
    };
    loadAppointment();
  }, [id]);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsCreateLoading(true);

    const updateResponse = {
      ...formValues,
      title: formValues.title || '',
      description: formValues.description || '',
      duration: formValues.duration || '',
      pricing: formValues.pricing || 0,
      location: formValues.location || '',
      community: formValues.community || selectedCommunityId || '',
      plan: (selectedPlans || []).map((plan: IPlan) => plan?._id),
      availability: formValues.availability || [],
    };

    try {
      if (id) {
        await updateAppointmentById(updateResponse, id, selectedCommunityId);
      } else {
        await createAppointment(updateResponse);
      }
    } catch (error) {
      console.error('Error creating appointment:', error);
    } finally {
      setIsCreateLoading(false);
    }
  };
  const { getPlansList } = usePlans();

  const loadPlansList = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const plans_data = await getPlansList(selectedCommunityId);
        if (plans_data) {
          setIsLoading(false);
          setPlansList(plans_data);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log(plansList);
  useEffect(() => {
    loadPlansList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);

  const plansTitles = plansList ? plansList.map((plan) => plan?.name) : [];


  const dispatch = useDispatch();

  const communityState = store.getState();
  const currentCommunity = communityState?.selectedCommunity?.selectedCommunity;


  const handleAvailability = async (startWeek: any, startTime: string, endTime: string) => {
    const currentDate = new Date().toISOString().split('T')[0];
    const payload = {
      StartDate: currentDate,
      startWeek,
      startTime: startTime,
      endTime: endTime,
      duration: durationFieldValue,
      month: (new Date().getMonth() + 3).toString(),
      startBuffer: '0',
      endBuffer: '0'
    };
    try {
      const response = await createAvailability(payload);
      setSelectedWeek(startWeek);
      setFormValues((prevValues) => ({
        ...prevValues,
        availability: response?.data?.availability || [],
        appointmentType: startWeek,
        startTime,
        endTime,
      }));
      return response?.data?.availability;
    } catch (error) {
      console.error(`Error scheduling meeting:`, error);
    }
  };

  const { createGoogleAuth, } = useLiveClasses()

  const handleSubmitGenerateAuth = async () => {
    const communityId = currentCommunity?._id;
    try {
      const response = await createGoogleAuth(communityId || "");
      if (response?.data?.authUrl) {
        window.open(response.data.authUrl, "_blank");
      }
      if (response?.data?.authUrl) {
        if (currentCommunity !== null && currentCommunity !== undefined) {
          const communityData: ICommunity = currentCommunity;
          dispatch(loadSelectedCommunity(communityData));
          // console.log(communityData, "communityData")
        }
      }
      return response;
    } catch (err) {
      console.log(err, "error");
    }
  };


  // console.log(formValues?.appointmentType, selectedWeek, "formValues and selectedWeek");



  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: stars,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  const DataAvailable = formValues?.title && formValues?.description && formValues?.location && formValues?.duration && selectedWeek








  return (
    <>
      <Helmet>
        <title>{metaConfig.title.addAppointments}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      {/* <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'flex' }, pb: 1 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => {
          navigate('/appointments');
        }} />
        <Typography
          sx={{
            fontSize: { xs: '16px', md: '18px' },
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Montserrat',
          }}
        >
          {id ? "Edit Appointment" : "Create Appointment"}
        </Typography>
      </Stack> */}
      <BreadcrumbDouble
        items={[
          { label: 'Appointments', path: '/appointments' },
          { label: `${id ? "Edit Appointment" : "Create Appointment"}` }
        ]}
      />
      <Paper elevation={0} sx={{ borderRadius: '12px !important', border: '1px solid #E7EBF1', backgroundColor: '#ffffff', height: { xs: '100vh', md: 'fit-content' }, overflow: 'auto', mt: 1 }}>
        {/* Sticky Navigation Menu */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1000,
            borderBottom: '1px solid #E7EBF1',
            borderRadius: '12px 12px 0px 0px',
            px: 2,
            pt: 2,

          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={() => scrollToSection(instantRef, 'instant')}
              sx={{
                textTransform: 'capitalize',
                color: selectedSection === 'instant' ? '#3760A9' : '#000000',
                borderBottom: selectedSection === 'instant' ? '1px solid #3760A9' : 'transparent',
                fontFamily: 'Montserrat',
                borderRadius: '0px'

              }}
            >
              Instant Appointment
              <Lottie
                options={defaultOptions}
                height={25}
                width={25}
              />
            </Button>
            {/* <Button
                            onClick={() => scrollToSection(schedulingRef, 'scheduling')}
                            sx={{
                                textTransform: 'capitalize',
                                color: selectedSection === 'scheduling' ? '#3760A9' : '#000000',
                                borderBottom: selectedSection === 'scheduling' ? '1px solid #3760A9' : 'transparent',
                                backgroundColor: selectedSection === 'scheduling' ? '1px solid #3760A9' : 'transparent',
                                fontFamily: 'Montserrat',

                            }}
                        >
                            Scheduling
                        </Button> */}
            {/* <Button
                            onClick={() => scrollToSection(availabilityRef, 'availability')}
                            sx={{
                                textTransform: 'capitalize',
                                color: selectedSection === 'availability' ? '#ffffff' : '#000000',
                                backgroundColor: selectedSection === 'availability' ? '#1976d2' : 'transparent',
                                fontFamily: 'Montserrat',
                                '&:hover': {
                                    backgroundColor: selectedSection === 'availability' ? '#1565c0' : '#f5f5f5',
                                },
                            }}
                        >
                            Availability
                        </Button> */}
            {/* <Button
                            onClick={() => scrollToSection(calenderRef, 'calendar')}
                            sx={{
                                textTransform: 'capitalize',
                                color: selectedSection === 'calendar' ? '#ffffff' : '#000000',
                                backgroundColor: selectedSection === 'calendar' ? '#1976d2' : 'transparent',
                                fontFamily: 'Montserrat',
                                '&:hover': {
                                    backgroundColor: selectedSection === 'calendar' ? '#1565c0' : '#f5f5f5',
                                },
                            }}
                        >
                            Calendar
                        </Button> */}
            {/* <Button
                            onClick={() => scrollToSection(bookingRef, 'booking')}
                            sx={{
                                textTransform: 'capitalize',
                                color: selectedSection === 'booking' ? '#ffffff' : '#000000',
                                backgroundColor: selectedSection === 'booking' ? '#1976d2' : 'transparent',
                                fontFamily: 'Montserrat',
                                '&:hover': {
                                    backgroundColor: selectedSection === 'booking' ? '#1565c0' : '#f5f5f5',
                                },
                            }}
                        >
                            Booking Form
                        </Button> */}
          </Box>
        </Box>

        <Box sx={{ width: '100%', typography: '', fontFamily: 'Montserrat', p: 2 }}>
          {/* Instant Appointment Section */}
          <Box ref={instantRef} sx={{ mb: 2 }}>
            <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
              Details
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                  <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                    Appointment Title
                  </Typography>
                  <TextField
                    value={formValues?.title}
                    name="title"
                    label="Appointment title"
                    size="small"
                    onChange={handleChanges}
                    InputLabelProps={{ style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', }, }}
                    sx={{
                      mt: 1, mb: 1, fontSize: '13px', backgroundColor: '#F9F9F9', fontFamily: 'Montserrat',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                  <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                    Duration
                  </Typography>
                  <FormControl fullWidth size="small" sx={{ borderRadius: '8px' }}>
                    <Select
                      label="Duration"
                      labelId="demo-simple-select-label"
                      id="duration"
                      placeholder="Plan Duration"
                      name="duration"
                      sx={{
                        borderRadius: '8px',
                        backgroundColor: '#F9F9F9', mt: 1, mb: 1,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                        },
                      }}
                      onChange={(e) => {
                        setDurationFieldValue(e.target.value);
                      }}
                      value={durationFieldValue}
                    >
                      {durationDropdownOptions.map((option) => (
                        <MenuItem
                          key={option.key}
                          value={option.value}
                          onClick={(e) => handleDurationChange(option.value)}
                          sx={{ fontSize: '14px', fontFamily: 'Montserrat', }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                  <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                    Location
                  </Typography>
                  <Stack flexDirection={'row'}>
                    <Button
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        p: '7px 20px',
                        border: locationType === 'ONLINE' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                        borderRadius: '10px',
                        color: locationType === 'ONLINE' ? '#2653A3' : '#646464',
                        backgroundColor: locationType === 'ONLINE' ? '#D4DDED4D' : '#ffffff',
                        m: 1,
                        ml: 0,
                        textTransform: 'capitalize',
                      }}
                      onClick={() => handleLocationTypeChange('ONLINE')}
                    >
                      Online
                    </Button>

                    <Button
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        p: '7px 20px',
                        border: locationType === 'offline' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                        borderRadius: '10px',
                        color: locationType === 'offline' ? '#2653A3' : '#646464',
                        backgroundColor: locationType === 'offline' ? '#D4DDED4D' : '#ffffff',
                        m: 1,
                        ml: 0,
                        textTransform: 'capitalize',
                      }}
                      onClick={() => handleLocationTypeChange('offline')}
                    >
                      Offline
                    </Button>
                  </Stack>

                  <Stack flexDirection={"row"} gap={1.5} mt={1} alignItems={'center'}>
                    {locationType === 'ONLINE' ? (
                      <>
                        <Box
                          component={'img'}
                          src={GooglesIcon}
                          width={'20px'}
                          height={'20px'}
                        />
                        <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontFamily: 'Montserrat', }}>
                          Join the conference on Google Meet
                        </Typography>
                      </>
                    ) : (
                      <>
                        <TextField
                          fullWidth
                          value={formValues?.location || ''}
                          name="location"
                          label="Enter Location"
                          size="small"
                          onChange={handleChanges}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MapPin size={20} strokeWidth={1.5} />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                          }}
                          sx={{
                            fontSize: '13px', backgroundColor: '#F9F9F9', fontFamily: 'Montserrat',
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '8px',
                            },
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                  <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                    Pricing  (Optional)
                  </Typography>
                  {/* <Autocomplete
                                        options={['No Plans', 'Select All', ...plansTitles]}
                                        size="small"
                                        disablePortal
                                        id="combo-box-demo"
                                        multiple
                                        disableCloseOnSelect
                                        loading={isLoading}
                                        sx={{ mt: 1, fontSize: '12px', backgroundColor: 'white', fontFamily: 'Montserrat', }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Plans (By Default No Plans)"
                                                sx={{
                                                    fontFamily: 'Montserrat',
                                                    '& .MuiInputLabel-root': { fontSize: '13px', fontFamily: 'Montserrat', },
                                                    '& .MuiInputLabel-root.Mui-focused': { fontSize: '13px', fontFamily: 'Montserrat', },
                                                }}
                                            />
                                        )}
                                        ListboxProps={{
                                            sx: { fontSize: '13px', font: 'Roboto', borderRadius: '30px', fontFamily: 'Montserrat', },
                                        }}
                                        noOptionsText="No Plans"
                                        value={(selectedPlans || []).map((plan: any) => (typeof plan === 'string' ? plan : plan?.name))} // Ensure selectedPlans is an array
                                        onChange={(event, newValue) => {
                                            if (newValue.includes('Select All')) {
                                                if ((selectedPlans || []).length === (plansList || []).length) {
                                                    setSelectedPlans([]);
                                                } else {
                                                    setSelectedPlans(plansList || []);
                                                }
                                            } else if (newValue.includes('No Plans')) {
                                                setSelectedPlans(['No Plans']);
                                            } else {
                                                const selectedPlansObjects = (plansList || []).filter((plan) =>
                                                    newValue.includes(plan?.name || plan)
                                                );
                                                setSelectedPlans(selectedPlansObjects || []);
                                            }
                                        }}

                                        renderOption={(props, option, { selected }) => {
                                            const isSelectAll = option === 'Select All';
                                            const isAllSelected = (selectedPlans?.length || 0) === (plansList?.length || 0);
                                            const isSomeSelected =
                                                selectedPlans?.length > 0 && selectedPlans?.length < (plansList?.length || 0);

                                            return (
                                                <li {...props}>
                                                    <Checkbox
                                                        checked={isSelectAll ? isAllSelected : selected}
                                                        indeterminate={isSelectAll ? isSomeSelected : false}
                                                        sx={{
                                                            marginRight: 1,
                                                            padding: 0,
                                                            color: '#646464',
                                                            fontFamily: 'Montserrat',
                                                            borderRadius: '0px',
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 18,
                                                                border: '1px solid #000000',
                                                                borderRadius: '4px',
                                                                color: isSelectAll && isAllSelected ? '#2653A3' : selected ? '#2653A3' : 'transparent',
                                                                backgroundColor: isSelectAll && isAllSelected ? '#e3f2fd' : selected ? '#e3f2fd' : 'transparent',
                                                            },
                                                            '&:hover .MuiSvgIcon-root': {
                                                                borderColor: '#1976d2',
                                                            },
                                                        }}
                                                    />
                                                    {option}
                                                </li>
                                            );
                                        }}
                                        renderTags={(value, getTagProps) => {
                                            const visiblePlans = value.slice(0, 2);
                                            const remainingPlansCount = value.length - 2;
                                            const noPlansSelected = value.includes('No Plans');

                                            return [
                                                ...visiblePlans.map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={noPlansSelected ? 'No Plans Selected' : option}
                                                        sx={{ fontSize: '12px', height: '3vh', fontFamily: 'Montserrat', }}
                                                    />
                                                )),
                                                remainingPlansCount > 0 && (
                                                    <Chip
                                                        key="remaining-plans"
                                                        label={`+${remainingPlansCount}`}
                                                        sx={{ fontSize: '12px', height: '3vh', fontFamily: 'Montserrat', }}
                                                    />
                                                ),
                                            ];
                                        }}
                                    /> */}


                  <Stack flexDirection={'row'} alignItems={'center'} mb={1} >
                    <Button
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        p: '7px 20px',
                        border: priceType === 'paid' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                        borderRadius: '10px',
                        color: priceType === 'paid' ? '#2653A3' : '#646464',
                        backgroundColor: priceType === 'paid' ? '#D4DDED4D' : '#ffffff',
                        m: 1,
                        ml: 0,
                        textTransform: 'capitalize',
                      }}
                      onClick={() => handlePriceTypeChange('paid')}
                    >
                      Paid
                    </Button>

                    <Button
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        p: '7px 20px',
                        border: priceType === 0 ? '1px solid #3760A9' : '1px solid #E7EBF1',
                        borderRadius: '10px',
                        color: priceType === 0 ? '#2653A3' : '#646464',
                        backgroundColor: priceType === 0 ? '#D4DDED4D' : '#ffffff',
                        m: 1,
                        ml: 0,
                        textTransform: 'capitalize',
                      }}
                      onClick={() => handlePriceTypeChange(0)}
                    >
                      Free
                    </Button>
                  </Stack>
                  <Stack>
                    {priceType === "paid" ? (
                      <TextField

                        fullWidth
                        value={formValues?.pricing}
                        name="pricing"
                        label="Enter Price"
                        size="small"
                        onChange={handleChanges}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IndianRupee size={20} strokeWidth={1.5} />
                            </InputAdornment>
                          ),
                          inputProps: {
                            pattern: "[0-9]*",
                            inputMode: "numeric",
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                        }}
                        sx={{
                          fontSize: '13px', backgroundColor: '#F9F9F9', fontFamily: 'Montserrat',
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          },
                        }}
                        onInput={(e: any) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                      />
                    ) : (
                      <>
                        <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontFamily: 'Montserrat', }}>
                          No pricing for this appointment
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                  <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Montserrat', }}>
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    value={formValues?.description}
                    name="description"
                    label="Enter description"
                    size="small"
                    multiline
                    rows={4}
                    onChange={handleChanges}
                    InputLabelProps={{
                      style: { fontSize: '13px', color: '#646464', fontFamily: 'Montserrat', },
                    }}
                    sx={{
                      mt: 1, mb: 1, fontSize: '13px', backgroundColor: '#F9F9F9', fontFamily: 'Montserrat',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />

            <Stack sx={{ display: { xs: 'none', md: 'block' } }}>
              <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mt: 2 }}>
                Start Instant Meeting
              </Typography>
              <Stack flexDirection={'row'} alignItems={'center'} mt={1} >
                <Button
                  sx={{
                    fontSize: '11px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    p: '7px 20px',
                    border: formValues?.appointmentType === 'MF5' || selectedWeek === 'MF5' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                    borderRadius: '10px',
                    color: formValues?.appointmentType === 'MF5' || selectedWeek === 'MF5' ? '#2653A3' : '#646464',
                    backgroundColor: formValues?.appointmentType === 'MF5' || selectedWeek === 'MF5' ? '#D4DDED4D' : "#ffffff",
                    m: 1,
                    ml: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleAvailability('MF5', "05:00", "18:00")}
                >
                  Mon-Fri (05:00am - 06:00pm)
                </Button>

                <Button
                  sx={{
                    fontSize: '11px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    p: '7px 20px',
                    border: formValues?.appointmentType === 'MF' || selectedWeek === 'MF' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                    borderRadius: '10px',
                    color: formValues?.appointmentType === 'MF' || selectedWeek === 'MF' ? '#2653A3' : '#646464',
                    backgroundColor: formValues?.appointmentType === 'MF' || selectedWeek === 'MF' ? '#D4DDED4D' : "#ffffff",
                    m: 1,
                    ml: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleAvailability('MF', "09:00", "18:00")}
                >
                  Mon-Fri (09:00am - 06:00pm)
                </Button>
                <Button
                  sx={{
                    fontSize: '11px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    p: '7px 20px',
                    border: formValues?.appointmentType === 'MS5' || selectedWeek === 'MS5' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                    borderRadius: '10px',
                    color: formValues?.appointmentType === 'MS5' || selectedWeek === 'MS5' ? '#2653A3' : '#646464',
                    backgroundColor: formValues?.appointmentType === 'MS5' || selectedWeek === 'MS5' ? '#D4DDED4D' : "#ffffff",
                    m: 1,
                    ml: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleAvailability('MS5', "05:00", "18:00")}
                >
                  Mon-Sat (05:00am - 06:00pm)
                </Button>
                <Button
                  sx={{
                    fontSize: '11px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    p: '7px 20px',
                    border: formValues?.appointmentType === 'MS' || selectedWeek === 'MS' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                    borderRadius: '10px',
                    color: formValues?.appointmentType === 'MS' || selectedWeek === 'MS' ? '#2653A3' : '#646464',
                    backgroundColor: formValues?.appointmentType === 'MS' || selectedWeek === 'MS' ? '#D4DDED4D' : "#ffffff",
                    m: 1,
                    ml: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleAvailability('MS', "09:00", "18:00")}
                >
                  Mon-Sat (09:00am - 06:00pm)
                </Button>
                <Button
                  sx={{
                    fontSize: '11px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    p: '7px 20px',
                    border: formValues?.appointmentType === 'ALL' || selectedWeek === 'ALL' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                    borderRadius: '10px',
                    color: formValues?.appointmentType === 'ALL' || selectedWeek === 'ALL' ? '#2653A3' : '#646464',
                    backgroundColor: formValues?.appointmentType === 'ALL' || selectedWeek === 'ALL' ? '#D4DDED4D' : "#ffffff",
                    m: 1,
                    ml: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleAvailability('ALL', "09:00", "18:00")}
                >
                  All Day
                </Button>
                {/* <Button

                                    sx={{
                                        fontSize: '12px',
                                        fontFamily: 'Montserrat',
                                        fontWeight: '400',
                                        p: '7px 20px',
                                        border: '1px solid #E7EBF1',
                                        borderRadius: '10px',
                                        color: '#646464',
                                        backgroundColor: '#ffffff',
                                        m: 1,
                                        ml: 0,
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    Custom
                                </Button> */}
              </Stack>
              <Divider sx={{ mt: 2 }} />
            </Stack>

            <Stack sx={{ display: { xs: 'block', md: 'none' } }}>
              <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mt: 2 }}>
                Start Instant Meeting
              </Typography>
              <Stack flexDirection={'row'} alignItems={'center'} mt={1} >
                <Button
                  sx={{
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    p: '7px 20px',
                    border: formValues?.appointmentType === 'MF' || selectedWeek === 'MF' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                    borderRadius: '10px',
                    color: formValues?.appointmentType === 'MF' || selectedWeek === 'MF' ? '#2653A3' : '#646464',
                    backgroundColor: formValues?.appointmentType === 'MF' || selectedWeek === 'MF' ? '#D4DDED4D' : "#ffffff",
                    m: 1,
                    ml: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleAvailability('MF', "09:00", "18:00")}
                >
                  Mon-Fri(9-6)
                </Button>
                <Button
                  sx={{
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    p: '7px 20px',
                    border: formValues?.appointmentType === 'MS' || selectedWeek === 'MS' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                    borderRadius: '10px',
                    color: formValues?.appointmentType === 'MS' || selectedWeek === 'MS' ? '#2653A3' : '#646464',
                    backgroundColor: formValues?.appointmentType === 'MS' || selectedWeek === 'MS' ? '#D4DDED4D' : "#ffffff",
                    m: 1,
                    ml: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleAvailability('MS', "09:00", "18:00")}
                >
                  Mon-Sat(9-6)
                </Button>
                <Button
                  sx={{
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    p: '7px 20px',
                    border: formValues?.appointmentType === 'ALL' || selectedWeek === 'ALL' ? '1px solid #3760A9' : '1px solid #E7EBF1',
                    borderRadius: '10px',
                    color: formValues?.appointmentType === 'ALL' || selectedWeek === 'ALL' ? '#2653A3' : '#646464',
                    backgroundColor: formValues?.appointmentType === 'ALL' || selectedWeek === 'ALL' ? '#D4DDED4D' : "#ffffff",
                    m: 1,
                    ml: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleAvailability('ALL', "09:00", "18:00")}
                >
                  All Day
                </Button>
                {/* <Button

                                    sx={{
                                        fontSize: '10px',
                                        fontFamily: 'Montserrat',
                                        fontWeight: '400',
                                        p: '7px 20px',
                                        border: '1px solid #E7EBF1',
                                        borderRadius: '10px',
                                        color: '#646464',
                                        backgroundColor: '#ffffff',
                                        m: 1,
                                        ml: 0,
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    Custom
                                </Button> */}
              </Stack>
              <Divider sx={{ mt: 2 }} />
            </Stack>

          </Box>


          {/* Availability Section */}
          {/* <Box>
                        <Stack
                            sx={{ p: 0.5, borderRadius: '10px', backgroundColor: '#ffffff' }}>
                            <Accordion

                                sx={{ borderRadius: '10px', boxShadow: 'none' }}
                            >
                                <AccordionSummary

                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{
                                        border: 'none',
                                        boxShadow: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', }}>
                                        Advance Options
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        pl: 1,
                                        pr: 1,
                                        backgroundColor: '#f4f4f4',
                                        border: 'none',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <Box ref={schedulingRef} >
                                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                                            Scheduling
                                        </Typography>
                                        <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '400', fontFamily: 'Montserrat', mb: 2 }}>
                                            Buffer time
                                        </Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Inter' }}>
                                                        Before
                                                    </Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Select
                                                            label="Duration"
                                                            labelId="demo-simple-select-label"
                                                            id="duration"
                                                            placeholder="Plan Duration"
                                                            name="duration"
                                                            sx={{ backgroundColor: 'white', mt: 1, mb: 1 }}
                                                            onChange={(e) => {
                                                                setBeforeTime(e.target.value);
                                                            }}
                                                            value={beforeTime}
                                                        >
                                                            {beforeTimeDropdown.map((option) => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    value={option.value}
                                                                    onClick={(e) => handleDurationChange(option.value)}
                                                                    sx={{ fontSize: '14px' }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Inter' }}>
                                                        After
                                                    </Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Select
                                                            label="Duration"
                                                            labelId="demo-simple-select-label"
                                                            id="duration"
                                                            placeholder="Plan Duration"
                                                            name="duration"
                                                            sx={{ backgroundColor: 'white', mt: 1, mb: 1 }}
                                                            onChange={(e) => {
                                                                setAfterTime(e.target.value);
                                                            }}
                                                            value={afterTime}
                                                        >
                                                            {afterTimeDropdown.map((option) => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    value={option.value}
                                                                    onClick={(e) => handleDurationChange(option.value)}
                                                                    sx={{ fontSize: '14px' }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                </Stack>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Inter' }}>
                                                        Maximum time in advance that an appointment can be booked
                                                    </Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Select
                                                            label="Duration"
                                                            labelId="demo-simple-select-label"
                                                            id="duration"
                                                            placeholder="Plan Duration"
                                                            name="duration"
                                                            sx={{ backgroundColor: 'white', mt: 1, mb: 1 }}
                                                            onChange={(e) => {
                                                                setMaxTime(e.target.value);
                                                            }}
                                                            value={maxTime}
                                                        >
                                                            {maxTimeDropdown.map((option) => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    value={option.value}
                                                                    onClick={(e) => handleDurationChange(option.value)}
                                                                    sx={{ fontSize: '14px' }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                    <Typography variant="h5" sx={{ fontSize: '14px', color: '#000000', fontWeight: '500', fontFamily: 'Inter' }}>
                                                        Minimum time before the appointment start that it can be booked
                                                    </Typography>
                                                    <FormControl fullWidth size="small">
                                                        <Select
                                                            label="Duration"
                                                            labelId="demo-simple-select-label"
                                                            id="duration"
                                                            placeholder="Plan Duration"
                                                            name="duration"
                                                            sx={{ backgroundColor: 'white', mt: 1, mb: 1 }}
                                                            onChange={(e) => {
                                                                setMinTime(e.target.value);
                                                            }}
                                                            value={minTime}
                                                        >
                                                            {minTimeDropdown.map((option) => (
                                                                <MenuItem
                                                                    key={option.key}
                                                                    value={option.value}
                                                                    onClick={(e) => handleDurationChange(option.value)}
                                                                    sx={{ fontSize: '14px' }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box ref={availabilityRef} >
                                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                                            Availability
                                        </Typography>
                                    </Box>


                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </Box> */}

          {/* Availability Section */}
          {/* <Box ref={availabilityRef} sx={{ mb: 6 }}>
                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                            Availability
                        </Typography>
                    </Box> */}

          {/* Availability Section */}
          {/* <Box ref={calenderRef}>
                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                            Availability
                        </Typography>

                    </Box> */}
          {/* Availability Section */}
          {/* <Box ref={bookingRef}>
                        <Typography variant="h5" sx={{ fontSize: '16px', color: '#2653A3', fontWeight: '600', fontFamily: 'Montserrat', mb: 2 }}>
                            Availability
                        </Typography>

                    </Box> */}
        </Box>
        <Stack direction="row" spacing={1} justifyContent={'end'} gap={2} mx={2} sx={{ pb: { xs: 5, md: 1 } }}>
          {/* <Button
                        size="large"
                        variant="outlined"
                        sx={{
                            textTransform: 'capitalize',
                            border: '1px solid #E7EBF1',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            color: '#000000',
                            backgroundColor: '#ffffff'
                        }}
                    >

                        Cancel
                    </Button> */}
          {!id ? (<>
            <Button
              disabled={!DataAvailable}
              size="large"
              variant="contained"
              sx={{
                backgroundColor: '#3C5AA0',
                textTransform: 'capitalize',
                border: 'none',
                borderRadius: '8px',
                boxShadow: 'none',
                color: 'white',
                fontFamily: 'Montserrat',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  color: 'white',
                  border: 'none',
                  boxShadow: 'none',
                },
              }}
              onClick={handleSubmit}
            >
              {isCreateLoading ? <CircularProgress size={24} sx={{ color: 'white', }} /> : "Create"}
            </Button>
          </>
          ) : (
            <>
              <Button
                size="large"
                variant="contained"
                sx={{
                  backgroundColor: '#3C5AA0',
                  textTransform: 'capitalize',
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  color: 'white',
                  fontFamily: 'Montserrat',
                  '&:hover': {
                    backgroundColor: '#3C5AA0',
                    color: 'white',
                    border: 'none',
                    boxShadow: 'none',
                  },
                }}
                onClick={handleSubmit}
              >
                {isCreateLoading ? <CircularProgress size={24} sx={{ color: 'white', }} /> : "Update"}
              </Button>
            </>
          )}
        </Stack>
      </Paper>
      {
        !currentCommunity?.googleAuth?.googleEmail &&
        <Dialog open={openConnect}
          fullWidth
          maxWidth="sm"
          // sx={{
          // '& .MuiDialog-paper': {
          // height: 'auto',
          // // minHeight: { xs: '100px', md: '200px' },
          // },
          // }}
          BackdropProps={{
            sx: {
              backdropFilter: 'blur(4px)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}>
          <DialogContent sx={{ textAlign: 'center', fontSize: { xs: "12px", md: '16px', fontWeight: 'bold' } }}>
            Sync your calendar for automatic 1-on-1 scheduling
          </DialogContent>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={{ xs: 1, md: 3 }} sx={{ py: { xs: 4, md: 4 } }}>
            <Box
              component={'img'}
              src={GooglesIcon}
              width={'25px'}
              height={'25px'}
            />
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
              {currentCommunity?.googleAuth?.googleEmail ? (
                <Typography>
                  {currentCommunity.googleAuth.googleEmail}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Montserrat' }}>
                  Connect your Gmail account
                </Typography>
              )}
            </Typography>
            {currentCommunity?.googleAuth?.googleEmail ? (
              <>
                <Button
                  size='small'
                  variant='outlined'
                  sx={{
                    color: "#3C5AA0",
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    border: '1px solid #3C5AA0',
                    fontFamily: 'Montserrat',
                    width: { xs: 110, md: 100 },
                  }}
                  onClick={() => (
                    navigate('/integrations')
                  )}
                >
                  change
                </Button>
              </>
            ) : (
              <>
                <Button
                  size='small'
                  variant='outlined'
                  sx={{
                    color: "#3C5AA0",
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    border: '1px solid #3C5AA0',
                    fontFamily: 'Montserrat',
                    width: { xs: 110, md: 100 },
                  }}
                  onClick={() => (
                    navigate('/integrations')
                  )}
                >
                  connect
                </Button>
              </>
            )}
          </Stack>

        </Dialog>
      }
    </>
  );
};

export default AddAppointments;