import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid, Stack, Box, Button, } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from '../../Loader/Loader';
import { AlternativePage } from '../../AlternatePages/AlternativePage';
import NoClass from '../../../assets/icons/svg-icons/noPlans.png';
import { Link, useNavigate } from 'react-router-dom';
// import { useAppointments } from '../../../hooks/useAppointments';
import { useEvents } from '../../../hooks/useEvents';
import { IEvents } from '../../../models/events.models';
import dayjs from 'dayjs';
import CompleteImage from "../../../assets/images/completed.webp"
import { CalendarDays, Clock3, IndianRupee, MapPin, User, Users } from 'lucide-react';





const PastEvents = ({ searchQuery }: { searchQuery: string }) => {


    const singleCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    let community_id = singleCommunity?._id;
    const { getPastEvents } = useEvents();

    const [events, setEvents] = React.useState<IEvents[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [selectedItemId, setSelectedItemId] = React.useState<string | null>(null);
    // const [status, setStatus] = React.useState('PAUSED')


    const fetchEvents = async () => {
        setIsLoading(true);
        try {
            const response = await getPastEvents(community_id);
            if (Array.isArray(response)) {
                setEvents(response);
            } else {
                setEvents([]);
            }
        } catch (err) {
            console.error('Error fetching live classes:', err);
            setEvents([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (community_id) {
            fetchEvents();
        }
    }, []);


    const navigate = useNavigate()


    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        itemId: string
    ) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        setSelectedItemId(itemId);
    };




    // const { updateAppointmentStatusById } = useAppointments();


    const filteredEvents = events.filter((event) =>
        event.title.toLowerCase().includes(searchQuery.toLowerCase())
    );



    return (
        <Box sx={{ position: 'relative', height: { xs: '100vh', md: '73vh' }, overflow: 'auto' }}>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Loader />
                </Box>
            ) : events.length === 0 ? (
                <AlternativePage title="No Past Events" image={NoClass} />
            ) : (
                <Grid container gap={1.5} py={1}>
                    {filteredEvents?.map((eventData, index) => (
                        <Grid item xs={12} md={5.9} key={index}>
                            <Card elevation={0}

                                sx={{
                                    borderLeft: '1px solid #D4DDED',
                                    borderRight: '1px solid #D4DDED',
                                    borderTop: '1px solid #D4DDED',
                                    borderBottom: '1px solid #D4DDED',
                                    borderRadius: '10px',
                                    '&:hover': {
                                        borderLeft: '3px solid #B1B1B1',
                                        borderRight: '1px solid #B1B1B1',
                                        borderTop: '1px solid #B1B1B1',
                                        borderBottom: '1px solid #B1B1B1',
                                    },
                                    // opacity: 1
                                }}
                            >
                                <CardContent
                                    sx={{
                                        p: 0,
                                        "&:last-child": {
                                            pb: '1px',
                                        },
                                    }}>

                                    <Grid container spacing={2} sx={{ p: 1 }} alignItems="center">
                                        {/* Date Section */}
                                        <Grid item xs={3} textAlign="center">
                                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '500', color: '#3760A9' }}>
                                                {dayjs(eventData?.availability?.[0]?.day).format('MMM').toUpperCase()}
                                            </Typography>
                                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '25px', fontWeight: '600', color: '#3760A9' }}>
                                                {dayjs(eventData?.availability?.[0]?.day).format('D')}
                                            </Typography>
                                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '500', color: '#3760A9' }}>
                                                {dayjs(eventData?.availability?.[0]?.day).format('dddd')}
                                            </Typography>
                                        </Grid>

                                        {/* Event Details */}
                                        <Grid item xs={6} >
                                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: { xs: '13px', md: '14px' }, fontWeight: '600', color: '#000000' }}>
                                                {eventData?.title?.length > 20 ? eventData?.title?.slice(0, 20) + "..." : eventData?.title}
                                            </Typography>

                                            {/* Date */}
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                {/* <CalendarMonthOutlinedIcon sx={{ fontSize: { xs: '15px', md: '18px' }, color: '#565656' }} /> */}
                                                <CalendarDays size={17} strokeWidth={1.5} color='#565656' />
                                                <Typography key={index} sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656' }}>
                                                    {dayjs(eventData?.availability[0]?.day).format('MMM D, YYYY')}
                                                    {" "}   to {" "}
                                                    {dayjs(eventData?.availability?.[eventData?.availability?.length - 1]?.day).format('MMM D, YYYY')}

                                                </Typography>
                                            </Stack>

                                            <Stack direction="row" alignItems="center" gap={1}>
                                                {/* <AccessAlarmOutlinedIcon sx={{ fontSize: { xs: '15px', md: '18px' }, color: '#565656' }} /> */}
                                                <Clock3 size={17} strokeWidth={1.5} color='#565656' />
                                                <Typography sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656', textTransform: 'uppercase' }}>
                                                    {eventData?.availability?.[0]?.availableTimes
                                                        ?.map((time) => {
                                                            const startTime = new Date(`1970-01-01T${time.startTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                            const endTime = new Date(`1970-01-01T${time.endTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                            return `${startTime} - ${endTime}`;
                                                        })
                                                        .join(' to ')}
                                                </Typography>
                                            </Stack>

                                            {/* Pricing */}
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                {/* <CurrencyRupeeOutlined sx={{ fontSize: { xs: '15px', md: '18px' }, color: '#565656' }} /> */}
                                                <IndianRupee size={17} strokeWidth={1.5} color='#565656' />
                                                <Typography sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656' }}>
                                                    {/* {eventData?.pricing } */}
                                                    {eventData?.pricing ? `${eventData.pricing}` : "Free"}
                                                </Typography>
                                            </Stack>

                                            {/* Location */}
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                {/* <PinDropOutlinedIcon sx={{ fontSize: { xs: '15px', md: '18px' }, color: '#565656' }} /> */}
                                                <MapPin size={17} strokeWidth={1.5} color='#565656' />
                                                <Typography sx={{ fontSize: { xs: '9px', md: '12px' }, fontWeight: '400', fontFamily: 'Montserrat', color: '#565656' }}>
                                                    {eventData?.location?.length > 22 ? eventData?.location?.slice(0, 22) + "..." : eventData?.location}
                                                </Typography>
                                            </Stack>
                                        </Grid>

                                        {/* Event Image */}
                                        <Grid item xs={3}>
                                            <Link to={`/events/${eventData?._id}`}>
                                                <Box sx={{ borderRadius: '10px', p: 0.5 }}>
                                                    <Box component="img"
                                                        src={CompleteImage}
                                                        width="100%"
                                                        sx={{ borderRadius: '10px', objectFit: 'cover', height: { xs: '50px', md: '80px' } }} />
                                                </Box>
                                            </Link>
                                        </Grid>
                                    </Grid>

                                    <Stack sx={{ backgroundColor: '#F2F2F2', px: 1.5, py: 0.5 }}>
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Stack direction="row" spacing={2}>
                                                <Stack direction="row" alignItems="center" gap={0} >
                                                    <User size={15} strokeWidth={1.5} color='#565656' />
                                                    <Typography
                                                        sx={{
                                                            fontSize: { xs: '10px', md: '12px' },
                                                            fontWeight: '400',
                                                            fontFamily: 'Montserrat',
                                                            color: '#565656'
                                                        }}
                                                    >
                                                        By {eventData?.hostedBy}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="row" alignItems="center" gap={0.5}>
                                                    <Users size={15} strokeWidth={1.5} color='#565656' />
                                                    <Typography
                                                        sx={{
                                                            fontSize: { xs: '10px', md: '12px' },
                                                            fontWeight: '400',
                                                            fontFamily: 'Montserrat',
                                                            color: '#565656'
                                                        }}
                                                    >
                                                        {eventData?.attendees?.length} Joined
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction='row' spacing={2}>
                                                <Stack direction="row" alignItems="center" gap={0.5}>
                                                    <Button
                                                        onClick={() => {
                                                            navigate(`/events/attendees/${eventData?._id}`);
                                                        }}
                                                        variant="contained"
                                                        sx={{
                                                            borderRadius: "5px",
                                                            fontFamily: "Montserrat",
                                                            textTransform: "capitalize",
                                                            color: "#ffffff",
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            boxShadow: "none",
                                                            backgroundColor: "#3C5AA0",
                                                            "&:hover": {
                                                                boxShadow: "none",
                                                                backgroundColor: "#3C5AA0",
                                                            },
                                                        }}
                                                        rel="noopener noreferrer"
                                                    >
                                                        Attendees
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

export default PastEvents;
