import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { IPost } from '../components/Posts/Posts';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  HomePosts,
  ProfilePosts,
  deletePost,
  getPosts,
  updatePost,
  createNewCommunityPost,
  deleteMultiplePosts,
  userPosts,
  likeUnLikePost,
} from '../services/post.service';

// export function stat
export const usePosts = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [postsData, setPostsData] = useState<IPost[]>([]);
  // To collect all posts

  // To collect all posts
  const getPostList = async () => {
    setIsLoading(true);
    try {
      const response = await getPosts(getAccessToken());
      if (response.status === 200) {
        // setPostsChanged(true)
        setIsLoading(false);
        return response.data as IPost[];
      } else {
        enqueueSnackbar('Fetching posts failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while fetching posts', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
  };

  // get only user posts
  const getProfilePostList = async (id: string) => {
    setIsLoading(true);
    const response = await ProfilePosts(getAccessToken(), id);
    if (response.status === 200) {
      setIsLoading(false);
      return response.data as IPost[];
    } else {
      enqueueSnackbar('Fetching posts failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }
  };
  // Get all posts for selected community
  const getCommunityPostsById = async (id: string) => {
    setIsLoading(true);
    const response = await HomePosts(getAccessToken(), id);
    if (response.status === 200) {
      setIsLoading(false);
      return response.data as IPost[];
    } else {
      enqueueSnackbar('Couldn’t fetch posts', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }
  };
  const getIndividualUserPostsById = async (
    communityId: string,
    userId: string
  ) => {
    setIsLoading(true);
    const response = await userPosts(getAccessToken(), communityId, userId);
    // console.log(response);
    if (response.status === 200) {
      setIsLoading(false);
      return response.data;
    } else {
      enqueueSnackbar('Fetching community posts failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }
  };

  const deletePostById = async (id: string) => {
    try {
      const response = await deletePost(getAccessToken(), id);
      if (response.status === 200) {
        setPostsData((prevPosts) =>
          prevPosts.filter((post) => post._id !== id)
        );
        enqueueSnackbar('Post deleted', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Couldn’t delete post', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('Couldn’t delete post', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  const deletePostByIds = async (idsToDelete: string[]) => {
    try {
      const response = await deleteMultiplePosts(getAccessToken(), idsToDelete);
      if (response.status === 200) {
        // Filter out the deleted posts from the current state
        const updatedPosts = postsData.filter(
          (post) => !idsToDelete.includes(post._id)
        );
        // Update the state with the filtered posts
        setPostsData(updatedPosts);
        enqueueSnackbar('Posts deleted', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Couldn’t delete posts', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('Couldn’t delete posts', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const createNewPost = async (formData: FormData, file: FileList | null) => {
    try {
      setIsLoading(true);
      const response = await createNewCommunityPost(
        getAccessToken(),
        file,
        formData
      );
      // console.log(response.data);

      if (response.status === 201) {
        enqueueSnackbar('Post created', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Couldn’t create post', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while creating the post', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const editPost = async (
    postId: string,
    formData: FormData,
    file: FileList | null
  ) => {
    try {
      setIsLoading(true);
      const response = await updatePost(
        getAccessToken(),
        postId,
        formData,
        file
      );
      if (response.status === 200) {
        enqueueSnackbar('Post updated', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Couldn’t update post', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar('Couldn’t update post', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  // const likeClick = async (postId: string) => {
  //   try {
  //     // Assuming you have a function to get the user's access token

  //     const response = await likePost(getAccessToken(), postId);
  //     if (response.status === 200) {
  //       enqueueSnackbar("Liked", { variant: "success", autoHideDuration: 3000 });
  //     } else {
  //       enqueueSnackbar("Failed to like post", { variant: "error", autoHideDuration: 3000 });
  //     }
  //     setIsLoading(false);
  //     return response;
  //     // Optionally, you can update your local state or perform any other actions
  //   } catch (error) {
  //     // console.error("Failed to handle like click:", error);
  //   }
  // };

  const likePost = async (postId: string, isLiked: boolean) => {
    try {
      const response = await likeUnLikePost(getAccessToken(), postId, isLiked);
      // console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    }
    catch (err) {
      console.log(err, "error")
    }
  };




  //////////////////////////////////////////////////////////////// return statement
  return {
    isLoading,
    getCommunityPostsById,
    deletePostById,
    createNewPost,
    getAccessToken,
    getProfilePostList,
    editPost,
    deletePostByIds,
    getPostList,
    getIndividualUserPostsById,
    likePost,
  };
};
//////////////////////////////// export function ending
