import React from 'react';
import { Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getStaticValue } from '../../../utils/StaticValues';

export interface IStatusCell {
  text: string;
  type: 'error' | 'warning' | 'info' | 'success';
  slug: string;
}

export const RoleTypeCell = ({ text, type, slug }: IStatusCell) => {
  const styles = {
    backgroundColor: 'red',
    color: '#3C5AA0',
    border: ' 1px solid #3C5AA0',
    width: '100px',
    textAlign: 'center',
    fontFamily: 'Montserrat',
  };

  const getStyles = (status: string) => {
    switch (status) {
      case 'success':
        styles.backgroundColor = 'white';
        styles.color = '#3C5AA0';
        styles.border = '1px solid #3C5AA0';
        break;
      case 'warning':
        styles.backgroundColor = 'white';
        styles.color = '#4FA1CA';
        styles.border = '1px solid #4FA1CA';
        break;
      case 'error':
        styles.backgroundColor = 'white';
        styles.color = '#3C5AA0';
        styles.border = ' 1px solid #3C5AA0';
        break;
    }
    return styles;
  };

  return (
    <>
      <Box
        sx={{
          padding: '3px',
          borderRadius: '5px',
          ...getStyles(type),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AddIcon sx={{ marginRight: '3px', fontSize: '15px' }} />
        <Typography sx={{ fontSize: { xs: '12px', md: '15px' }, fontFamily: 'Montserrat' }}>
          {getStaticValue(text)}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          fontFamily: 'Montserrat',
          width: '100px',
          fontSize: { xs: '10px', md: '14px' },
        }}
      >
        {slug}
      </Typography>
    </>
  );
};
