import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import React, { useEffect } from "react";
import { USER_APP_BASE_URL } from "../../configurations/url.config";
import { useEvents } from "../../hooks/useEvents";
import { IEvents } from "../../models/events.models";
import dayjs from "dayjs";
import BreadcrumbDouble from "../AlternatePages/BreadCumsDouble";
import { CalendarDays, Clock3, IndianRupee, MapPin, SquareUserRound, Video } from "lucide-react";


const EventDetails = () => {


    const { id } = useParams();

    // console.log(id, "id")

    // const singleCommunity = useSelector(
    //     (state: any) => state.selectedCommunity.selectedCommunity
    // );



    const { getEventById } = useEvents();

    const [eventDetails, setEventDetails] = React.useState<IEvents>();
    const [isLoading, setIsLoading] = React.useState(false);

    // console.log(eventDetails, "eventDetails")

    const fetchEventDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getEventById(id || "");
            // console.log(response, "response")
            setEventDetails(response);
        } catch (err) {
            console.error('Error fetching live classes:', err);

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchEventDetails();
        }
    }, []);





    return (
        <>

            <>
                <BreadcrumbDouble
                    items={[
                        { label: 'Events', path: '/events' },
                        { label: `${eventDetails?.title}` }
                    ]}
                />
                <Box sx={{ mb: { xs: 5, md: 0 } }}>
                    <Paper sx={{ p: 2, mt: 1, borderRadius: '10px' }} elevation={0}>
                        <Stack sx={{ backgroundColor: '#F0F9FF', p: { xs: 1, md: 2 }, borderRadius: '5px' }}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: 'Montserrat',
                                            fontSize: { xs: '16px', md: '20px' },
                                            color: '#3760A9'
                                        }}>
                                        {eventDetails?.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: '12px', md: '13px' },
                                            color: '#000000',
                                            fontFamily: 'Montserrat',
                                            textAlign: 'justify'

                                        }}>
                                        {eventDetails?.description}
                                    </Typography>
                                    <Stack gap={2} display={'flex'} flexDirection={'row'}>
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            sx={{
                                                borderRadius: '5px',
                                                fontFamily: 'Montserrat',
                                                textTransform: 'capitalize',
                                                color: '#3C5AA0',
                                                border: '1px solid #3C5AA0',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                mt: 2
                                            }}
                                            component="a"
                                            href={`https://wa.me/?text=${encodeURIComponent(
                                                `Join the event using this link: ${`${USER_APP_BASE_URL}/event/${eventDetails?.title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${eventDetails?._id}`}`
                                            )}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Share
                                        </Button>
                                        {eventDetails?.meetingLink ? (
                                            <Button
                                                size="medium"
                                                variant="contained"
                                                sx={{
                                                    borderRadius: '5px',
                                                    fontFamily: 'Montserrat',
                                                    textTransform: 'capitalize',
                                                    color: '#ffffff',
                                                    border: '1px solid #3C5AA0',
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    mt: 2,
                                                    backgroundColor: '#3C5AA0',
                                                    boxShadow: 'none',
                                                    "&:hover": {
                                                        backgroundColor: '#3C5AA0',
                                                        boxShadow: 'none',
                                                    }

                                                }}
                                                component="a"
                                                href={`${eventDetails?.meetingLink}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Join
                                            </Button>

                                        ) : (
                                            <>
                                            </>

                                        )}

                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box
                                        component={'img'}
                                        src={eventDetails?.coverImage?.value}
                                        width="100%"
                                        height={200}
                                        sx={{ borderRadius: '10px', objectFit: 'cover' }}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                        <Paper sx={{ mt: 2, border: '1px solid #E9E9E9', borderRadius: '10px' }} elevation={0}>
                            <Stack>
                                <List>
                                    {[
                                        {
                                            icon: <CalendarDays size={20} strokeWidth={1.5} />,
                                            text: `${dayjs(eventDetails?.availability[0]?.day).format('MMM D, YYYY')} to ${dayjs(eventDetails?.availability?.[eventDetails?.availability?.length - 1]?.day).format('MMM D, YYYY')}`
                                        },
                                        {
                                            icon: <Clock3 size={20} strokeWidth={1.5} />,
                                            text: eventDetails?.availability?.[0]?.availableTimes
                                                ?.map((time) => {
                                                    const startTime = new Date(`1970-01-01T${time.startTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                    const endTime = new Date(`1970-01-01T${time.endTime}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                    return `${startTime} - ${endTime}`;
                                                })
                                                .join(' to ')

                                        },
                                        {
                                            icon: <IndianRupee size={20} strokeWidth={1.5} />,
                                            text: eventDetails?.pricing ? `${eventDetails.pricing}` : "Free",
                                        },

                                        {
                                            icon: eventDetails?.location && <MapPin size={20} strokeWidth={1.5} />,
                                            text: eventDetails?.location && eventDetails?.location,
                                        },
                                        {
                                            icon: eventDetails?.meetingLink && <Video size={20} strokeWidth={1.5} />,
                                            text: eventDetails?.meetingLink && eventDetails?.meetingLink,
                                        },
                                    ].map((item, index) => (
                                        <ListItem disablePadding key={index}>
                                            <ListItemButton>
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 500,
                                                                color: '#565656',
                                                                fontFamily: 'Montserrat',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            {item.text}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>

                                <List sx={{ backgroundColor: '#F2F2F2', borderRadius: '0px 0px 10px 10px ' }}>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            sx={{
                                                backgroundColor: '#F2F2F2',
                                            }}>
                                            <ListItemIcon>
                                                <SquareUserRound size={20} strokeWidth={1.5} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            color: '#565656',
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        By -  {eventDetails?.hostedBy}
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Stack>

                        </Paper>
                    </Paper>
                </Box>

            </>



        </>
    )
}
export default EventDetails