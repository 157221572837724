import axios from 'axios';
import { BASE_URL } from '../configurations/url.config';
import { IPlan } from '../components/Plans/Plans';
import { IAddPlan } from '../components/Plans/AddPlan';
import { ICreatePlan } from '../components/Plans/CreatePlan';

export const getPlans = async (token: string) => {
  try {
    // console.log(token);
    const response = await axios.get(`${BASE_URL}/plans`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      response.data = response.data.map((d: IPlan) => {
        return {
          ...d,
          _id: d._id,
          name: d.name ?? '',
          image: d.image ?? '',
          description: d.description ?? '',
          createdAt: d.createdAt ?? '',
          updatedAt: d.updatedAt ?? '',
          parameters: [
            {
              name: 'Subscribers',
              value: '46.89K',
              icon: '',
            },
          ],
          status: d.status ?? 'IN_ACTIVE',
          id: d._id ?? '',
          promocode: d.promocode ?? '',
          totalPlanValue: d.totalPlanValue ?? 0,
          duration: d.duration ?? '',
          interval: d.interval ?? 0,
          startDate: d.startDate ?? '',
          billingFrequency: d.billingFrequency ?? '',
          summary: d.summary ?? '',
          offerValue: d.offerValue ?? 0,
          community: d.community ?? '',
        };
      });
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const getCommunityPlans = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/plans/community/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      response.data = response.data.map((d: IPlan) => {
        return {
          ...d,
          _id: d._id,
          name: d.name ?? '',
          image: d.image ?? '',
          description: d.description ?? '',
          createdAt: d.createdAt ?? '',
          updatedAt: d.updatedAt ?? '',
          parameters: [
            {
              name: 'Subscribers',
              value: '46.89K',
              icon: '',
            },
          ],
          status: d.status ?? 'IN_ACTIVE',
          id: d._id ?? '',
          promocode: d.promocode ?? '',
          totalPlanValue: d.totalPlanValue ?? 0,
          duration: d.duration ?? '',
          interval: d.interval ?? 0,
          startDate: d.startDate ?? '',
          billingFrequency: d.billingFrequency ?? '',
          summary: d.summary ?? '',
          offerValue: d.offerValue ?? 0,
          community: d.community ?? '',
          images: d?.image ?? '',
          documents: d?.documents ?? '',
        };
      });
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const getPlansCommunity = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/plans/community/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log(response?.data?.plans, "response")
    return response?.data?.plans;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const getPlan = async (token: string, id: string) => {
  try {
    // console.log(token);
    const response = await axios.get(`${BASE_URL}/plans/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      let d = response.data;
      //response.data = d;
      //console.log(d);
      response.data = {
        ...d,
        _id: d._id,
        name: d.name ?? '',
        image: d.image ?? '',
        description: d.description ?? '',
        createdAt: d.createdAt ?? '',
        updatedAt: d.updatedAt ?? '',
        parameters: [
          {
            name: 'Subscribers',
            value: '46.89K',
            icon: '',
          },
        ],
        status: d.status ?? 'IN_ACTIVE',
        id: d._id ?? '',
        promocode: d.promocode ?? '',
        totalPlanvalue: d.totalPlanvalue ?? 0,
        duration: d.duration ?? '',
        interval: d.interval ?? 0,
        startDate: d.startDate ?? '',
        billingFrequency: d.billingFrequency ?? [],
        summary: d.summary ?? '',
        offerValue: d.offerValue ?? 0,
        endDate: d.endDate ?? false,
        community: d.community ?? '',
        images: d?.image ?? '',
        documents: d?.documents ?? '',
        isOfferEnds: d?.isOfferEnds ?? false,
        offerEndsAfter: d?.offerEndsAfter ?? 0,
        offerEndsDuration: d?.offerEndsDuration ?? ' ',
        offerEndDate: d?.offerEndDate ?? ' ',
      };
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const updatePlan = async (
  id: string,
  token: string,
  data: IAddPlan,
  files: FileList | null,
  documents: FileList | null
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/json',
      Accept: '*',
    };

    const formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('image', file);
      }
    }
    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        const file = documents[i];
        formData.append('document', file);
      }
    }
    //const new_data :IAddPlan = data;
    for (const [field, value] of Object.entries(data)) {
      formData.append(field, value);
    }
    const response = await axios.put(`${BASE_URL}/plans/${id}`, formData, {
      headers,
    });
    if (response.status === 200) {
      let d = response.data;
      response.data = {
        ...d,
        _id: d._id,
        name: d.name ?? '',
        image: d.image ?? '',
        description: d.description ?? '',
        createdAt: d.createdAt ?? '',
        updatedAt: d.updatedAt ?? '',
        parameters: [
          {
            name: 'Subscribers',
            value: '46.89K',
            icon: '',
          },
        ],
        status: d.status ?? 'IN_ACTIVE',
        id: d._id ?? '',
        promocode: d.promocode ?? '',
        totalPlanvalue: d.totalPlanvalue ?? 0,
        duration: d.duration ?? '',
        interval: d.interval ?? 0,
        startDate: d.startDate ?? '',
        billingFrequency: d.billingFrequency ?? false,
        summary: d.summary ?? '',
        offerPrice: d.offerPrice ?? 0,
        endDate: d.endDate ?? false,
        images: d?.image ?? '',
        documents: d?.documents ?? '',
        isOfferEnds: d?.isOfferEnds ?? false,
        offerEndsAfter: d?.offerEndsAfter ?? 0,
        offerEndsDuration: d?.offerEndsDuration ?? ' ',
        offerEndDate: d?.offerEndDate ?? ' ',
      };
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const deletePlan = async (token: string, id: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/plans/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
export const savePlan = async (
  id: string,
  token: string,
  data: IAddPlan,
  files: FileList | null,
  documents: FileList | null
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
      'Content-Type': 'multipart/json',
      Accept: '*',
    };

    const formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('image', file);
      }
    }
    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        const file = documents[i];
        formData.append('document', file);
      }
    }
    //const new_data :IAddPlan = data;
    for (const [field, value] of Object.entries(data)) {
      formData.append(field, value);
    }
    const response = await axios.post(`${BASE_URL}/plans`, formData, {
      headers,
    });

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const createPlan = async (token: string, details: ICreatePlan) => {
  try {
    const response = await axios.post(`${BASE_URL}/plans`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const updatePlanById = async (
  id: string,
  token: string,
  details: ICreatePlan
) => {
  try {
    const response = await axios.put(`${BASE_URL}/plans/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const getPlanById = async (id: string, token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/plan/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log('After', response.data);
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
