import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  Link,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DragAndAddFiles from './DragAndAddFiles';
import AddSectionPopUp from './AddSectionPopUp';
import AddLinkPopUp from './AddLinkPopUp';
import {
  ILinks,
  ISections,
  interfaceCourse,
} from '../../../models/courses.model';
import { useCourses } from '../../../hooks/useCourses';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditDocumentPopUp from './EditDocumentPopUp';
import { AddSectionDocument } from '../../../services/courses.service';
import { AuthContext, IAuthContext } from '../../../contexts/Auth.context';
import { FileCopyOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useWebBuilders } from '../../../hooks/useWebBuilder';
import { Pencil, Trash2 } from 'lucide-react';
import { LinkIcon } from 'lucide-react';
interface sectionProps {
  data: interfaceCourse;
  selectedCommunityId: string;
  refetchCourseData: () => void;
}
const TestSection: React.FC<sectionProps> = ({
  data,
  selectedCommunityId,
  refetchCourseData,
}) => {
  const {
    updateCourseSectionsById,
    deleteSectionDocument,
    deleteSectionCourse,
  } = useCourses();
  const [isLoading, setIsLoading] = useState(false);
  const [sectionValues, setSectionValues] = useState<ISections>();
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const section = data?.sections?.length > 0 ? data?.sections : [];
  const courseId = data?._id;
  const [sections, setSections] = useState(section);

  // console.log(data, "data")

  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [openAddLinkDialog, setOpenAddLinkDialog] = useState(false);
  const [openEditDocument, setOpenEditDocument] = useState(false);
  const [openAddSection, setOpenAddSection] = useState(false);
  const [linkInputs, setLinkInputs] = useState(['']);
  const [documents, setDocuments] = useState();
  const [expanded, setExpanded] = useState<string | null>(null);
  const [deleteSectionPopOverAnchor, setDeleteSectionPopOverAnchor] =
    useState<null | HTMLElement>(null);
  const [deleteLinkAnchor, setDeleteLinkAnchor] = useState<null | HTMLElement>(
    null
  );
  const [deleteDocumentPopOverAnchor, setDeleteDocumentPopOverAnchor] =
    useState<null | HTMLElement>(null);
  const [sectionItem, setSectionItem] = useState<ISections | null>(null);
  const [linkItem, setLinkItem] = useState<ILinks>();

  const { enqueueSnackbar } = useSnackbar();

  const { uploadImages } = useWebBuilders();

  useEffect(() => {
    if (data?.sections) {
      setSections(data?.sections);
    }
  }, [data]);

  const handleFilesSelected = async (files: FileList | null) => {
    if (files && sectionItem) {
      try {
        await AddSectionDocument(
          getAccessToken(),
          selectedCommunityId,
          courseId,
          sectionItem?._id,
          files
        );
        refetchCourseData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const openSectionPopOver = Boolean(deleteSectionPopOverAnchor);
  const idDeleteSectionPopover = openSectionPopOver
    ? 'delete-popover'
    : undefined;

  const openLinkPopOver = Boolean(deleteLinkAnchor);
  const idDeleteLinkPopover = openLinkPopOver ? 'delete-popover' : undefined;

  const openDeleteDocumentPopOver = Boolean(deleteDocumentPopOverAnchor);
  const idDeleteDocumentPopover = openDeleteDocumentPopOver
    ? 'delete-popover'
    : undefined;

  const handleSectionDelete = (e: any, item: ISections) => {
    setDeleteSectionPopOverAnchor(e.currentTarget);
    setSectionItem(item);
  };

  const handleCloseSectionPopOver = () => {
    setDeleteSectionPopOverAnchor(null);
  };

  const handleLinkDelete = (e: any, item: ILinks) => {
    setDeleteLinkAnchor(e.currentTarget);
    setLinkItem(item);
  };

  const handleCloseLinkPopOver = () => {
    setDeleteLinkAnchor(null);
  };

  const handleDeleteDocument = (e: any, item: any) => {
    setDeleteDocumentPopOverAnchor(e.currentTarget);
    setDocuments(item);
  };
  const handleCloseDeleteDocument = () => {
    setDeleteDocumentPopOverAnchor(null);
  };
  const handleDeleteSectionDocument = async () => {
    if (documents && sectionItem) {
      const { _id } = documents;
      try {
        setIsLoading(true);
        await deleteSectionDocument(sectionItem?._id, _id);
      } catch (err) {
        console.log('error');
      } finally {
        refetchCourseData();
        setIsLoading(false);
        handleCloseDeleteDocument();
      }
    }
  };

  const handleSectionLinkDelete = async () => {
    if (sectionItem) {
      const updatedLinks = sectionItem?.links.filter(
        (link) => link._id !== linkItem?._id
      );
      try {
        setIsLoading(true);
        await updateCourseSectionsById(
          selectedCommunityId,
          courseId,
          sectionItem?._id,
          { links: updatedLinks }
        );
      } catch (error) {
        console.log('error');
      } finally {
        handleCloseLinkPopOver();
        refetchCourseData();
        setIsLoading(false);
      }
    }
  };

  //DRAG
  const [draggedItem, setDraggedItem] = useState<number | null>(null);
  const handleDragStart = (index: number) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index: number) => {
    const newSections = [...sections];
    const draggedSection = newSections[draggedItem!];
    newSections.splice(draggedItem!, 1);
    newSections.splice(index, 0, draggedSection);
    setSections(newSections);
    setDraggedItem(index);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null);
    };

  // const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const fileList = event.target.files;
  //   if (fileList) {
  //     const filesArray = Array.from(fileList); // Convert FileList to array
  //     setDocuments(filesArray);
  //   }
  // };

  const handleAddLink = () => {
    setLinkInputs([...linkInputs, '']); // Add a new input to the array
  };

  const handleDeleteLink = (index: any) => {
    const updatedInputs = linkInputs.filter((_, i) => i !== index);
    setLinkInputs(updatedInputs);
  };

  const handleInputChange = (value: any, index: any) => {
    const updatedInputs = [...linkInputs];
    updatedInputs[index] = value;
    setLinkInputs(updatedInputs);
  };

  // const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleOpenAddLinkDialog = (item: ISections) => {
    setOpenAddLinkDialog(true);
    setSectionValues(item);
  };
  const handleCloseAddLinkDialog = () => {
    setOpenAddLinkDialog(false);
  };
  const handleOpenAddSectionDialog = (e: any, item: ISections) => {
    setOpenAddSection(true);
    setSectionValues(item);
  };
  const handleCloseAddSectionDialog = () => {
    setOpenAddSection(false);
  };

  const handleEditDocument = (e: any, item: any) => {
    setOpenEditDocument(true);
    setDocuments(item);
  };
  const handleCloseEditDocument = () => {
    setOpenEditDocument(false);
  };

  const handleDeleteSections = async () => {
    if (sectionItem) {
      try {
        setIsLoading(true);
        await deleteSectionCourse(courseId, sectionItem?._id);
        refetchCourseData();
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        handleCloseSectionPopOver();
      }
    }
  };

  return (
    <Stack>
      {sections?.slice().map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: 0, // Remove the margin for MuiAccordionSummary-content
            },
            boxShadow: 'none',
            mb: 1,
            '& .Mui-expanded': {
              minHeight: '0', // Remove the minimum height for expanded state
            },
            '&:before': {
              height: '0 !important', // Set height to 0 for Accordion's :before pseudo-element
            },
            '& .MuiAccordion-root.Mui-expanded': {
              minHeight: '0 !important',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              m: 0,
            },
            '& .MuiButtonBase-root-MuiAccordionSummary-root': {
              minHeight: 0,
            },
          }}
        >
          <AccordionSummary
            sx={{ p: 0, m: 0 }}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onClick={() => {
              setSelectedItem(item);
            }}
            onDrop={() => handleDragOver(index)}
          >
            <Grid container alignItems="center" sx={{ p: 0, m: 0 }}>
              <Grid
                item
                xs={12}
                sm={12}
                justifyContent={'space-between'}
                sx={{
                  p: 2,
                  backgroundColor: '#F9F9F9',
                  border: '1px solid #E7EBF1',
                  borderRadius: '8px',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center">
                    <DragIndicatorOutlinedIcon
                      sx={{ color: '#565656', fontSize: '20px', pr: '3px' }}
                    />
                    {expanded === `panel${index}` ? (
                      <ArrowForwardIosIcon
                        sx={{ fontSize: '11px', transform: 'rotate(90deg)' }}
                      />
                    ) : (
                      <ArrowForwardIosIcon sx={{ fontSize: '11px' }} />
                    )}
                    <Stack sx={{ px: '10px' }}>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: '#565656',
                          fontFamily: 'Montserrat',
                          fontWeight: '600',
                        }}
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#565656',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                        }}
                      >
                        {item?.description}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Pencil
                      size={19} strokeWidth={1.5}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleOpenAddSectionDialog(e, item);
                      }}
                    />
                    <Stack
                      sx={{ ml: '10px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSectionDelete(e, item);
                      }}
                    >
                      <Trash2
                        size={19} strokeWidth={1.5}

                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              pb: 1,
              mb: 1,
              mt: '0px',
              border: '1px solid #D9EBF6',
              borderRadius: '8px',
              '& .css-veskz-MuiAccordionDetails-root': {
                p: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Stack onClick={() => setSectionItem(item)}>
                <DragAndAddFiles
                  data={data}
                  sectionPopUp={true}
                  section={selectedItem}
                  refetchCourseData={refetchCourseData}
                  onFilesSelected={handleFilesSelected}
                  inputId={'section-file'}
                />
              </Stack>

              <Stack sx={{ px: 2 }}>
                {item?.documents?.slice().map((doc: any, docIndex) => (
                  <Grid
                    item
                    key={doc?._id}
                    xs={12}
                    sm={12}
                    alignItems="center"
                    sx={{
                      p: 2,
                      mb: 1,
                      backgroundColor: '#F0F9FF',
                      border: '1px solid #E7EBF1',
                      borderRadius: '8px',
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" alignItems="center">
                        <FileCopyOutlined
                          sx={{ color: '#3C5AA0', fontSize: '20px' }}
                        />
                        <Stack sx={{ px: 2 }}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#565656',
                              fontFamily: 'Montserrat',
                              fontWeight: '600',
                            }}
                          >
                            {doc?.label}
                          </Typography>
                          <Link
                            href={`${doc?.value}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: '#3C5AA0',
                                fontFamily: 'Montserrat',
                                fontWeight: '600',
                              }}
                            >
                              Link
                            </Typography>
                          </Link>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        {/* <EditIcon
                              sx={{
                                color: '#3C5AA0',
                                fontSize: '20px',
                                pr: '3px',
                              }}
                              onClick={(e: any) => {
                                handleEditDocument(e, doc);
                              }}
                            /> */}
                        <Stack
                          sx={{ ml: '10px' }}
                          onClick={(e) => {
                            handleDeleteDocument(e, doc);
                            setSectionItem(item);
                          }}
                        >
                          <Trash2
                            size={19} strokeWidth={1.5}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Stack>
              {/* )} */}
              <Stack sx={{ px: 2 }}>
                {item?.links?.slice().map((links, linksIndex) => (
                  <Grid
                    item
                    key={linksIndex}
                    xs={12}
                    sm={12}
                    alignItems="center"
                    sx={{
                      p: 2,
                      mb: 1,
                      backgroundColor: '#F0F9FF',
                      border: '1px solid #E7EBF1',
                      borderRadius: '8px',
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" alignItems="center">
                        <LinkIcon
                          size={19} strokeWidth={1.5}
                        />
                        <Stack sx={{ px: 2 }}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#565656',
                              fontFamily: 'Montserrat',
                              fontWeight: 600,
                            }}
                          >
                            {links?.name}
                          </Typography>
                          <Link
                            href={`${links?.value}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: '#3C5AA0',
                                fontFamily: 'Montserrat',
                                fontWeight: '600',
                              }}
                            >
                              Link
                            </Typography>
                          </Link>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Pencil
                          size={19} strokeWidth={1.5}
                          onClick={() => {
                            handleOpenAddLinkDialog(item);
                            setLinkItem(links);
                          }}
                        />
                        <Stack
                          sx={{ ml: '10px' }}
                          onClick={(e) => {
                            handleLinkDelete(e, links);
                            setSectionItem(item);
                          }}
                        >
                          <Trash2
                            size={19} strokeWidth={1.5}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openAddSection}
        onClose={handleCloseAddSectionDialog}
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <AddSectionPopUp
          handleCloseAddSectionDialog={handleCloseAddSectionDialog}
          section={sectionValues}
          data={data}
          refetchCourseData={refetchCourseData}
        />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openAddLinkDialog}
        onClose={handleCloseAddLinkDialog}
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <AddLinkPopUp
          handleCloseAddLinkDialog={handleCloseAddLinkDialog}
          linkInputs={linkInputs}
          handleInputChange={handleInputChange}
          handleDeleteLink={handleDeleteLink}
          handleAddLink={handleAddLink}
          data={data}
          sectionPopUp={true}
          section={sectionValues}
          refetchCourseData={refetchCourseData}
          linkData={linkItem}
          courseLinks={''}
        />
      </Dialog>
      {/* edit document */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openEditDocument}
        onClose={handleCloseEditDocument}
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <EditDocumentPopUp
          handleCloseEditDocument={handleCloseEditDocument}
          document={documents}
          data={data}
          refetchCourseData={refetchCourseData}
        />
      </Dialog>

      <Popover
        id={idDeleteSectionPopover}
        open={openSectionPopOver}
        anchorEl={deleteSectionPopOverAnchor}
        onClose={() => handleCloseSectionPopOver()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '13px',
            textAlign: 'center',
            fontWeight: '600',
            fontFamily: 'Montserrat',
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          you want to delete Section?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseSectionPopOver}
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              color: '#3C5AA0',
              '&:hover': {
                borderColor: '#3C5AA0',
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteSections}
              sx={{
                backgroundColor: ' #3C5AA0',
                textTransform: 'capitalize',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  borderColor: '#3C5AA0',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Delete'
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>

      <Popover
        id={idDeleteLinkPopover}
        open={openLinkPopOver}
        anchorEl={deleteLinkAnchor}
        onClose={() => handleCloseLinkPopOver()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '600',
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          Are you sure you want to delete link?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseLinkPopOver}
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              fontFamily: 'Montserrat',
              color: '#3C5AA0',
              '&:hover': {
                borderColor: '#3C5AA0',
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleSectionLinkDelete}
              sx={{
                backgroundColor: ' #3C5AA0',
                textTransform: 'capitalize',
                fontFamily: 'Montserrat',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  borderColor: '#3C5AA0',
                  boxShadow: 'none',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Delete'
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>
      {/* delete document */}
      <Popover
        id={idDeleteDocumentPopover}
        open={openDeleteDocumentPopOver}
        anchorEl={deleteDocumentPopOverAnchor}
        onClose={() => handleCloseDeleteDocument()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '13px',
            textAlign: 'center',
            fontWeight: '600',
            fontFamily: 'Montserrat',
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '12px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          you want to delete document?
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseDeleteDocument}
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              color: '#3C5AA0',
              boxShadow: 'none',
              '&:hover': {
                borderColor: '#3C5AA0',
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeleteSectionDocument}
              sx={{
                backgroundColor: ' #3C5AA0',
                textTransform: 'capitalize',
                boxShadow: 'none',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  borderColor: '#3C5AA0',
                  boxShadow: 'none',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Delete'
              )}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default TestSection;
