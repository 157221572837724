import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { NavLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import { AuthContext } from '../../../../contexts/Auth.context';
import { styles } from './Sidebar.styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import { BoxTickIcon, Setting2Icon } from './../../../../assets/icons';
import store from '../../../../store';
import { BookOpenText, CalendarDays, CalendarSync, Cast, ChartNoAxesCombined, ChevronDown, ChevronUp, Component, Globe, House, Info, NotebookText, ReceiptIndianRupee, TicketPercent } from 'lucide-react';
import { Wallet } from 'lucide-react';
import { Users } from 'lucide-react';
import { Typography } from '@mui/material';



const getIcon = (name: string) => {
  switch (name) {
    case 'HomeIcon':
      return <House strokeWidth={1.5} size={20} />;
    case 'CardTickIcon':
      return <Wallet size={20} strokeWidth={1.5} />;
    case 'IcDashboardIcon':
      return <ReceiptIndianRupee size={20} strokeWidth={1.5} />;
    case 'PeopleIcon':
      return <Users size={20} strokeWidth={1.5} />;
    case 'BoxTickIcon':
      return <BoxTickIcon />;
    case 'SmsTrackingIcon':
      return <NotebookText size={20} strokeWidth={1.5} />;
    case 'CommunityIcon':
      return <Component size={20} strokeWidth={1.5} />;
    case 'HelpOutlineIcon':
      return <HelpOutlineIcon />;
    case 'Setting2Icon':
      return <Setting2Icon />;
    case 'LogoutIcon':
      return <LogoutIcon />;
    case 'AboutIcon':
      return <Info size={20} strokeWidth={1.5} />;
    case 'ExploreIcon':
      return <Globe size={20} strokeWidth={1.5} />;
    case 'QuizOutlinedIcon':
      return <QuizOutlinedIcon />;
    case 'ReportsIcon':
      return <ChartNoAxesCombined size={20} strokeWidth={1.5} />;
    case 'CourseIcon':
      return <BookOpenText size={20} strokeWidth={1.5} />;
    case 'LiveIcon':
      return <Cast size={20} strokeWidth={1.5} />;
    case 'SubScriberIcon':
      return <CalendarSync size={20} strokeWidth={1.5} />;
    case 'AppointmentIcon':
      return <CalendarDays size={20} strokeWidth={1.5} />;
    case 'EventsIcon':
      return <TicketPercent size={20} strokeWidth={1.5} />;
    default:
      return null;
  }
};

// Menu item interface
interface IMenuItem {
  name: string;
  icon: string;
  url: string;
  type?: string;
  children?: IMenuItem[];
}

interface ISidebar {
  open: boolean;
}

export default function Sidebar({ open }: ISidebar) {
  const { logout, ...user } = useContext(AuthContext);

  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const community_id = selectedCommunity?._id;

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const enabledService = useSelector(
    (state: any) => state?.selectedCommunity?.selectedCommunity?.enabledService
  );

  const handleMenuClick = (menu: IMenuItem) => {
    if (menu.url === 'logout') {
      logout();
    } else {
      const isExpanded = expandedItems.includes(menu.name);
      const newExpandedItems = isExpanded
        ? expandedItems.filter((item) => item !== menu.name)
        : [...expandedItems, menu.name];
      setExpandedItems(newExpandedItems);
    }
  };

  const filterMenusBasedOnEnabledService = (menus: IMenuItem[][]) => {
    return menus.map((menuGroup) =>
      menuGroup.filter((menu) => {
        if (!enabledService) return true;

        switch (menu.name) {
          case 'Manage Plans':
            return enabledService.subscription ?? true;
          case 'Manage Events':
            return enabledService.event ?? true;
          case 'Appointments':
            return enabledService.appointment ?? true;
          case 'Manage Courses':
            return enabledService.course ?? true;
          case 'Manage Meetings':
            return enabledService.meeting ?? true;
          default:
            return true;
        }
      })
    );
  };


  const menusList: IMenuItem[][] = [
    [
      {
        name: 'Home',
        icon: 'HomeIcon',
        url: '/home',
        children: [],
      },
      {
        name: 'Manage Members',
        icon: 'PeopleIcon',
        url: '/users',
        children: [],
      },
      {
        name: 'Manage Payments',
        icon: 'CardTickIcon',
        url: '/payments',
        children: [],
      },
      {
        name: 'Manage Reports',
        icon: 'ReportsIcon',
        url: '/subscription-reports',
      },
      {
        name: 'Manage Posts',
        icon: 'SmsTrackingIcon',
        url: '/posts',
      },
      {
        name: 'Manage Plans',
        icon: 'IcDashboardIcon',
        url: '/plans',
        children: [
          {
            name: 'Add Plan',
            icon: 'IcDashboardIcon',
            url: '/create-plan',
          },
          {
            name: 'Plans',
            icon: 'IcDashboardIcon',
            url: '/plans',
          },
        ],
      },
      {
        name: 'Subscribers',
        icon: 'SubScriberIcon',
        url: '/subscribers',
        children: [
          {
            name: 'Add Subscriber',
            icon: 'IcDashboardIcon',
            url: '/create-subscription',
          },
          {
            name: 'Subscribers',
            icon: 'IcDashboardIcon',
            url: '/subscribers',
          },
        ],
      },
      {
        name: 'Manage Meetings',
        icon: 'LiveIcon',
        url: '/meetings',
        children: [
          {
            name: 'Add Meeting',
            icon: 'IcDashboardIcon',
            url: '/create-meeting',
          },
          {
            name: 'Meetings',
            icon: 'IcDashboardIcon',
            url: '/meetings',
          },
        ],
      },
      {
        name: 'Appointments',
        icon: 'AppointmentIcon',
        url: '/appointments',
        children: [
          {
            name: 'Add Appointment',
            icon: 'IcDashboardIcon',
            url: '/add-appointment',
          },
          {
            name: 'Appointments',
            icon: 'IcDashboardIcon',
            url: '/appointments',
          },
        ],
      },
      {
        name: 'Manage Events',
        icon: 'EventsIcon',
        url: '/events',
        children: [
          {
            name: 'Add Event',
            icon: 'IcDashboardIcon',
            url: '/add-event',
          },
          {
            name: 'Events',
            icon: 'IcDashboardIcon',
            url: '/events',
          },
        ],
      },
      {
        name: 'Manage Courses',
        icon: 'CourseIcon',
        url: '/courses',
        children: [
          {
            name: 'Add Course',
            icon: 'IcDashboardIcon',
            url: '/create-course',
          },
          {
            name: 'Courses',
            icon: 'IcDashboardIcon',
            url: '/courses',
          },
        ],
      },
      {
        name: 'Communities',
        icon: 'CommunityIcon',
        url: '/communities',
        children: [],
      },
      ...(loggedInUserData?.user?.role === 'SUPERADMIN'
        ? [
          {
            name: 'All Communities',
            icon: 'CommunityIcon',
            url: '/all-communities',
            children: [],
          },
        ]
        : []),
      {
        name: 'About Community ',
        icon: 'AboutIcon',
        url: `/community/${community_id}/about`,
        children: [],
      },
      {
        name: 'Explore',
        icon: 'ExploreIcon',
        url: '/explore-communities',
        children: [],
      },
    ],
  ];

  const filteredMenusList = filterMenusBasedOnEnabledService(menusList);

  const menuItem = (menu: IMenuItem) => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className="menu-item"
    >
      <Box display="flex" alignItems="center">
        {getIcon(menu.icon)} {open ? menu.name : ''}
      </Box>
      {menu.children &&
        menu.children.length > 0 &&
        open &&
        (expandedItems.includes(menu.name) ? (
          <ChevronUp size={20} strokeWidth={1.5} />
        ) : (
          <ChevronDown size={20} strokeWidth={1.5} />
        ))}
    </Stack>
  );

  return (
    <Box sx={styles}>
      <Stack spacing={open ? 1 : 0} className={open ? 'expanded' : 'collapsed'}>
        {filteredMenusList.map((list: IMenuItem[], i: number) => (
          <React.Fragment key={i}>
            {list.map((menu: IMenuItem, ii: number) => (
              <React.Fragment key={ii}>
                <NavLink
                  to={menu.url}
                  className={({ isActive }) =>
                    `nav-link ${isActive ? 'active' : ''}`
                  }
                  onClick={() => handleMenuClick(menu)}
                >
                  {menuItem(menu)}
                </NavLink>
                {menu.children && menu.children.length > 0 && (
                  <Collapse
                    in={expandedItems.includes(menu.name)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box>
                      <ul>
                        <Stack
                          sx={{
                            fontSize: '13px',
                            marginTop: '-10px',
                            ml: 1,
                            p: 1,
                            color: '#565656',
                          }}
                        >
                          {menu.children.map((subMenu: IMenuItem) => (
                            <li
                              key={subMenu.name}
                              style={{ textDecoration: 'none', padding: '8px' }}
                            >
                              <NavLink
                                className={({ isActive }) =>
                                  `nav-link ${isActive ? 'active' : ''}`
                                }
                                to={subMenu.url}
                              >
                                {subMenu.name}
                              </NavLink>
                            </li>
                          ))}
                        </Stack>
                      </ul>
                    </Box>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
            {filteredMenusList.length > i + 1 && <Divider />}
          </React.Fragment>
        ))}

      </Stack>

      {/* <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', mt: 8, ml: 4 }}>Powered By Communn.io</Typography> */}
    </Box>

  );
}
