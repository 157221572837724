import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { AppBar, styles } from './NavigationBar.styles';
import { Stack } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import { useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  ListItemText,
  Modal,
  Typography,
  styled,
  Tooltip,
  DialogActions,
  DialogProps,
  SelectChangeEvent,
  Fade,
} from '@mui/material';
import SwitchCommunity from './SwitchCommunity';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import store from '../../../../store';
import { useSelector } from 'react-redux';
import ShareCommunity from '../../../Home/ShareCommunity';
import { useCommunity } from '../../../../hooks/useCommunity';
import { USER_APP, USER_APP_BASE_URL } from '../../../../configurations/url.config';
import { useSnackbar } from 'notistack';
import MobileSideBar from '../MobileNav/MobileSidebar';
import { Invitations } from '../../../../models/Invitation.model';
import { useRequests } from '../../../../hooks/useRequests';
import { useInvitations } from '../../../../hooks/useInvitations';
import { BankAccInterface } from '../../../../models/BankAcc.models';
import { IPlan } from '../../../Plans/Plans';
import { useBankAccounts } from '../../../../hooks/useBankAccounts';
import { usePlans } from '../../../../hooks/usePlans';
import { setSelectedCommunityProgress } from '../../../../store/slices/CommunitySlice';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Close from '@mui/icons-material/Close';
import QrCodeScan from '../../../AboutCommunity/QrCode';
import { logoutService } from '../../../../services/logOut.service';
import { NotificationList } from '../../../../hooks/useNotification';
import { INotification } from '../../../../models/notifications.model';
import PlayStore from '../../../../assets/images/google-play-store.svg';
import AppStore from '../../../../assets/images/apple-app-store.svg';
import PlaystoreQR from '../../../../assets/images/playstore.png';
import AppstoreQR from '../../../../assets/images/appstore.png';
import { ArrowLeftRight, Share2, SquareArrowOutUpRight, QrCode, UserPlus, UserRoundPen, Landmark, Settings, Download, LogOut, Cable, Command } from 'lucide-react';


interface INavbarProps { }
// eslint-disable-next-line no-empty-pattern
export default function NavigationBar({ }: INavbarProps) {


  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getCommunityById } = useCommunity();
  const [searchCommunityData, setSearchCommunityData] = useState<null | any>(null);
  const [community_id, setCommunityId] = useState<string | null>(null);
  const { getInvitesForUser } = useInvitations();
  const { getCommunityRequests } = useRequests();
  const [requests, setRequests] = useState<Invitations[]>([]);
  // eslint-disable-next-line
  const [invitedCommunities, setInvitedCommunities] = useState<Invitations[]>([]);
  const { getCommunityPlansList } = usePlans();
  const { getBankDetailById } = useBankAccounts();
  const { getInviteList } = useInvitations();
  // eslint-disable-next-line
  const [plans, setPlans] = React.useState<IPlan[]>([]);
  // eslint-disable-next-line
  const [accountDetails, setAccountDetails] = React.useState<BankAccInterface[]>([]);
  // eslint-disable-next-line
  const [invitesList, setInvitesList] = React.useState<Invitations[]>([]);
  const [progress, setProgress] = React.useState<number>(0);

  const selectedCommunity = useSelector((state: any) => state.selectedCommunity);

  // console.log('selectedCommunityNav', selectedCommunity);
  // const community_id = selectedCommunity.selectedCommunity?._id;
  // ? selectedCommunity.selectedCommunity.id
  // : selectedCommunity.selectedCommunity._id;

  const [notifications, setNotifications] = useState<INotification[]>([]);

  // console.log(notifications, "notifications")

  const { getNotificationList } = NotificationList();

  const loadNotifcations = async () => {
    try {
      const response = await getNotificationList(userId);
      if (response && response.data) {
        const notificationData = response.data.notifications;
        setNotifications(notificationData);
      } else {
        // console.log('No notifications found.');
      }
    } catch (err) {
      console.log('Error: ', err);
    } finally {
    }
  };

  const sentNotificationsCount = notifications.filter((notification) => {
    return (
      notification?.status === 'SENT' &&
      selectedCommunity.selectedCommunity?._id === notification?.communityId
    );
  });

  useEffect(() => {
    loadNotifcations();
  }, []);

  const fetchData = async () => {
    const data = await getInvitesForUser();
    setInvitedCommunities(data);
    const temp = await getCommunityRequests();
    setRequests(temp?.data);
  };

  useEffect(
    () => {
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!community_id && selectedCommunity) {
      setCommunityId(selectedCommunity.selectedCommunity?._id);
    }
  }, [community_id, selectedCommunity]);

  const state = store.getState();
  const loggedInUserData = useSelector(() => { return state?.loggedInUser });

  const usersData = store.getState();
  const [userId] = useState(usersData.loggedInUser.user?.id);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [link, setLink] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const isMenuOpen = Boolean(anchorEl);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const handleProfileMenuOpen = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };
  const checkCommunityProgress = useCallback(async () => {
    if (community_id) {
      setProgress(0);
      if (selectedCommunity?.selectedCommunity?.status === 'CREATED') {
        setProgress((prevProgress) => prevProgress + 10);
      }
      if (selectedCommunity?.selectedCommunity?.status === 'PUBLISHED') {
        setProgress((prevProgress) => prevProgress + 30);
      }
      const plans_data = await getCommunityPlansList(community_id);
      if (plans_data && plans_data?.length > 0) {
        setPlans(plans_data);
        setProgress((prevProgress) => prevProgress + 30);
      }

      const response = await getBankDetailById(community_id);
      if (response && response.data?.length > 0) {
        setAccountDetails(response.data);
        setProgress((prevProgress) => prevProgress + 25);
      }
      const invitesList = await getInviteList(community_id);
      if (invitesList && invitesList.data?.length > 0) {
        setInvitesList(invitesList.data);
        setProgress((prevProgress) => prevProgress + 5);
      }
      store.dispatch(
        setSelectedCommunityProgress({
          selectedCommunityId: community_id,
          progressValue: progress,
        })
      );
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community_id]);

  useEffect(() => {
    if (isProfileMenuOpen) {
      checkCommunityProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileMenuOpen, checkCommunityProgress]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // const handleCloseProfile = () => {
  //   setOpenProfile(false);
  // };
  const open = Boolean(anchorEl);
  const handleClick = () => {
    setOpenModal(true);
  };



  const getData = async (data: any) => {
    const response = await getCommunityById(data._id);
    // console.log('response777', response);
    const fetchedCommunity = response?.data;
    setSearchCommunityData(fetchedCommunity);
    return fetchedCommunity;
  };
  //console.log(searchCommunityData?.name)
  // leave community dialog popup

  const handleCommunitySelect = () => {
    setOpenModal(false);
  };
  // leave community dialog popup//

  // notifications dialog popup //

  // const handleDeleteNotification = (index: any) => {
  //   const newArr = notificationData.filter((item, i) => i !== index);
  //   setNotificationData(newArr);
  // };

  const closeNotificationsDialog = () => {
    setOpenDialog(false);
  };

  const handleShareCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseShere = () => {
    setModalOpen(false);
  };
  const handleInvitationsPage = () => {
    navigate('/invitations');
  };

  // const handleTemplateNavigate = () => {
  //   navigate('/template-select');
  // };
  // const handleNotificationPage = () => {
  //   navigate('/notifications');
  // };

  const handleCopyLink = () => {
    // console.log("Selected Community:", selectedCommunity?.selectedCommunity?.status);

    if (selectedCommunity?.selectedCommunity?.status === 'PUBLISHED') {
      const labelContent = `${USER_APP_BASE_URL}/community/${selectedCommunity?.selectedCommunity?.name
        ?.trim()
        .toLocaleLowerCase()
        .replace(/\s+/g, '-')}/${selectedCommunity?.selectedCommunity?._id}`;

      navigator.clipboard
        .writeText(labelContent)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
          enqueueSnackbar('Link copied successfully', { variant: 'success' });
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
        });
    } else {
      // Handle the case when the status is not "PUBLISHED"
      setLink(
        `/communities/build/${selectedCommunity?.selectedCommunity?._id}`
      );
      enqueueSnackbar('Cannot copy link for non-published items', {
        variant: 'error',
      });
    }
  };

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${selectedCommunity?.selectedCommunity?.name
      ?.trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${selectedCommunity?.selectedCommunity?._id}`
  )}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${selectedCommunity?.selectedCommunity?.name
      ?.trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${selectedCommunity?.selectedCommunity?._id}`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${selectedCommunity?.selectedCommunity?.name
      ?.trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${selectedCommunity?.selectedCommunity?._id}`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${selectedCommunity?.selectedCommunity?.name
      ?.trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${selectedCommunity?.selectedCommunity?._id}`
  )}`;

  // const handleCopyLinkUrl = () => {
  //   const labelContent = `${USER_APP_BASE_URL}/community/${selectedCommunity?.selectedCommunity?.name
  //     ?.trim()
  //     .toLocaleLowerCase()
  //     .replace(/\s+/g, '-')}/${community_id}`;

  //   navigator.clipboard
  //     .writeText(labelContent)
  //     .then(() => {
  //       setUrlCopy(true);
  //       setTimeout(() => setCopiedUrl(false), 3000);
  //       enqueueSnackbar('Link copied successfully', {
  //         variant: 'success',
  //         autoHideDuration: 3000,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error('Error copying text: ', error);
  //     });
  // };

  const styleModal = {
    position: 'fixed' as 'fixed',
    top: { xs: '50%', md: '50%' },
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 'auto', md: '35%' },
    bgcolor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: 5,
    pb: 2,
    pl: 4,
    pr: 4,
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));


  const { id, name } = useParams();
  const location = useLocation();
  const isButtonDisabled =
    location.pathname ===
    `/communities/${selectedCommunity?.selectedCommunity?._id}` ||
    location.pathname ===
    `/get-started/${selectedCommunity?.selectedCommunity?._id}` ||
    location.pathname ===
    `/get-started` ||
    location.pathname ===
    `/subscription-reports` ||
    location.pathname === `/posts/user/${name}/${id}` ||
    location.pathname ===
    `/community/${selectedCommunity?.selectedCommunity?._id}/about` ||
    location.pathname ===
    `/create-community/${selectedCommunity?.selectedCommunity?._id}` ||
    location.pathname ===
    `/communities/build/${selectedCommunity?.selectedCommunity?._id}` ||
    location.pathname ===
    `/communities/${selectedCommunity?.selectedCommunity?._id}/build` ||
    location.pathname ===
    `/communities/${selectedCommunity?.selectedCommunity?._id}` ||
    location.pathname === `/courses/add/${id}` ||
    location.pathname === `/posts/profile/${id}` ||
    location.pathname === `/plans` ||
    location.pathname ===
    `/communities/${selectedCommunity?.selectedCommunity?._id}/plans`;
  const communityNames = searchCommunityData?.title
    ? searchCommunityData.title.length > 0
      ? `${searchCommunityData.title.substring(0, 40)}...`
      : searchCommunityData.title
    : selectedCommunity?.selectedCommunity?.name
      ? selectedCommunity.selectedCommunity.name.length > 40
        ? `${selectedCommunity.selectedCommunity.name.substring(0, 40)}...`
        : selectedCommunity.selectedCommunity.name
      : '...';

  const communityNameCard = searchCommunityData?.title
    ? searchCommunityData.title.length > 0
      ? `${searchCommunityData.title.substring(0, 17)}...`
      : searchCommunityData.title
    : selectedCommunity?.selectedCommunity?.name
      ? selectedCommunity.selectedCommunity.name.length > 17
        ? `${selectedCommunity.selectedCommunity.name.substring(0, 17)}...`
        : selectedCommunity.selectedCommunity.name
      : '...';

  const communityNamesMobile = searchCommunityData?.title
    ? searchCommunityData.title.length > 11
      ? `${searchCommunityData.title.substring(0, 11)}...`
      : searchCommunityData.title
    : selectedCommunity?.selectedCommunity?.name
      ? selectedCommunity.selectedCommunity.name.length > 11
        ? `${selectedCommunity.selectedCommunity.name.substring(0, 11)}...`
        : selectedCommunity.selectedCommunity.name
      : '...';

  // const isButtonDisabled = disabledPaths.includes(location.pathname) || location.pathname === `/community/${community_id}`;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EFEFEF',
      // theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
      // backgroundColor: theme.palette.mode === "light" ? "#EFEFEF" : "#EFEFEF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#7FC41B' : '#7FC41B',
    },
  }));

  const menuId = 'primary-search-account-menu';

  const [opens, setOpens] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [openQrCode, setOpenQrCode] = React.useState(false);
  const [downloadApp, setDownloadApp] = React.useState(false);
  const [fullWidth] = React.useState(true);

  const handleClickOpens = () => {
    setOpens(true);
  };
  const openNotificationsDialog = () => {
    setOpenDialog(true);
  };

  const handleClickopenQrCode = () => {
    setOpenQrCode(true);
  };
  const handleClickCloseQrCode = () => {
    setOpenQrCode(false);
  };

  const handleClickopenApp = () => {
    setDownloadApp(true);
  };
  const handleClickCloseApp = () => {
    setDownloadApp(false);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  const handleLogout = async () => {
    const success = await logoutService();
    if (success) {
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      window.location.reload();
    } else {
      console.error('Logout failed, unable to navigate to login.');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const communityId = `${selectedCommunity?.selectedCommunity?._id}`;
  const communityUrl = `${USER_APP}/community/${selectedCommunity?.selectedCommunity?.name
    ?.trim()
    .toLocaleLowerCase().replace(/\s+/g, '-')}/${communityId}`;

  const [maxImgWidth] = React.useState<DialogProps['maxWidth']>('sm');

  // const androidUrl = "https://play.google.com/store/apps/details?id=com.communn_mobile_app"
  // const IosUrl = "https://apps.apple.com/in/app/onecommunn/id6499468652"

  const renderMenu = (
    <>
      <Box sx={{ borderRadius: '10px' }}>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiMenu-list': {
              borderRadius: '100px !important',
              boxShadow: 'none',
            },
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <Card
            elevation={0}
            sx={{
              height: { xs: '13vh', md: '16vh' },
              width: { lg: '20vw', sm: '80%' },
              backgroundColor: '#E8F3F8',
              m: 2,
              borderRadius: '15px',
            }}
          >
            <Stack
              direction={'row'}
              spacing={3}
              display={'flex'}
              alignItems={'center'}
              sx={{ ml: 2 }}
            >
              <Stack></Stack>
            </Stack>
            <Card
              elevation={0}
              sx={{
                height: { xs: '13vh', md: '16vh' },
                borderRadius: '10px',
                backgroundColor: '#E8F3F8',
                cursor: 'pointer',
                textDecoration: 'none',
                fontFamily: 'Montserrat',
              }}
              component={Link}
              to={`/communities/${selectedCommunity?.selectedCommunity?._id}`}
              onClick={handleProfileClick}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt="Logo"
                    sx={{ backgroundColor: 'white', p: 0.5 }}
                    src={selectedCommunity?.selectedCommunity?.logo}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: 'inline',
                          fontSize: '15px',
                          fontWeight: '600',
                          fontFamily: 'Montserrat',
                        }}
                        component="span"
                        variant="body2"
                        color="text.danger"
                      >
                        {communityNameCard}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: 'inline',
                          fontSize: '13px',
                          fontFamily: 'Montserrat',
                        }}
                        component="span"
                        variant="body2"
                        color="text.danger"
                      >
                        {selectedCommunity?.selectedCommunity?.members?.length}{' '}
                        Members
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Stack sx={{ ml: 2, mr: 2 }}>
                <BorderLinearProgress variant="determinate" value={progress} />
                <Typography
                  sx={{
                    color: '#838383',
                    fontWeight: '400',
                    fontSize: { xs: '11px', md: '13px' },
                    display: 'flex',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Community creation {progress}% Completed
                </Typography>
              </Stack>
            </Card>
          </Card>

          <MenuItem
            component={Link}
            to={`/posts/profile/${userId}`}
            onClick={handleProfileClick}
            sx={{
              minHeight: '0px',
              p: 1,
              mx: 2,
              my: 0.5,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                location.pathname === `/posts/profile/${userId}`
                  ? '#EAF2FF'
                  : 'transparent',
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ color: '#575757', fontWeight: '400' }}
            >
              <Command size={20} strokeWidth={1.5} style={{
                color:
                  location.pathname === `/posts/profile/${userId}`
                    ? '#3C5AA0'
                    : '#575757',
              }} />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Montserrat',
                  color:
                    location.pathname === `/posts/profile/${userId}`
                      ? '#3C5AA0'
                      : '#575757',
                }}
              >
                My Community Profile
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => navigate(`/profile/${userId}`)}
            sx={{
              minHeight: '0px',
              p: 1,
              mx: 2,
              my: 0.5,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                location.pathname === `/profile/${userId}`
                  ? '#EAF2FF'
                  : 'transparent',
            }}
          >
            <Stack
              direction={'row'}
              spacing={1}
              alignItems="center"
              sx={{ color: '#575757', fontWeight: '400' }}
              onClick={handleProfileClick}
            >
              <UserRoundPen size={20} strokeWidth={1.5}
                style={{
                  color: location.pathname === `/profile/${userId}` ? '#3C5AA0' : '#575757',
                  fontFamily: 'Montserrat',
                }} />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Montserrat',
                  color:
                    location.pathname === `/profile/${userId}`
                      ? '#3C5AA0'
                      : '#575757',
                }}
              >
                Edit Profile
              </Typography>
            </Stack>
          </MenuItem>
          {loggedInUserData?.user?.role === `${'SUPERADMIN'}` && (
            <MenuItem
              component={Link}
              to="/admin-bank-details"
              onClick={handleProfileClick}
              sx={{
                minHeight: '0px',
                p: 1,
                mx: 2,
                my: 0.5,
                borderRadius: 2,
                backgroundColor:
                  location.pathname === '/admin-bank-details'
                    ? '#EAF2FF'
                    : 'transparent',
              }}
            >
              <Stack
                direction={'row'}
                spacing={1}
                sx={{ color: '#575757', fontWeight: '400' }}
              >
                <PaidOutlinedIcon
                  sx={{
                    fontSize: '16px',
                    fontFamily: 'Montserrat',
                    color:
                      location.pathname === '/admin-bank-details'
                        ? '#3C5AA0'
                        : '#575757',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                    color:
                      location.pathname === '/admin-bank-details'
                        ? '#3C5AA0'
                        : '#575757',
                  }}
                >
                  PayOut
                </Typography>
              </Stack>
            </MenuItem>
          )}
          <MenuItem
            component={Link}
            to="/payments/bank-details"
            onClick={handleProfileClick}
            sx={{
              minHeight: '0px',
              p: 1,
              mx: 2,
              my: 0.5,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                location.pathname === '/payments/bank-details'
                  ? '#EAF2FF'
                  : 'transparent',
            }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{
                color: '#575757',
                fontWeight: '400',
              }}
            >
              <Landmark size={20} strokeWidth={1.5}
                style={{
                  color:
                    location.pathname === '/payments/bank-details'
                      ? '#3C5AA0'
                      : '#575757',
                }} />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Montserrat',
                  color:
                    location.pathname === '/payments/bank-details'
                      ? '#3C5AA0'
                      : '#575757',
                }}
              >
                Bank Details
              </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            component={Link}
            to="/settings"
            onClick={handleProfileClick}
            sx={{
              minHeight: '0px',
              p: 1,
              mx: 2,
              my: 0.5,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                location.pathname === '/settings' ? '#EAF2FF' : 'transparent',
            }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{
                color: '#575757',
                fontWeight: '400',
              }}
            >
              <Settings size={20} strokeWidth={1.5} style={{
                color:
                  location.pathname === '/settings' ? '#3C5AA0' : '#575757',
              }}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Montserrat',
                  color:
                    location.pathname === '/settings' ? '#3C5AA0' : '#575757',
                }}
              >
                Settings
              </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            component={Link}
            to={'#'}
            onClick={handleClickopenQrCode}
            sx={{
              minHeight: '0px',
              p: 1,
              mx: 2,
              my: 0.5,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Stack
              direction={'row'}
              spacing={1}
              alignItems="center"
              sx={{ color: '#575757', fontWeight: '400' }}
            >

              <QrCode size={20} strokeWidth={1.5} />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Montserrat',
                  color: '#575757',
                }}
              >
                {' '}
                QR Code
              </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            component={Link}
            to={'#'}
            onClick={handleClickopenApp}
            sx={{
              minHeight: '0px',
              p: 1,
              mx: 2,
              my: 0.5,
              borderRadius: 2,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
            }}
          >
            <Stack
              direction={'row'}
              spacing={1}
              alignItems="center"
              sx={{ color: '#575757', fontWeight: '400' }}
              onClick={handleProfileClick}
            >
              <Download size={20} strokeWidth={1.5} />
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#575757',
                  fontFamily: 'Montserrat',
                }}
              >
                Download mobile app
              </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            component={Link}
            to="/integrations"
            sx={{
              minHeight: '0px',
              p: 1,
              mx: 2,
              my: 0.5,
              borderRadius: 2,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              backgroundColor: location.pathname === '/integrations' ? '#EAF2FF' : 'transparent',
            }}
          >
            <Stack
              direction={'row'}
              spacing={1}
              alignItems="center"
              sx={{ color: '#575757', fontWeight: '400' }}
            >

              <Cable size={20} strokeWidth={1.5} style={{
                color:
                  location.pathname === '/integrations'
                    ? '#3C5AA0'
                    : '#575757',
              }} />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Montserrat',
                  color:
                    location.pathname === '/integrations'
                      ? '#3C5AA0'
                      : '#575757',
                }}
              >
                Integrations
              </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            onClick={handleClickOpens}
            sx={{
              minHeight: '0px',
              p: 1,
              mx: 2,
              my: 0.5,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{ color: '#575757', fontWeight: '400' }}
            >

              <LogOut size={20} strokeWidth={1.5} color='#575757' />
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#575757',
                  fontFamily: 'Montserrat',
                }}
              >
                Logout
              </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </Box>

      <Dialog maxWidth={maxWidth} open={opens} onClose={handleCloses}>
        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ backgroundColor: '#F0F9FF' }}
          >
            <Stack></Stack>
            <Stack></Stack>
            <Stack sx={{ backgroundColor: '#F0F9FF' }}>
              <Box
                onClick={handleCloses}
                sx={{
                  backgroundColor: '#50A1CA',
                  padding: '4px',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '10px',
                  mt: '0.5px',
                  mr: '10px',
                  cursor: 'pointer',
                  '&:hover': {
                    // backgroundColor: "#50A1CA", // Background color on hover
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}
              >
                <Close sx={{ fontSize: '15px' }} />
              </Box>
            </Stack>
          </Stack>
        </DialogActions>
        <DialogTitle>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'Montserrat',
              // color: "red",
            }}
          >
            Are you sure you want to logout?
          </Typography>
        </DialogTitle>
        <Divider />

        <Stack
          direction="row"
          sx={{ alignItems: 'center', justifyContent: 'space-evenly' }}
        >
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              onClick={handleLogout}
              sx={{
                cursor: 'pointer',
                fontFamily: 'Montserrat',
                textTransform: 'capitalize',
                color: 'White',
                boxShadow: 'none',
                backgroundColor: ' #3C5AA0',
                '&:hover': {
                  backgroundColor: ' #3C5AA0',
                  boxShadow: 'none',
                },
              }}
            >
              Yes
            </Button>
            {/* <Divider orientation="vertical" style={{ marginLeft: 25 }} /> */}
          </DialogActions>
          <Button
            size="small"
            variant="outlined"
            sx={{
              cursor: 'pointer',
              textTransform: 'capitalize',
              color: '#3C5AA0',
              fontFamily: 'Montserrat',
              border: '1px solid #3C5AA0',
            }}
            onClick={handleCloses}
          >
            No
          </Button>
        </Stack>
      </Dialog>
    </>
  );

  return (
    <Grid container>
      <Stack sx={styles}>
        <AppBar color="transparent">
          <Toolbar>
            <Grid
              item
              xs={1.1}
              md={6}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <MobileSideBar />
              </Box>
            </Grid>
            <Grid item xs={10} md={8.4}>
              <Card
                elevation={0}
                sx={{
                  height: { xs: 35, md: 50 },
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: { xs: '2vw', md: '1vw' },
                  backgroundColor: '#fff',
                  marginLeft: { xs: '0px', md: '-0.56vw' },
                  borderRadius: '10px !important',
                  border: '1px solid #E7EBF1',
                  // boxShadow: '1px 1px 8px 1px rgba(0, 0, 0, 0.05);',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    alt="not Sharp"
                    src={selectedCommunity?.selectedCommunity?.logo}
                    sx={{
                      marginRight: '1vw',
                      width: { xs: '6vw', md: '2vw' },
                      height: { xs: '6vw', md: '2vw' },
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      display: { xs: 'none', md: 'block' },
                      fontSize: { xs: '12px', md: '17px' },
                      fontWeight: 'bold',
                      background:
                        '-webkit-linear-gradient(90deg, #3C5AA0 0%, #50A1CA 100%);',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {communityNames}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      display: { xs: 'block', md: 'none' },
                      fontSize: { xs: '12px', md: '17px' },
                      fontWeight: 'bold',
                      background:
                        '-webkit-linear-gradient(90deg, #3C5AA0 0%, #50A1CA 100%);',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {communityNamesMobile}
                  </Typography>
                </Box>
                <Stack
                  direction={'row'}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                  <Tooltip
                    title={"Preview"}
                    arrow
                    slots={{
                      transition: Fade,
                    }}
                    slotProps={{
                      transition: { timeout: 600 },
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontFamily: "Montserrat",
                          textAlign: 'center'
                        },
                      },
                    }}
                  >
                    <IconButton
                      component="a"
                      href={`${USER_APP_BASE_URL}/community/${selectedCommunity?.selectedCommunity?.name
                        ?.trim()
                        .toLocaleLowerCase()
                        .replace(/\s+/g, '-') || 'default-name'
                        }/${selectedCommunity?.selectedCommunity?._id || 'default-id'}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SquareArrowOutUpRight size={20} strokeWidth={1.5} color='#3C5AA0' />
                    </IconButton>
                  </Tooltip>
                  <Divider
                    orientation="vertical"
                    style={{ color: 'red', height: '50px' }}
                  />
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="text"
                    disableElevation
                    onClick={handleClick}
                    startIcon={
                      <ArrowLeftRight size={20} strokeWidth={1.5} />
                    }
                    sx={{
                      fontSize: { xs: '13px', md: '15px' },
                      textTransform: 'none',
                      color: '#3C5AA0',
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                    }}
                    disabled={isButtonDisabled}
                  >
                    Switch
                  </Button>
                </Stack>
                <Stack
                  direction={'row'}
                  sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                  <Divider
                    orientation="vertical"
                    style={{ color: 'red', height: '50px' }}
                  />

                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="text"
                    disableElevation
                    onClick={handleClick}
                    startIcon={
                      <ArrowLeftRight size={20} strokeWidth={1.5} style={{ marginLeft: '24px' }} />
                    }
                    sx={{
                      fontSize: { xs: '13px', md: '15px' },
                      textTransform: 'none',
                      color: '#3C5AA0',
                      fontFamily: 'Montserrat',
                    }}
                    disabled={isButtonDisabled}
                  ></Button>
                </Stack>
              </Card>
            </Grid>
            <Stack
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                ml: 1,
              }}
              gap={1}
            >
              <Badge badgeContent={requests?.length} color="error">
                <UserPlus size={20} strokeWidth={1.5} color='#3C5AA0' onClick={handleInvitationsPage} />
              </Badge>
              {/* <Badge
                color="error"
                badgeContent={sentNotificationsCount?.length}
              >
                <NotificationsNoneOutlinedIcon
                  onClick={handleNotificationPage}
                  sx={{ color: '#3C5AA0', fontSize: '18px' }}
                />
              </Badge> */}
            </Stack>

            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                },
                alignItems: 'end',
                justifyContent: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <IconButton disableRipple size="large">
                  <Tooltip title="Build Website">
                    <WidgetsOutlinedIcon
                      onClick={handleTemplateNavigate}
                      sx={{ color: '#3C5AA0', fontSize: '23px' }}
                    />
                  </Tooltip>
                </IconButton> */}
                {/* <IconButton size="large" onClick={handleNotificationPage}>
                  <Tooltip title="Notifications">
                    <Badge
                      badgeContent={sentNotificationsCount?.length}
                      color="error"
                    >
                      <NotificationsNoneOutlinedIcon
                        sx={{ color: '#3C5AA0', fontSize: '23px' }}
                      />
                    </Badge>

                  </Tooltip>
                </IconButton> */}
                <IconButton disableRipple size="large" onClick={handleInvitationsPage}>
                  <Tooltip
                    title={"Invitations"}
                    arrow
                    slots={{
                      transition: Fade,
                    }}
                    slotProps={{
                      transition: { timeout: 600 },
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontFamily: "Montserrat",
                          textAlign: 'center'
                        },
                      },
                    }}
                  >
                    <Badge badgeContent={requests?.length} color="error">
                      <UserPlus size={20} strokeWidth={1.5} color='#3C5AA0' />
                    </Badge>
                  </Tooltip>
                </IconButton>
                <IconButton disableRipple size="large" onClick={handleClickopenQrCode}>
                  {selectedCommunity?.selectedCommunity?.status ===
                    'PUBLISHED' ? (
                    <Tooltip
                      title={"QR Code"}
                      arrow
                      slots={{
                        transition: Fade,
                      }}
                      slotProps={{
                        transition: { timeout: 600 },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: "Montserrat",
                            textAlign: 'center'
                          },
                        },
                      }}
                    >
                      <QrCode size={20} strokeWidth={1.5} color='#3C5AA0' />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </IconButton>

                <IconButton disableRipple size="large" onClick={handleShareCommunity}>
                  <Tooltip
                    title={"Share"}
                    arrow
                    slots={{
                      transition: Fade,
                    }}
                    slotProps={{
                      transition: { timeout: 600 },
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontFamily: "Montserrat",
                          textAlign: 'center'
                        },
                      },
                    }}
                  >
                    <Share2 size={20} strokeWidth={1.5} color='#3C5AA0' />
                  </Tooltip>
                </IconButton>


                <Dialog
                  fullWidth={fullWidth}
                  maxWidth={maxImgWidth}
                  open={openQrCode}
                  onClose={handleClickCloseQrCode}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={selectedCommunity?.selectedCommunity?.logo}
                      sx={{
                        backgroundColor: 'white',
                        padding: 1,
                        width: '80px',
                        height: '80px',
                        position: 'fixed',
                        borderRadius: '100px',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 10,
                        boxShadow: 1,
                      }}
                    />
                  </div>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{ backgroundColor: '#ffffff', p: 2 }}
                  >
                    <Stack>
                      <Box
                        sx={{}}
                        component={'img'}
                        src={require('./../../../../assets/images/Communn-favicon.png')}
                        alt=""
                        width={'30%'}
                      />
                    </Stack>
                    <Stack></Stack>
                    <Stack sx={{ backgroundColor: '#ffffff' }}>
                      <Box
                        onClick={handleClickCloseQrCode}
                        sx={{
                          backgroundColor: '#50A1CA',
                          padding: '4px',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontSize: '10px',
                          cursor: 'pointer',
                          '&:hover': {
                            cursor: 'pointer',
                            border: 'none',
                          },
                        }}
                      >
                        <Close sx={{ fontSize: '15px' }} />
                      </Box>
                    </Stack>
                  </Stack>

                  <DialogContent sx={{ padding: '0px' }}>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Stack></Stack>
                      <Stack
                        display={'flex'}
                        alignItems={'center'}
                        sx={{ pb: { xs: 2, md: 5 } }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: '14px', md: '18px' },
                            color: '#2A53A2',
                            fontWeight: 600,
                            fontFamily: 'Montserrat',
                          }}
                        >
                          {communityNames}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: '12px', md: '14px' },
                            color: '#000000',
                            textAlign: 'center',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          Invite members easily—just ask them to scan to join.
                        </Typography>
                        <QrCodeScan id={communityId} url={communityUrl} />
                      </Stack>
                      <Stack></Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
              </div>

              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxImgWidth}
                open={downloadApp}
                onClose={handleClickCloseApp}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    height: '100%',
                  }}
                >
                  <Box
                    component={'img'}
                    src={require('./../../../../assets/images/Communn-favicon.png')}
                    sx={{
                      backgroundColor: 'white',
                      padding: 1,
                      width: '80px',
                      height: '80px',
                      position: 'fixed',
                      borderRadius: '100px',
                      left: '50%',
                      transform: 'translate(-50%, 0)',
                      zIndex: 10,
                      boxShadow: 1,
                    }}
                  />
                </div>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ backgroundColor: '#ffffff', p: 2 }}
                >
                  <Stack>
                    {/* <Box
                      sx={{}}
                      component={'img'}
                      src={require('./../../../../assets/images/Communn-favicon.png')}
                      alt=""
                      width={'30%'}
                    /> */}
                  </Stack>
                  <Stack></Stack>
                  <Stack sx={{ backgroundColor: '#ffffff' }}>
                    <Box
                      onClick={handleClickCloseApp}
                      sx={{
                        backgroundColor: '#50A1CA',
                        padding: '4px',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          cursor: 'pointer',
                          border: 'none',
                        },
                      }}
                    >
                      <Close sx={{ fontSize: '15px' }} />
                    </Box>
                  </Stack>
                </Stack>

                <DialogContent sx={{ padding: '0px' }}>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Stack></Stack>
                    <Stack
                      display={'flex'}
                      alignItems={'center'}
                      sx={{ pb: { xs: 2, md: 5 } }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '13px', md: '16px' },
                          color: '#000000',
                          textAlign: 'center',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        Scan a QR code to <b>download the mobile app</b>
                      </Typography>
                      <Stack
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        gap={5}
                        mt={5}
                      >
                        <Stack alignItems="center" spacing={2}>
                          <Box component="img" src={PlayStore} />
                          <Box
                            component={'img'}
                            src={PlaystoreQR}
                            width={'80%'}
                            sx={{
                              border: '1px solid black',
                              borderRadius: '10px',
                            }}
                          />
                          {/* <DownloadApps id={communityId} url={androidUrl} /> */}
                        </Stack>
                        <Stack alignItems="center" spacing={2}>
                          <Box component="img" src={AppStore} />
                          <Box
                            component={'img'}
                            src={AppstoreQR}
                            width={'90%'}
                            sx={{
                              border: '1px solid black',
                              borderRadius: '10px',
                            }}
                          />
                          {/* <DownloadApps id={communityId} url={IosUrl} /> */}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack></Stack>
                  </Stack>
                </DialogContent>
              </Dialog>
              <IconButton
                disableRipple
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                >
                  <Avatar
                    src={loggedInUserData?.user?.avatar}
                    sx={{ backgroundColor: '#3C5AA0' }}
                  >
                    {loggedInUserData?.user?.avatar
                      ? null
                      : loggedInUserData?.user?.firstName?.charAt(0) +
                      '' +
                      loggedInUserData?.user?.lastName?.charAt(0)}
                  </Avatar>
                </StyledBadge>
              </IconButton>
            </Box>
            <Box
              onClick={handleProfileMenuOpen}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <IconButton
                disableRipple
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar
                  src={loggedInUserData?.user?.avatar}
                  sx={{ backgroundColor: '#3C5AA0' }}
                >
                  {loggedInUserData?.user?.avatar
                    ? null
                    : loggedInUserData?.user?.firstName?.charAt(0) +
                    '' +
                    loggedInUserData?.user?.lastName?.charAt(0)}
                </Avatar>
              </IconButton>
            </Box>
          </Toolbar>
          <Modal
            key={loggedInUserData?.user?.id}
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              <SwitchCommunity
                getData={getData}
                onCommunitySelect={handleCommunitySelect}
              />
            </Box>
          </Modal>
        </AppBar>
        {renderMenu}
      </Stack>
      <ShareCommunity
        isOpen={isModalOpen}
        handleClose={handleCloseShere}
        dpSrc={selectedCommunity?.selectedCommunity?.logo || ''}
        title={selectedCommunity?.selectedCommunity?.name || ''}
        city={selectedCommunity?.selectedCommunity?.city || ''}
        members={selectedCommunity?.selectedCommunity?.members || []}
        communityId={selectedCommunity?.selectedCommunity?._id || ''}
        handleCopyLink={handleCopyLink}
        copied={copied}
        link={link}
        status={selectedCommunity?.selectedCommunity?.status || ''}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={selectedCommunity?.selectedCommunity?.banner || ''}
      />
      {/* {isLoading && <Loader />} */}
    </Grid>
  );
}
