import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { CalenderIcon, CourseIcon, FormsIcon, LinkShareIcon, PaymentsIcon, ProductIcon, WebsiteIcon } from "../../../assets/icons";





const eventsData = [
    {
        id: 1, title: 'Create plan',
        description: 'Create and manage your plans seamlessly.',
        bgColor: '#BC6BFF',
        icon: <ProductIcon />,
        link: '/admin/create-plan',
    },
    {
        id: 2, title: 'Create event',
        description: 'Plan, organize, and host your event seamlessly.',
        bgColor: '#EB5757',
        icon: <CalenderIcon />,
        link: '/admin/add-event',
    },
    {
        id: 3, title: 'Create Appointment',
        description: 'Easily design and launch your personalized website.',
        bgColor: '#63C366',
        icon: <WebsiteIcon />,
        link: '/admin/add-appointment',
    },
    {
        id: 4, title: 'Collect payment',
        description: 'Easily receive payments with secure and efficient methods.',
        bgColor: '#FFC107',
        icon: <PaymentsIcon />,
        link: '/admin/payments',
    },
    {
        id: 5, title: 'Create course',
        description: 'Develop and share your knowledge through a custom course.',
        bgColor: '#FFE17A',
        icon: <CourseIcon />,
        link: '/admin/create-course',
    },
    {
        id: 6, title: 'Create forms',
        description: 'Design and customize forms to collect data effortlessly.',
        bgColor: '#6E8CC2',
        icon: <FormsIcon />,
        link: '/admin/coming-soon',
    },


];



const LinkCards = () => {
    return (
        <>
            <Box >
                <Typography
                    sx={{
                        fontFamily: 'Montserrat',
                        color: '#000000',
                        fontWeight: '600',
                        fontSize: {
                            xs: '15px',
                            sm: '15px',
                            md: '18px',
                            lg: '18px',
                        },
                    }}
                >
                    Empower Your Community
                </Typography>
                <Grid container spacing={2} mt={1} >
                    {eventsData.map((event) => (
                        <Grid item xs={12} sm={6} md={4} key={event.id}>
                            <Stack sx={{ borderRadius: '12px', border: '1px solid #E7EBF1', p: 2 }}>
                                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                    <IconButton sx={{ backgroundColor: event.bgColor }}>
                                        {event.icon}
                                    </IconButton>
                                    <IconButton component={'a'} href={event.link}>
                                        <LinkShareIcon />
                                    </IconButton>
                                </Stack>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        color: '#000000',
                                        fontWeight: '600',
                                        fontSize: {
                                            xs: '15px',
                                            sm: '15px',
                                            md: '14px',
                                            lg: '14px',
                                        },
                                    }}
                                >
                                    {event.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        color: '#000000',
                                        fontWeight: '400',
                                        fontSize: {
                                            xs: '12px',
                                            sm: '12px',
                                            md: '12px',
                                            lg: '12px',
                                        },
                                    }}
                                >
                                    {event.description}
                                </Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Box>


        </>
    )
}
export default LinkCards;