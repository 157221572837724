import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  CardContent,
  Card,
  CardMedia,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  TextField,
  InputAdornment,
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { SubNavbar } from '../Layout/components/SubNavbar';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IMultiMedia } from '../../models/multi-media.model';
import { ICommunity } from '../../models/communities.modal';
import 'simplebar-react/dist/simplebar.min.css';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useCourses } from '../../hooks/useCourses';
import { useSelector } from 'react-redux';
import { updateCourseByCourseId } from '../../services/courses.service';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { useSnackbar } from 'notistack';
import store from '../../store';
import { metaConfig } from '../../utils/constants';
import { DeleteIcon, EditIcon, SearchIcon1 } from '../../assets/icons';
import { getStaticValue } from '../../utils/StaticValues';
import Loader from '../Loader/Loader';
import { ICourse } from './CreateCourse';
import { NoCourses } from '../AlternatePages/NoCourses';
import { ChevronDown, CirclePlus } from 'lucide-react';

interface IParam {
  name: string;
  value: string;
  icon: string;
}
export interface IPlan {
  _id: string;
  name: string;
  description: string;
  image: string;
  parameters: IParam[];
  createdAt: string;
  updatedAt: string;
  status: string;
  duration: string;
  interval: string;
  billingFrequency: string;
  summary: string;
  promocode: string;
  totalPlanValue: number;
  startDate: string;
  offerValue: number;
  community: string;
  images: IMultiMedia | '';
  documents: IMultiMedia | '';
  document: IMultiMedia | '';
  communityData: ICommunity;
  offerEndsDuration: string;
  subscribers: string;
}


// interface Course {
//   _id: string;
//   community: {
//     _id: string;
//     name: string;
//     type: "PRIVATE" | "PUBLIC";
//     status: "PUBLISHED" | "DRAFT";
//     logo: string;
//   };
//   createdBy: string;
//   name: string;
//   description: string;
//   coverImage: {
//     _id: string;
//     type: string;
//     value: string;
//     label: string;
//   };
//   pricing: "PAID" | "FREE";
//   plan: {
//     _id: string;
//     name: string;
//     startDate: string;
//     endDate: string;
//     subscribers: string[];
//     pricing: string;
//   }[];
//   status: "CREATED" | "PUBLISHED";
//   sections: {
//     _id: string;
//     name: string;
//     description: string;
//     createdBy: string;
//     links: {
//       name: string;
//       value: string;
//       _id: string;
//     }[];
//     documents: {
//       _id: string;
//       type: string;
//       value: string;
//       label: string;
//       createdAt: string;
//       updatedAt: string;
//     }[];
//   }[];
//   documents: {
//     _id: string;
//     type: string;
//     value: string;
//     label: string;
//   }[];
//   amount: string;
//   isPaidCourse: boolean;
//   attendees: string[];
//   links: {
//     name: string;
//     value: string;
//     _id: string;
//   }[];
//   createdAt: string;
//   updatedAt: string;
// }


export const Courses = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();


  // courseListByCommunityIdc

  // const handleClickOpenPlans = () => {
  //   setOpenPlans(true);
  // };


  const navigate = useNavigate();

  const handleCreateNewCourse = () => {
    navigate('/create-course');
  };

  const handleNavigate = (row: any) => {
    navigate(`/courses/add-course/${row?._id}`);
  };

  // ****Course list by communityId Api //
  const { courseListByCommunityId } = useCourses();
  const [courseList, setCourseList] = useState<ICourse[]>([]);;

  const [selectedId, setSelectedId] = useState<any>();
  const [selectedRowStatus, setSelectedRowStatus] = useState<any>();
  // console.log(selectedRowStatus);
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );
  const selectedCommunityId = selectedCommunity?.selectedCommunity?._id;

  const [reload, setReload] = useState(false);

  useEffect(() => {
    const loadCourseList = async () => {
      try {
        setLoading(true);
        const data = await courseListByCommunityId(selectedCommunityId);
        if (data && data.courses) {
          setCourseList(data.courses);
        }
      } catch (error) {
        console.error('Error fetching course list:', error);
      } finally {
        setLoading(false);
      }
    };
    loadCourseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId, reload]);

  // table actions
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedId('');
    setSelectedRowStatus('');
  };

  const [courseValues] = useState<any>({
    status: '',
  });
  const handleSubmit = async (e: any, status: string, id: string) => {
    e.preventDefault();

    courseValues.status = status;

    // setLoader(true); // Set loading to true when initiating the request
    if (selectedId || id) {
      // Editing an existing plan
      const updateResponse = await updateCourseByCourseId(
        selectedCommunityId,
        selectedId || id,
        getAccessToken(),
        courseValues,
        null,
        null
      );
      if (updateResponse.data && updateResponse.status === 200) {
        if (status === 'CREATED') {
          enqueueSnackbar('Course Unpublished Succesfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else if (status === 'PUBLISHED') {
          enqueueSnackbar('Course Published Succesfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }

        setReload(!reload);
        handleCloseMenu();
      } else {
        enqueueSnackbar('Failed to update Course', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };
  // console.log(selectedId);

  const usersData = store.getState();


  const filteredCourses = courseList.filter((course) =>
    course?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.courses}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>

      <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, pb: 1 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
        {/* <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px' }} onClick={() => {
                          navigate('/your-target-route');
                      }} /> */}
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '16px' },
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Montserrat',
          }}
        >
          Courses
        </Typography>
      </Stack>

      <SubNavbar>
        <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0}>
          <Stack direction={'row'} flexGrow={1} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: 600,
                color: '#000000',
                fontFamily: 'Montserrat',
              }}
            >
              Courses
            </Typography>
          </Stack>
          <Stack direction={'row'} flexGrow={1} sx={{ justifyContent: { xs: 'space-between', md: 'flex-end' } }} gap={2} >
            <TextField
              placeholder="Search "
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon1 sx={{ mt: 1, }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#F9F9F9',
                  fontSize: { xs: '12px', md: '15px' },
                  height: '37px',
                  width: { xs: '100%', md: '300px' },
                  color: '#000000',
                  fontFamily: 'Montserrat',
                  ml: { xs: -1 },
                  '& input::placeholder': {
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    color: '#9e9e9e',
                    opacity: 1,
                  },
                },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <>
              <Button
                variant="contained"
                onClick={handleCreateNewCourse}
                endIcon={<CirclePlus size={20} strokeWidth={1.5} color="white" />}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  backgroundColor: '#3C5AA0',
                  fontFamily: 'Montserrat',
                  boxShadow: 'none',
                  display: { xs: 'none', md: 'flex' },
                  '&:hover': {
                    backgroundColor: '#3C5AA0',
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}
              >
                Add New
              </Button>
              <IconButton
                onClick={handleCreateNewCourse}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  backgroundColor: '#3C5AA0',
                  fontFamily: 'Montserrat',
                  boxShadow: 'none',
                  display: { xs: 'flex', md: 'none' },
                  '&:hover': {
                    backgroundColor: '#3C5AA0',
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}>

                <CirclePlus size={20} strokeWidth={1.5} color="white" />
              </IconButton>
            </>
          </Stack>
        </Stack >
      </SubNavbar>
      <Stack
        sx={{
          backgroundColor: '#ffffff',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '12px',
          mt: { xs: 2, md: 1 }
        }}
      >
        <Accordion
          sx={{
            boxShadow: 'none',
            backgroundColor: '#ffffff',
            border: '1px solid #E7EBF1',
            borderLeft: '6px solid #D4DDED',
            borderRight: '1px solid #D4DDED',
            borderTop: '1px solid #D4DDED',
            borderBottom: '1px solid #D4DDED',
            '&:hover': {
              borderLeft: '6px solid #3C5AA0',
            },
            borderRadius: '12px',
            '&:last-of-type': {
              borderRadius: '12px',
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ChevronDown strokeWidth={1.5} size={20} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              border: 'none',
              boxShadow: 'none',
              borderRadius: '12px',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: 600,
                color: '#3C5AA0',
                fontFamily: 'Montserrat',
              }}
            >
              Welcome {usersData?.loggedInUser?.user?.firstName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              pl: 1,
              pr: 1,
              backgroundColor: '#f4f4f4',
              border: 'none',
              boxShadow: 'none',
            }}
          >
            <Stack
              gap={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row', sm: 'row', md: 'row' },
                alignItems: { xs: 'center' },
              }}
            >
              <Stack >
                <Box
                  component={'img'}
                  sx={{
                    height: { xs: '50px', md: '60px' },
                    width: { xs: '50px', md: '60px' },
                  }}
                  src={require('../../assets/images/Group (1).png')}
                  alt=""
                />
              </Stack>
              <Stack >
                <Typography
                  sx={{
                    fontSize: { xs: '12px', md: '12px' },
                    fontWeight: 500,
                    color: '#838383',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Give your members content or exclusive ebook and courses to your
                  members
                </Typography>
              </Stack>
            </Stack>

          </AccordionDetails>
        </Accordion>
      </Stack>

      <Stack direction={'column'} spacing={1} mt={1} >
        <Box sx={{ flexGrow: 1, p: 1, bgcolor: '#ffffff', border: '1px solid #E7EBF1', borderRadius: '8px !important', mt: 1, pb: 5, height: '75vh', overflow: 'auto' }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Loader />
            </Box>
          ) : filteredCourses.length === 0 ? (
            <NoCourses />
          ) : (
            <Grid container spacing={1} pb={5}>
              {filteredCourses.map((plan: any, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "8px !important",
                      border: "1px solid #E7EBF1",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="180"
                      src={plan?.coverImage?.value}
                      onClick={() => {
                        handleNavigate(plan);
                      }}
                      sx={{
                        cursor: "pointer",
                        borderRadius: "10px",
                        padding: 1,
                      }}
                    />
                    <CardContent sx={{ pb: 1, "&:last-child": { paddingBottom: "5px" } }}>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() => handleNavigate(plan)}
                      >
                        {plan.name?.length > 20 ? plan?.name?.slice(0, 20) + "..." : plan?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 400,
                          height: "70px",
                        }}
                      >
                        {plan?.description?.length
                          ? plan.description.length > 110
                            ? plan.description.slice(0, 110) + "..."
                            : plan.description
                          : "\u00A0"}
                      </Typography>
                      <Box display="flex" justifyContent="space-between" mt={1} alignItems={"center"}>
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#969696",
                          }}
                        >
                          {plan?.subscribers?.length} Members
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 400,
                            color: plan?.status === "PUBLISHED" ? "#27AE1B" : "#ff4f00",
                          }}
                        >
                          {getStaticValue(plan.status)}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={1} alignItems={"center"}>
                        <Stack>
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 600,
                              color: "#000000",
                            }}
                          >
                            {plan?.amount > 0 && plan?.plan?.length > 0
                              ? `₹ ${plan?.amount} + Plan`
                              : plan?.amount > 0
                                ? `₹ ${plan?.amount}`
                                : plan?.plan.length > 0
                                  ? "Plan"
                                  : "Free"}
                          </Typography>
                        </Stack>
                        <Stack display={"flex"} direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                          <IconButton
                            sx={{ borderRadius: "100px" }}
                            aria-controls={`menu-${index}`}
                            onClick={(event) => {
                              handleClick(event);
                              setSelectedId(plan?._id);
                              setSelectedRowStatus(plan?.status);
                            }}
                          >
                            <MoreVertOutlinedIcon sx={{ fontSize: "15px" }} />
                          </IconButton>
                          <Menu
                            id={`menu-${index}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            elevation={1}
                          >
                            <>
                              {selectedRowStatus === "PUBLISHED" && (
                                <MenuItem
                                  sx={{ fontSize: "13px", fontFamily: "Montserrat" }}
                                  onClick={(e) => handleSubmit(e, "UN_PUBLISHED", "")}
                                >
                                  UnPublish
                                </MenuItem>
                              )}
                              <MenuItem
                                sx={{ fontSize: "13px", fontFamily: "Montserrat" }}
                                onClick={() => {
                                  navigate(`/edit-courses/${selectedId}`);
                                }}
                              >
                                <EditIcon sx={{ fontSize: "15px", marginRight: 1 }} /> Edit
                              </MenuItem>
                              <MenuItem
                                sx={{ fontSize: "13px", fontFamily: "Montserrat" }}
                                onClick={(e) => handleSubmit(e, "INACTIVE", "")}
                              >
                                <DeleteIcon sx={{ fontSize: "15px", marginRight: 1 }} /> Delete
                              </MenuItem>
                            </>
                          </Menu>
                        </Stack>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}


        </Box>
      </Stack>
    </>
  );
};
