import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useSnackbar } from 'notistack';
import { InvitationsBulk } from '../../models/Invitation.model';
import { IColumn } from '../../models/table.model';
import { useSelector } from 'react-redux';
import { useInvitations } from '../../hooks/useInvitations';
import { useNavigate } from 'react-router-dom';

interface ITableProps {
  data: InvitationsBulk[];
  columns: IColumn[];
}

export const BulkInviteTable = ({ columns, data = [] }: ITableProps) => {
  const navigate = useNavigate();
  const { addInvites } = useInvitations();
  const { enqueueSnackbar } = useSnackbar();
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<InvitationsBulk[]>([]);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: 'asc' | 'desc' | undefined;
  }>({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  useEffect(() => {
    if (Array.isArray(data)) {
      setTableData(data);
      setPage(0);
    }
  }, [data]);

  const sortedData = Array.isArray(tableData) ? [...tableData] : [];

  const handleSort = (columnTitle: string) => {
    setSortDirections((prev) => {
      const newDirection = prev[columnTitle] === 'asc' ? 'desc' : 'asc';
      return { [columnTitle]: newDirection };
    });
    setSortedColumns([columnTitle]);
  };

  const getValueByDataKey = (item: any, dataKey: string): any => {
    switch (dataKey) {
      case 'firstName':
        return item?.firstName || '';
      case 'emailId':
        return item?.emailId || '';
      case 'phoneNumber':
        return item?.phoneNumber || '';
      case 'role':
        return item?.role || '';
      case 'message':
        return item?.message || '';
      default:
        return '';
    }
  };

  const sortedColumnTitle = sortedColumns[0];
  if (sortedColumnTitle && sortDirections[sortedColumnTitle]) {
    const sortDirection = sortDirections[sortedColumnTitle];
    const column = columns.find((col) => col?.title === sortedColumnTitle);

    if (column) {
      sortedData.sort((a, b) => {
        const aValue = getValueByDataKey(a, column.dataKey);
        const bValue = getValueByDataKey(b, column.dataKey);

        if (aValue != null && bValue != null) {
          if (sortDirection === 'asc') {
            return aValue.localeCompare
              ? aValue.localeCompare(bValue)
              : aValue - bValue;
          } else {
            return bValue.localeCompare
              ? bValue.localeCompare(aValue)
              : bValue - aValue;
          }
        }
        return 0;
      });
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);

  const handleInviteClick = async () => {
    const formattedData = tableData.map((user) => ({
      firstName: user.firstName,
      lastName: '',
      role: user.role,
      emailId: user.emailId,
      phoneNumber: user.phoneNumber,
      message: user.message,
      status: 'INVITED',
      community: selectedCommunity._id || '',
    }));

    const requestData = {
      data: formattedData,
    };

    const response = await addInvites(requestData, selectedCommunity._id ?? '');

    if (response && response.status === 201) {
      enqueueSnackbar('User Invited Successfully', {
        variant: 'success',
      });
      navigate('/users/invitations');
    } else {
      console.error('Error adding users:', response);
    }
    enqueueSnackbar('Invite data prepared successfully!', {
      variant: 'success',
    });
  };

  return (
    <>
      <SimpleBar style={{ height: '65vh', overflowY: 'auto' }}>
        <Box sx={{ borderRadius: '12px !important', }}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              height: { xs: 'calc(100vh - 35vh)', md: 'calc(100vh - 35vh)' },
              borderRadius: '12px !important',
              border: '1px solid #E7EBF1',
            }}
          >
            <Table
              sx={{
                '& th': {
                  fontSize: '12px',
                  fontWeight: 600,
                  color: '#565656',
                  p: 1,
                  fontFamily: 'Montserrat'
                },
                '& td': {
                  fontSize: '15px',
                  fontWeight: 500,
                  color: '#1A1A1A',
                  p: '5px 10px',
                  '& .MuiTypography-root': { m: 0, fontSize: '11px' },
                },
              }}
            >
              <TableHead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  background: '#f9f9f9',
                }}
              >
                <TableRow>
                  {columns?.map((column: any, i: number) => (
                    <TableCell
                      key={i}
                      onClick={() => handleSort(column.title)}
                      sx={{ width: '100px' }}
                    >
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData?.map((item: any, index) => (
                  <TableRow hover key={index}>
                    {columns.map((column: any, colIndex) => (
                      <TableCell key={colIndex} sx={{ width: '220px' }}>
                        {column.dataKey === 'firstName' ? (
                          <Stack flexDirection={'row'} alignItems={'center'}>
                            <Avatar
                              src={item?.firstName?.charAt(0)}
                              sx={{
                                height: '30px',
                                width: '30px',
                                fontSize: '11px',
                              }}
                              alt={`${item?.firstName}`}
                            />
                            <Typography
                              style={{
                                marginLeft: '8px',
                                fontWeight: 600,
                                fontSize: '12px !important',
                                color: '#1A1A1A',
                                textTransform: 'capitalize',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              {item?.firstName}
                            </Typography>
                          </Stack>
                        ) : column.dataKey === 'emailId' ? (
                          <Typography
                            sx={{
                              fontSize: '12px !important',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {item?.emailId}
                          </Typography>
                        ) : column.dataKey === 'phoneNumber' ? (
                          <Typography
                            sx={{
                              fontSize: '12px !important',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {item?.phoneNumber}
                          </Typography>
                        ) : column.dataKey === 'role' ? (
                          <Typography
                            sx={{
                              textTransform: 'Capitalize',
                              fontSize: '12px !important',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {item?.role}
                          </Typography>
                        ) : column.dataKey === 'message' ? (
                          <Typography
                            sx={{
                              fontSize: '12px !important',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {item?.message}
                          </Typography>
                        ) : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </SimpleBar>

      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mt: 2, mb: 2, mr: 2 }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#3C5AA0',
            textTransform: 'capitalize',
            border: 'none',
            borderRadius: '8px',
            boxShadow: 'none',
            color: 'white',
            cursor: 'pointer',
            fontFamily: 'Montserrat',
            '&:hover': {
              backgroundColor: '#3C5AA0',
              cursor: 'pointer',
              color: 'white',
              border: 'none',
              boxShadow: 'none',
            },
          }}
          onClick={handleInviteClick}
        >
          Send Invite
        </Button>
      </Stack>

      <Stack
        direction={'row-reverse'}
        alignItems={'center'}
        sx={{
          color: '#A5A5A5',
          fontSize: { xs: '10px', md: '1.5vh' },
          pr: { xs: '0px', md: '25px' },
          mb: { xs: 12, md: 0 },
        }}
      >
        <Typography
          sx={{
            color: '#A5A5A5',
            fontSize: '13px',
            display: { xs: 'none', md: 'block' },
            fontFamily: 'Montserrat',
          }}
        >
          Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{' '}
          {data.length} entries
        </Typography>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
              View
            </span>
          }
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: {
                border: '1px solid #A5A5A5',
                borderRadius: '5px',
                backgroundColor: 'white',
                fontFamily: 'Montserrat', fontSize: '14px'
              },
            },

            MenuProps: {
              PaperProps: { style: { border: '1px solid #A5A5A5', fontFamily: 'Montserrat', fontSize: '14px' } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(data.length / rowsPerPage);
            return (
              <Stack
                direction={'row'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
              >
                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)}
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <KeyboardArrowLeft />
                </IconButton>

                {[...Array(3)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: '1.9vh',
                          border:
                            page === pageNumber ? '1px solid #3C5AA0' : 'none',
                          color: page === pageNumber ? '#3C5AA0' : '#000000',
                          width: '30px',
                          height: '30px',
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  }
                  return null;
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)}
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <KeyboardArrowRight />
                </IconButton>
              </Stack>
            );
          }}
        />
      </Stack>


    </>
  );
};
