import { Paper, Stack, Typography } from "@mui/material";


const ComingSoon = () => {
    return (
        <>
            <Paper elevation={0} sx={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <Stack sx={{}}>
                    <Typography
                        sx={{
                            flexShrink: 0,
                            fontFamily: 'Montserrat',
                            fontSize: { xs: '20px', md: '30px' },
                            fontWeight: 600
                        }}
                    >
                        Coming Soon
                    </Typography>
                </Stack>
            </Paper>
        </>
    )
}
export default ComingSoon;