import {
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import { ICommunity } from '../../../models/communities.modal';
import { metaConfig } from '../../../utils/constants';
import { Helmet } from 'react-helmet';
import { AdminCommunities } from '../AllCommunities/AdminCommunities';
import { useCommunity } from '../../../hooks/useCommunity';
import { useEffect, useState } from 'react';
import { RotatingLines } from "react-loader-spinner"

interface ITableProps {
    data: ICommunity[];
    searchQuery: string;
}

const AdminCommunitiesNew = ({ data, searchQuery }: ITableProps) => {
    const { getCommunitiesList } = useCommunity();

    const [communities, setCommunities] = useState<ICommunity[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchCommunitiesList = async () => {
            try {
                setIsLoading(true);
                if (!communities.length) {
                    const communitiesData = await getCommunitiesList();
                    setCommunities(communitiesData);
                }
            } catch (e) {
                setError('An error occurred while fetching communities.');
                console.error('Error occurred while fetching communities:', e);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCommunitiesList();
    }, []);


    const communitiesToRender = communities.length > 0 ? communities : data;


    const filteredCommunities = communitiesToRender.filter((community) =>
        community.name.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const myCommunities = filteredCommunities.map((community) => (

        <Grid item key={community.id} xs={6} sm={2} md={1.5} lg={1.5} xl={1.5}>

            <AdminCommunities {...community} />
        </Grid>
    ));


    return (
        <>
            <Helmet>
                <title>{metaConfig.title.adminCommunities}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>

            {isLoading ? (
                <Stack
                    sx={{
                        color: 'grey.500',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '40vh',
                    }}
                    spacing={2}
                    direction="row"
                >
                    <RotatingLines
                        visible={true}
                        width="30"
                        strokeWidth="5"
                        strokeColor="#777777"
                        ariaLabel="rotating-lines-loading"
                    />
                </Stack>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <Grid container spacing={0}>
                    {myCommunities}
                </Grid>
            )}
        </>
    );
};

export default AdminCommunitiesNew;
