import React, { useContext, useEffect } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
    Chip,
    Checkbox,
    InputAdornment,
    Tooltip,
    Fade,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { ICommunity } from '../../models/communities.modal';
import store from '../../store';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Loader from '../Loader/Loader';
import { IMultiMedia } from '../../models/multi-media.model';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import { usePlans } from '../../hooks/usePlans';
import {
    createCourse,
    updateCourses,
} from '../../services/courses.service';
import { useCourses } from '../../hooks/useCourses';
import { metaConfig } from '../../utils/constants';
import { Helmet } from 'react-helmet';
import BreadcrumbDouble from '../AlternatePages/BreadCumsDouble';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { ImagePlus, IndianRupee, Info } from 'lucide-react';


dayjs.extend(utc);
//Asynchronous load on
interface Community {
    name: string;
    id: string;
}

//
export interface IAddCourse {
    name: string;
    coverImage: string | null;
    description: string;
    endDate?: string;
    documents: string[];
    status: string;
    plan: string[];
    amount: string;
    instructorName: string;
    endDateDurationCount: string;
    endDateDuration: string;
}
interface ISelectedCommunity {
    selectedCommunity: ICommunity[];
    closeDialog: () => void;
    loadPlans: () => void;
}

export interface ICourse {
    _id: string;
    name: string;
    description: string;
    endDate: string;
    pricing: string;
    status: string;
    image: IMultiMedia | '';
    document: string;
    community: string;
    images: IMultiMedia | '';
    amount: string;
    instructorName: string;
}

// duration dropdown values




const expireDropdownOptions: { key: string; value: string; label: string }[] =
    [
        { key: 'YEAR', value: 'Year', label: 'Year' },
        { key: 'MONTH', value: 'Month', label: 'Month' },
        { key: 'WEEK', value: 'Week', label: 'Week' },
        { key: 'DAY', value: 'Day', label: 'Day' },
    ];

export const CreateCourse: React.FC<ISelectedCommunity> = ({
    closeDialog,
    loadPlans,
}) => {
    const navigate = useNavigate();

    const communityState = store.getState();
    const currentCommunity = communityState?.selectedCommunity?.selectedCommunity;
    // console.log(currentCommunity,"currentCommunity")

    const [value, setValue] = React.useState('FREE');


    const [planDetail] = useState<any>();
    const [selectedPlan, setSelectedPlan] = useState<any>(planDetail);

    const { uploadImages } = useWebBuilders();

    const [imageUploading, setImageUploading] = useState(false);
    const [priceType, setPriceType] = useState<'' | 'free' | 'paid'>('');
    const [endDateDuration, setEndDateDuration] = useState<string>('');
    const [disabledPlans, setDisabledPlans] = useState(false);


    // console.log(selectedPlan);

    const [courseValues, setCourseValues] = useState<IAddCourse>({
        name: '',
        description: '',
        endDate: '',
        status: '',
        coverImage: '',
        documents: [],
        plan: selectedPlan?._id,
        amount: '',
        instructorName: '',
        endDateDurationCount: '',
        endDateDuration: '',
    });

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = event.target;
    //     setValue((event.target as HTMLInputElement).value);
    //     if (event.target.value === 'FREE') {
    //     }
    //     setCourseValues((prevValues: any) => ({
    //         ...prevValues,
    //         [name]: value,
    //     }));
    // };


    const handleEndDurationChange = (endDateDuration: string) => {
        setCourseValues((prevValues) => {
            let updatedValues = { ...prevValues, endDateDuration };
            return updatedValues;
        });
    };


    const handleImageChange = async (files: FileList | null) => {
        if (!files?.length) return;
        setImageUploading(true);
        try {
            const file = files[0];
            const [response] = await uploadImages([file]);
            const uploadedImageUrl = response?._id;
            if (!uploadedImageUrl) {
                throw new Error('Image upload failed: No response value');
            }
            setCourseValues((prev) => ({
                ...prev,
                coverImage: uploadedImageUrl,
            }));
            enqueueSnackbar('Banner image uploaded successfully', { variant: 'success' });
        } catch (error) {
            console.error('Error uploading image:', error);
            enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
        } finally {
            setImageUploading(false);
        }
    };

    const { id } = useParams();

    const { getCourseByCourseId } = useCourses();

    const { getAccessToken } = useContext<IAuthContext>(AuthContext);
    const [community] = React.useState(false);
    const [options] = React.useState<Community[]>([]);
    const loading = community && options.length === 0;
    // eslint-disable-next-line
    const [error, setError] = useState(false);
    //attach file logic end
    const { enqueueSnackbar } = useSnackbar();
    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(null);
    // eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    // eslint-disable-next-line
    const [plans, setPlans] = useState<IAddCourse[]>([]);

    useEffect(() => {
        if (!id) return;
        const loadCourseList = async () => {
            const data = await getCourseByCourseId(id || '');
            const firstCourse = data;
            const isPriceFree = Number(data?.amount) === 0;
            const labelEndDateValue: any = expireDropdownOptions.find((e) => e.key === data.endDateDuration);
            setEndDateDuration(labelEndDateValue?.value)
            setCourseValues({
                ...courseValues,
                name: firstCourse?.name,
                description: firstCourse?.description,
                status: firstCourse?.status,
                endDate: formateStartDate(firstCourse?.expiryDate),
                instructorName: firstCourse?.instructorName,
                amount: firstCourse?.amount,
                endDateDurationCount: firstCourse?.endDateDurationCount,
                endDateDuration: firstCourse?.endDateDuration,
                coverImage: firstCourse?.coverImage
            });
            setValue(data?.amount);
            setSelectedPlan(data?.plan || []);
            if (firstCourse?.plan && firstCourse.pricing === 'PAID') {
                setSelectedPlan(firstCourse.plan);
            }
            setSelectedPlan(firstCourse.plan);
            setPriceType(isPriceFree ? 'free' : 'paid');

        };
        loadCourseList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const formateStartDate = (date: string) => {
        const startDate = date?.slice(0, 10);
        setSelectedStartDate(dayjs.utc(startDate));
        return startDate;
    };

    // Functions

    const handleMyDateChange = (e: any) => {
        const selectedNewDate = new Date(e);
        const timezoneOffsetInMinutes = selectedNewDate.getTimezoneOffset();
        selectedNewDate.setMinutes(selectedNewDate.getMinutes() - timezoneOffsetInMinutes);
        const isoDateString = selectedNewDate.toISOString();
        setCourseValues({ ...courseValues, endDate: isoDateString });
    };

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === 'offerValue') {
            const numericValue = parseFloat(value);
            if (!isNaN(numericValue) && numericValue) {
                console.error('Offer Price cannot exceed Actual Price');
                return;
            }
        }
        setCourseValues((prevValues: any) => ({
            ...prevValues,
            [name]: value,
        }));
        setError(value === '');
    };
    // eslint-disable-next-line
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const handleSubmit = async (e: any, status: string) => {
        setIsSubmitClicked(true);
        e.preventDefault();
        courseValues.status = status;
        const filteredPlans = selectedPlan
            ?.filter((plan: any) => plan && typeof plan === 'object' && plan?._id)
            .map((plan: any) => plan._id)
            .filter((id: string) => id?.trim() !== "");
        const updatedCourse = {
            ...courseValues,
            plan: filteredPlans?.length > 0 ? filteredPlans : selectedPlan,
            name: courseValues?.name || '',
            description: courseValues?.description || '',
            endDate: selectedStartDate?.format('YYYY-MM-DD') || '',
            coverImage: courseValues?.coverImage || '67e4fab889d571a22c1f2818',
            amount: courseValues?.amount || '',
            instructorName: courseValues?.instructorName || ''
        };
        console.log(updatedCourse, "updatedCourse");
        try {
            setLoader(true);
            if (id) {
                const updateResponse = await updateCourses(id, updatedCourse, getAccessToken());
                console.log(updateResponse, "updateResponse")
                if (updateResponse.data && updateResponse.status === 200) {
                    enqueueSnackbar('Course updated', {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                    navigate('/courses');
                    closeDialog();
                } else {
                    enqueueSnackbar('Failed to update Course', {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }
            } else {
                const createResponse = await createCourse(currentCommunity?._id ?? '', updatedCourse, getAccessToken());
                setLoader(true);
                if (createResponse.status === 201) {
                    setPlans((prevPlans) => [...prevPlans, createResponse.data]);
                    enqueueSnackbar('Course created successful', {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                    navigate('/courses');
                    closeDialog();
                    loadPlans();
                    setLoader(true);
                } else {
                    enqueueSnackbar('Failed to add course ', {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoader(false);
        }
    };



    const locations = useLocation();
    const IsTitleDisplay = locations.pathname.includes('/plans/new') && id;
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [plansList, setPlansList] = useState<ICourse[]>([]);
    const selectedCommunityId = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity?._id
    );
    const { getPlansList } = usePlans();


    const loadPlansList = async () => {
        try {
            setIsLoading(true);
            if (selectedCommunityId) {
                const plans_data = await getPlansList(selectedCommunityId);

                if (plans_data) {
                    setIsLoading(false);
                    setPlansList(plans_data);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setIsLoading(false);
        }
    };
    // console.log(plansList);
    useEffect(() => {
        loadPlansList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCommunityId]);

    const plansTitles = plansList ? plansList.map((plan) => plan?.name) : [];

    const handlePriceTypeChange = (type: 'free' | 'paid') => {
        setPriceType(type);
        setCourseValues((prev) => ({
            ...prev,
            pricing: type === 'free' ? 0 : prev.amount, // Ensure pricing is retained correctly
        }));
    };



    const data = `
    <strong>Plan + Paid: </strong> <br />
     Requires both an active plan subscription and additional payment; includes full community membership benefits plus course access; automatically expires when their plan ends.<br/>
    <strong>Plan + Free: </strong>
    <br/> Available exclusively to members with an active plan subscription at no additional cost; includes full community membership benefits plus course access; automatically expires when their plan ends. <br/>
    <strong>No Plan + Paid: </strong>
    <br/> Requires payment for direct course access(no community membership) access valid until the set expiry date <br/>
    <strong>No Plan + Free:  </strong>
    <br/> Available to anyone without requiring community membership or payment; provides course-only access valid until the set expiry date. <br/>
   `;




    return (
        <>
            <Helmet>
                <title>{metaConfig.title.addCourses}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>

            <BreadcrumbDouble
                items={[
                    { label: 'Courses', path: '/courses' },
                    { label: `${id ? "Edit Courses" : "Create Course"}` }
                ]}
            />
            <div>
                {loading ? (
                    <Loader />
                ) : (
                    <Stack direction={'column'} spacing={1}>
                        <Stack sx={{ display: IsTitleDisplay ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    color: '#3760A9',
                                    fontFamily: 'Montserrat',
                                }}
                            >
                                {id ? 'Publish' : 'Create'} a Course
                            </Typography>
                        </Stack>

                        <Box
                            sx={{
                                p: 1,
                                borderRadius: '12px !important', border: '1px solid #E7EBF1', backgroundColor: '#ffffff',
                                height: { xs: 'fit-content', md: 'fit-content' },
                            }}
                        >
                            <>
                                <Box
                                    sx={{
                                        borderRadius: '10px',
                                        backgroundColor: '#ffffff',
                                    }}
                                >
                                    <Grid container sx={{ borderRadius: '10px' }} >
                                        <Grid item xs={12} md={6}>
                                            <Stack spacing={1} sx={{ m: 1 }}>
                                                <Stack>
                                                    <Stack>
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: '14px',
                                                                color: '#000000',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Course Name
                                                        </Typography>
                                                        <TextField
                                                            required
                                                            name="name"
                                                            label="Name"
                                                            size="small"
                                                            onChange={handleChanges}
                                                            value={courseValues?.name}

                                                            InputLabelProps={{
                                                                style: { fontSize: '13px', color: '#565656' },
                                                            }}
                                                            sx={{
                                                                mt: 1,
                                                                mr: 1,
                                                                mb: 1,
                                                                fontSize: '13px',
                                                                backgroundColor: '#F9F9F9',
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Montserrat',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius: '8px',
                                                                },
                                                                '& input': {
                                                                    fontFamily: 'Montserrat',

                                                                },
                                                            }}
                                                        ></TextField>
                                                    </Stack>
                                                </Stack>

                                                <Stack>
                                                    <Stack>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                color: '#000000',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Course Description
                                                        </Typography>
                                                        <TextField
                                                            name="description"
                                                            id="description"
                                                            label="Description"
                                                            size="medium"
                                                            value={courseValues?.description}
                                                            onChange={handleChanges}
                                                            multiline
                                                            rows={3}
                                                            InputLabelProps={{
                                                                style: { fontSize: 13, color: '#565656' },
                                                            }}
                                                            sx={{
                                                                fontFamily: 'Montserrat',
                                                                mt: 1, mr: 1,
                                                                backgroundColor: '#F9F9F9',
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Montserrat',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius: '8px',
                                                                },
                                                                '& textarea': {
                                                                    fontFamily: 'Montserrat',

                                                                },
                                                            }}
                                                        ></TextField>
                                                    </Stack>
                                                </Stack>
                                                <Stack>
                                                    <Stack>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                color: '#000000',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Instructor Name
                                                        </Typography>
                                                        <TextField
                                                            name="instructorName"
                                                            id="instructorName"
                                                            label="Instructor"
                                                            size="small"
                                                            value={courseValues?.instructorName}
                                                            onChange={handleChanges}

                                                            InputLabelProps={{
                                                                style: { fontSize: 13, color: '#565656' },
                                                            }}
                                                            sx={{
                                                                fontFamily: 'Montserrat',
                                                                mt: 1, mr: 1,
                                                                backgroundColor: '#F9F9F9',
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Montserrat',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius: '8px',
                                                                },
                                                                '& input': {
                                                                    fontFamily: 'Montserrat',

                                                                },
                                                            }}
                                                        ></TextField>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            <Stack>
                                                <Stack>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                            fontWeight: '500',
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        Cover Image
                                                    </Typography>
                                                    <Stack
                                                        sx={{
                                                            backgroundColor: '#F5F5F5',
                                                            p: 1.5,
                                                            m: 1,

                                                            borderRadius: '5px',
                                                            border: '1px dashed #000000',
                                                            height: { xs: '20vh', md: '25vh' },
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            backgroundImage: courseValues?.coverImage
                                                                ? `url(${courseValues?.coverImage})`
                                                                : 'none',
                                                            backgroundPosition: 'center',
                                                        }}
                                                    >
                                                        {imageUploading ? (
                                                            <Loader />
                                                        ) : courseValues?.coverImage ? (
                                                            <></>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                        <ImagePlus size={30} strokeWidth={1.5} color='#5E6C84' />
                                                        <Typography
                                                            sx={{ fontSize: '13px', color: '#5E6C84', pb: 1, fontFamily: 'Montserrat' }}
                                                        >
                                                            Upload Cover Image
                                                        </Typography>
                                                        <input
                                                            type="file"
                                                            name="images"
                                                            onChange={(e) => handleImageChange(e.target.files)}
                                                            style={{
                                                                border: '2px solid #5E6C84',
                                                                borderRadius: '5px',
                                                                padding: '8px',
                                                                backgroundColor: '#ffffff',
                                                                color: '#5E6C84',
                                                                fontSize: '10px',
                                                                fontWeight: '500',
                                                                cursor: 'pointer',
                                                                width: '70%',
                                                                marginTop: '10px',
                                                            }}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Stack sx={{ pt: 1, pl: 0.5, pr: 0.5 }}>
                                                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontSize: '14px',
                                                            color: '#000000',
                                                            fontWeight: '500',
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        Pricing
                                                    </Typography>
                                                    <Tooltip
                                                        title={
                                                            <span
                                                                dangerouslySetInnerHTML={{ __html: data }}
                                                            />
                                                        }
                                                        arrow
                                                        slots={{
                                                            transition: Fade,
                                                        }}
                                                        placement='right'

                                                        slotProps={{
                                                            transition: { timeout: 600 },
                                                        }}
                                                        componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    fontFamily: "Montserrat",
                                                                    backgroundColor: '#F9F9F9',
                                                                    color: '#000000',
                                                                    borderRadius: '10px',
                                                                    border: '1px solid #E7EBF1'

                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <Info size={15} strokeWidth={1.5} color='#646464' style={{ cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </Stack>

                                                <Stack sx={{}}>
                                                    <Autocomplete
                                                        // disabled={!!id}
                                                        options={["No Plans", ...plansTitles]}
                                                        size="small"
                                                        disablePortal
                                                        disableCloseOnSelect
                                                        id="combo-box-demo"
                                                        multiple
                                                        loading={isLoading}
                                                        sx={{
                                                            mt: 1,
                                                            fontSize: "12px",
                                                            backgroundColor: "#F9F9F9",
                                                            fontFamily: "Montserrat",
                                                            borderRadius: "8px",
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: "8px",
                                                            },
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                required
                                                                {...params}
                                                                label="Select Plans"
                                                                sx={{
                                                                    "& .MuiOutlinedInput-root": {
                                                                        borderRadius: "8px",
                                                                    },
                                                                    "& .MuiInputLabel-root": { fontSize: "13px", fontFamily: "Montserrat" },
                                                                    "& .MuiInputLabel-root.Mui-focused": { fontSize: "13px", fontFamily: "Montserrat" },
                                                                    "& input": { fontFamily: "Montserrat" },
                                                                }}
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            sx: { fontSize: "13px", fontFamily: "Montserrat", borderRadius: "30px" },
                                                        }}
                                                        noOptionsText="No Plans"
                                                        value={selectedPlan?.length === 0 ? ['No Plans'] : selectedPlan?.map((plan: { name: any }) => plan.name)}

                                                        onChange={(event, newValue) => {
                                                            if (newValue.includes("No Plans")) {
                                                                setSelectedPlan([]);
                                                                setDisabledPlans(true);
                                                            } else {
                                                                setDisabledPlans(false);
                                                                setSelectedPlan(plansList.filter(plan => newValue.includes(plan.name)));
                                                            }
                                                        }}
                                                        renderOption={(props, option, { selected }) => {
                                                            const isNoPlans = option === "No Plans";
                                                            const isDisabled = disabledPlans && !isNoPlans;
                                                            const isNoPlansDisabled = selectedPlan?.length > 0 && isNoPlans;

                                                            return (
                                                                <li
                                                                    {...props}
                                                                    style={{
                                                                        pointerEvents: isDisabled || isNoPlansDisabled ? "none" : "auto",
                                                                        opacity: isDisabled || isNoPlansDisabled ? 0.5 : 1,
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        checked={isNoPlans ? selectedPlan?.length === 0 : selected}
                                                                        sx={{
                                                                            marginRight: 1,
                                                                            padding: 0,
                                                                            color: "#646464",
                                                                            borderRadius: "0px",
                                                                            "& .MuiSvgIcon-root": {
                                                                                fontSize: 18,
                                                                                border: "1px solid #000000",
                                                                                borderRadius: "4px",
                                                                                color: selected ? "#2653A3" : "transparent",
                                                                                backgroundColor: selected ? "#e3f2fd" : "transparent",
                                                                            },
                                                                            "&:hover .MuiSvgIcon-root": {
                                                                                borderColor: "#1976d2",
                                                                            },
                                                                        }}
                                                                    />
                                                                    {option}
                                                                </li>
                                                            );
                                                        }}
                                                        renderTags={(value, getTagProps) => {
                                                            if (value.includes("No Plans")) {
                                                                return (
                                                                    <Chip
                                                                        label="No Plans"
                                                                        sx={{ fontSize: "12px", height: "3vh" }}
                                                                    />
                                                                );
                                                            }


                                                            const visiblePlans = value.slice(0, 1);
                                                            const remainingPlansCount = value.length - 1;

                                                            return [
                                                                ...visiblePlans.map((option, index) => (
                                                                    <Chip
                                                                        label={option}
                                                                        {...getTagProps({ index })}
                                                                        sx={{ fontSize: "12px", height: "3vh" }}
                                                                    />
                                                                )),
                                                                remainingPlansCount > 0 && (
                                                                    <Chip
                                                                        key="more"
                                                                        label={`+${remainingPlansCount}`}
                                                                        sx={{ fontSize: "12px", height: "3vh" }}
                                                                    />
                                                                ),
                                                            ];
                                                        }}
                                                    />
                                                    {/* <Autocomplete
                                                        disabled={!!id}
                                                        options={['No Plans', ...plansTitles]}
                                                        size="small"
                                                        disablePortal
                                                        disableCloseOnSelect
                                                        id="combo-box-demo"
                                                        multiple
                                                        loading={isLoading}
                                                        sx={{
                                                            mt: 1, fontSize: '12px',
                                                            backgroundColor: '#F9F9F9',
                                                            fontFamily: 'Montserrat',
                                                            borderRadius: '8px',
                                                            '& .MuiOutlinedInput-root': {
                                                                borderRadius: '8px',
                                                            },
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                required
                                                                {...params}
                                                                label="Select Plans"
                                                                sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        borderRadius: '8px',
                                                                    },
                                                                    '& .MuiInputLabel-root': { fontSize: '13px', fontFamily: 'Montserrat' },
                                                                    '& .MuiInputLabel-root.Mui-focused': { fontSize: '13px', fontFamily: 'Montserrat' },
                                                                    '& input': { fontFamily: 'Montserrat' },
                                                                }}
                                                            />
                                                        )}
                                                        ListboxProps={{
                                                            sx: { fontSize: '13px', fontFamily: 'Montserrat', borderRadius: '30px' },
                                                        }}
                                                        noOptionsText="No Plans"


                                                        value={selectedPlan?.length === 0 ? ['No Plans'] : selectedPlan?.map((plan: { name: any; }) => plan.name)}

                                                        onChange={(event, newValue) => {
                                                            if (newValue.includes('No Plans')) {
                                                                setSelectedPlan([]);
                                                            } else {
                                                                setSelectedPlan(plansList?.filter(plan => newValue.includes(plan.name)) || []);
                                                            }
                                                        }}

                                                        renderOption={(props, option, { selected }) => {
                                                            const isNoPlansSelected = selectedPlan?.length === 0;
                                                            const isNoPlans = option === 'No Plans';
                                                            const isDisabled = isNoPlansSelected && !isNoPlans;

                                                            return (
                                                                <li
                                                                    {...props}
                                                                    style={{
                                                                        pointerEvents: isDisabled ? 'none' : 'auto',
                                                                        opacity: isDisabled ? 0.5 : 1
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        checked={isNoPlans ? selectedPlan?.length === 0 : selected}
                                                                        sx={{
                                                                            marginRight: 1,
                                                                            padding: 0,
                                                                            color: '#646464',
                                                                            borderRadius: '0px',
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 18,
                                                                                border: '1px solid #000000',
                                                                                borderRadius: '4px',
                                                                                color: selected ? '#2653A3' : 'transparent',
                                                                                backgroundColor: selected ? '#e3f2fd' : 'transparent',
                                                                            },
                                                                            '&:hover .MuiSvgIcon-root': {
                                                                                borderColor: '#1976d2',
                                                                            },
                                                                        }}
                                                                    />
                                                                    {option}
                                                                </li>
                                                            );
                                                        }}

                                                        renderTags={(value, getTagProps) => {
                                                            if (value.includes('No Plans')) {
                                                                return (
                                                                    <Chip
                                                                        label="No Plans"
                                                                        sx={{ fontSize: '12px', height: '3vh' }}
                                                                    />
                                                                );
                                                            }


                                                            const visiblePlans = value.slice(0, 1);
                                                            const remainingPlansCount = value.length - 1;

                                                            return [
                                                                ...visiblePlans.map((option, index) => (
                                                                    <Chip
                                                                        label={option}
                                                                        {...getTagProps({ index })}
                                                                        sx={{ fontSize: '12px', height: '3vh' }}
                                                                    />
                                                                )),
                                                                remainingPlansCount > 0 && (
                                                                    <Chip
                                                                        label={`+${remainingPlansCount}`}
                                                                        sx={{ fontSize: '12px', height: '3vh' }}
                                                                    />
                                                                ),
                                                            ];
                                                        }}
                                                    /> */}
                                                </Stack>
                                                <Stack flexDirection={'row'} alignItems={'center'} mb={1} mt={1}>
                                                    <Button
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontFamily: 'Montserrat',
                                                            fontWeight: '400',
                                                            p: '7px 20px',
                                                            border:
                                                                priceType === 'paid'
                                                                    ? '1px solid #3760A9'
                                                                    : '1px solid #E7EBF1',
                                                            borderRadius: '10px',
                                                            color: priceType === 'paid' ? '#2653A3' : '#646464',
                                                            backgroundColor:
                                                                priceType === 'paid' ? '#D4DDED4D' : '#ffffff',
                                                            m: 1,
                                                            ml: 0,
                                                            textTransform: 'capitalize',
                                                        }}
                                                        onClick={() => handlePriceTypeChange('paid')}
                                                    >
                                                        Paid
                                                    </Button>

                                                    <Button
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontFamily: 'Montserrat',
                                                            fontWeight: '400',
                                                            p: '7px 20px',
                                                            border: priceType === 'free'
                                                                ? '1px solid #3760A9'
                                                                : '1px solid #E7EBF1',
                                                            borderRadius: '10px',
                                                            color: priceType === 'free' ? '#2653A3' : '#646464',
                                                            backgroundColor: priceType === 'free' ? '#D4DDED4D' : '#ffffff',
                                                            m: 1,
                                                            ml: 0,
                                                            textTransform: 'capitalize',
                                                        }}
                                                        onClick={() => handlePriceTypeChange('free')}
                                                    >
                                                        Free
                                                    </Button>

                                                    {priceType === 'paid' ? (
                                                        <>
                                                            <TextField
                                                                fullWidth
                                                                value={courseValues?.amount}
                                                                name="amount"
                                                                label="Enter Price"
                                                                size="small"
                                                                onChange={handleChanges}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <IndianRupee size={20} strokeWidth={1.5} />
                                                                        </InputAdornment>
                                                                    ),
                                                                    inputProps: {
                                                                        pattern: '[0-9]*',
                                                                        inputMode: 'numeric',
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    style: {
                                                                        fontSize: '13px',
                                                                        color: '#646464',
                                                                        fontFamily: 'Montserrat',
                                                                    },
                                                                }}
                                                                sx={{
                                                                    fontSize: '13px',
                                                                    backgroundColor: '#F9F9F9',
                                                                    fontFamily: 'Montserrat',
                                                                    '& .MuiOutlinedInput-root': {
                                                                        borderRadius: '8px',
                                                                    },
                                                                    '& input': {
                                                                        fontFamily: 'Montserrat',
                                                                    },
                                                                }}
                                                                onInput={(e: any) => {
                                                                    e.target.value = e.target.value.replace(
                                                                        /[^0-9]/g,
                                                                        ''
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>

                                                        </>
                                                    )}
                                                </Stack>
                                                <Stack>
                                                    <Typography
                                                        sx={{
                                                            fontSize: { xs: '12px', md: '13px' },
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        {selectedPlan?.length > 0 && priceType === 'paid' ? (
                                                            "*Requires both an active plan subscription and additional payment; includes full community membership benefits plus course access; automatically expires when their plan ends."
                                                        ) : selectedPlan?.length > 0 && priceType === 'free' ? (
                                                            "Available exclusively to members with an active plan subscription at no additional cost; includes full community membership benefits plus course access; automatically expires when their plan ends."
                                                        ) : selectedPlan?.length === 0 && priceType === 'paid' ? (
                                                            "Requires payment for direct course access (no community membership); access valid until the set expiry date."
                                                        ) : selectedPlan?.length === 0 && priceType === 'free' ? (
                                                            "Available to anyone without requiring community membership or payment; provides course-only access valid until the set expiry date."
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            {(selectedPlan === null || selectedPlan?.length === 0) && (
                                                <Stack spacing={1} sx={{ m: 1 }}>
                                                    <Stack sx={{}}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                color: '#000000',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Expiry date of course
                                                        </Typography>
                                                    </Stack>
                                                    <Grid container gap={2}>
                                                        <Grid xs={12} md={5.8}>
                                                            <FormControl fullWidth size="small" >
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="duration"
                                                                    label="Plan Duration"
                                                                    name="duration"


                                                                    sx={{
                                                                        mt: 1,
                                                                        backgroundColor: '#F9F9F9',
                                                                        borderRadius: '8px',
                                                                        '& .MuiOutlinedInput-root': {
                                                                            borderRadius: '8px',
                                                                        },
                                                                        '& input': {
                                                                            fontFamily: 'Montserrat',
                                                                        },
                                                                    }}
                                                                    onChange={
                                                                        (e) => {
                                                                            setEndDateDuration(e.target.value);
                                                                        }
                                                                    }
                                                                    value={endDateDuration}
                                                                >
                                                                    {expireDropdownOptions.map((option) => (
                                                                        <MenuItem
                                                                            key={option.key}
                                                                            value={option.value}
                                                                            onClick={(e) =>
                                                                                handleEndDurationChange(option.key)
                                                                            }
                                                                            sx={{ fontFamily: 'Montserrat' }}
                                                                        >
                                                                            {option.value}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid xs={12} md={5.8}>
                                                            <TextField
                                                                sx={{
                                                                    mt: 1,
                                                                    backgroundColor: '#F9F9F9',
                                                                    borderRadius: "8px",
                                                                    "& .MuiInputLabel-root": {
                                                                        fontFamily: "Montserrat",
                                                                    },
                                                                    "& .MuiOutlinedInput-root": {
                                                                        borderRadius: "8px",
                                                                    },
                                                                    "& input": {
                                                                        fontFamily: "Montserrat",

                                                                    },
                                                                }}
                                                                required
                                                                fullWidth
                                                                onChange={handleChanges}
                                                                name="endDateDurationCount"
                                                                id="endDateDurationCount"
                                                                value={courseValues?.endDateDurationCount}
                                                                label="Enter Day, Month, Year"
                                                                size="small"
                                                                type="number"
                                                                inputProps={{
                                                                    inputMode: 'numeric',
                                                                    pattern: '[0-9]*',
                                                                    min: 1,
                                                                    onInput: (e: any) => {
                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    style: { fontSize: 13, color: "#565656" },
                                                                }}
                                                                InputProps={{

                                                                }}
                                                            />

                                                        </Grid>

                                                    </Grid>
                                                </Stack>
                                            )}
                                        </Grid>

                                        {/* <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <Stack spacing={1} sx={{ m: 1 }}>
                                                <Stack sx={{}}>
                                                    <Typography
                                                        sx={{

                                                            fontSize: '13px',
                                                            color: '#2653A3',
                                                            fontWeight: '500',
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        Expiry date of course
                                                    </Typography>
                                                </Stack>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer
                                                        components={['DesktopDatePicker']}
                                                        sx={{
                                                            overflow: 'hidden',
                                                            backgroundColor: '#F9F9F9',
                                                            mt: 1,
                                                            p: 0,
                                                        }}
                                                    >
                                                        <DatePicker
                                                            value={selectedStartDate}
                                                            disablePast
                                                            onChange={handleMyDateChange}
                                                            sx={{
                                                                fontSize: '12px',
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Montserrat',
                                                                },
                                                                backgroundColor: '#F9F9F9',
                                                                '& .css-q9e13y-MuiStack-root>.MuiTextField-root':
                                                                {
                                                                    backgroundColor: '#F9F9F9',
                                                                },
                                                            }}
                                                            slotProps={{
                                                                textField: {

                                                                    size: 'small',
                                                                    fullWidth: true,
                                                                    sx: {
                                                                        '& .MuiOutlinedInput-root': {
                                                                            borderRadius: '8px',
                                                                        },

                                                                        '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                                                                        {
                                                                            // Styling for the label
                                                                            color: '#00000061',
                                                                        },
                                                                        '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                                                        {
                                                                            // Styling for the input box
                                                                            borderColor: 'green',
                                                                            border: '1px solid',
                                                                        },
                                                                        '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                                                                        {
                                                                            color: '#92929D',
                                                                            fontSize: '13px',
                                                                        },
                                                                        '& .MuiInputLabel-root': {
                                                                            fontSize: '13px',
                                                                        },
                                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                                            fontSize: '13px',
                                                                        },
                                                                    },
                                                                },
                                                                layout: {
                                                                    sx: {
                                                                        color: '#2653A3',
                                                                        borderRadius: '20px',
                                                                        borderWidth: '0px',
                                                                        borderColor: '#2653A3',
                                                                        border: '0px solid',
                                                                        backgroundColor: 'white',
                                                                        fontFamily: 'Montserrat',
                                                                        boxShadow: 'none'
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Stack>

                                            <Stack sx={{ mt: 2 }}>
                                                <Stack>
                                                    <Typography
                                                        sx={{
                                                            mt: 1,
                                                            fontSize: '13px',
                                                            color: '#2653A3',
                                                            fontWeight: '500',
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        Pricing
                                                    </Typography>
                                                </Stack>

                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="pricing"
                                                        value={value}
                                                        onChange={handleChange}
                                                    >
                                                        <FormControlLabel
                                                            value="FREE"
                                                            control={<Radio
                                                                sx={{
                                                                    color: '#888',
                                                                    '&.Mui-checked': {
                                                                        color: '#2653A3',
                                                                    },
                                                                }}
                                                            />}
                                                            label="Free"
                                                            sx={{
                                                                fontFamily: 'Montserrat',
                                                                '& .MuiInputLabel-root': {
                                                                    fontFamily: 'Montserrat',
                                                                },
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontFamily: 'Montserrat',
                                                                    fontSize: '14px',
                                                                },
                                                            }}
                                                        />
                                                        {currentCommunity?.collectSubscription ===
                                                            'YES' ? (
                                                            <FormControlLabel
                                                                value="PAID"
                                                                control={<Radio
                                                                    sx={{
                                                                        color: '#888',
                                                                        '&.Mui-checked': {
                                                                            color: '#2653A3',
                                                                        },
                                                                    }} />}
                                                                label="Paid"
                                                                sx={{
                                                                    fontFamily: 'Montserrat',
                                                                    '& .MuiInputLabel-root': {
                                                                        fontFamily: 'Montserrat',
                                                                    },
                                                                    '& .MuiFormControlLabel-label': {
                                                                        fontFamily: 'Montserrat',
                                                                        fontSize: '14px',
                                                                    },
                                                                }}
                                                            />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Stack>

                                            {value === "PAID" ? (
                                                <Grid item xs={12}>
                                                    <Stack sx={{}}>
                                                        <Typography
                                                            sx={{
                                                                ml: 1,

                                                                fontSize: '13px',
                                                                color: '#2653A3',
                                                                fontWeight: '500',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            Select Plan
                                                        </Typography>
                                                    </Stack>{' '}
                                                    <Stack sx={{}}>
                                                        <Autocomplete
                                                            disabled={!!id}
                                                            options={['Select All', ...plansTitles]}
                                                            size="small"
                                                            disablePortal
                                                            disableCloseOnSelect
                                                            id="combo-box-demo"
                                                            multiple
                                                            loading={isLoading}
                                                            sx={{
                                                                mt: 1, fontSize: '12px',
                                                                backgroundColor: '#F9F9F9',
                                                                fontFamily: 'Montserrat',
                                                                borderRadius: '8px',
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius: '8px',
                                                                },
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    required
                                                                    {...params}
                                                                    label="Select Plans"
                                                                    sx={{
                                                                        '& .MuiOutlinedInput-root': {
                                                                            borderRadius: '8px',
                                                                        },
                                                                        '& .MuiInputLabel-root': { fontSize: '13px', fontFamily: 'Montserrat', },
                                                                        '& .MuiInputLabel-root.Mui-focused': { fontSize: '13px', fontFamily: 'Montserrat', },
                                                                        '& input': {
                                                                            fontFamily: 'Montserrat',
                                                                        },
                                                                    }}

                                                                />
                                                            )}
                                                            ListboxProps={{
                                                                sx: { fontSize: '13px', fontFamily: 'Montserrat', borderRadius: '30px' },
                                                            }}
                                                            noOptionsText="No Plans"
                                                            value={selectedPlan?.map((plan: any) => plan?.name) || []}
                                                            onChange={(event, newValue) => {
                                                                if (newValue.includes('Select All')) {
                                                                    if ((selectedPlan?.length || 0) === (plansList?.length || 0)) {
                                                                        setSelectedPlan([]);
                                                                    } else {
                                                                        setSelectedPlan(plansList || []);
                                                                    }
                                                                } else {
                                                                    const selectedPlansObjects = plansList?.filter((plan) =>
                                                                        newValue.includes(plan?.name)
                                                                    );
                                                                    setSelectedPlan(selectedPlansObjects || []);
                                                                }
                                                            }}
                                                            renderOption={(props, option, { selected }) => {
                                                                const isSelectAll = option === 'Select All';
                                                                const isAllSelected = (selectedPlan?.length || 0) === (plansList?.length || 0);
                                                                const isSomeSelected =
                                                                    selectedPlan?.length > 0 && selectedPlan?.length < (plansList?.length || 0);

                                                                return (
                                                                    <li {...props}>
                                                                        <Checkbox
                                                                            checked={isSelectAll ? isAllSelected : selected}
                                                                            indeterminate={isSelectAll ? isSomeSelected : false}
                                                                            sx={{
                                                                                marginRight: 1,
                                                                                padding: 0,
                                                                                color: '#646464',
                                                                                borderRadius: '0px',
                                                                                '& .MuiSvgIcon-root': {
                                                                                    fontSize: 18,
                                                                                    border: '1px solid #000000',
                                                                                    borderRadius: '4px',
                                                                                    color: isSelectAll && isAllSelected ? '#2653A3' : selected ? '#2653A3' : 'transparent',
                                                                                    backgroundColor: isSelectAll && isAllSelected ? '#e3f2fd' : selected ? '#e3f2fd' : 'transparent',
                                                                                },
                                                                                '&:hover .MuiSvgIcon-root': {
                                                                                    borderColor: '#1976d2',
                                                                                },
                                                                            }}
                                                                        />
                                                                        {option}
                                                                    </li>
                                                                );
                                                            }}
                                                            renderTags={(value, getTagProps) => {
                                                                const visiblePlans = value.slice(0, 1);
                                                                const remainingPlansCount = value.length - 1;
                                                                return [
                                                                    ...visiblePlans.map((option, index) => (
                                                                        <Chip
                                                                            label={option}
                                                                            {...getTagProps({ index })}
                                                                            sx={{ fontSize: '12px', height: '3vh' }}
                                                                        />
                                                                    )),
                                                                    remainingPlansCount > 0 && (
                                                                        <Chip
                                                                            label={`+${remainingPlansCount}`}
                                                                            sx={{ fontSize: '12px', height: '3vh' }}
                                                                        />
                                                                    ),
                                                                ];
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>

                                            ) : (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontFamily: 'Montserrat', }}>
                                                            No pricing for this course
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid> */}
                                    </Grid>
                                    <Grid container sx={{ mt: 2, pb: { xs: 5, md: 2 } }}>
                                        <Grid item xs={12} md={12} sx={{ ml: 1 }}>
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                display={'flex'}
                                                alignItems={'center'}
                                            >
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: '#3C5AA0',
                                                        textTransform: 'capitalize',
                                                        border: 'none',
                                                        borderRadius: '8px',
                                                        width: '150px',
                                                        boxShadow: 'none',
                                                        fontFamily: 'Montserrat',
                                                        color: 'white',
                                                        '&:hover': {
                                                            backgroundColor: '#3C5AA0',
                                                            cursor: 'pointer',
                                                            color: 'white',
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                    onChange={(e: any) => setCourseValues(e.target.value)}
                                                    onClick={(e) => {
                                                        if (courseValues?.name && courseValues?.description) {
                                                            handleSubmit(e, courseValues?.status || 'CREATED');
                                                        } else {
                                                            if (
                                                                courseValues?.name &&
                                                                !courseValues?.description
                                                            ) {
                                                                setErrorMessage(
                                                                    '*Please enter a Course description before submitting'
                                                                );
                                                            } else {
                                                                setErrorMessage(
                                                                    '*Please enter a Course name before submitting'
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {id ? 'Update' : "Create"}
                                                </Button>
                                                <Typography sx={{ color: 'red', fontFamily: 'Montserrat', fontSize: '13px' }}>
                                                    {' '}
                                                    {errorMessage}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        </Box>
                    </Stack>
                )}
            </div>
        </>
    );
};
