import { Card, CardContent, CardMedia, Paper, Stack, Typography } from '@mui/material';

// Use lowercase for the function name
export const NoSubscribers = () => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          // height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          spacing={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ paddingBottom: '10px', p: 2 }}
        >
          <Card
            elevation={0}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <CardMedia
              component="img"
              alt="No Subscriber"
              image={require('./../../assets/icons/svg-icons/noPayments.png')}
              sx={{
                objectFit: 'contain',
                width: '100%',
                height: '50vh',
              }}
            />
          </Card>
          <CardContent sx={{ display: { xs: 'none', md: 'block' } }}>
            <h1
              style={{
                color: '#777777',
                fontSize: '15px',
                fontWeight: '500',
                fontFamily: 'Montserrat',
              }}
            >
              No Subscribers Available{' '}
            </h1>
          </CardContent>
          <CardContent sx={{ display: { xs: 'block', md: 'none' } }}>
            <h1
              style={{
                color: '#777777',
                fontSize: '13px',
                fontWeight: '500',
                fontFamily: 'Montserrat',
              }}
            >
              No Subscribers Available{' '}
            </h1>
          </CardContent>
        </Stack>
      </Paper>
    </>
  );
};
