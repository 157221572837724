import { useEffect, useState } from 'react';
import { usePlans } from '../../hooks/usePlans';
import store from '../../store';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { metaConfig } from '../../utils/constants';
import { SubNavbar } from '../Layout/components/SubNavbar';
import {
  DeleteIcon,
  EditIcon,
  SearchIcon1,
  Share1Icon,
} from '../../assets/icons';
import {
  Close,
  RemoveRedEyeOutlined,
} from '@mui/icons-material';
import { IMultiMedia } from '../../models/multi-media.model';
import { getStaticValue } from '../../utils/StaticValues';
import { format } from 'date-fns';
import SimpleBar from 'simplebar-react';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Loader from '../Loader/Loader';
import { NoPlans } from '../AlternatePages/noPlans';
import { CreatePlan } from './CreatePlan';
import { USER_APP_BASE_URL } from '../../configurations/url.config';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import React from 'react';
import { CirclePlus } from 'lucide-react';
import { useSnackbar } from 'notistack';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';

export interface IPlan {
  slice(arg0: number, arg1: number): unknown;
  map(arg0: (plan: any, planIdx: any) => JSX.Element): React.ReactNode;
  _id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  duration: string;
  interval: string;
  pricing: number;
  startDate: string;
  offerValue: number;
  community: string;
  image: IMultiMedia | '';
  document: IMultiMedia | '';
  subscribers: string;
  initialPayment: string;
}

export const PlansList = () => {


  const { getPlansList } = usePlans();
  const [openPlans, setOpenPlans] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState<DialogProps['maxWidth']>('lg');
  const state = store.getState();
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedItemId, setSelectedItemId] = React.useState<string | null>(null);
  const selectedCommunity = useSelector(() => { return state?.selectedCommunity; });
  const communitiesSuggestion = useSelector(() => { return state?.communities; });




  let filteredSelectedCommunity: any = [];
  if (Array.isArray(communitiesSuggestion?.communities)) {
    filteredSelectedCommunity = communitiesSuggestion.communities.filter(
      (community: any) =>
        community._id === selectedCommunity.selectedCommunity?._id
    );
  }
  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const loadPlans = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const plans_data = await getPlansList(selectedCommunityId);
        // console.log(plans_data, "plans_data");
        if (plans_data) {
          setIsLoading(false);
          setPlans(plans_data);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadPlans();
  }, [selectedCommunityId]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClickOpenPlans = () => {
    setOpenPlans(true);
  };
  const handleClose = () => {
    setOpenPlans(false);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const filteredPlans = plans.filter((plan) =>
    plan?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [openPreview, setOpensPreview] = useState(false);
  const [opens, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [copied, setCopied] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(null);
  const { deletePlanById } = usePlans();

  const formatDate = (dateString: string | number | Date) => {
    const originalDate = new Date(dateString);
    return format(originalDate, 'do MMM. yyyy');
  };

  const handleClickOpenPreview = () => {
    setOpensPreview(true);
  };

  const handleOpenPreview = (plan: any) => {
    setSelectedPlan(plan);
    setOpensPreview(true);
  };

  const handleClickClosesPreview = () => {
    setOpensPreview(false);
  };

  const handleDeletePlan = async (plan: any) => {
    setSelectedPlan(plan);
    await deletePlanById(plan?._id);
    setOpen(false);
    await loadPlans();
  };

  const handleCloses = () => setOpen(false);



  const handleClick = (event: React.MouseEvent<HTMLElement>, itemId: string) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setSelectedItemId(itemId);
  };

  const handleClosess = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };


  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>, row: IPlan) => {
    setOpenDelete(true);
    setAnchorEl(e.currentTarget);
  };


  const handleOpenDelete = (e: React.MouseEvent<HTMLButtonElement>, row: IPlan) => {
    setOpen(false);
    setAnchorEl(e.currentTarget);
  };


  const handleClickOpenPublish = () => {
    setOpenDelete(true);
  };

  const handleClickClosesPublish = () => {
    setOpenDelete(false);
  };



  const defaultOne = "https://upload-community-files.s3.ap-south-1.amazonaws.com/undefined/Plans 1.png"
  const defaultTwo = "https://upload-community-files.s3.ap-south-1.amazonaws.com/undefined/Planss.png"

  const handleCopyLink = (labelContent: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(labelContent)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
          enqueueSnackbar('Link copied successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
          enqueueSnackbar('Failed to copy link', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    } else {
      console.error('Clipboard API not supported');
      enqueueSnackbar('Clipboard API not supported', { variant: 'error' });
    }
  };


  return (
    <>
      <Helmet>
        <title>{metaConfig.title.plans}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>

      <Stack
        flexGrow={1}
        sx={{ display: { xs: 'flex', md: 'none' }, pb: 1 }}
        flexDirection={'row'}
        gap={1}
        display={'flex'}
        justifyItems={'center'}
        alignItems={'center'}
      >
        <Typography
          sx={{
            fontSize: { xs: '14px' },
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Montserrat',
          }}
        >
          Plans
        </Typography>
      </Stack>

      <Stack direction={'column'} spacing={1}>
        <SubNavbar>
          <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0}>
            <Stack
              direction={'row'}
              flexGrow={1}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: 600,
                  color: '#000000',
                  fontFamily: 'Montserrat',
                }}
              >
                Plans
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              flexGrow={1}
              sx={{ justifyContent: { xs: 'space-between', md: 'flex-end' } }}
              gap={2}
            >
              <TextField
                placeholder="Search "
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon1 sx={{ mt: 1 }} />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: '8px',
                    backgroundColor: '#F9F9F9',
                    fontSize: { xs: '12px', md: '15px' },
                    height: '37px',
                    width: { xs: '100%', md: '300px' },
                    color: '#000000',
                    fontFamily: 'Montserrat',
                    ml: { xs: -1 },
                    '& input::placeholder': {
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      color: '#9e9e9e',
                      opacity: 1,
                    },
                  },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {location.pathname.match('/plans') && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate('/create-plan');
                    }}
                    endIcon={<CirclePlus size={20} strokeWidth={1.5} color="white" />}
                    sx={{
                      textTransform: 'capitalize',
                      borderRadius: '8px',
                      backgroundColor: '#3C5AA0',
                      fontFamily: 'Montserrat',
                      boxShadow: 'none',
                      display: { xs: 'none', md: 'flex' },
                      '&:hover': {
                        backgroundColor: '#3C5AA0',
                        cursor: 'pointer',
                        border: 'none',
                      },
                    }}
                  >
                    Add New
                  </Button>
                  <IconButton
                    onClick={() => {
                      navigate('/create-plan');
                    }}
                    sx={{
                      textTransform: 'capitalize',
                      borderRadius: '8px',
                      backgroundColor: '#3C5AA0',
                      fontFamily: 'Montserrat',
                      boxShadow: 'none',
                      display: { xs: 'flex', md: 'none' },
                      '&:hover': {
                        backgroundColor: '#3C5AA0',
                        cursor: 'pointer',
                        border: 'none',
                      },
                    }}>

                    <CirclePlus size={20} strokeWidth={1.5} color="white" />
                  </IconButton>
                </>
              )}
              {(location.pathname.match(/^\/communities\/[a-zA-Z0-9]+$/) ||
                location.pathname.match(/^\/get-started\/[a-zA-Z0-9]+$/) ||
                location.pathname === '/get-started') && (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleClickOpenPlans}
                      endIcon={<CirclePlus size={20} strokeWidth={1.5} color="white" />}
                      sx={{
                        textTransform: 'capitalize',
                        borderRadius: '8px',
                        backgroundColor: '#3C5AA0',
                        fontFamily: 'Montserrat',
                        boxShadow: 'none',
                        display: { xs: 'none', md: 'flex' },
                        '&:hover': {
                          backgroundColor: '#3C5AA0',
                          cursor: 'pointer',
                          border: 'none',
                        },
                      }}
                    >
                      Add New
                    </Button>
                    <IconButton
                      onClick={handleClickOpenPlans}
                      sx={{
                        textTransform: 'capitalize',
                        borderRadius: '8px',
                        backgroundColor: '#3C5AA0',
                        fontFamily: 'Montserrat',
                        boxShadow: 'none',
                        display: { xs: 'flex', md: 'none' },
                        '&:hover': {
                          backgroundColor: '#3C5AA0',
                          cursor: 'pointer',
                          border: 'none',
                        },
                      }}>

                      <CirclePlus size={20} strokeWidth={1.5} color="white" />
                    </IconButton>
                  </>
                )}

              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={openPlans}
                onClose={handleClose}
              >
                <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ backgroundColor: '#F0F9FF' }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '16px', md: '18px' },
                        fontWeight: 600,
                        textDecoration: 'none',
                        color: '#3C5AA0',
                        marginLeft: 2,
                        fontFamily: 'Montserrat',
                      }}
                    >
                      {id ? 'Publish' : 'Create'} a Plan
                    </Typography>
                    <Box
                      onClick={handleClose}
                      sx={{
                        backgroundColor: '#3C5AA0',
                        padding: '4px',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '10px',
                        mt: '0.5px',
                        mr: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          cursor: 'pointer',
                          border: 'none',
                        },
                      }}
                    >
                      <Close sx={{ fontSize: '15px' }} />
                    </Box>
                  </Grid>
                </DialogActions>
                <DialogContent>
                  <CreatePlan
                    selectedCommunity={filteredSelectedCommunity}
                    closeDialog={handleClose}
                    loadPlans={loadPlans}
                  />
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </SubNavbar>
        <Box
          sx={{
            position: 'relative',
            height: { xs: '75vh', md: '85vh' },
            overflow: 'auto',
            mt: 1,
            backgroundColor: '#ffffff',
            p: 2,
            border: '1px solid #E7EBF1',
            borderRadius: '12px',
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Loader />
            </Box>
          ) : filteredPlans.length === 0 ? (
            <NoPlans />
          ) : (
            <Stack>
              <Grid container spacing={1} pb={5}>
                {filteredPlans.map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      sx={{
                        borderRadius: 3,
                        boxShadow: 'none',
                        border: '1px solid #E7EBF1',
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="150"
                        width={'100%'}
                        src={
                          plan?.image
                            ? plan.image.value
                            : index % 2 === 0
                              ? defaultOne
                              : defaultTwo
                        }
                        alt={plan.name}
                        sx={{ objectFit: 'cover', backgroundColor: '#f9f9f9' }}
                      />
                      <CardContent
                        sx={{
                          pb: 1,
                          '&:last-child': { paddingBottom: '5px' },
                        }}
                      >
                        <Stack
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Chip
                            label={plan?.interval + ' ' + getStaticValue(plan?.duration)}
                            sx={{
                              fontWeight: 600,
                              border: '1px solid #3C5AA0',
                              color: '#3C5AA0',
                              backgroundColor: 'transparent',
                              fontFamily: 'Montserrat',
                              textTransform: 'capitalize',
                            }}
                          />
                          <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                marginRight: '1px',
                                animation: 'wave 1s infinite alternate',
                                backgroundColor:
                                  plan?.status === 'CREATED'
                                    ? '#ff4f00'
                                    : '#27AE1B',
                              }}
                            ></span>
                            <Chip
                              label={getStaticValue(plan?.status)}
                              color="success"
                              size="small"
                              sx={{
                                fontWeight: 600,
                                color:
                                  plan?.status === 'CREATED'
                                    ? '#000000'
                                    : '#000000',
                                backgroundColor: 'transparent',
                                fontFamily: 'Montserrat',
                                fontSize: '13px',
                                textTransform: 'capitalize'
                              }}
                            />
                          </Stack>
                        </Stack>
                        <Tooltip
                          title={plan?.name}
                          arrow
                          placement={'top'}
                          slots={{
                            transition: Fade,
                          }}

                          slotProps={{
                            transition: { timeout: 600 },
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontFamily: "Montserrat",
                                textAlign: 'center'
                              },
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Montserrat',
                              fontSize: '16px',
                              fontWeight: 600,
                            }}
                          >
                            {plan?.name.length < 25
                              ? plan?.name
                              : plan?.name.slice(0, 25) + '..'}
                          </Typography>
                        </Tooltip>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 400,
                            height: '70px',
                          }}
                        >
                          {plan?.description?.length
                            ? plan.description.length > 150
                              ? plan.description.slice(0, 150) + '...'
                              : plan.description
                            : '\u00A0'}
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={1}
                          alignItems={'center'}
                        >
                          <Stack>
                            <Link to={'/subscribers'}>
                              <Typography
                                sx={{
                                  color: '#565656',
                                  fontWeight: '400',
                                  fontSize: '12px',
                                  textAlign: 'end',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                {plan?.subscribers?.length > 0
                                  ? `${plan.subscribers.length} Subscribers`
                                  : 'No Subscribers'}
                              </Typography>
                            </Link>
                          </Stack>
                          <Stack>
                            <Typography
                              sx={{
                                fontFamily: 'Montserrat',
                                fontSize: '13px',
                                fontWeight: 600,
                                color: '#000000',
                              }}
                            >
                              ₹ {plan?.pricing} / {plan?.interval}{" "}
                              {plan?.interval > "1"
                                ? `${getStaticValue(plan?.duration)}s`
                                : getStaticValue(plan?.duration)
                              }
                              {Number(plan?.initialPayment) > 0 && ` +  ₹ ${plan?.initialPayment} One Time`}
                            </Typography>
                          </Stack>
                        </Box>
                        <Divider sx={{ pt: 2 }} />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          flexDirection={'row'}
                          alignContent={'flex-end'}
                          sx={{ pt: 1 }}
                        >
                          <Stack>
                            <Typography
                              sx={{
                                fontFamily: 'Montserrat',
                                fontSize: '11px',
                                fontWeight: 400,
                              }}
                            >
                              Updated: {formatDate(plan.updatedAt)}
                            </Typography>
                          </Stack>

                          <Stack flexDirection={'row'} alignItems={'center'}>
                            {plan?.status === 'CREATED' && (
                              <Button
                                variant="contained"
                                sx={{
                                  textTransform: 'capitalize',
                                  background: '#3C5AA0',
                                  color: 'white',
                                  fontWeight: 500,
                                  borderRadius: '8px',
                                  boxShadow: 'none',
                                  fontSize: { xs: '12px', md: '14px' },
                                  height: { xs: '3vh', md: '4vh' },
                                  fontFamily: 'Montserrat',
                                  '&:hover': {
                                    backgroundColor: '#3C5AA0',
                                    cursor: 'pointer',
                                    border: 'none',
                                    boxShadow: 'none',
                                  },
                                }}
                                onClick={() =>
                                  navigate(`/edit-plan-new/${plan?._id}`)
                                }
                              >
                                Publish
                              </Button>
                            )}
                            {location.pathname === '/plans' && (
                              <>
                                <IconButton sx={{}}
                                  onClick={(event) => handleClick(event, plan?._id!)}>
                                  <MoreVertOutlinedIcon sx={{ fontSize: '20px', color: '#969696' }} />
                                </IconButton>
                                <Menu
                                  id={`menu-${index}`}
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl) && selectedItemId === plan?._id}
                                  onClose={handleClosess}
                                  elevation={1}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  sx={{
                                    '& .MuiPaper-root': {
                                      minWidth: '125px',
                                      borderRadius: '10px',
                                      border: '1px solid #D4DDED',

                                    },
                                  }}
                                >
                                  <MenuItem
                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                    onClick={() =>
                                      navigate(`/edit-plan-new/${plan?._id}`)
                                    }
                                    disabled={isLoading}
                                  >
                                    <EditIcon sx={{ fontSize: '15px', marginRight: 1 }} /> Edit
                                  </MenuItem>
                                  <MenuItem
                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: '30px' } }}
                                    component="a"
                                    target="_blank"
                                    onClick={() => handleOpenPreview(plan)}
                                    disabled={isLoading}
                                  >
                                    <RemoveRedEyeOutlined sx={{ fontSize: '13px', marginRight: 1 }} />Preview
                                  </MenuItem>
                                  <MenuItem
                                    disabled={plan?.status === 'CREATED' || isLoading}
                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                    component="a"
                                    target='_blank'
                                    href={`https://wa.me/?text=${encodeURIComponent(
                                      `Plan Details : ${`${USER_APP_BASE_URL}/plan/${plan?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${plan?._id}`}`
                                    )}`}

                                  >
                                    <Share1Icon disable={plan?.status === 'CREATED'} sx={{ fontSize: '13px', marginRight: 1 }} />  Share
                                  </MenuItem>
                                  <MenuItem
                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: '30px' } }}
                                    component="a"
                                    target="_blank"
                                    onClick={() => handleCopyLink(`${USER_APP_BASE_URL}/plan/${plan?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${plan?._id}`)}
                                    disabled={isLoading}
                                  >
                                    <ContentCopyOutlined sx={{ fontSize: '13px', marginRight: 1 }} /> Copy URL
                                  </MenuItem>
                                  <MenuItem
                                    sx={{ fontSize: '13px', fontFamily: 'Montserrat', minHeight: { xs: '20px', md: "30px" } }}
                                    component="a"
                                    target='_blank'
                                    onClick={(e: any) => {
                                      handleClickOpenPublish();
                                    }}
                                    disabled={plan?.status === 'ACTIVE' ||
                                      plan?.subscribers?.length > 1 || isLoading}
                                  >
                                    <DeleteIcon sx={{ fontSize: '13px', marginRight: 1 }} />  Delete
                                  </MenuItem>
                                </Menu>

                              </>
                            )}
                          </Stack>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          )}
        </Box>
      </Stack>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openPreview}
        onClose={handleClickOpenPreview}
      >
        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ backgroundColor: '#F0F9FF' }}
          >
            <Stack></Stack>
            <Stack></Stack>
            <Stack sx={{ backgroundColor: '#F0F9FF' }}>
              <Box
                onClick={handleClickClosesPreview}
                sx={{
                  backgroundColor: '#3C5AA0',
                  padding: '4px',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '10px',
                  mt: '0.5px',
                  mr: '10px',
                  cursor: 'pointer',
                  '&:hover': {
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}
              >
                <Close sx={{ fontSize: '15px' }} />
              </Box>
            </Stack>
          </Stack>
        </DialogActions>
        <DialogContent>
          <Box>
            <Paper sx={{}} elevation={0}>
              <Grid container spacing={{ xs: 1, sm: 1, md: 2 }}>
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <Stack sx={{ display: { xs: 'none', md: 'inline-block' } }}>
                    <CardMedia
                      component="img"
                      height="180"
                      src={
                        selectedPlan?.image
                          ? selectedPlan?.image?.value
                          : selectedCommunity?.selectedCommunity?.logo
                      }
                      sx={{
                        width: '100%',
                        height: '25vh',
                        objectFit: 'cover',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                    />
                  </Stack>
                  <Stack sx={{ display: { xs: 'inline-block', md: 'none' } }}>
                    <Chip
                      label={
                        selectedPlan?.interval + '  ' + +' - Starts From - '
                      }
                      sx={{
                        width: { xs: '100%', md: '50%' },
                        fontWeight: 600,
                        border: '1px solid #3C5AA0',
                        backgroundColor: 'transparent',
                        fontSize: '14px',
                      }}
                    ></Chip>
                  </Stack>
                </Grid>

                <Grid item xs={5} md={7} lg={7} xl={7}>
                  <Stack direction={'column'} spacing={1}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: '16px', md: '20px' },
                        color: '#000000',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      {selectedPlan?.name}
                    </Typography>
                    <SimpleBar style={{ height: '150px', overflowY: 'auto' }}>
                      <Typography
                        variant="caption"
                        paragraph={true}
                        className="description"
                        sx={{
                          color: '#565656',
                          fontSize: '13px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          pr: 2,
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {showFullDescription
                          ? selectedPlan?.description
                          : selectedPlan?.description?.slice(0, 500) + ' '}
                      </Typography>
                    </SimpleBar>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={2} lg={2} xl={2} sx={{}}>
                  <Stack
                    display={'flex'}
                    justifyContent={'right'}
                    alignItems={'center'}
                    direction={'row'}
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        marginRight: '4px',
                        animation: 'wave 1s infinite alternate',
                        backgroundColor:
                          selectedPlan?.status === 'CREATED'
                            ? '#ff4f00'
                            : '#27AE1B',
                      }}
                    ></span>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '11px',
                          md: '13px',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          color:
                            selectedPlan?.status === 'CREATED'
                              ? '#ff4f00'
                              : '#27AE1B',
                        },
                      }}
                    >
                      {getStaticValue(selectedPlan?.status || '')}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{ flexGrow: 2 }}
                    direction={'row'}
                    display={'flex'}
                    alignItems={'end'}
                  >
                    <Stack
                      direction={'row'}
                      alignItems="end"
                      justifyContent={'end'}
                      spacing={1}
                      sx={{ flexGrow: 1, mr: 0 }}
                    >
                      <GroupOutlinedIcon
                        sx={{ color: '#787878', fontSize: '20px' }}
                      />
                      <Stack
                        alignItems={'center'}
                        spacing={1}
                        justifyContent={'right'}
                      >
                        <Link to={'/subscribers'}>
                          <Typography
                            sx={{
                              color: '#565656',
                              fontWeight: '400',
                              fontSize: '12px',
                              textAlign: 'end',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {selectedPlan?.subscribers?.length
                              ? `${selectedPlan.subscribers.length} Subscribers`
                              : 'No Subscribers'}
                          </Typography>
                        </Link>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack
                    direction={'column'}
                    display={'flex'}
                    alignItems={'flex-end'}
                    justifyContent={'flex-end'}
                    sx={{ mt: 1 }}
                  >
                    {selectedPlan?.initialPayment && (
                      <Typography
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#000000',
                        }}
                      >
                        ₹  {Number(selectedPlan?.initialPayment) > 0 && ` ${selectedPlan?.initialPayment} One Time`}
                      </Typography>
                    )}

                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#000000',
                      }}
                    >
                      ₹ {selectedPlan?.pricing} /{' '}
                      {getStaticValue(selectedPlan?.duration || '')}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </DialogContent>
      </Dialog>

      {/* <Popover
        id={id}
        open={openDelete}
        anchorEl={anchorEl}
        onClose={handleCloses}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: '#494949',
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '600',
            fontFamily: 'Montserrat',
          }}
        >
          Are you sure?
        </Typography>

        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Montserrat',
            padding: '0px 16px 16px 16px',
            maxWidth: '275px',
            color: '#565656',
          }}
        >
          Do you really want to delete this plan?
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '15px',
              textAlign: 'center',
              fontFamily: 'Montserrat',
            }}
          >
            {selectedPlan?.name}
          </Typography>
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloses}
            sx={{
              textTransform: 'capitalize',
              borderColor: '#3C5AA0',
              color: '#3C5AA0',
              '&:hover': {
                borderColor: '#3C5AA0',
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeletePlan}
              sx={{
                backgroundColor: ' #3C5AA0',
                boxShadow: 'none',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#3C5AA0',
                  borderColor: '#3C5AA0',
                  boxShadow: 'none',
                },
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover> */}


      <Dialog

        fullWidth={fullWidth}
        maxWidth={'xs'}
        open={openDelete}
        onClose={handleClickClosesPublish}
      >
        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: '#F0F9FF' }}
          >
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: 500,
                textDecoration: 'none',
                color: '#3C5AA0',
                fontFamily: 'Montserrat',

                marginLeft: 2,
              }}
            >
              {'Delete Plan?'}
            </Typography>
            <Box
              onClick={handleClickClosesPublish}
              sx={{
                backgroundColor: '#3C5AA0',
                padding: '4px',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '10px',
                mt: '0.5px',
                mr: '10px',
                cursor: 'pointer',
                '&:hover': {
                  cursor: 'pointer',
                  border: 'none',
                },
              }}
            >
              <Close sx={{ fontSize: '15px' }} />
            </Box>
          </Grid>
        </DialogActions>

        <DialogContent>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '15px',
              fontFamily: 'Montserrat',
              padding: '0px 16px 16px 16px',
              color: '#565656',
            }}
          >
            Do you really want to delete this plan?
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '15px',
                textAlign: 'center',
                fontFamily: 'Montserrat',
              }}
            >
              {selectedPlan?.name}
            </Typography>
          </Typography>
        </DialogContent>

        <DialogActions >
          <Button
            // onChange={(e: any) => setPlanValues(e.target.value)}
            // onClick={(e) => handleSubmit(e, 'ACTIVE')}
            size="large"
            variant="contained"
            sx={{
              backgroundColor:
                '#3C5AA0 ',
              textTransform: 'capitalize',
              border: 'none',
              borderRadius: '8px',
              boxShadow: 'none',
              color: 'white',
              fontFamily: 'Montserrat',
              '&:hover': {
                backgroundColor:
                  '#3C5AA0',
                cursor: 'pointer',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            {/* {loadingButton ? (
              <CircularProgress
                size={24}
                sx={{ color: 'white' }}
              />
            ) : (
              'Publish'
            )} */}
            Delete
          </Button>
          <Button
            size="large"
            sx={{
              textTransform: 'capitalize',
              // fontWeight:600,
              color: '#3C5AA0',
              border: '1px solid #3C5AA0',
              borderRadius: '8px',
              boxShadow: 'none',
              fontFamily: 'Montserrat',
              '&:hover': {
                cursor: 'pointer',
                border: '1px solid #3C5AA0',
                boxShadow: 'none',
              },
            }}
            onClick={handleClickClosesPublish}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
