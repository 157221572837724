import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Stack,
  IconButton,
  CardContent,
  Card,
  Menu,
  MenuItem,
  Button,
  Popover,
  Box,
} from '@mui/material';
import course from '../../../assets/images/courses/courseIcon@2x.png';
import { MoreVert } from '@mui/icons-material';
import { PublishIconSvg } from '../../../assets/icons';
import CustomButton from './CustomButton';
import { useCourses } from '../../../hooks/useCourses';
import { useNavigate } from 'react-router-dom';







interface CourseInfoCardProps {
  data: any;
  refetchCourseData: () => void;
}

const CourseInfoCard: React.FC<CourseInfoCardProps> = ({
  data,
  refetchCourseData,
}) => {
  const courseData = data;
  console.log(courseData, "data")
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { updateCourse, deleteCourseByIds } = useCourses();
  const [deleteCourseAnchor, setDeleteCourseAnchor] =
    useState<null | HTMLElement>(null);
  const openDeleteCoursePopOver = Boolean(deleteCourseAnchor);
  const idDeleteCourse = openDeleteCoursePopOver ? 'delete-popover' : undefined;
  const navigate = useNavigate();

  const handleStatus = async () => {
    setLoading(true);
    await updateCourse(courseData?.community?._id, courseData?._id, {
      status: 'PUBLISHED',
    });
    setLoading(false);
    refetchCourseData();
  };

  const handleStatusUnPublish = async () => {
    setLoading(true);
    await updateCourse(courseData?.community?._id, courseData?._id, {
      status: 'UN_PUBLISHED',
    });
    setLoading(false);
    handleClose();
    refetchCourseData();
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteCourse = (e: any) => {
    setDeleteCourseAnchor(e.currentTarget);
  };
  const handleCloseDelete = () => {
    setDeleteCourseAnchor(null);
  };

  const handleCourseDelete = async () => {
    setLoading(true);
    await deleteCourseByIds(courseData?.community?._id, courseData?._id);
    navigate('/courses');
    setLoading(false);
    refetchCourseData();
  };

  return (


    <>

      <Stack spacing={1}>
        <Grid container>
          <Grid item xs={12}>
            <Card elevation={0} sx={{ borderRadius: '8px !important', }}>
              <CardContent>
                <Stack
                  sx={{
                    display: { xs: 'block', md: 'flex' },
                    flexDirection: 'row',
                    px: 1,
                    alignItems: 'center',
                  }}
                >
                  <Box component={'img'}
                    src={
                      courseData?.coverImage ? courseData?.coverImage.value : course
                    }
                    sx={{
                      height: '156px',
                      width: { xs: '-webkit-fill-available', md: '250px' },
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                    alt={courseData?.coverImage?.label}
                  />

                  <Stack sx={{ pl: { xs: '5px', md: '20px' }, flexGrow: 1 }}>
                    <Stack
                      direction={'row'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >

                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '600',
                          fontSize: { xs: '13px', md: '15px' },
                        }}
                      >
                        {courseData?.name}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <IconButton aria-label="menu" onClick={handleClick}>
                          <MoreVert sx={{ fontSize: '18px' }} />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          {courseData?.status === 'UN_PUBLISHED' && (
                            <MenuItem
                              onClick={() => {
                                handleStatus();
                                handleClose();
                              }}
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                            >
                              Publish
                            </MenuItem>
                          )}
                          {courseData?.status === 'PUBLISHED' && (
                            <MenuItem
                              onClick={() => {
                                handleStatusUnPublish();
                              }}
                              sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                            >
                              UnPublish
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={(e: any) => {
                              handleDeleteCourse(e);
                            }}
                            sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </Stack>
                    </Stack>
                    <Typography sx={{
                      fontFamily: 'Montserrat',
                      fontSize: { xs: '12px', md: '15px' },
                      color: '#969696',
                      fontWeight: 600
                    }}>
                      {courseData?.amount > 0 && "Paid"}
                      {courseData?.amount > 0 && courseData?.plan?.length > 0 && " + "}
                      {courseData?.plan?.length > 0 && "Plan"}

                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '11px', md: '13px' },
                        lineHeight: '1.5',
                        marginBottom: '2px',
                        // width: {'80%',
                        color: '#000',
                      }}
                    >
                      {courseData?.description}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '12px', md: '14px' },
                        fontWeight: 600,
                        color: '#969696',
                      }}
                    >

                      Plans Linked :
                      {courseData?.plan && courseData.plan.length > 0 ? (
                        courseData.plan.map((names: any, index: number) => (
                          <span key={index}>
                            {" "}{names?.name}
                            {index < courseData.plan.length - 1 && ', '}
                          </span>
                        ))
                      ) : (
                        <span>{" "}No Plans</span> // Default fallback text
                      )}

                    </Typography>
                    <Stack
                      direction={'row'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 1
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '600',
                          fontSize: { xs: '13px', md: '15px' },
                          lineHeight: '1.5',
                          marginBottom: '5px',
                        }}
                      >
                        {courseData?.amount > 0 ? (
                          <>
                            ₹{courseData?.amount}
                          </>
                        ) : (
                          <>
                            No Price
                          </>
                        )}
                      </Typography>
                      {(courseData?.status === 'UN_PUBLISHED' || courseData?.status === 'CREATED') && (
                        <CustomButton
                          loading={loading}
                          children="Publish"
                          icon={<PublishIconSvg />}
                          background={'#2952A2'}
                          textColor="#fff"
                          onClick={handleStatus}
                        />
                      )}

                    </Stack>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Divider sx={{ mx: 2 }} />
          </Grid>
        </Grid>

        <Popover
          id={idDeleteCourse}
          open={openDeleteCoursePopOver}
          anchorEl={deleteCourseAnchor}
          onClose={() => handleCloseDelete()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography
            sx={{
              p: 2,
              color: '#494949',
              fontSize: '16px',
              textAlign: 'center',
              fontWeight: '600',
            }}
          >
            Are you sure?
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '11px',
              fontFamily: 'inter',
              padding: '0px 16px 16px 16px',
              maxWidth: '275px',
              color: '#565656',
            }}
          >
            Are you sure you want to delete section?
          </Typography>
          <Divider />
          <Stack direction="row" sx={{ padding: '16px' }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCloseDelete}
              sx={{
                textTransform: 'capitalize',
                borderColor: '#3C5AA0',
                color: '#3C5AA0',
                '&:hover': {
                  borderColor: '#3C5AA0',
                },
              }}
            >
              Cancel
            </Button>
            <Stack direction="row-reverse" flexGrow={1}>
              <Button
                variant="contained"
                size="small"
                onClick={handleCourseDelete}
                sx={{
                  background: 'linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: '#3C5AA0',
                    borderColor: '#3C5AA0',
                  },
                }}
              >
                {/* {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )} */}
                Delete
              </Button>
            </Stack>
          </Stack>
        </Popover>
      </Stack >
    </>
  );
};

export default CourseInfoCard;
