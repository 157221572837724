import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Tooltip, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux";
import { useLiveClasses } from "../../hooks/useLiveClassess";
import React, { useEffect } from "react";
import { ILiveClass } from "../../models/liveClasses.model";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';
import Loader from "../Loader/Loader";
import { USER_APP_BASE_URL } from "../../configurations/url.config";
import { VideoCameraFrontOutlined } from "@mui/icons-material";
import BreadcrumbDouble from "../AlternatePages/BreadCumsDouble";
import { CalendarDays, FileVideo, FolderUp, SquareUser } from "lucide-react";


const ClassDetails = () => {


    const { id } = useParams();

    // console.log(id, "id")

    const singleCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    let community_id = singleCommunity?._id;
    const { getClassById } = useLiveClasses();


    const [classDetails, setClassDetails] = React.useState<ILiveClass[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // console.log(classDetails, "classDetails")

    const fetchClassDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getClassById(community_id, id || "");
            if (Array.isArray(response)) {
                setClassDetails(response);
            } else {
                setClassDetails([]);
            }
        } catch (err) {
            console.error('Error fetching live classes:', err);
            setClassDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClassDetails();
    }, []);


    const defaultImages = 'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png'

    const navigate = useNavigate()

    return (
        <>
            {isLoading && <Loader />
            }
            {classDetails?.map((classDetail, index) => {
                return (
                    <>

                        <BreadcrumbDouble
                            items={[
                                { label: 'Meetings', path: '/meetings' },
                                { label: `${classDetail?.name}` }
                            ]}
                        />
                        <Box>
                            <Paper sx={{ p: 2, mt: 1, borderRadius: '10px' }} elevation={0}>
                                <Stack sx={{ backgroundColor: '#F0F9FF', p: { xs: 1, md: 5 }, borderRadius: '5px' }}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontFamily: 'Montserrat',
                                                    fontSize: { xs: '20px', md: '25px' },
                                                    color: '#3760A9'
                                                }}>
                                                {classDetail?.name}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '13px',
                                                    color: '#5E6C84',
                                                    fontFamily: 'Montserrat',

                                                }}>
                                                {classDetail?.description}
                                            </Typography>
                                            <Button
                                                size="medium"
                                                variant="outlined"
                                                sx={{
                                                    borderRadius: '5px',
                                                    fontFamily: 'Montserrat',
                                                    textTransform: 'capitalize',
                                                    color: '#3C5AA0',
                                                    border: '1px solid #3C5AA0',
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    mt: 2
                                                }}
                                                component="a"
                                                href={`https://wa.me/?text=${encodeURIComponent(
                                                    `Join the class using this link: ${`${USER_APP_BASE_URL}/live-class/${classDetail?._id}`}`
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Share
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6} >
                                            <Box
                                                component={'img'}
                                                src={classDetail?.coverImage?.value
                                                    ? `${classDetail.coverImage.value}`
                                                    : `${defaultImages}`}
                                                width="100%"
                                                height={200}
                                                sx={{ borderRadius: '10px', objectFit: 'cover' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>

                                <Paper sx={{ mt: 5, border: '1px solid #E9E9E9', borderRadius: '10px' }} elevation={0}>
                                    <Stack>
                                        <List>
                                            {[
                                                {
                                                    icon: <CalendarDays size={20} strokeWidth={1.5} />,
                                                    text: `${new Date(classDetail?.startDate).toLocaleString('en-GB', {
                                                        timeZone: 'Asia/Kolkata',
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: '2-digit',
                                                        hour12: false,
                                                    })} | ${classDetail?.startTime} - ${classDetail?.endTime}`

                                                },
                                                {
                                                    icon: classDetail?.coverNote?.value ? (
                                                        <a
                                                            href={classDetail?.coverNote?.value}
                                                            download
                                                            style={{ textDecoration: 'none', color: '#575757', flexWrap: 'wrap' }}
                                                        >
                                                            <Tooltip title="Download Document" placement="left-start">

                                                                <FolderUp size={20} strokeWidth={1.5} />
                                                            </Tooltip>
                                                        </a>
                                                    ) : (
                                                        <UploadFileOutlinedIcon />
                                                    ),
                                                    text: classDetail?.coverNote?.value || 'No document available',
                                                },
                                                {
                                                    icon: <FileVideo size={20} strokeWidth={1.5} />,
                                                    text: classDetail?.googleMeetLink,
                                                },
                                            ].map((item, index) => (
                                                <ListItem disablePadding key={index}>
                                                    <ListItemButton>
                                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        fontWeight: 500,
                                                                        color: '#565656',
                                                                        fontFamily: 'Montserrat',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                    }}
                                                                >
                                                                    {item.text}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>

                                        <List sx={{ backgroundColor: '#F2F2F2', borderRadius: '0px 0px 10px 10px ' }}>
                                            <ListItem disablePadding key={index}>
                                                <ListItemButton
                                                    sx={{
                                                        backgroundColor: '#F2F2F2',
                                                    }}>
                                                    <ListItemIcon>
                                                        <SquareUser size={20} strokeWidth={1.5} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#565656',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                By -  {classDetail?.instructorName}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </Stack>

                                </Paper>
                            </Paper>
                        </Box>

                    </>
                )
            })}

        </>
    )
}
export default ClassDetails