import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/system';
import { CustomTableHeader } from './components/CustomTableHeader';
import { IColumn } from '../../models/table.model';
import { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IPaymentNotify, ISubscribers } from '../../models/subscription.model';
import { useSubscription } from '../../hooks/useSubscription';
import Loader from '../Loader/Loader';
import { useUsers } from '../../hooks/useUsers';
import SimpleBar from 'simplebar-react';
import { getStaticValue } from '../../utils/StaticValues';
import { BellRing, ChevronDown, ChevronLeft, ChevronRight, EllipsisVertical } from 'lucide-react';
import { sendPaymentNotify } from '../../services/subscription.service';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { useSnackbar } from 'notistack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { NoSubscribers } from '../AlternatePages/NoSubscribers';


interface ITableProps {
  data: ISubscribers[];
  columns: IColumn[];
  subscriptionList: () => void;
}

export const SubscriptionListTable = ({
  columns,
  data = [],
  subscriptionList,
}: ITableProps) => {
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<ISubscribers[]>(data);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [, setDeleteSubscriber] = useState('');
  // const { changeSequenceStatusId, } = useSubscription();
  const { removeSubscriberUserById } = useUsers();
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [notify, setNotify] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();


  // console.log(notify, "notify")

  useEffect(() => {
    // console.log("Incoming data:", data);
    setTableData(Array.isArray(data) ? data : []);
  }, [data]);

  // console.log(tableData, "tableData");
  const sortedData = Array.isArray(tableData) ? [...tableData] : [];

  // console.log(sortedData, "sortedData");
  const [sortDirections, setSortDirections] = useState<{ [key: string]: 'asc' | 'desc' | undefined }>({});

  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
      let newSortedColumns: string[] = [];

      // Toggle the sorting direction for the clicked column
      if (prevSortDirections[columnTitle] === 'asc') {
        newSortDirections[columnTitle] = 'desc';
      } else {
        newSortDirections[columnTitle] = 'asc';
      }

      // Include the clicked column in the list of sorted columns
      newSortedColumns.push(columnTitle);
      setSortedColumns(newSortedColumns);
      return newSortDirections;
    });
  };

  const sortedColumnTitles = sortedColumns?.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );


  function getValueByDataKey(item: ISubscribers, dataKey: string): any {
    switch (dataKey) {
      case 'user':
        return item?.user?.firstName || '';
      case 'plan':
        return item?.plan?.name || '';
      case 'amount':
        return item?.plan?.offerValue || item?.plan?.totalPlanValue;
      case 'subscription':
        return item?.plan?.duration || '';
      case 'created':
        return item?.createdAt || '';
      case 'status':
        return item?.status || '';
      case 'actions':
        return '';
      default:
        return ''; // Default for unrecognized dataKeys
    }
  }


  const getDueDateStatus = (nextDueDate?: string) => {
    const isExpired = nextDueDate && new Date(nextDueDate) < new Date();
    return {
      label: isExpired ? "Expired" : "Active",
      color: isExpired ? "#EB3223" : "#05A231",
    };
  };

  const getDaysLeftColor = (daysLeft: number) => {
    if (daysLeft <= 3) return { bg: '#ff4d4d1a', color: '#000000' };
    if (daysLeft <= 7) return { bg: '#ffa5001a', color: '#000000' };
    if (daysLeft <= 10) return { bg: '#ffff001a', color: '#000000' };
    return { bg: '#10a00d1a', color: '#000000' };
  };

  const formatDate = (date?: string | Date) =>
    date && date !== "forever"
      ? new Date(date).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      : date === "forever"
        ? "Forever"
        : "Not Started";


  if (sortedColumnTitles.length > 0) {
    sortedData.sort((a: ISubscribers, b: ISubscribers) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns.find((col) => col?.title === columnTitle);
        if (column) {
          const { dataKey } = column;
          const aValue = getValueByDataKey(a, dataKey);
          const bValue = getValueByDataKey(b, dataKey);
          if (aValue != null && bValue != null) {
            if (sortDirection === 'asc') {
              return aValue.localeCompare ? aValue.localeCompare(bValue) : aValue - bValue;
            } else {
              return bValue.localeCompare ? bValue.localeCompare(aValue) : bValue - aValue;
            }
          }
        }
      }
      return 0;
    });
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    handleChangePage(null, 0);
  }, [data]);


  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);
  const navigate = useNavigate();

  // console.log(paginatedData, "paginatedData")

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    itemId: string
  ) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setSelectedItemId(itemId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  // const updateSequenceStatusByRow = async (
  //   communityID: string,
  //   sequenceID: string,
  //   subscription_status: string
  // ) => {
  //   setIsLoading(true);
  //   try {
  //     const updateResponse = await changeSequenceStatusId(
  //       communityID,
  //       sequenceID,
  //       subscription_status
  //     );
  //     setUpdateStatus(updateResponse);
  //     subscriptionList();
  //   } catch (err) {
  //     console.log('Error: ', err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const deleteSubscriber = async (planId: string, userId: string) => {
    setIsLoading(true);
    try {
      const deleteResponse = await removeSubscriberUserById(planId, userId);
      setDeleteSubscriber(deleteResponse?.data);
      subscriptionList();
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      setIsLoading(false);
    }
  };


  const NotifyPayment = async (subscriptionId: string) => {
    try {
      setNotify(true);
      const subscriptionNotify: IPaymentNotify = {
        notify: true,
      };
      const response = await sendPaymentNotify(getAccessToken(), subscriptionId || "", subscriptionNotify);
      enqueueSnackbar("Notification sent", { variant: "success" });
      return response;
    } catch (err) {
      console.log(err, "err");
    }
  };


  return (
    <>
      {/* Desktop Version */}
      <Stack sx={{ display: { xs: 'none', md: 'block' }, }}>
        <SimpleBar style={{ height: '76vh', overflowY: 'auto', borderRadius: '12px' }}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              height: {
                xs: 'calc(100vh - 32vh)',
                md: 'calc(100vh - 24vh)'
              },
              borderRadius: '12px !important',
              border: '1px solid #E7EBF1',
            }}
          >
            <Table
              sx={{
                '& th': {
                  fontSize: '12px',
                  fontWeight: 700,
                  color: '#565656',
                  p: 1.5,
                },
                '& td': {
                  fontSize: '15px',
                  fontWeight: 500,

                  color: '#1A1A1A',
                  p: '5px 10px',
                  '& .MuiTypography-root': { m: 0, fontSize: '11px' },
                },
              }}
            >
              <TableHead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  background: '#f9f9f9',
                }}
              >
                <TableRow sx={{}}>
                  {columns?.map((column: IColumn, i: number) => (
                    <TableCell
                      key={i}
                      onClick={() => handleSort(column.title)}

                    >
                      <CustomTableHeader
                        title={column.title}
                        sortDirection={sortDirections[column.title]}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody >
                {paginatedData?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center" sx={{ border: 'none', }}>
                      <NoSubscribers />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {paginatedData?.map((item: ISubscribers, index) => (
                      <TableRow hover key={index} >
                        <TableCell >
                          {columns[0].dataKey === 'user' && (
                            <>
                              <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                sx={{ cursor: 'pointer', width: '100px', display: { xs: 'none', md: 'block' } }}
                                onClick={() => navigate(`/subscribers/${item?._id}`)}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Avatar
                                    src={item?.user?.avatar}
                                    sx={{
                                      height: '35px',
                                      width: '35px',
                                      fontSize: '11px',
                                    }}
                                    alt={`${item?.user?.firstName}`}
                                  />
                                  <Typography
                                    style={{
                                      marginLeft: '8px',
                                      fontWeight: 600,
                                      fontSize: '11px !important',
                                      color: '#1A1A1A',
                                      flexDirection: 'row',
                                      minWidth: 100,
                                      textTransform: 'capitalize',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    <Tooltip
                                      title={item?.user?.firstName}
                                      placement="bottom"
                                      slots={{
                                        transition: Fade,
                                      }}
                                      arrow
                                      slotProps={{
                                        transition: { timeout: 600 },
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            fontFamily: "Montserrat",
                                            textAlign: 'center'
                                          },
                                        },
                                      }}
                                    >
                                      <Stack>
                                        {item?.user?.firstName?.length > 10 ? `${item?.user?.firstName?.substring(0, 10)}...` : `${item?.user?.firstName}`}
                                      </Stack>
                                    </Tooltip>
                                    <Typography sx={{
                                      fontSize: '10px',
                                      fontFamily: 'Montserrat',
                                      fontWeight: 400,
                                      display: 'flex',
                                      color: '#969696'
                                    }}>
                                      {item?.user?.phoneNumber}
                                    </Typography>
                                  </Typography>

                                </div>
                              </Stack>

                              <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                sx={{ cursor: 'pointer', width: '100px', display: { xs: 'block', md: 'none' } }}
                                onClick={() => navigate(`/subscribers/${item?._id}`)}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Avatar
                                    src={item?.user?.avatar}
                                    sx={{
                                      height: '35px',
                                      width: '35px',
                                      fontSize: '11px',
                                    }}
                                    alt={`${item?.user?.firstName}`}
                                  />
                                  <Typography
                                    style={{
                                      marginLeft: '8px',
                                      fontWeight: 600,
                                      fontSize: '11px !important',
                                      color: '#1A1A1A',
                                      flexDirection: 'row',
                                      minWidth: 100,
                                      textTransform: 'capitalize',
                                      fontFamily: 'Montserrat'
                                    }}
                                  >
                                    <Tooltip
                                      title={item?.user?.firstName}
                                      placement="bottom"
                                      sx={{ textTransform: 'capitalize', }}
                                      slots={{
                                        transition: Fade,
                                      }}
                                      arrow
                                      slotProps={{
                                        transition: { timeout: 600 },
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            fontFamily: "Montserrat",
                                            textAlign: 'center'
                                          },
                                        },
                                      }}
                                    >
                                      <Stack>
                                        {item?.user?.firstName?.length > 8 ? `${item?.user?.firstName?.substring(0, 8)}...` : `${item?.user?.firstName}`}
                                      </Stack>
                                    </Tooltip>
                                  </Typography>

                                </div>
                              </Stack>
                            </>
                          )}
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>
                          {columns[1].dataKey === 'plan' && (
                            <Typography sx={{ textTransform: 'Capitalize', fontSize: '11px !important', fontFamily: 'Montserrat' }}>
                              <Tooltip
                                title={item?.plan?.name}
                                placement="bottom"
                                sx={{ textTransform: 'capitalize', }}
                                slots={{
                                  transition: Fade,
                                }}
                                arrow
                                slotProps={{
                                  transition: { timeout: 600 },
                                }}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontFamily: "Montserrat",
                                      textAlign: 'center'
                                    },
                                  },
                                }}
                              >
                                <Stack>
                                  {item?.plan?.name?.length > 8 ? `${item?.plan?.name?.substring(0, 8)}...` : `${item?.plan?.name}`}
                                </Stack>
                              </Tooltip>
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>
                          {columns[2].dataKey === 'amount' && (
                            <>
                              <Typography sx={{ fontFamily: 'Montserrat', }}>
                                ₹
                                {item?.plan?.offerValue || item?.plan?.totalPlanValue || item?.plan?.pricing}
                              </Typography>
                            </>
                          )}
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>
                          {columns[3].dataKey === 'subscription' && (
                            <Typography sx={{ fontFamily: 'Montserrat' }}>
                              {item?.plan?.interval}{' '}
                              {getStaticValue(item?.plan?.duration)}
                              { }
                            </Typography>
                          )}
                        </TableCell>


                        <TableCell sx={{ width: '100px' }}>
                          <Stack>
                            <Typography sx={{ fontFamily: 'Montserrat' }}>
                              {/* {item?.startDate} */}
                              {new Date(item?.startDate).toLocaleString('en-GB', {
                                timeZone: 'Asia/Kolkata',
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                              })}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            width: '100px',
                          }}
                        >
                          <Typography sx={{ fontFamily: 'Montserrat' }}>
                            {!item?.nextDueDate || item?.nextDueDate === ""
                              ? "Not Started"
                              : item?.nextDueDate === "forever"
                                ? "Forever"
                                : new Date(item?.nextDueDate).toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                })}
                          </Typography>
                        </TableCell>

                        <TableCell sx={{ width: '100px' }}>
                          <Stack
                            display={'flex'}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                            sx={{ boxShadow: 'none' }}
                          >
                            <Chip
                              size='small'
                              label={`${item?.daysLeft ? item.daysLeft : 0} Days`}
                              sx={{
                                width: '90%',
                                fontFamily: 'Montserrat',
                                fontSize: '11px',
                                backgroundColor: (() => {
                                  const daysLeft = Number(item?.daysLeft) || 0;
                                  return daysLeft <= 3
                                    ? '#ff4d4d1a'
                                    : daysLeft <= 7
                                      ? '#ffa5001a'
                                      : daysLeft <= 10
                                        ? '#ffff001a'
                                        : '#10a00d1a';
                                })(),
                                color: (() => {
                                  const daysLeft = Number(item?.daysLeft) || 0;
                                  return daysLeft <= 3
                                    ? '#000000'
                                    : daysLeft <= 7
                                      ? '#000000'
                                      : daysLeft <= 10
                                        ? '#000000'
                                        : '#000000';
                                })(),

                                borderRadius: '30px',
                                textTransform: 'capitalize',
                                padding: '4px 10px',
                                fontWeight: 500,
                              }}
                            />
                          </Stack>
                        </TableCell>

                        <TableCell sx={{ width: '100px' }}>
                          <Grid container>
                            <Grid item xs={12} md={12} lg={8}>
                              <Box
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: '400',
                                  boxShadow: 'none',
                                  textAlign: 'center',
                                  borderRadius: '4px',
                                  textTransform: 'capitalize',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  px: 1,
                                  py: 0.5,
                                }}
                              >
                                <Typography sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  color:
                                    item?.nextDueDate && new Date(item.nextDueDate) < new Date()
                                      ? '#EB3223'
                                      : '#05A231',
                                }}>
                                  <FiberManualRecordIcon sx={{
                                    height: '9px',
                                    width: '9px',
                                    color:
                                      item?.nextDueDate && new Date(item.nextDueDate) < new Date()
                                        ? '#EB3223'
                                        : '#05A231',
                                  }}
                                  />
                                  {item?.nextDueDate && new Date(item.nextDueDate) < new Date()
                                    ? 'Expired'
                                    : 'Active'}
                                </Typography>

                              </Box>
                            </Grid>
                          </Grid>
                        </TableCell>



                        <TableCell sx={{ width: '20px' }}>
                          <Stack
                            display={'flex'}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                            sx={{ boxShadow: 'none' }}>

                            <Button
                              sx={{
                                color: '#3C5AA0',
                                textTransform: 'capitalize',
                                fontWeight: 500,
                                fontFamily: 'Montserrat',

                              }}
                              onClick={() => navigate(`/subscribers/${item?._id}`)}
                            >
                              Details
                            </Button>
                            <Tooltip
                              title={`Send the payment link for ${item?.plan?.name} subscription to ${item?.user?.firstName} via WhatsApp and email for payment completion.`}
                              slots={{
                                transition: Fade,
                              }}
                              arrow
                              slotProps={{
                                transition: { timeout: 600 },
                              }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontFamily: "Montserrat",
                                    textAlign: 'center'
                                  },
                                },
                              }}
                            >
                              <IconButton
                                onClick={async () => {
                                  await NotifyPayment(item?._id);
                                }}>
                                <BellRing
                                  style={{ color: '#646464' }}
                                  size={20} strokeWidth={1.5}
                                />
                              </IconButton>
                            </Tooltip>
                            <IconButton
                              aria-controls={`menu-${index}`}
                              aria-haspopup="true"
                              onClick={(event) => handleClick(event, item?._id!)}
                            >

                              <EllipsisVertical size={20} strokeWidth={1.5} />
                            </IconButton>
                            <Menu
                              id={`menu-${index}`}
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl) && selectedItemId === item?._id}
                              onClose={handleClose}
                              elevation={1}
                            >
                              {/* {item?.subscription_status === 'ACTIVE' ? (
                          <MenuItem
                            sx={{ fontSize: '13px', fontFamily: 'Montserrat', }}
                            onClick={() => {
                              updateSequenceStatusByRow(
                                item?.community?._id,
                                item._id,
                                'STOP'
                              );
                              handleClose();
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? <Loader /> : 'Stop'}
                          </MenuItem>
                        ) : (
                          <>
                            <MenuItem
                              sx={{ fontSize: '13px' }}
                              onClick={() => {
                                updateSequenceStatusByRow(
                                  item?.community?._id,
                                  item._id,
                                  'ACTIVE'
                                );
                                handleClose();
                              }}
                              disabled={isLoading}
                            >
                              {isLoading ? 'Loading...' : 'Resume'}
                            </MenuItem>
                          </>
                        )} */}

                              <MenuItem
                                sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                                onClick={() => {
                                  navigate(`/edit-subscription/${item?._id}`)
                                }}
                                disabled={isLoading}
                              >
                                {isLoading ? <Loader /> : 'Edit'}
                              </MenuItem>

                              <MenuItem
                                sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}
                                onClick={() => {
                                  deleteSubscriber(item?.plan?._id, item?.user?._id);
                                  handleClose();
                                }}
                                disabled={isLoading}
                              >
                                {isLoading ? <Loader /> : 'Delete'}
                              </MenuItem>
                            </Menu>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </SimpleBar>
      </Stack>


      {/* Mobile Version */}

      <Stack
        sx={{
          display: { xs: 'block', md: 'none' },
          mb: 5,
          borderRadius: '12px !important',
          border: '1px solid #E7EBF1',
          // m: 1.5,

        }}
      >
        {paginatedData?.length === 0 ? (
          <TableRow>
            <TableCell colSpan={columns.length} align="center" sx={{ border: 'none', }}>
              <NoSubscribers />
            </TableCell>
          </TableRow>
        ) : (
          <>
            {paginatedData?.map((order: ISubscribers) => {
              const { label, color } = getDueDateStatus(order?.nextDueDate);
              const { bg, color: chipColor } = getDaysLeftColor(Number(order?.daysLeft) || 0);
              return (
                <Accordion
                  key={order?._id}
                  sx={{ boxShadow: 'none', borderRadius: '12px', backgroundColor: 'transparent' }}
                >
                  <AccordionSummary
                    expandIcon={<ChevronDown strokeWidth={1.5} size={20} />}
                    aria-controls="panel-content"
                    id="panel-header"
                  >
                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                      {/* LEFT SIDE */}
                      <Grid item>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Avatar
                            src={order?.user?.avatar}
                            alt={order?.user?.firstName}
                            sx={{ height: 30, width: 30, fontSize: '11px' }}
                          />
                          <Stack>
                            <Typography fontSize={12} fontWeight={600} fontFamily="Montserrat">
                              {order?.user?.firstName}
                            </Typography>
                            <Typography fontSize={10} fontWeight={400} fontFamily="Montserrat" color="#969696">
                              {order?.user?.phoneNumber}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack direction="row" alignItems="center" gap={3} mt={2}>
                          <Stack>
                            <Typography fontSize={12} color="#969696" fontWeight={500} fontFamily="Montserrat">Amount</Typography>
                            <Typography fontSize={12} fontWeight={500} fontFamily="Montserrat">₹ {order?.plan?.pricing}</Typography>
                          </Stack>

                          <Stack>
                            <Typography fontSize={12} color="#969696" fontWeight={500} fontFamily="Montserrat">Cycle</Typography>
                            <Typography fontSize={12} fontWeight={500} fontFamily="Montserrat">
                              {order?.plan?.interval} {getStaticValue(order?.plan?.duration)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>

                      {/* RIGHT SIDE */}
                      <Grid item>
                        <Typography fontSize={12} fontWeight={500} fontFamily="Montserrat" color={color} textAlign="end">
                          <FiberManualRecordIcon sx={{ height: 9, width: 9, color }} /> {label}
                        </Typography>
                        {/* <Chip
                          label={
                            <Stack direction="row" alignItems="center" gap={1}>
                              <FiberManualRecordRounded sx={{ fontSize: 10, color }} />
                              <Typography sx={{ fontSize: '12px', fontWeight: 500, color, fontFamily: 'Montserrat' }}>{label}</Typography>
                            </Stack>
                          }
                        /> */}

                        <Stack direction="row" alignItems="center" gap={0.5} mt={2}>
                          <Tooltip
                            title={`Send the payment link for ${order?.plan?.name} subscription to ${order?.user?.firstName}`}
                            arrow
                            slots={{ transition: Fade }}
                            slotProps={{ transition: { timeout: 600 } }}
                            componentsProps={{
                              tooltip: { sx: { fontFamily: 'Montserrat', textAlign: 'center' } },
                            }}
                          >
                            <IconButton onClick={async () => await NotifyPayment(order?._id)}>
                              <BellRing size={20} strokeWidth={1.5} style={{ color: '#646464' }} />
                            </IconButton>
                          </Tooltip>

                          <Chip
                            size="small"
                            label={`${order?.daysLeft ?? 0} Days`}
                            sx={{
                              fontSize: 12,
                              fontWeight: 500,
                              fontFamily: 'Montserrat',
                              backgroundColor: bg,
                              color: chipColor,
                              borderRadius: '30px',
                              padding: '4px 10px',
                              textTransform: 'capitalize',
                            }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container justifyContent="space-between">
                      {/* LEFT */}
                      <Grid item>
                        <Stack direction="row" gap={3}>
                          <Stack>
                            <Typography fontSize={12} fontWeight={500} fontFamily="Montserrat" color="#969696">Plan</Typography>
                            <Typography fontSize={12} fontWeight={500} fontFamily="Montserrat">₹ {order?.plan?.name}</Typography>
                          </Stack>
                        </Stack>

                        <Stack direction="row" gap={3} mt={1}>
                          <Stack>
                            <Typography fontSize={12} fontFamily="Montserrat" fontWeight={500} color="#969696">Due Date</Typography>
                            <Typography fontSize={12} fontFamily="Montserrat" fontWeight={500}>{formatDate(order?.nextDueDate)}</Typography>
                          </Stack>
                        </Stack>
                      </Grid>

                      {/* RIGHT */}
                      <Grid item>
                        <Stack>
                          <Typography fontSize={12} fontFamily="Montserrat" fontWeight={500} color="#969696">Start Date</Typography>
                          <Typography fontSize={12} fontWeight={500} fontFamily="Montserrat">
                            {formatDate(order?.startDate)}
                          </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" gap={0.5} mt={1}>
                          <Button
                            sx={{
                              color: '#3C5AA0',
                              textTransform: 'capitalize',
                              fontWeight: 500,
                              fontFamily: 'Montserrat',
                            }}
                            onClick={() => navigate(`/subscribers/${order?._id}`)}
                          >
                            Details
                          </Button>

                          <IconButton
                            aria-controls={`menu-${order._id}`}
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, order?._id!)}
                          >
                            <EllipsisVertical size={20} strokeWidth={1.5} />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
        )}
      </Stack>


      <Stack
        direction={'row-reverse'}
        alignItems={'center'}
        sx={{
          color: '#A5A5A5',
          fontSize: { xs: '10px', md: '1.5vh' },
          pr: { xs: '0px', md: '25px' },
          mb: { xs: 12, md: 0 }

        }}
      >
        <Typography
          sx={{
            color: '#A5A5A5',
            fontSize: '13px',
            display: { xs: 'none', md: 'block' },
            fontFamily: 'Montserrat',
          }}
        >
          Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{' '}
          {data.length} entries
        </Typography>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>View</span>
          }
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: { border: '1px solid #A5A5A5', borderRadius: '5px', fontFamily: 'Montserrat', fontSize: '14px' },
            },
            MenuProps: {
              PaperProps: { style: { border: '1px solid #A5A5A5', fontFamily: 'Montserrat', } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(data.length / rowsPerPage);

            return (
              <Stack
                direction={'row'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
              >
                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)}
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <ChevronLeft size={20} strokeWidth={1.5} />
                </IconButton>

                {[...Array(3)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: '14px',
                          border:
                            page === pageNumber ? '1px solid #3C5AA0' : 'none',
                          color: page === pageNumber ? '#3C5AA0' : '#000000',
                          width: page === pageNumber ? '30px' : '30px',
                          height: page === pageNumber ? '30px' : '30px',
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  } else {
                    return null;
                  }
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)}
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >

                  <ChevronRight size={20} strokeWidth={1.5} />
                </IconButton>
              </Stack>
            );
          }}
        />
      </Stack>
    </>
  );
};
